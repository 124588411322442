import React, { useEffect, useState } from "react";
import Back from "../../../components/UI/Back";

// config import
import config from "../../../config/appConfig";

// material ui and rsuite import
import { Button, Container, Divider, Typography } from "@mui/material";
import { Row, Col, Table } from "react-bootstrap";
import { useTheme, useMediaQuery } from "@mui/material";
import { DateRangePicker } from "rsuite";


// data table import
import DataTable from "react-data-table-component";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import { FaEye } from "react-icons/fa6";
import { FaRotateRight } from "react-icons/fa6";
import TooltipComponent from "../../../components/UI/Tooltip";
import ModalComponent from "../../../components/modal";
import MainButton from "../../../components/UI/extend/MainButton";


const PurchaseList = () => {

	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const [ data, setData ] = useState<any>([]);
	const [ isLoading, setIsLoading ] = useState<boolean>(true);
	const [ isViewPaymentModelOpen, setIsViewPaymentModelOpen ] = useState<boolean>(false);
	const [ selectedHistory, setSelectedModule ] = useState<any>(null);
	const [ selectedDateRange, setSelectedDateRange ] = useState<any>([ new Date(new Date().setDate(new Date().getDate() - 30)), new Date() ]);
	const Ranges: any = [
    	{
    		label: <div className="sw-date-range-option">Today</div>,
    		value: [ new Date(), new Date() ]
    	},
    	{
    		label: <div className="sw-date-range-option">Yesterday</div>,
    		value: [ new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1)) ]
    	},
    	{
    		label: <div className="sw-date-range-option">Last 7 Days</div>,
    		value: [ new Date(new Date().setDate(new Date().getDate() - 7)), new Date() ]
    	},
    	{
    		label: <div className="sw-date-range-option">Last 30 Days</div>,
    		value: [ new Date(new Date().setDate(new Date().getDate() - 30)), new Date() ]
    	},
    	{
    		label: <div className="sw-date-range-option">This Month</div>,
    		value: [ new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date() ]
    	},
    	{
    		label: <div className="sw-date-range-option">Last Month</div>,
    		value: [ new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0) ]
    	}
	];

	useEffect(() => {

		const fromDate = new Date(new Date().setDate(new Date().getDate() - 30));
		const toDate = new Date();

		fetchPaymentHistory(fromDate, toDate);


	}, []);

	const fetchPaymentHistory = (fromDate: Date, toDate: Date) => {

    	setIsLoading(true);

		const payload: any = {};

		if (fromDate && toDate) {

			payload["fromDate"] = fromDate;
			payload["toDate"] = toDate;

		}
		
    	ApiRequest("post", APIUrl.getPaymentHistory, payload).then((response: any) => {

    		setIsLoading(false);
    		// console.info(response);
    		setData(response);

    	}).catch((error: any) => {

    		console.error("Error fetching data", error);

    		setIsLoading(false);
    		setData([]);

    	}
    	);
	
	};
	
	// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
	const { DataTableStyles } = require("../../../Utils/utils");


	const cellRenderer = (text: any) => {

    	const cellText = String(text);

    	return <> {
    		cellText?.length > 25 ? <>
    			<TooltipComponent text={cellText} placement="top-start">
    				<div>{cellText.substring(0, 25) + "..."}</div>
    			</TooltipComponent>
    		</> : cellText
    	}
    	</>;

	};

	const generalSortFunction = (key: string) => (a: any, b: any) => {

    	const valueA = a[key];
    	const valueB = b[key];
	  
    	// Handle cases where values are not present
    	if (valueA === undefined || valueB === undefined) return 0;
	  
    	// If both values are numbers
    	if (!isNaN(valueA) && !isNaN(valueB)) {

		  return parseFloat(valueA) - parseFloat(valueB);
		
    	}
	  
    	// Otherwise, treat them as strings
    	return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
	  
	};

	const columns: any = [
    	{
    		name        : "Course Name",
    		selector    : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
    		cell        : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
    		sortable    : true,
    		center      : true,
    		grow        : 2,
    		sortFunction: generalSortFunction("courseName")
    	},
    	{
    		name        : "Module Name",
    		selector    : (row:any) => cellRenderer(row.purchasedModules ? row.purchasedModules : ""),
    		cell        : (row:any) => cellRenderer(row.purchasedModules ? row.purchasedModules : ""),
    		sortable    : true,
    		grow        : 2,
    		sortFunction: generalSortFunction("purchasedModules")
    	},
    	{
    		name        : "user",
    		selector    : (row:any) => cellRenderer(row.user ? row.user : ""),
    		cell        : (row:any) => cellRenderer(row.user ? row.user : ""),
    		sortable    : true,
    		grow        : 1.5,
    		sortFunction: generalSortFunction("user")

    	},
    	{
    		name        : "currency",
    		selector    : (row:any) => cellRenderer(row.currency ? row.currency : ""),
    		cell        : (row:any) => cellRenderer(row.currency ? row.currency : ""),
    		sortable    : true,
    		grow        : 1.5,
    		sortFunction: generalSortFunction("currency")

    	},
    	{
    		name        : "Amount",
    		center      : true,
    		grow        : 0.5,
    		selector    : (row:any) => row.totalAmount,
    		cell        : (row:any) => row.totalAmount,
    		sortable    : true,
    		sortFunction: generalSortFunction("totalAmount")
    	},
    	{
    		name        : "Status",
    		center      : true,
    		selector    : (row:any) => row.status,
    		cell        : (row:any) => row.status,
    		sortable    : true,
    		sortFunction: generalSortFunction("status")

    	},
    	{
    		name    : "Purchased At",
    		center  : true,
    		selector: (row: any) => {

    			const createdAt = row.createdAt ? new Date(row.createdAt).toLocaleString() : "";
				
    			return createdAt ?
				  <TooltipComponent text={createdAt} placement="top">
    					<div>{createdAt}</div>
				  </TooltipComponent>
				 : "";
			  
    		},
    		grow    : 1.5,
    		sortable: true
    	},
    	{
    		name  : "Action",
    		center: true,
    		cell  : (params: any)=>
    			<div className="sh-fontawesome-edit-delete">
    				<TooltipComponent text="View Payment details" placement="top-start" >
    					<span className="sh-fontawesome-edit">
    						<FaEye size={15} onClick={()=> {

    							setSelectedModule(params);
    							setIsViewPaymentModelOpen(true);
							
    						}} color={theme.palette.text.primary} />
    					</span>
    				</TooltipComponent>
    			</div>,
    		width: "100px"
            
    	}
  
	];

	const handleRotate = () => {

		if (selectedDateRange) {

			const fromDate = selectedDateRange[0];
			const toDate = selectedDateRange[1];

			fetchPaymentHistory(fromDate, toDate);

		} else {

			const fromDate = new Date(new Date().setDate(new Date().getDate() - 30));
			const toDate = new Date();
                
			fetchPaymentHistory(fromDate, toDate);
		
		}
	
	};

	const ViewHistoryModalContent = () => {

    	return <div>
    		<Row className="mb-3">
    			<Col>
    				<Typography variant="subtitle1"><strong>User  :</strong> {selectedHistory?.user}</Typography>
    				<Typography variant="subtitle1"><strong>Email :</strong> {selectedHistory?.userEmail}</Typography>
    			</Col>
    			<Col>
    				<Typography variant="subtitle1"><strong>Purchase Type:</strong> {selectedHistory?.purchaseType}</Typography>
    				<Typography variant="subtitle1"><strong>Status:</strong> {selectedHistory?.status}</Typography>
    			</Col>
    		</Row>
    		<Divider className="mb-3" />
    		<Row className="mb-3">
    			<Col>
    				<Typography variant="subtitle1"><strong>Course Name:</strong> {selectedHistory?.courseName}</Typography>
    				<Typography variant="subtitle1"><strong>Purchased Modules:</strong> {selectedHistory?.purchasedModules}</Typography>
    			</Col>
    		</Row>
    		<Divider className="mb-3" />
    		<Table
    			variant="dark"
    			striped bordered hover>
    			<thead>
    				<tr>
    					<th>Amount</th>
    					<th>Tax Amount</th>
    					<th>Total Amount</th>
    					<th>Currency</th>
    				</tr>
    			</thead>
    			<tbody>
    				<tr>
    					<td>{selectedHistory?.amount.toFixed(2)}</td>
    					<td>{selectedHistory?.taxAmount.toFixed(2)}</td>
    					<td>{selectedHistory?.totalAmount.toFixed(2)}</td>
    					<td>{selectedHistory?.currency}</td>
    				</tr>
    			</tbody>
    		</Table>
    		<Row className="mt-3">
    			<Col>
    				<Typography variant="subtitle2"><strong>Created At:</strong> {new Date(selectedHistory?.createdAt).toLocaleString()}</Typography>
    				<Typography variant="subtitle2"><strong>Updated At:</strong> {new Date(selectedHistory?.updatedAt).toLocaleString()}</Typography>
    			</Col>
    		</Row>
    	</div>;

	};

	const downloadCSV = (array: any) => {

    	const link = document.createElement("a");
    	let csv = convertArrayOfObjectsToCSV(array);
    	if (csv == null) return;
    
    	const filename = `purchase-history-${new Date().toLocaleDateString()}.csv`;
    
    	if (!csv.match(/^data:text\/csv/i)) {

    		csv = `data:text/csv;charset=utf-8,${csv}`;
		
    	}
    
    	link.setAttribute("href", encodeURI(csv));
    	link.setAttribute("download", filename);
    	link.click();
	
	};

	const convertArrayOfObjectsToCSV = (array: any) => {

    	let result: any;
    
    	const columnDelimiter = ",";
    	const lineDelimiter = "\n";
    	const keys = Object.keys(data[0]);
    
    	result = "";
    	result += keys.join(columnDelimiter);
    	result += lineDelimiter;
    
    	array.forEach((item: any) => {

    		let ctr = 0;
    		keys.forEach(key => {

    			if (ctr > 0) result += columnDelimiter;
    
    			result += item[key];
                
    			ctr++;
			
    		});
    		result += lineDelimiter;
		
    	});
    
    	return result;
	
	};

	return (
    
    	<Container>

    		<Row>
    			<Col md={6} sm={6} xl={6} xs={6} >
    				<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
    				>
    					<Back
    						props={{
    							route  : config.routes.dashboard,
    							state  : null,
    							toolTip: "Back to Admin Dashboard",
    							text   : "Back to admin dashboard"
    						}}
    					/>
    				</div>
    			</Col>
    			<Col
    				md={6} sm={6} xl={6} xs={6}
    				className="sh-refresh-rotate-icon">
    				<Button onClick={handleRotate} style={{ backgroundColor: theme?.palette?.secondary.main }} className="sh-list-lesson-refresh-icon-button sh-module-intro-add-btn">
    					<FaRotateRight color={theme.palette.text.primary} />
    				</Button>
    			</Col>
    		</Row>
			<Row className="sw-admin-purchase-history-header-con">
				<Col
					md={5} sm={5} xl={5} xs={5}
					className="admin-dash-board-back-title-con">
					<Typography variant="h2" >Purchase History</Typography>
				</Col>
				<Col
					md={7} sm={7} xl={7} xs={7}
					className="sw-admin-purchase-history-date-range-con"
				>
					<Row
						style={{ width: "100%" }}
					>
						<Col style={{ padding: 0 }} md={8} sm={8} xl={8} xs={8}>
							<DateRangePicker
								showOneCalendar
								value={selectedDateRange ? selectedDateRange : []}
								cleanable={true}
								onClean={() => setSelectedDateRange([])}
								style={{ width: "100%" }}
								placeholder="Select Date Range"
								ranges={Ranges}
								onChange={value => {

									setSelectedDateRange(value);

								}}
								onOk={(value: any) => {

									setSelectedDateRange(value);

								}}
							/>
						</Col>
						<Col md={2} sm={2} xl={2} xs={2}>
							<Button
								onClick={() => {

									if (selectedDateRange) {

										const fromDate = selectedDateRange[0];
										const toDate = selectedDateRange[1];

										fetchPaymentHistory(fromDate, toDate);

									}
								
								}}
								sx={{ width: "100%", fontSize: "12px" }}
								variant="contained"
								color="primary"
							>Fetch
							</Button>
						</Col>
						<Col style={{ padding: 0 }} md={2} sm={2} xl={2} xs={2}>
							<Button
								sx={{ width: "100%", fontSize: "12px" }}
								variant="contained"
								color="primary"
								onClick={() => downloadCSV(data)}
							>Download
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
    		<Row
    			style={{ marginTop: theme.spacing(2) }}
    			className="module-list-data-table">
    			<Col className="module-list-data-table-col">
    				<DataTable
    					columns={columns}
    					progressPending={isLoading}
    					progressComponent={<div className="sh-table-progress-con"><Typography variant="h4">Loading...</Typography></div>}
    					data={data}
    					customStyles={DataTableStyles}
    					fixedHeader
    					pagination
    					paginationPerPage={10}
    				/>
    			</Col>
    		</Row>
	
    		<ModalComponent
    			show={isViewPaymentModelOpen}
    			closeButton={true}
    			size="xl"
    			onHide={() => setIsViewPaymentModelOpen(false)}
    			modalTitle={`'${selectedHistory?.purchasedModules}' Payment Details`}
    			modalContent={<div>
    				<ViewHistoryModalContent />
    			</div>}
    			modalFooter={<div
    				style={{
    					display       : "flex",
    					justifyContent: "flex-end",
    					alignItems    : "center"
    				}}
    			>
    				<MainButton
    					type="scale"
    					direction="bottom"
    					style={{ position: "relative", marginRight: "10px" }}
    					offset={matchesXS ? 0 : 20}
    					scale={1.04}>
    					<Button
    						className="sh-module-intro-enroll-now-btn"
    						variant="outlined"
    						color="secondary"
    						onClick={() => setIsViewPaymentModelOpen(false)}
    					>Close
    					</Button></MainButton>
    			</div>
    			}
    		/>
    	</Container>
        
	);

};

export default PurchaseList;
