/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from "react";
import { useDispatch } from "../../../store";
import { Link, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
	Box,
	Button,
	Checkbox,
	Chip,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	// TextField,
	Typography
	// useMediaQuery
} from "@mui/material";

import { openSnackbar } from "../../../store/slices/snackbar";
import useAuth from "../../../Hooks/useAuth";
import { updateProfile, signOut } from "firebase/auth";
import { auth } from "../../../firebase";

// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FcGoogle } from "react-icons/fc";
import config from "../../../config/appConfig";

// Register form component

const JWTRegister = ({ ...others }) => {

	const theme: any = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// eslint-disable-next-line no-undef
	const { strengthIndicatorNumFunc, strengthColor } = require("../../../Utils/utils");
	const { createUser, sendEmailVerificationToFirebase, googleSignIn } = useAuth();

	// const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
	const [ showPassword, setShowPassword ] = useState(false);
	const [ checked, setChecked ] = useState(true);
	const [ strength, setStrength ] = useState(0);
	const [ level, setLevel ] = useState<any>();
  
	const [ formData, setFormData ] = useState({
		email    : "",
		password : "",
		firstName: "",
		lastName : ""
	});
	
	const [ formErrors, setFormErrors ] = useState({
		firstName: "",
		lastName : "",
		email    : "",
		password : "",
		submit   : "",
		isGoogle : ""
	});

	const [ submitting, setSubmitting ] = useState(false);

	const handleClickShowPassword = () => {

		setShowPassword(!showPassword);

	};

	const handleMouseDownPassword = (event: any) => {

		event.preventDefault();

	};

	const changePassword = (value: any) => {

		const temp = strengthIndicatorNumFunc(value);
		setStrength(temp);
		setLevel(strengthColor(temp));

	};

	useEffect(() => {

		changePassword("123456");

	}, []);

	const handleInputChange = (e: any) => {

		setSubmitting(false);
		setFormErrors((prevState: any) => {

			return { ...prevState, submit: "" };

		});

		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		setFormErrors((prevState: any) => {
        
			return { ...prevState, [name]: "" };
  
		});

		if (name === "password") {

			changePassword(value);


		}

	};

	const handleCheckboxChange = (event: any) => {

		setChecked(event.target.checked);

	};

	const validateForm = () => {

		const errors: any = {};
		if (!formData.firstName) errors.firstName = "First Name is required";
		if (!formData.lastName) errors.lastName = "Last Name is required";
		if (!formData.email) errors.email = "Email is required";
		if (!formData.password) errors.password = "Password is required";

		return errors;

	};

	const handleSubmit = async (e: any) => {

		setSubmitting(true);

		e.preventDefault();
		const errors = validateForm();
		if (Object.keys(errors).length === 0) {

			try {

				createUser(formData.email, formData.password).then(async () => {

					dispatch(
						openSnackbar({
							open   : true,
							message: "Your registration has been successfully completed. Please check your email for verification.",
							variant: "alert",
							alert  : {
								color: "success"
							},
							close: false
						})
					);

					navigate("/login", { replace: true });
			
					sendEmailVerificationToFirebase().then(() => {

						updateProfile(auth.currentUser as any, {
							displayName: `${formData.firstName}#_${formData.lastName}`
						}).then(async () => {

							setSubmitting(false);

							await signOut(auth);

						}).catch(async (err: any) => {

							await signOut(auth);

							setFormErrors((prevState: any) => {

								return { ...prevState, submit: err.message };

							});

						});


					}).catch(async (err: any) => {

						console.error(err);

						await signOut(auth);

						setFormErrors((prevState: any) => {

							return { ...prevState, submit: err.message };

						});

					});

				}).catch(async (err: any) => {

					await signOut(auth);

					console.error(err.message);

					if (err.message?.includes("Firebase: Error (auth/invalid-email).")) {

						setFormErrors((prevState: any) => {

							return { ...prevState, submit: "Invalid email" };

						});

					} else if (err.message?.includes("Firebase: Error (auth/email-already-in-use).")) {

						setFormErrors((prevState: any) => {

							return { ...prevState, submit: "Email is already in use" };

						});

					} else if (err.message === "auth/invalid-email") {

						setFormErrors((prevState: any) => {

							return { ...prevState, email: "Invalid email" };

						});

					} else {

						setFormErrors((prevState: any) => {

							return { ...prevState, submit: err.message };

						});

					}


				});

			} catch (err: any) {

				await signOut(auth);

				console.error(err.message);

				if (err.message === "auth/email-already-in-use") {

					setFormErrors((prevState: any) => {

						return { ...prevState, email: "Email is already in use" };

					});

				} else if (err.message === "auth/weak-password") {

					setFormErrors((prevState: any) => {

						return { ...prevState, password: "Password is too weak" };

					});

				} else if (err.message === "auth/invalid-email") {

					setFormErrors((prevState: any) => {

						return { ...prevState, email: "Invalid email" };

					});

				} else {

					setFormErrors((prevState: any) => {

						return { ...prevState, submit: err.message };

					});

				}

			}

		} else {

			setFormErrors(errors);

		}

	};

	const handleSignInWithGoogle = async () => {

		try {

			const res = await googleSignIn();

			if (res && res?.user?.emailVerified === true) {

				navigate(config.routes.userIntro);

				return;
        
			} {

				setFormErrors((prevState: any) => {

					return { ...prevState, isGoogle: "Email not verified. Please verify your email address." };

				});

			}

			return;

		} catch (err: any) {

			console.error(err);

			setFormErrors((prevState: any) => {

				return { ...prevState, isGoogle: err.message };

			});

		}

	};

	return (
		<>
			<form noValidate onSubmit={handleSubmit} {...others}>
							
				<FormControl fullWidth error={Boolean(formErrors.firstName)} sx={{ ...theme.typography.customInput }}>
					<InputLabel htmlFor="outlined-adornment-email-register">First Name</InputLabel>
					<OutlinedInput
						id="outlined-adornment-email-register"
						type="text"
						value={formData.firstName}
						name="firstName"
						onChange={handleInputChange}
						inputProps={{}}
					/>
					{formErrors.firstName &&
            <FormHelperText error id="standard-weight-helper-text--register">
            	{formErrors.firstName}
            </FormHelperText>
					}
				</FormControl>
				<FormControl fullWidth error={Boolean(formErrors.lastName)} sx={{ ...theme.typography.customInput }}>
					<InputLabel htmlFor="outlined-adornment-email-register">Last Name</InputLabel>
					<OutlinedInput
						id="outlined-adornment-email-register"
						type="email"
						value={formData.lastName}
						name="lastName"
						onChange={handleInputChange}
						inputProps={{}}
					/>
					{formErrors.lastName &&
            <FormHelperText error id="standard-weight-helper-text--register">
            	{formErrors.lastName}
            </FormHelperText>
					}
				</FormControl>
				<FormControl fullWidth error={Boolean(formErrors.email)} sx={{ ...theme.typography.customInput }}>
					<InputLabel htmlFor="outlined-adornment-email-register">Email Address</InputLabel>
					<OutlinedInput
						id="outlined-adornment-email-register"
						type="email"
						value={formData.email}
						name="email"
						onChange={handleInputChange}
						inputProps={{}}
					/>
					{formErrors.email &&
            <FormHelperText error id="standard-weight-helper-text--register">
            	{formErrors.email}
            </FormHelperText>
					}
				</FormControl>

				<FormControl fullWidth error={Boolean(formErrors.password)} sx={{ ...theme.typography.customInput }}>
					<InputLabel htmlFor="outlined-adornment-password-register">Password</InputLabel>
					<OutlinedInput
						id="outlined-adornment-password-register"
						type={showPassword ? "text" : "password"}
						value={formData.password}
						name="password"
						onChange={handleInputChange}
						onBlur={handleInputChange}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
									size="large"
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
						inputProps={{}}
					/>
					{formErrors.password &&
            <FormHelperText error id="standard-weight-helper-text-password-register">
            	{formErrors.password}
            </FormHelperText>
					}
				</FormControl>

				{strength !== 0 && formData.password &&
          <FormControl fullWidth>
          	<Box sx={{ mb: 2 }}>
          		<Grid container spacing={2} alignItems="center">
          			<Grid item>
          				<Box
          					style={{ backgroundColor: level?.color }}
          					sx={{ width: 85, height: 8, borderRadius: "7px" }}
          				/>
          			</Grid>
          			<Grid item>
          				<Typography variant="subtitle1" fontSize="0.75rem">
          					{level?.label}
          				</Typography>
          			</Grid>
          		</Grid>
          	</Box>
          </FormControl>
				}

				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item>
						<FormControlLabel
							control={
								<Checkbox
									checked={checked}
									onChange={handleCheckboxChange}
									name="checked"
									color="primary"
								/>
							}
							label={
								<Typography variant="subtitle1">
                  Agree with &nbsp;
									<Typography variant="subtitle1"
										target="_blank"
										component={Link} to={config.routes.termsAndConditions}>
                    Terms & Condition.
									</Typography>
								</Typography>
							}
						/>
					</Grid>
				</Grid>
				{formErrors.submit &&
          <Box sx={{ display: "flex", justifyContent: "center" }}>
          	<FormHelperText error>{formErrors.submit}</FormHelperText>
          </Box>
				}

				<Box sx={{ mt: 2 }}>
					<Button
						sx={{ borderRadius: "1.5em" }}
						disableElevation
						disabled={submitting || !checked}
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						color="primary"
					>
            Sign up
					</Button>
				</Box>
				<Divider sx={{ mt: 3, mb: 2 }} >
					<Chip sx={{ color: theme.palette.text.primary }} size="small" label="OR" />
				</Divider>
				<Box sx={{ mt: 2 }}>
					<Button
						sx={{
							backgroundColor: theme.palette.grey[50],
							color          : theme.palette.primary.main,
							borderRadius   : "1.5em",
							"&:hover"      : { backgroundColor: theme.palette.grey[100] }
						}}
						fullWidth size="large"
						onClick={() => handleSignInWithGoogle()}
					>
						<FcGoogle size={25} style={{ marginRight: "14px" }} /> Sign up with Google
					</Button>
				</Box>
			</form>
		</>
	);

};

export default JWTRegister;
