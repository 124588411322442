import React, { useState } from "react";
// import Container from "@mui/material/Container";
import { Row, Col } from "react-bootstrap";
import Typography from "@mui/material/Typography";
// import Level from "../../../assets/images/level.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import firstTimer from "../../../assets/images/firstTimer.png";

import beginner from "../../../assets/images/beginner.png";
import intermediate from "../../../assets/images/intermediate.png";
import advanced from "../../../assets/images/advanced.png";

import { useTheme, useMediaQuery } from "@mui/material";
import MainButton from "../../../components/UI/extend/MainButton";
import { FaCircleCheck } from "react-icons/fa6";


const UserLevel = ({ setSelectedSkillLevel }: any) => {

	const [ hoveredCard, setHoveredCard ] = useState<string>("");

	const theme: any = useTheme();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));

	const skillData = [
		{
			id   : 1,
			title: "First Timer",
			value: "firstTimer",
			image: firstTimer
		},
		{
			id   : 2,
			title: "Beginner",
			value: "beginner",
			image: beginner
		},
		{
			id   : 3,
			title: "Intermediate",
			value: "intermediate",
			image: intermediate
		},
		{
			id   : 4,
			title: "Advanced",
			value: "advanced",
			image: advanced
		}
	];

	return (
		<div>
			{/* <Container> */}
			<Row className="sh-level-page-container">
				<Col className="sh-level-page-left-side-container">
					<Typography variant="h2" gutterBottom className="" style={{ paddingTop: "1em" }}>
            Your Violin Journey Begins Here
					</Typography>
					<div>
						<Row style={{ display: "flex", justifyContent: "space-between", paddingBlock: "6em" }}>

							{ skillData.map((skill: any) => {

								return <Col xs={12} md={3} lg={3} xl={3} key={skill.id} style={{ padding: "0px" }}>
									<MainButton
										type="scale"
										direction="right"
										style={{ padding: "10px 1em", position: "relative" }}
										offset={matchesXS ? 0 : 20}
										scale={1.04}>
										<Card
											style={{ border: "none" }}
											onClick={() => {setSelectedSkillLevel(skill.value); setHoveredCard(skill.id);}}
											
											className="sh-level-page-left-side-individual-cards">
											<CardContent
												sx={hoveredCard == skill.id ?
													{
														borderColor    : theme?.palette?.secondary?.main,
														backgroundColor: theme?.palette?.secondary?.light,
														color          : theme?.palette?.secondary?.main
													} : { borderColor: theme?.palette?.secondary?.main }}
												className="sh-level-page-card-content"
												style={{ position: "relative", padding: "0px" }}
											>
												<div
													style={{ width: "100%", height: "100%" }}
												>
													<img
														style={{ width: "100%", height: "100%", objectFit: "cover" }}
														src={skill.image}
														alt="first-timer" />
												</div>
												<Typography variant="h5"
													// style={hoveredCard == "card2" ? { color: theme?.palette?.secondary?.main } : { }}
													style={{
														position       : "absolute",
														bottom         : "0px",
														color          : theme?.palette?.text?.primary,
														fontWeight     : hoveredCard == skill.id ? "bold" : "normal",
														backgroundColor: "rgba(0, 0, 0, 0.5)",
														padding        : "15px 65px",
														borderRadius   : "4px",
														whiteSpace     : "nowrap",
														width          : "100%"
											  }}
													component="div">
													{skill.title}
												</Typography>
											</CardContent>
										</Card>
										{
											hoveredCard == skill.id &&
											<div
												style={{
													position: "absolute",
													top     : "0",
													right   : "5px"
												}}
											>
												<FaCircleCheck
													style={{
														background  : theme?.palette?.text?.primary,
														color       : theme?.palette?.secondary?.main,
														fontSize    : 30,
														borderRadius: "50%",
														border      : "1px solid",
														borderColor : theme?.palette?.text?.primary
													}}
												/>
											</div>
										}
									</MainButton>
								</Col>;

							})
							}

						</Row>
					</div>
				</Col>
			</Row>
		</div>
	);

};

export default UserLevel;