import appConfig from "../config/appConfig";

const baseUrl = appConfig.backendUrl;

// Define all the API endpoints here.

// get-all-level

// get API URLs
export const APIUrl = {
	"getUserInfo"                   : `${baseUrl}/user/get-user-info/`,
	"getSignedUrlForProfileIcon"    : `${baseUrl}/user/get-signed-url-upload-profile/`,
	"deleteUserProfileIcon"         : `${baseUrl}/user/delete-user-profile/`,
	"updateUserSkill"               : `${baseUrl}/user/update-user-skill/`,
	"getAllCourse"                  : `${baseUrl}/admin/get-all-course/`,
	"updateCourse"                  : `${baseUrl}/admin/edit-course/`,
	"listAllCourse"                 : `${baseUrl}/course/get-all-course/`,
	"listAllModuleByCourseId"       : `${baseUrl}/course/get-all-module-course-id/`,
	"listAllLevelByModule"          : `${baseUrl}/admin/get-all-level/`,
	"listAllLevelAndLessonByModule" : `${baseUrl}/course/get-all-lesson-and-level-module-id/`,
	"updateUserPurchasedModules"    : `${baseUrl}/user/update-user-purchase-module/`,
	"updateUserProgress"            : `${baseUrl}/user/update-user-progress/`,
	"getAllLessons"                 : `${baseUrl}/admin/lesson/get-all-lesson/`,
	"getAllModules"                 : `${baseUrl}/admin/module/get-all-module/`,
	"addNewModule"                  : `${baseUrl}/admin/module/add-module/`,
	"updateModule"                  : `${baseUrl}/admin/module/edit-module/`,
	"getSignedUrlUploadModuleIcon"  : `${baseUrl}/admin/module/get-signed-url/`,
	"getSignedUrlVideo"             : `${baseUrl}/course/get-signed-url-video/`,
	"updateLesson"                  : `${baseUrl}/admin/lesson/edit-lesson/`,
	"addLesson"                     : `${baseUrl}/admin/lesson/add-lesson/`,
	"addLevel"                      : `${baseUrl}/admin/add-level/`,
	"updateLevel"                   : `${baseUrl}/admin/edit-level/`,
	"getSignedUrlForUploadVideo"    : `${baseUrl}/admin/lesson/get-signed-url/`,
	"deleteVideoData"               : `${baseUrl}/admin/bunny/delete-video/`,
	"uploadVideoToBunnyStream"      : `${baseUrl}/admin/lesson/upload-video-by-url/`,
	"createOrder"                   : `${baseUrl}/payment/create-order/`,
	"verifyOrder"                   : `${baseUrl}/payment/verify-order/`,
	"updateUserFeedBack"            : `${baseUrl}/feedback/add-or-update/`,
	"getSignedUrlToUploadRefDoc"    : `${baseUrl}/admin/lesson/get-url-upload-ref-doc/`,
	"getSignedUrlDownloadRefDoc"    : `${baseUrl}/course/get-signed-url-download-reference-doc/`,
	"deleteLesson"                  : `${baseUrl}/admin/lesson/delete-lesson/`,
	"deleteLevel"                   : `${baseUrl}/admin/delete-level/`,
	"deleteModule"                  : `${baseUrl}/admin/module/delete-module/`,
	"getSignedUrlUploadIntroVideo"  : `${baseUrl}/admin/module/intro-video/get-signed-url/`,
	"publishModule"                 : `${baseUrl}/admin/module/publish/`,
	"listAllPosts"                  : `${baseUrl}/community/get-all-the-post/`,
	"addPost"                       : `${baseUrl}/community/add-post/`,
	"likePost"                      : `${baseUrl}/community/like-post/`,
	"listCommentByPost"             : `${baseUrl}/community/list-comment/`,
	"addCommentToPost"              : `${baseUrl}/community/post-comment/`,
	"removeCommentToPost"           : `${baseUrl}/community/delete-comment/`,
	"getSignedUrlForUploadPostImage": `${baseUrl}/community/get-signed-url-upload-img/`,
	"deletePostById"                : `${baseUrl}/community/delete-post-by-id/`,
	"updateUserAddress"             : `${baseUrl}/user/update-user-address/`,
	"publishOrUnPublishLesson"      : `${baseUrl}/admin/lesson/publish-or-un-publish/`,
	"getPurchaseHistory"            : `${baseUrl}/user/get-payment-history/`,
	"emailPaymentReceipt"           : `${baseUrl}/user/download-payment-receipt/`,
	"getPaymentHistory"             : `${baseUrl}/payment/get-payment-history/`,
	"updateLastViewdPost"           : `${baseUrl}/user/update-last-viewed-post/`,
	"getLastPostId"                 : `${baseUrl}/community/get-last-post-id/`
};
