
interface Config {
   countryState?: any
     
}

const stateConfig: Config = {
	countryState: {
		"AF": [
			{
				"countryName": "Afghanistan",
				"name"       : "Badakhshan",
				"state_code" : "BDS"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Badghis",
				"state_code" : "BDG"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Baghlan",
				"state_code" : "BGL"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Balkh",
				"state_code" : "BAL"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Bamyan",
				"state_code" : "BAM"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Daykundi",
				"state_code" : "DAY"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Farah",
				"state_code" : "FRA"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Faryab",
				"state_code" : "FYB"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Ghazni",
				"state_code" : "GHA"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Ghōr",
				"state_code" : "GHO"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Helmand",
				"state_code" : "HEL"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Herat",
				"state_code" : "HER"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Jowzjan",
				"state_code" : "JOW"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Kabul",
				"state_code" : "KAB"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Kandahar",
				"state_code" : "KAN"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Kapisa",
				"state_code" : "KAP"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Khost",
				"state_code" : "KHO"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Kunar",
				"state_code" : "KNR"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Kunduz Province",
				"state_code" : "KDZ"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Laghman",
				"state_code" : "LAG"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Logar",
				"state_code" : "LOG"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Nangarhar",
				"state_code" : "NAN"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Nimruz",
				"state_code" : "NIM"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Nuristan",
				"state_code" : "NUR"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Paktia",
				"state_code" : "PIA"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Paktika",
				"state_code" : "PKA"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Panjshir",
				"state_code" : "PAN"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Parwan",
				"state_code" : "PAR"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Samangan",
				"state_code" : "SAM"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Sar-e Pol",
				"state_code" : "SAR"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Takhar",
				"state_code" : "TAK"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Urozgan",
				"state_code" : "URU"
			},
			{
				"countryName": "Afghanistan",
				"name"       : "Zabul",
				"state_code" : "ZAB"
			}
		],
		"AX": [],
		"AL": [
			{
				"countryName": "Albania",
				"name"       : "Berat County",
				"state_code" : "01"
			},
			{
				"countryName": "Albania",
				"name"       : "Berat District",
				"state_code" : "BR"
			},
			{
				"countryName": "Albania",
				"name"       : "Bulqizë District",
				"state_code" : "BU"
			},
			{
				"countryName": "Albania",
				"name"       : "Delvinë District",
				"state_code" : "DL"
			},
			{
				"countryName": "Albania",
				"name"       : "Devoll District",
				"state_code" : "DV"
			},
			{
				"countryName": "Albania",
				"name"       : "Dibër County",
				"state_code" : "09"
			},
			{
				"countryName": "Albania",
				"name"       : "Dibër District",
				"state_code" : "DI"
			},
			{
				"countryName": "Albania",
				"name"       : "Durrës County",
				"state_code" : "02"
			},
			{
				"countryName": "Albania",
				"name"       : "Durrës District",
				"state_code" : "DR"
			},
			{
				"countryName": "Albania",
				"name"       : "Elbasan County",
				"state_code" : "03"
			},
			{
				"countryName": "Albania",
				"name"       : "Fier County",
				"state_code" : "04"
			},
			{
				"countryName": "Albania",
				"name"       : "Fier District",
				"state_code" : "FR"
			},
			{
				"countryName": "Albania",
				"name"       : "Gjirokastër County",
				"state_code" : "05"
			},
			{
				"countryName": "Albania",
				"name"       : "Gjirokastër District",
				"state_code" : "GJ"
			},
			{
				"countryName": "Albania",
				"name"       : "Gramsh District",
				"state_code" : "GR"
			},
			{
				"countryName": "Albania",
				"name"       : "Has District",
				"state_code" : "HA"
			},
			{
				"countryName": "Albania",
				"name"       : "Kavajë District",
				"state_code" : "KA"
			},
			{
				"countryName": "Albania",
				"name"       : "Kolonjë District",
				"state_code" : "ER"
			},
			{
				"countryName": "Albania",
				"name"       : "Korçë County",
				"state_code" : "06"
			},
			{
				"countryName": "Albania",
				"name"       : "Korçë District",
				"state_code" : "KO"
			},
			{
				"countryName": "Albania",
				"name"       : "Krujë District",
				"state_code" : "KR"
			},
			{
				"countryName": "Albania",
				"name"       : "Kuçovë District",
				"state_code" : "KC"
			},
			{
				"countryName": "Albania",
				"name"       : "Kukës County",
				"state_code" : "07"
			},
			{
				"countryName": "Albania",
				"name"       : "Kukës District",
				"state_code" : "KU"
			},
			{
				"countryName": "Albania",
				"name"       : "Kurbin District",
				"state_code" : "KB"
			},
			{
				"countryName": "Albania",
				"name"       : "Lezhë County",
				"state_code" : "08"
			},
			{
				"countryName": "Albania",
				"name"       : "Lezhë District",
				"state_code" : "LE"
			},
			{
				"countryName": "Albania",
				"name"       : "Librazhd District",
				"state_code" : "LB"
			},
			{
				"countryName": "Albania",
				"name"       : "Lushnjë District",
				"state_code" : "LU"
			},
			{
				"countryName": "Albania",
				"name"       : "Malësi e Madhe District",
				"state_code" : "MM"
			},
			{
				"countryName": "Albania",
				"name"       : "Mallakastër District",
				"state_code" : "MK"
			},
			{
				"countryName": "Albania",
				"name"       : "Mat District",
				"state_code" : "MT"
			},
			{
				"countryName": "Albania",
				"name"       : "Mirditë District",
				"state_code" : "MR"
			},
			{
				"countryName": "Albania",
				"name"       : "Peqin District",
				"state_code" : "PQ"
			},
			{
				"countryName": "Albania",
				"name"       : "Përmet District",
				"state_code" : "PR"
			},
			{
				"countryName": "Albania",
				"name"       : "Pogradec District",
				"state_code" : "PG"
			},
			{
				"countryName": "Albania",
				"name"       : "Pukë District",
				"state_code" : "PU"
			},
			{
				"countryName": "Albania",
				"name"       : "Sarandë District",
				"state_code" : "SR"
			},
			{
				"countryName": "Albania",
				"name"       : "Shkodër County",
				"state_code" : "10"
			},
			{
				"countryName": "Albania",
				"name"       : "Shkodër District",
				"state_code" : "SH"
			},
			{
				"countryName": "Albania",
				"name"       : "Skrapar District",
				"state_code" : "SK"
			},
			{
				"countryName": "Albania",
				"name"       : "Tepelenë District",
				"state_code" : "TE"
			},
			{
				"countryName": "Albania",
				"name"       : "Tirana County",
				"state_code" : "11"
			},
			{
				"countryName": "Albania",
				"name"       : "Tirana District",
				"state_code" : "TR"
			},
			{
				"countryName": "Albania",
				"name"       : "Tropojë District",
				"state_code" : "TP"
			},
			{
				"countryName": "Albania",
				"name"       : "Vlorë County",
				"state_code" : "12"
			},
			{
				"countryName": "Albania",
				"name"       : "Vlorë District",
				"state_code" : "VL"
			}
		],
		"DZ": [
			{
				"countryName": "Algeria",
				"name"       : "Adrar",
				"state_code" : "01"
			},
			{
				"countryName": "Algeria",
				"name"       : "Aïn Defla",
				"state_code" : "44"
			},
			{
				"countryName": "Algeria",
				"name"       : "Aïn Témouchent",
				"state_code" : "46"
			},
			{
				"countryName": "Algeria",
				"name"       : "Algiers",
				"state_code" : "16"
			},
			{
				"countryName": "Algeria",
				"name"       : "Annaba",
				"state_code" : "23"
			},
			{
				"countryName": "Algeria",
				"name"       : "Batna",
				"state_code" : "05"
			},
			{
				"countryName": "Algeria",
				"name"       : "Béchar",
				"state_code" : "08"
			},
			{
				"countryName": "Algeria",
				"name"       : "Béjaïa",
				"state_code" : "06"
			},
			{
				"countryName": "Algeria",
				"name"       : "Béni Abbès",
				"state_code" : "53"
			},
			{
				"countryName": "Algeria",
				"name"       : "Biskra",
				"state_code" : "07"
			},
			{
				"countryName": "Algeria",
				"name"       : "Blida",
				"state_code" : "09"
			},
			{
				"countryName": "Algeria",
				"name"       : "Bordj Baji Mokhtar",
				"state_code" : "52"
			},
			{
				"countryName": "Algeria",
				"name"       : "Bordj Bou Arréridj",
				"state_code" : "34"
			},
			{
				"countryName": "Algeria",
				"name"       : "Bouïra",
				"state_code" : "10"
			},
			{
				"countryName": "Algeria",
				"name"       : "Boumerdès",
				"state_code" : "35"
			},
			{
				"countryName": "Algeria",
				"name"       : "Chlef",
				"state_code" : "02"
			},
			{
				"countryName": "Algeria",
				"name"       : "Constantine",
				"state_code" : "25"
			},
			{
				"countryName": "Algeria",
				"name"       : "Djanet",
				"state_code" : "56"
			},
			{
				"countryName": "Algeria",
				"name"       : "Djelfa",
				"state_code" : "17"
			},
			{
				"countryName": "Algeria",
				"name"       : "El Bayadh",
				"state_code" : "32"
			},
			{
				"countryName": "Algeria",
				"name"       : "El M'ghair",
				"state_code" : "49"
			},
			{
				"countryName": "Algeria",
				"name"       : "El Menia",
				"state_code" : "50"
			},
			{
				"countryName": "Algeria",
				"name"       : "El Oued",
				"state_code" : "39"
			},
			{
				"countryName": "Algeria",
				"name"       : "El Tarf",
				"state_code" : "36"
			},
			{
				"countryName": "Algeria",
				"name"       : "Ghardaïa",
				"state_code" : "47"
			},
			{
				"countryName": "Algeria",
				"name"       : "Guelma",
				"state_code" : "24"
			},
			{
				"countryName": "Algeria",
				"name"       : "Illizi",
				"state_code" : "33"
			},
			{
				"countryName": "Algeria",
				"name"       : "In Guezzam",
				"state_code" : "58"
			},
			{
				"countryName": "Algeria",
				"name"       : "In Salah",
				"state_code" : "57"
			},
			{
				"countryName": "Algeria",
				"name"       : "Jijel",
				"state_code" : "18"
			},
			{
				"countryName": "Algeria",
				"name"       : "Khenchela",
				"state_code" : "40"
			},
			{
				"countryName": "Algeria",
				"name"       : "Laghouat",
				"state_code" : "03"
			},
			{
				"countryName": "Algeria",
				"name"       : "M'Sila",
				"state_code" : "28"
			},
			{
				"countryName": "Algeria",
				"name"       : "Mascara",
				"state_code" : "29"
			},
			{
				"countryName": "Algeria",
				"name"       : "Médéa",
				"state_code" : "26"
			},
			{
				"countryName": "Algeria",
				"name"       : "Mila",
				"state_code" : "43"
			},
			{
				"countryName": "Algeria",
				"name"       : "Mostaganem",
				"state_code" : "27"
			},
			{
				"countryName": "Algeria",
				"name"       : "Naama",
				"state_code" : "45"
			},
			{
				"countryName": "Algeria",
				"name"       : "Oran",
				"state_code" : "31"
			},
			{
				"countryName": "Algeria",
				"name"       : "Ouargla",
				"state_code" : "30"
			},
			{
				"countryName": "Algeria",
				"name"       : "Ouled Djellal",
				"state_code" : "51"
			},
			{
				"countryName": "Algeria",
				"name"       : "Oum El Bouaghi",
				"state_code" : "04"
			},
			{
				"countryName": "Algeria",
				"name"       : "Relizane",
				"state_code" : "48"
			},
			{
				"countryName": "Algeria",
				"name"       : "Saïda",
				"state_code" : "20"
			},
			{
				"countryName": "Algeria",
				"name"       : "Sétif",
				"state_code" : "19"
			},
			{
				"countryName": "Algeria",
				"name"       : "Sidi Bel Abbès",
				"state_code" : "22"
			},
			{
				"countryName": "Algeria",
				"name"       : "Skikda",
				"state_code" : "21"
			},
			{
				"countryName": "Algeria",
				"name"       : "Souk Ahras",
				"state_code" : "41"
			},
			{
				"countryName": "Algeria",
				"name"       : "Tamanghasset",
				"state_code" : "11"
			},
			{
				"countryName": "Algeria",
				"name"       : "Tébessa",
				"state_code" : "12"
			},
			{
				"countryName": "Algeria",
				"name"       : "Tiaret",
				"state_code" : "14"
			},
			{
				"countryName": "Algeria",
				"name"       : "Timimoun",
				"state_code" : "54"
			},
			{
				"countryName": "Algeria",
				"name"       : "Tindouf",
				"state_code" : "37"
			},
			{
				"countryName": "Algeria",
				"name"       : "Tipasa",
				"state_code" : "42"
			},
			{
				"countryName": "Algeria",
				"name"       : "Tissemsilt",
				"state_code" : "38"
			},
			{
				"countryName": "Algeria",
				"name"       : "Tizi Ouzou",
				"state_code" : "15"
			},
			{
				"countryName": "Algeria",
				"name"       : "Tlemcen",
				"state_code" : "13"
			},
			{
				"countryName": "Algeria",
				"name"       : "Touggourt",
				"state_code" : "55"
			}
		],
		"AS": [],
		"AD": [
			{
				"countryName": "Andorra",
				"name"       : "Andorra la Vella",
				"state_code" : "07"
			},
			{
				"countryName": "Andorra",
				"name"       : "Canillo",
				"state_code" : "02"
			},
			{
				"countryName": "Andorra",
				"name"       : "Encamp",
				"state_code" : "03"
			},
			{
				"countryName": "Andorra",
				"name"       : "Escaldes-Engordany",
				"state_code" : "08"
			},
			{
				"countryName": "Andorra",
				"name"       : "La Massana",
				"state_code" : "04"
			},
			{
				"countryName": "Andorra",
				"name"       : "Ordino",
				"state_code" : "05"
			},
			{
				"countryName": "Andorra",
				"name"       : "Sant Julià de Lòria",
				"state_code" : "06"
			}
		],
		"AO": [
			{
				"countryName": "Angola",
				"name"       : "Bengo Province",
				"state_code" : "BGO"
			},
			{
				"countryName": "Angola",
				"name"       : "Benguela Province",
				"state_code" : "BGU"
			},
			{
				"countryName": "Angola",
				"name"       : "Bié Province",
				"state_code" : "BIE"
			},
			{
				"countryName": "Angola",
				"name"       : "Cabinda Province",
				"state_code" : "CAB"
			},
			{
				"countryName": "Angola",
				"name"       : "Cuando Cubango Province",
				"state_code" : "CCU"
			},
			{
				"countryName": "Angola",
				"name"       : "Cuanza Norte Province",
				"state_code" : "CNO"
			},
			{
				"countryName": "Angola",
				"name"       : "Cuanza Sul",
				"state_code" : "CUS"
			},
			{
				"countryName": "Angola",
				"name"       : "Cunene Province",
				"state_code" : "CNN"
			},
			{
				"countryName": "Angola",
				"name"       : "Huambo Province",
				"state_code" : "HUA"
			},
			{
				"countryName": "Angola",
				"name"       : "Huíla Province",
				"state_code" : "HUI"
			},
			{
				"countryName": "Angola",
				"name"       : "Luanda Province",
				"state_code" : "LUA"
			},
			{
				"countryName": "Angola",
				"name"       : "Lunda Norte Province",
				"state_code" : "LNO"
			},
			{
				"countryName": "Angola",
				"name"       : "Lunda Sul Province",
				"state_code" : "LSU"
			},
			{
				"countryName": "Angola",
				"name"       : "Malanje Province",
				"state_code" : "MAL"
			},
			{
				"countryName": "Angola",
				"name"       : "Moxico Province",
				"state_code" : "MOX"
			},
			{
				"countryName": "Angola",
				"name"       : "Uíge Province",
				"state_code" : "UIG"
			},
			{
				"countryName": "Angola",
				"name"       : "Zaire Province",
				"state_code" : "ZAI"
			}
		],
		"AI": [],
		"AQ": [],
		"AG": [
			{
				"countryName": "Antigua And Barbuda",
				"name"       : "Barbuda",
				"state_code" : "10"
			},
			{
				"countryName": "Antigua And Barbuda",
				"name"       : "Redonda",
				"state_code" : "11"
			},
			{
				"countryName": "Antigua And Barbuda",
				"name"       : "Saint George Parish",
				"state_code" : "03"
			},
			{
				"countryName": "Antigua And Barbuda",
				"name"       : "Saint John Parish",
				"state_code" : "04"
			},
			{
				"countryName": "Antigua And Barbuda",
				"name"       : "Saint Mary Parish",
				"state_code" : "05"
			},
			{
				"countryName": "Antigua And Barbuda",
				"name"       : "Saint Paul Parish",
				"state_code" : "06"
			},
			{
				"countryName": "Antigua And Barbuda",
				"name"       : "Saint Peter Parish",
				"state_code" : "07"
			},
			{
				"countryName": "Antigua And Barbuda",
				"name"       : "Saint Philip Parish",
				"state_code" : "08"
			}
		],
		"AR": [
			{
				"countryName": "Argentina",
				"name"       : "Buenos Aires",
				"state_code" : "B"
			},
			{
				"countryName": "Argentina",
				"name"       : "Catamarca",
				"state_code" : "K"
			},
			{
				"countryName": "Argentina",
				"name"       : "Chaco",
				"state_code" : "H"
			},
			{
				"countryName": "Argentina",
				"name"       : "Chubut",
				"state_code" : "U"
			},
			{
				"countryName": "Argentina",
				"name"       : "Ciudad Autónoma de Buenos Aires",
				"state_code" : "C"
			},
			{
				"countryName": "Argentina",
				"name"       : "Córdoba",
				"state_code" : "X"
			},
			{
				"countryName": "Argentina",
				"name"       : "Corrientes",
				"state_code" : "W"
			},
			{
				"countryName": "Argentina",
				"name"       : "Entre Ríos",
				"state_code" : "E"
			},
			{
				"countryName": "Argentina",
				"name"       : "Formosa",
				"state_code" : "P"
			},
			{
				"countryName": "Argentina",
				"name"       : "Jujuy",
				"state_code" : "Y"
			},
			{
				"countryName": "Argentina",
				"name"       : "La Pampa",
				"state_code" : "L"
			},
			{
				"countryName": "Argentina",
				"name"       : "La Rioja",
				"state_code" : "F"
			},
			{
				"countryName": "Argentina",
				"name"       : "Mendoza",
				"state_code" : "M"
			},
			{
				"countryName": "Argentina",
				"name"       : "Misiones",
				"state_code" : "N"
			},
			{
				"countryName": "Argentina",
				"name"       : "Neuquén",
				"state_code" : "Q"
			},
			{
				"countryName": "Argentina",
				"name"       : "Río Negro",
				"state_code" : "R"
			},
			{
				"countryName": "Argentina",
				"name"       : "Salta",
				"state_code" : "A"
			},
			{
				"countryName": "Argentina",
				"name"       : "San Juan",
				"state_code" : "J"
			},
			{
				"countryName": "Argentina",
				"name"       : "San Luis",
				"state_code" : "D"
			},
			{
				"countryName": "Argentina",
				"name"       : "Santa Cruz",
				"state_code" : "Z"
			},
			{
				"countryName": "Argentina",
				"name"       : "Santa Fe",
				"state_code" : "S"
			},
			{
				"countryName": "Argentina",
				"name"       : "Santiago del Estero",
				"state_code" : "G"
			},
			{
				"countryName": "Argentina",
				"name"       : "Tierra del Fuego",
				"state_code" : "V"
			},
			{
				"countryName": "Argentina",
				"name"       : "Tucumán",
				"state_code" : "T"
			}
		],
		"AM": [
			{
				"countryName": "Armenia",
				"name"       : "Aragatsotn Region",
				"state_code" : "AG"
			},
			{
				"countryName": "Armenia",
				"name"       : "Ararat Province",
				"state_code" : "AR"
			},
			{
				"countryName": "Armenia",
				"name"       : "Armavir Region",
				"state_code" : "AV"
			},
			{
				"countryName": "Armenia",
				"name"       : "Gegharkunik Province",
				"state_code" : "GR"
			},
			{
				"countryName": "Armenia",
				"name"       : "Kotayk Region",
				"state_code" : "KT"
			},
			{
				"countryName": "Armenia",
				"name"       : "Lori Region",
				"state_code" : "LO"
			},
			{
				"countryName": "Armenia",
				"name"       : "Shirak Region",
				"state_code" : "SH"
			},
			{
				"countryName": "Armenia",
				"name"       : "Syunik Province",
				"state_code" : "SU"
			},
			{
				"countryName": "Armenia",
				"name"       : "Tavush Region",
				"state_code" : "TV"
			},
			{
				"countryName": "Armenia",
				"name"       : "Vayots Dzor Region",
				"state_code" : "VD"
			},
			{
				"countryName": "Armenia",
				"name"       : "Yerevan",
				"state_code" : "ER"
			}
		],
		"AW": [],
		"AU": [
			{
				"countryName": "Australia",
				"name"       : "Australian Capital Territory",
				"state_code" : "ACT"
			},
			{
				"countryName": "Australia",
				"name"       : "New South Wales",
				"state_code" : "NSW"
			},
			{
				"countryName": "Australia",
				"name"       : "Northern Territory",
				"state_code" : "NT"
			},
			{
				"countryName": "Australia",
				"name"       : "Queensland",
				"state_code" : "QLD"
			},
			{
				"countryName": "Australia",
				"name"       : "South Australia",
				"state_code" : "SA"
			},
			{
				"countryName": "Australia",
				"name"       : "Tasmania",
				"state_code" : "TAS"
			},
			{
				"countryName": "Australia",
				"name"       : "Victoria",
				"state_code" : "VIC"
			},
			{
				"countryName": "Australia",
				"name"       : "Western Australia",
				"state_code" : "WA"
			}
		],
		"AT": [
			{
				"countryName": "Austria",
				"name"       : "Burgenland",
				"state_code" : "1"
			},
			{
				"countryName": "Austria",
				"name"       : "Carinthia",
				"state_code" : "2"
			},
			{
				"countryName": "Austria",
				"name"       : "Lower Austria",
				"state_code" : "3"
			},
			{
				"countryName": "Austria",
				"name"       : "Salzburg",
				"state_code" : "5"
			},
			{
				"countryName": "Austria",
				"name"       : "Styria",
				"state_code" : "6"
			},
			{
				"countryName": "Austria",
				"name"       : "Tyrol",
				"state_code" : "7"
			},
			{
				"countryName": "Austria",
				"name"       : "Upper Austria",
				"state_code" : "4"
			},
			{
				"countryName": "Austria",
				"name"       : "Vienna",
				"state_code" : "9"
			},
			{
				"countryName": "Austria",
				"name"       : "Vorarlberg",
				"state_code" : "8"
			}
		],
		"AZ": [
			{
				"countryName": "Azerbaijan",
				"name"       : "Absheron District",
				"state_code" : "ABS"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Agdam District",
				"state_code" : "AGM"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Agdash District",
				"state_code" : "AGS"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Aghjabadi District",
				"state_code" : "AGC"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Agstafa District",
				"state_code" : "AGA"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Agsu District",
				"state_code" : "AGU"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Astara District",
				"state_code" : "AST"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Babek District",
				"state_code" : "BAB"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Baku",
				"state_code" : "BA"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Balakan District",
				"state_code" : "BAL"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Barda District",
				"state_code" : "BAR"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Beylagan District",
				"state_code" : "BEY"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Bilasuvar District",
				"state_code" : "BIL"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Dashkasan District",
				"state_code" : "DAS"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Fizuli District",
				"state_code" : "FUZ"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Ganja",
				"state_code" : "GA"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Gədəbəy",
				"state_code" : "GAD"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Gobustan District",
				"state_code" : "QOB"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Goranboy District",
				"state_code" : "GOR"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Goychay",
				"state_code" : "GOY"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Goygol District",
				"state_code" : "GYG"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Hajigabul District",
				"state_code" : "HAC"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Imishli District",
				"state_code" : "IMI"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Ismailli District",
				"state_code" : "ISM"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Jabrayil District",
				"state_code" : "CAB"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Jalilabad District",
				"state_code" : "CAL"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Julfa District",
				"state_code" : "CUL"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Kalbajar District",
				"state_code" : "KAL"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Kangarli District",
				"state_code" : "KAN"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Khachmaz District",
				"state_code" : "XAC"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Khizi District",
				"state_code" : "XIZ"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Khojali District",
				"state_code" : "XCI"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Kurdamir District",
				"state_code" : "KUR"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Lachin District",
				"state_code" : "LAC"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Lankaran",
				"state_code" : "LAN"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Lankaran District",
				"state_code" : "LA"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Lerik District",
				"state_code" : "LER"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Martuni",
				"state_code" : "XVD"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Masally District",
				"state_code" : "MAS"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Mingachevir",
				"state_code" : "MI"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Nakhchivan Autonomous Republic",
				"state_code" : "NX"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Neftchala District",
				"state_code" : "NEF"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Oghuz District",
				"state_code" : "OGU"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Ordubad District",
				"state_code" : "ORD"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Qabala District",
				"state_code" : "QAB"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Qakh District",
				"state_code" : "QAX"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Qazakh District",
				"state_code" : "QAZ"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Quba District",
				"state_code" : "QBA"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Qubadli District",
				"state_code" : "QBI"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Qusar District",
				"state_code" : "QUS"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Saatly District",
				"state_code" : "SAT"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Sabirabad District",
				"state_code" : "SAB"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Sadarak District",
				"state_code" : "SAD"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Salyan District",
				"state_code" : "SAL"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Samukh District",
				"state_code" : "SMX"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Shabran District",
				"state_code" : "SBN"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Shahbuz District",
				"state_code" : "SAH"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Shaki",
				"state_code" : "SA"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Shaki District",
				"state_code" : "SAK"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Shamakhi District",
				"state_code" : "SMI"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Shamkir District",
				"state_code" : "SKR"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Sharur District",
				"state_code" : "SAR"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Shirvan",
				"state_code" : "SR"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Shusha District",
				"state_code" : "SUS"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Siazan District",
				"state_code" : "SIY"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Sumqayit",
				"state_code" : "SM"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Tartar District",
				"state_code" : "TAR"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Tovuz District",
				"state_code" : "TOV"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Ujar District",
				"state_code" : "UCA"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Yardymli District",
				"state_code" : "YAR"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Yevlakh",
				"state_code" : "YE"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Yevlakh District",
				"state_code" : "YEV"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Zangilan District",
				"state_code" : "ZAN"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Zaqatala District",
				"state_code" : "ZAQ"
			},
			{
				"countryName": "Azerbaijan",
				"name"       : "Zardab District",
				"state_code" : "ZAR"
			}
		],
		"BH": [
			{
				"countryName": "Bahrain",
				"name"       : "Capital",
				"state_code" : "13"
			},
			{
				"countryName": "Bahrain",
				"name"       : "Central",
				"state_code" : "16"
			},
			{
				"countryName": "Bahrain",
				"name"       : "Muharraq",
				"state_code" : "15"
			},
			{
				"countryName": "Bahrain",
				"name"       : "Northern",
				"state_code" : "17"
			},
			{
				"countryName": "Bahrain",
				"name"       : "Southern",
				"state_code" : "14"
			}
		],
		"BD": [
			{
				"countryName": "Bangladesh",
				"name"       : "Bagerhat District",
				"state_code" : "05"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Bahadia",
				"state_code" : "33"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Bandarban District",
				"state_code" : "01"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Barguna District",
				"state_code" : "02"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Barisal District",
				"state_code" : "06"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Barisal Division",
				"state_code" : "A"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Bhola District",
				"state_code" : "07"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Bogra District",
				"state_code" : "03"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Brahmanbaria District",
				"state_code" : "04"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Chandpur District",
				"state_code" : "09"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Chapai Nawabganj District",
				"state_code" : "45"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Chittagong District",
				"state_code" : "10"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Chittagong Division",
				"state_code" : "B"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Chuadanga District",
				"state_code" : "12"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Comilla District",
				"state_code" : "08"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Cox's Bazar District",
				"state_code" : "11"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Dhaka District",
				"state_code" : "13"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Dhaka Division",
				"state_code" : "C"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Dinajpur District",
				"state_code" : "14"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Faridpur District",
				"state_code" : "15"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Feni District",
				"state_code" : "16"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Gaibandha District",
				"state_code" : "19"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Gazipur District",
				"state_code" : "18"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Gopalganj District",
				"state_code" : "17"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Habiganj District",
				"state_code" : "20"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Jamalpur District",
				"state_code" : "21"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Jessore District",
				"state_code" : "22"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Jhalokati District",
				"state_code" : "25"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Jhenaidah District",
				"state_code" : "23"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Joypurhat District",
				"state_code" : "24"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Khagrachari District",
				"state_code" : "29"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Khulna District",
				"state_code" : "27"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Khulna Division",
				"state_code" : "D"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Kishoreganj District",
				"state_code" : "26"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Kurigram District",
				"state_code" : "28"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Kushtia District",
				"state_code" : "30"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Lakshmipur District",
				"state_code" : "31"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Lalmonirhat District",
				"state_code" : "32"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Madaripur District",
				"state_code" : "36"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Meherpur District",
				"state_code" : "39"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Moulvibazar District",
				"state_code" : "38"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Munshiganj District",
				"state_code" : "35"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Mymensingh District",
				"state_code" : "34"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Mymensingh Division",
				"state_code" : "H"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Naogaon District",
				"state_code" : "48"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Narail District",
				"state_code" : "43"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Narayanganj District",
				"state_code" : "40"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Natore District",
				"state_code" : "44"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Netrokona District",
				"state_code" : "41"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Nilphamari District",
				"state_code" : "46"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Noakhali District",
				"state_code" : "47"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Pabna District",
				"state_code" : "49"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Panchagarh District",
				"state_code" : "52"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Patuakhali District",
				"state_code" : "51"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Pirojpur District",
				"state_code" : "50"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Rajbari District",
				"state_code" : "53"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Rajshahi District",
				"state_code" : "54"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Rajshahi Division",
				"state_code" : "E"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Rangamati Hill District",
				"state_code" : "56"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Rangpur District",
				"state_code" : "55"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Rangpur Division",
				"state_code" : "F"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Satkhira District",
				"state_code" : "58"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Shariatpur District",
				"state_code" : "62"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Sherpur District",
				"state_code" : "57"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Sirajganj District",
				"state_code" : "59"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Sunamganj District",
				"state_code" : "61"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Sylhet District",
				"state_code" : "60"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Sylhet Division",
				"state_code" : "G"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Tangail District",
				"state_code" : "63"
			},
			{
				"countryName": "Bangladesh",
				"name"       : "Thakurgaon District",
				"state_code" : "64"
			}
		],
		"BB": [
			{
				"countryName": "Barbados",
				"name"       : "Christ Church",
				"state_code" : "01"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint Andrew",
				"state_code" : "02"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint George",
				"state_code" : "03"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint James",
				"state_code" : "04"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint John",
				"state_code" : "05"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint Joseph",
				"state_code" : "06"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint Lucy",
				"state_code" : "07"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint Michael",
				"state_code" : "08"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint Peter",
				"state_code" : "09"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint Philip",
				"state_code" : "10"
			},
			{
				"countryName": "Barbados",
				"name"       : "Saint Thomas",
				"state_code" : "11"
			}
		],
		"BY": [
			{
				"countryName": "Belarus",
				"name"       : "Brest Region",
				"state_code" : "BR"
			},
			{
				"countryName": "Belarus",
				"name"       : "Gomel Region",
				"state_code" : "HO"
			},
			{
				"countryName": "Belarus",
				"name"       : "Grodno Region",
				"state_code" : "HR"
			},
			{
				"countryName": "Belarus",
				"name"       : "Minsk",
				"state_code" : "HM"
			},
			{
				"countryName": "Belarus",
				"name"       : "Minsk Region",
				"state_code" : "MI"
			},
			{
				"countryName": "Belarus",
				"name"       : "Mogilev Region",
				"state_code" : "MA"
			},
			{
				"countryName": "Belarus",
				"name"       : "Vitebsk Region",
				"state_code" : "VI"
			}
		],
		"BE": [
			{
				"countryName": "Belgium",
				"name"       : "Antwerp",
				"state_code" : "VAN"
			},
			{
				"countryName": "Belgium",
				"name"       : "Brussels-Capital Region",
				"state_code" : "BRU"
			},
			{
				"countryName": "Belgium",
				"name"       : "East Flanders",
				"state_code" : "VOV"
			},
			{
				"countryName": "Belgium",
				"name"       : "Flanders",
				"state_code" : "VLG"
			},
			{
				"countryName": "Belgium",
				"name"       : "Flemish Brabant",
				"state_code" : "VBR"
			},
			{
				"countryName": "Belgium",
				"name"       : "Hainaut",
				"state_code" : "WHT"
			},
			{
				"countryName": "Belgium",
				"name"       : "Liège",
				"state_code" : "WLG"
			},
			{
				"countryName": "Belgium",
				"name"       : "Limburg",
				"state_code" : "VLI"
			},
			{
				"countryName": "Belgium",
				"name"       : "Luxembourg",
				"state_code" : "WLX"
			},
			{
				"countryName": "Belgium",
				"name"       : "Namur",
				"state_code" : "WNA"
			},
			{
				"countryName": "Belgium",
				"name"       : "Wallonia",
				"state_code" : "WAL"
			},
			{
				"countryName": "Belgium",
				"name"       : "Walloon Brabant",
				"state_code" : "WBR"
			},
			{
				"countryName": "Belgium",
				"name"       : "West Flanders",
				"state_code" : "VWV"
			}
		],
		"BZ": [
			{
				"countryName": "Belize",
				"name"       : "Belize District",
				"state_code" : "BZ"
			},
			{
				"countryName": "Belize",
				"name"       : "Cayo District",
				"state_code" : "CY"
			},
			{
				"countryName": "Belize",
				"name"       : "Corozal District",
				"state_code" : "CZL"
			},
			{
				"countryName": "Belize",
				"name"       : "Orange Walk District",
				"state_code" : "OW"
			},
			{
				"countryName": "Belize",
				"name"       : "Stann Creek District",
				"state_code" : "SC"
			},
			{
				"countryName": "Belize",
				"name"       : "Toledo District",
				"state_code" : "TOL"
			}
		],
		"BJ": [
			{
				"countryName": "Benin",
				"name"       : "Alibori Department",
				"state_code" : "AL"
			},
			{
				"countryName": "Benin",
				"name"       : "Atakora Department",
				"state_code" : "AK"
			},
			{
				"countryName": "Benin",
				"name"       : "Atlantique Department",
				"state_code" : "AQ"
			},
			{
				"countryName": "Benin",
				"name"       : "Borgou Department",
				"state_code" : "BO"
			},
			{
				"countryName": "Benin",
				"name"       : "Collines Department",
				"state_code" : "CO"
			},
			{
				"countryName": "Benin",
				"name"       : "Donga Department",
				"state_code" : "DO"
			},
			{
				"countryName": "Benin",
				"name"       : "Kouffo Department",
				"state_code" : "KO"
			},
			{
				"countryName": "Benin",
				"name"       : "Littoral Department",
				"state_code" : "LI"
			},
			{
				"countryName": "Benin",
				"name"       : "Mono Department",
				"state_code" : "MO"
			},
			{
				"countryName": "Benin",
				"name"       : "Ouémé Department",
				"state_code" : "OU"
			},
			{
				"countryName": "Benin",
				"name"       : "Plateau Department",
				"state_code" : "PL"
			},
			{
				"countryName": "Benin",
				"name"       : "Zou Department",
				"state_code" : "ZO"
			}
		],
		"BM": [
			{
				"countryName": "Bermuda",
				"name"       : "Devonshire Parish",
				"state_code" : "DEV"
			},
			{
				"countryName": "Bermuda",
				"name"       : "Hamilton Parish",
				"state_code" : "HA"
			},
			{
				"countryName": "Bermuda",
				"name"       : "Paget Parish",
				"state_code" : "PAG"
			},
			{
				"countryName": "Bermuda",
				"name"       : "Pembroke Parish",
				"state_code" : "PEM"
			},
			{
				"countryName": "Bermuda",
				"name"       : "Saint George's Parish",
				"state_code" : "SGE"
			},
			{
				"countryName": "Bermuda",
				"name"       : "Sandys Parish",
				"state_code" : "SAN"
			},
			{
				"countryName": "Bermuda",
				"name"       : "Smith's Parish,",
				"state_code" : "SMI"
			},
			{
				"countryName": "Bermuda",
				"name"       : "Southampton Parish",
				"state_code" : "SOU"
			},
			{
				"countryName": "Bermuda",
				"name"       : "Warwick Parish",
				"state_code" : "WAR"
			}
		],
		"BT": [
			{
				"countryName": "Bhutan",
				"name"       : "Bumthang District",
				"state_code" : "33"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Chukha District",
				"state_code" : "12"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Dagana District",
				"state_code" : "22"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Gasa District",
				"state_code" : "GA"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Haa District",
				"state_code" : "13"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Lhuntse District",
				"state_code" : "44"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Mongar District",
				"state_code" : "42"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Paro District",
				"state_code" : "11"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Pemagatshel District",
				"state_code" : "43"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Punakha District",
				"state_code" : "23"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Samdrup Jongkhar District",
				"state_code" : "45"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Samtse District",
				"state_code" : "14"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Sarpang District",
				"state_code" : "31"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Thimphu District",
				"state_code" : "15"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Trashigang District",
				"state_code" : "41"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Trongsa District",
				"state_code" : "32"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Tsirang District",
				"state_code" : "21"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Wangdue Phodrang District",
				"state_code" : "24"
			},
			{
				"countryName": "Bhutan",
				"name"       : "Zhemgang District",
				"state_code" : "34"
			}
		],
		"BO": [
			{
				"countryName": "Bolivia",
				"name"       : "Beni Department",
				"state_code" : "B"
			},
			{
				"countryName": "Bolivia",
				"name"       : "Chuquisaca Department",
				"state_code" : "H"
			},
			{
				"countryName": "Bolivia",
				"name"       : "Cochabamba Department",
				"state_code" : "C"
			},
			{
				"countryName": "Bolivia",
				"name"       : "La Paz Department",
				"state_code" : "L"
			},
			{
				"countryName": "Bolivia",
				"name"       : "Oruro Department",
				"state_code" : "O"
			},
			{
				"countryName": "Bolivia",
				"name"       : "Pando Department",
				"state_code" : "N"
			},
			{
				"countryName": "Bolivia",
				"name"       : "Potosí Department",
				"state_code" : "P"
			},
			{
				"countryName": "Bolivia",
				"name"       : "Santa Cruz Department",
				"state_code" : "S"
			},
			{
				"countryName": "Bolivia",
				"name"       : "Tarija Department",
				"state_code" : "T"
			}
		],
		"BQ": [
			{
				"countryName": "Bonaire, Sint Eustatius and Saba",
				"name"       : "Bonaire",
				"state_code" : "BQ1"
			},
			{
				"countryName": "Bonaire, Sint Eustatius and Saba",
				"name"       : "Saba",
				"state_code" : "BQ2"
			},
			{
				"countryName": "Bonaire, Sint Eustatius and Saba",
				"name"       : "Sint Eustatius",
				"state_code" : "BQ3"
			}
		],
		"BA": [
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Bosnian Podrinje Canton",
				"state_code" : "05"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Brčko District",
				"state_code" : "BRC"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Canton 10",
				"state_code" : "10"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Central Bosnia Canton",
				"state_code" : "06"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Federation of Bosnia and Herzegovina",
				"state_code" : "BIH"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Herzegovina-Neretva Canton",
				"state_code" : "07"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Posavina Canton",
				"state_code" : "02"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Republika Srpska",
				"state_code" : "SRP"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Sarajevo Canton",
				"state_code" : "09"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Tuzla Canton",
				"state_code" : "03"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Una-Sana Canton",
				"state_code" : "01"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "West Herzegovina Canton",
				"state_code" : "08"
			},
			{
				"countryName": "Bosnia and Herzegovina",
				"name"       : "Zenica-Doboj Canton",
				"state_code" : "04"
			}
		],
		"BW": [
			{
				"countryName": "Botswana",
				"name"       : "Central District",
				"state_code" : "CE"
			},
			{
				"countryName": "Botswana",
				"name"       : "Ghanzi District",
				"state_code" : "GH"
			},
			{
				"countryName": "Botswana",
				"name"       : "Kgalagadi District",
				"state_code" : "KG"
			},
			{
				"countryName": "Botswana",
				"name"       : "Kgatleng District",
				"state_code" : "KL"
			},
			{
				"countryName": "Botswana",
				"name"       : "Kweneng District",
				"state_code" : "KW"
			},
			{
				"countryName": "Botswana",
				"name"       : "Ngamiland",
				"state_code" : "NG"
			},
			{
				"countryName": "Botswana",
				"name"       : "North-East District",
				"state_code" : "NE"
			},
			{
				"countryName": "Botswana",
				"name"       : "North-West District",
				"state_code" : "NW"
			},
			{
				"countryName": "Botswana",
				"name"       : "South-East District",
				"state_code" : "SE"
			},
			{
				"countryName": "Botswana",
				"name"       : "Southern District",
				"state_code" : "SO"
			}
		],
		"BV": [],
		"BR": [
			{
				"countryName": "Brazil",
				"name"       : "Acre",
				"state_code" : "AC"
			},
			{
				"countryName": "Brazil",
				"name"       : "Alagoas",
				"state_code" : "AL"
			},
			{
				"countryName": "Brazil",
				"name"       : "Amapá",
				"state_code" : "AP"
			},
			{
				"countryName": "Brazil",
				"name"       : "Amazonas",
				"state_code" : "AM"
			},
			{
				"countryName": "Brazil",
				"name"       : "Bahia",
				"state_code" : "BA"
			},
			{
				"countryName": "Brazil",
				"name"       : "Ceará",
				"state_code" : "CE"
			},
			{
				"countryName": "Brazil",
				"name"       : "Distrito Federal",
				"state_code" : "DF"
			},
			{
				"countryName": "Brazil",
				"name"       : "Espírito Santo",
				"state_code" : "ES"
			},
			{
				"countryName": "Brazil",
				"name"       : "Goiás",
				"state_code" : "GO"
			},
			{
				"countryName": "Brazil",
				"name"       : "Maranhão",
				"state_code" : "MA"
			},
			{
				"countryName": "Brazil",
				"name"       : "Mato Grosso",
				"state_code" : "MT"
			},
			{
				"countryName": "Brazil",
				"name"       : "Mato Grosso do Sul",
				"state_code" : "MS"
			},
			{
				"countryName": "Brazil",
				"name"       : "Minas Gerais",
				"state_code" : "MG"
			},
			{
				"countryName": "Brazil",
				"name"       : "Pará",
				"state_code" : "PA"
			},
			{
				"countryName": "Brazil",
				"name"       : "Paraíba",
				"state_code" : "PB"
			},
			{
				"countryName": "Brazil",
				"name"       : "Paraná",
				"state_code" : "PR"
			},
			{
				"countryName": "Brazil",
				"name"       : "Pernambuco",
				"state_code" : "PE"
			},
			{
				"countryName": "Brazil",
				"name"       : "Piauí",
				"state_code" : "PI"
			},
			{
				"countryName": "Brazil",
				"name"       : "Rio de Janeiro",
				"state_code" : "RJ"
			},
			{
				"countryName": "Brazil",
				"name"       : "Rio Grande do Norte",
				"state_code" : "RN"
			},
			{
				"countryName": "Brazil",
				"name"       : "Rio Grande do Sul",
				"state_code" : "RS"
			},
			{
				"countryName": "Brazil",
				"name"       : "Rondônia",
				"state_code" : "RO"
			},
			{
				"countryName": "Brazil",
				"name"       : "Roraima",
				"state_code" : "RR"
			},
			{
				"countryName": "Brazil",
				"name"       : "Santa Catarina",
				"state_code" : "SC"
			},
			{
				"countryName": "Brazil",
				"name"       : "São Paulo",
				"state_code" : "SP"
			},
			{
				"countryName": "Brazil",
				"name"       : "Sergipe",
				"state_code" : "SE"
			},
			{
				"countryName": "Brazil",
				"name"       : "Tocantins",
				"state_code" : "TO"
			}
		],
		"IO": [],
		"BN": [
			{
				"countryName": "Brunei",
				"name"       : "Belait District",
				"state_code" : "BE"
			},
			{
				"countryName": "Brunei",
				"name"       : "Brunei-Muara District",
				"state_code" : "BM"
			},
			{
				"countryName": "Brunei",
				"name"       : "Temburong District",
				"state_code" : "TE"
			},
			{
				"countryName": "Brunei",
				"name"       : "Tutong District",
				"state_code" : "TU"
			}
		],
		"BG": [
			{
				"countryName": "Bulgaria",
				"name"       : "Blagoevgrad Province",
				"state_code" : "01"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Burgas Province",
				"state_code" : "02"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Dobrich Province",
				"state_code" : "08"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Gabrovo Province",
				"state_code" : "07"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Haskovo Province",
				"state_code" : "26"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Kardzhali Province",
				"state_code" : "09"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Kyustendil Province",
				"state_code" : "10"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Lovech Province",
				"state_code" : "11"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Montana Province",
				"state_code" : "12"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Pazardzhik Province",
				"state_code" : "13"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Pernik Province",
				"state_code" : "14"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Pleven Province",
				"state_code" : "15"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Plovdiv Province",
				"state_code" : "16"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Razgrad Province",
				"state_code" : "17"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Ruse Province",
				"state_code" : "18"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Shumen",
				"state_code" : "27"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Silistra Province",
				"state_code" : "19"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Sliven Province",
				"state_code" : "20"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Smolyan Province",
				"state_code" : "21"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Sofia City Province",
				"state_code" : "22"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Sofia Province",
				"state_code" : "23"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Stara Zagora Province",
				"state_code" : "24"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Targovishte Province",
				"state_code" : "25"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Varna Province",
				"state_code" : "03"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Veliko Tarnovo Province",
				"state_code" : "04"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Vidin Province",
				"state_code" : "05"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Vratsa Province",
				"state_code" : "06"
			},
			{
				"countryName": "Bulgaria",
				"name"       : "Yambol Province",
				"state_code" : "28"
			}
		],
		"BF": [
			{
				"countryName": "Burkina Faso",
				"name"       : "Balé Province",
				"state_code" : "BAL"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Bam Province",
				"state_code" : "BAM"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Banwa Province",
				"state_code" : "BAN"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Bazèga Province",
				"state_code" : "BAZ"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Boucle du Mouhoun Region",
				"state_code" : "01"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Bougouriba Province",
				"state_code" : "BGR"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Boulgou",
				"state_code" : "BLG"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Cascades Region",
				"state_code" : "02"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Centre",
				"state_code" : "03"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Centre-Est Region",
				"state_code" : "04"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Centre-Nord Region",
				"state_code" : "05"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Centre-Ouest Region",
				"state_code" : "06"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Centre-Sud Region",
				"state_code" : "07"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Comoé Province",
				"state_code" : "COM"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Est Region",
				"state_code" : "08"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Ganzourgou Province",
				"state_code" : "GAN"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Gnagna Province",
				"state_code" : "GNA"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Gourma Province",
				"state_code" : "GOU"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Hauts-Bassins Region",
				"state_code" : "09"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Houet Province",
				"state_code" : "HOU"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Ioba Province",
				"state_code" : "IOB"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Kadiogo Province",
				"state_code" : "KAD"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Kénédougou Province",
				"state_code" : "KEN"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Komondjari Province",
				"state_code" : "KMD"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Kompienga Province",
				"state_code" : "KMP"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Kossi Province",
				"state_code" : "KOS"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Koulpélogo Province",
				"state_code" : "KOP"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Kouritenga Province",
				"state_code" : "KOT"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Kourwéogo Province",
				"state_code" : "KOW"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Léraba Province",
				"state_code" : "LER"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Loroum Province",
				"state_code" : "LOR"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Mouhoun",
				"state_code" : "MOU"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Nahouri Province",
				"state_code" : "NAO"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Namentenga Province",
				"state_code" : "NAM"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Nayala Province",
				"state_code" : "NAY"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Nord Region, Burkina Faso",
				"state_code" : "10"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Noumbiel Province",
				"state_code" : "NOU"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Oubritenga Province",
				"state_code" : "OUB"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Oudalan Province",
				"state_code" : "OUD"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Passoré Province",
				"state_code" : "PAS"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Plateau-Central Region",
				"state_code" : "11"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Poni Province",
				"state_code" : "PON"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Sahel Region",
				"state_code" : "12"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Sanguié Province",
				"state_code" : "SNG"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Sanmatenga Province",
				"state_code" : "SMT"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Séno Province",
				"state_code" : "SEN"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Sissili Province",
				"state_code" : "SIS"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Soum Province",
				"state_code" : "SOM"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Sourou Province",
				"state_code" : "SOR"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Sud-Ouest Region",
				"state_code" : "13"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Tapoa Province",
				"state_code" : "TAP"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Tuy Province",
				"state_code" : "TUI"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Yagha Province",
				"state_code" : "YAG"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Yatenga Province",
				"state_code" : "YAT"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Ziro Province",
				"state_code" : "ZIR"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Zondoma Province",
				"state_code" : "ZON"
			},
			{
				"countryName": "Burkina Faso",
				"name"       : "Zoundwéogo Province",
				"state_code" : "ZOU"
			}
		],
		"BI": [
			{
				"countryName": "Burundi",
				"name"       : "Bubanza Province",
				"state_code" : "BB"
			},
			{
				"countryName": "Burundi",
				"name"       : "Bujumbura Mairie Province",
				"state_code" : "BM"
			},
			{
				"countryName": "Burundi",
				"name"       : "Bujumbura Rural Province",
				"state_code" : "BL"
			},
			{
				"countryName": "Burundi",
				"name"       : "Bururi Province",
				"state_code" : "BR"
			},
			{
				"countryName": "Burundi",
				"name"       : "Cankuzo Province",
				"state_code" : "CA"
			},
			{
				"countryName": "Burundi",
				"name"       : "Cibitoke Province",
				"state_code" : "CI"
			},
			{
				"countryName": "Burundi",
				"name"       : "Gitega Province",
				"state_code" : "GI"
			},
			{
				"countryName": "Burundi",
				"name"       : "Karuzi Province",
				"state_code" : "KR"
			},
			{
				"countryName": "Burundi",
				"name"       : "Kayanza Province",
				"state_code" : "KY"
			},
			{
				"countryName": "Burundi",
				"name"       : "Kirundo Province",
				"state_code" : "KI"
			},
			{
				"countryName": "Burundi",
				"name"       : "Makamba Province",
				"state_code" : "MA"
			},
			{
				"countryName": "Burundi",
				"name"       : "Muramvya Province",
				"state_code" : "MU"
			},
			{
				"countryName": "Burundi",
				"name"       : "Muyinga Province",
				"state_code" : "MY"
			},
			{
				"countryName": "Burundi",
				"name"       : "Mwaro Province",
				"state_code" : "MW"
			},
			{
				"countryName": "Burundi",
				"name"       : "Ngozi Province",
				"state_code" : "NG"
			},
			{
				"countryName": "Burundi",
				"name"       : "Rumonge Province",
				"state_code" : "RM"
			},
			{
				"countryName": "Burundi",
				"name"       : "Rutana Province",
				"state_code" : "RT"
			},
			{
				"countryName": "Burundi",
				"name"       : "Ruyigi Province",
				"state_code" : "RY"
			}
		],
		"KH": [
			{
				"countryName": "Cambodia",
				"name"       : "Banteay Meanchey",
				"state_code" : "1"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Battambang",
				"state_code" : "2"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Kampong Cham",
				"state_code" : "3"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Kampong Chhnang",
				"state_code" : "4"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Kampong Speu",
				"state_code" : "5"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Kampong Thom",
				"state_code" : "6"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Kampot",
				"state_code" : "7"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Kandal",
				"state_code" : "8"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Kep",
				"state_code" : "23"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Koh Kong",
				"state_code" : "9"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Kratie",
				"state_code" : "10"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Mondulkiri",
				"state_code" : "11"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Oddar Meanchey",
				"state_code" : "22"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Pailin",
				"state_code" : "24"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Phnom Penh",
				"state_code" : "12"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Preah Vihear",
				"state_code" : "13"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Prey Veng",
				"state_code" : "14"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Pursat",
				"state_code" : "15"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Ratanakiri",
				"state_code" : "16"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Siem Reap",
				"state_code" : "17"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Sihanoukville",
				"state_code" : "18"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Stung Treng",
				"state_code" : "19"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Svay Rieng",
				"state_code" : "20"
			},
			{
				"countryName": "Cambodia",
				"name"       : "Takeo",
				"state_code" : "21"
			}
		],
		"CM": [
			{
				"countryName": "Cameroon",
				"name"       : "Adamawa",
				"state_code" : "AD"
			},
			{
				"countryName": "Cameroon",
				"name"       : "Centre",
				"state_code" : "CE"
			},
			{
				"countryName": "Cameroon",
				"name"       : "East",
				"state_code" : "ES"
			},
			{
				"countryName": "Cameroon",
				"name"       : "Far North",
				"state_code" : "EN"
			},
			{
				"countryName": "Cameroon",
				"name"       : "Littoral",
				"state_code" : "LT"
			},
			{
				"countryName": "Cameroon",
				"name"       : "North",
				"state_code" : "NO"
			},
			{
				"countryName": "Cameroon",
				"name"       : "Northwest",
				"state_code" : "NW"
			},
			{
				"countryName": "Cameroon",
				"name"       : "South",
				"state_code" : "SU"
			},
			{
				"countryName": "Cameroon",
				"name"       : "Southwest",
				"state_code" : "SW"
			},
			{
				"countryName": "Cameroon",
				"name"       : "West",
				"state_code" : "OU"
			}
		],
		"CA": [
			{
				"countryName": "Canada",
				"name"       : "Alberta",
				"state_code" : "AB"
			},
			{
				"countryName": "Canada",
				"name"       : "British Columbia",
				"state_code" : "BC"
			},
			{
				"countryName": "Canada",
				"name"       : "Manitoba",
				"state_code" : "MB"
			},
			{
				"countryName": "Canada",
				"name"       : "New Brunswick",
				"state_code" : "NB"
			},
			{
				"countryName": "Canada",
				"name"       : "Newfoundland and Labrador",
				"state_code" : "NL"
			},
			{
				"countryName": "Canada",
				"name"       : "Northwest Territories",
				"state_code" : "NT"
			},
			{
				"countryName": "Canada",
				"name"       : "Nova Scotia",
				"state_code" : "NS"
			},
			{
				"countryName": "Canada",
				"name"       : "Nunavut",
				"state_code" : "NU"
			},
			{
				"countryName": "Canada",
				"name"       : "Ontario",
				"state_code" : "ON"
			},
			{
				"countryName": "Canada",
				"name"       : "Prince Edward Island",
				"state_code" : "PE"
			},
			{
				"countryName": "Canada",
				"name"       : "Quebec",
				"state_code" : "QC"
			},
			{
				"countryName": "Canada",
				"name"       : "Saskatchewan",
				"state_code" : "SK"
			},
			{
				"countryName": "Canada",
				"name"       : "Yukon",
				"state_code" : "YT"
			}
		],
		"CV": [
			{
				"countryName": "Cape Verde",
				"name"       : "Barlavento Islands",
				"state_code" : "B"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Boa Vista",
				"state_code" : "BV"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Brava",
				"state_code" : "BR"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Maio Municipality",
				"state_code" : "MA"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Mosteiros",
				"state_code" : "MO"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Paul",
				"state_code" : "PA"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Porto Novo",
				"state_code" : "PN"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Praia",
				"state_code" : "PR"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Ribeira Brava Municipality",
				"state_code" : "RB"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Ribeira Grande",
				"state_code" : "RG"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Ribeira Grande de Santiago",
				"state_code" : "RS"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Sal",
				"state_code" : "SL"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Santa Catarina",
				"state_code" : "CA"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Santa Catarina do Fogo",
				"state_code" : "CF"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Santa Cruz",
				"state_code" : "CR"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "São Domingos",
				"state_code" : "SD"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "São Filipe",
				"state_code" : "SF"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "São Lourenço dos Órgãos",
				"state_code" : "SO"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "São Miguel",
				"state_code" : "SM"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "São Vicente",
				"state_code" : "SV"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Sotavento Islands",
				"state_code" : "S"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Tarrafal",
				"state_code" : "TA"
			},
			{
				"countryName": "Cape Verde",
				"name"       : "Tarrafal de São Nicolau",
				"state_code" : "TS"
			}
		],
		"KY": [],
		"CF": [
			{
				"countryName": "Central African Republic",
				"name"       : "Bamingui-Bangoran Prefecture",
				"state_code" : "BB"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Bangui",
				"state_code" : "BGF"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Basse-Kotto Prefecture",
				"state_code" : "BK"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Haut-Mbomou Prefecture",
				"state_code" : "HM"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Haute-Kotto Prefecture",
				"state_code" : "HK"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Kémo Prefecture",
				"state_code" : "KG"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Lobaye Prefecture",
				"state_code" : "LB"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Mambéré-Kadéï",
				"state_code" : "HS"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Mbomou Prefecture",
				"state_code" : "MB"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Nana-Grébizi Economic Prefecture",
				"state_code" : "KB"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Nana-Mambéré Prefecture",
				"state_code" : "NM"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Ombella-M'Poko Prefecture",
				"state_code" : "MP"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Ouaka Prefecture",
				"state_code" : "UK"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Ouham Prefecture",
				"state_code" : "AC"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Ouham-Pendé Prefecture",
				"state_code" : "OP"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Sangha-Mbaéré",
				"state_code" : "SE"
			},
			{
				"countryName": "Central African Republic",
				"name"       : "Vakaga Prefecture",
				"state_code" : "VK"
			}
		],
		"TD": [
			{
				"countryName": "Chad",
				"name"       : "Bahr el Gazel",
				"state_code" : "BG"
			},
			{
				"countryName": "Chad",
				"name"       : "Batha",
				"state_code" : "BA"
			},
			{
				"countryName": "Chad",
				"name"       : "Borkou",
				"state_code" : "BO"
			},
			{
				"countryName": "Chad",
				"name"       : "Chari-Baguirmi",
				"state_code" : "CB"
			},
			{
				"countryName": "Chad",
				"name"       : "Ennedi-Est",
				"state_code" : "EE"
			},
			{
				"countryName": "Chad",
				"name"       : "Ennedi-Ouest",
				"state_code" : "EO"
			},
			{
				"countryName": "Chad",
				"name"       : "Guéra",
				"state_code" : "GR"
			},
			{
				"countryName": "Chad",
				"name"       : "Hadjer-Lamis",
				"state_code" : "HL"
			},
			{
				"countryName": "Chad",
				"name"       : "Kanem",
				"state_code" : "KA"
			},
			{
				"countryName": "Chad",
				"name"       : "Lac",
				"state_code" : "LC"
			},
			{
				"countryName": "Chad",
				"name"       : "Logone Occidental",
				"state_code" : "LO"
			},
			{
				"countryName": "Chad",
				"name"       : "Logone Oriental",
				"state_code" : "LR"
			},
			{
				"countryName": "Chad",
				"name"       : "Mandoul",
				"state_code" : "MA"
			},
			{
				"countryName": "Chad",
				"name"       : "Mayo-Kebbi Est",
				"state_code" : "ME"
			},
			{
				"countryName": "Chad",
				"name"       : "Mayo-Kebbi Ouest",
				"state_code" : "MO"
			},
			{
				"countryName": "Chad",
				"name"       : "Moyen-Chari",
				"state_code" : "MC"
			},
			{
				"countryName": "Chad",
				"name"       : "N'Djamena",
				"state_code" : "ND"
			},
			{
				"countryName": "Chad",
				"name"       : "Ouaddaï",
				"state_code" : "OD"
			},
			{
				"countryName": "Chad",
				"name"       : "Salamat",
				"state_code" : "SA"
			},
			{
				"countryName": "Chad",
				"name"       : "Sila",
				"state_code" : "SI"
			},
			{
				"countryName": "Chad",
				"name"       : "Tandjilé",
				"state_code" : "TA"
			},
			{
				"countryName": "Chad",
				"name"       : "Tibesti",
				"state_code" : "TI"
			},
			{
				"countryName": "Chad",
				"name"       : "Wadi Fira",
				"state_code" : "WF"
			}
		],
		"CL": [
			{
				"countryName": "Chile",
				"name"       : "Aisén del General Carlos Ibañez del Campo",
				"state_code" : "AI"
			},
			{
				"countryName": "Chile",
				"name"       : "Antofagasta",
				"state_code" : "AN"
			},
			{
				"countryName": "Chile",
				"name"       : "Arica y Parinacota",
				"state_code" : "AP"
			},
			{
				"countryName": "Chile",
				"name"       : "Atacama",
				"state_code" : "AT"
			},
			{
				"countryName": "Chile",
				"name"       : "Biobío",
				"state_code" : "BI"
			},
			{
				"countryName": "Chile",
				"name"       : "Coquimbo",
				"state_code" : "CO"
			},
			{
				"countryName": "Chile",
				"name"       : "La Araucanía",
				"state_code" : "AR"
			},
			{
				"countryName": "Chile",
				"name"       : "Libertador General Bernardo O'Higgins",
				"state_code" : "LI"
			},
			{
				"countryName": "Chile",
				"name"       : "Los Lagos",
				"state_code" : "LL"
			},
			{
				"countryName": "Chile",
				"name"       : "Los Ríos",
				"state_code" : "LR"
			},
			{
				"countryName": "Chile",
				"name"       : "Magallanes y de la Antártica Chilena",
				"state_code" : "MA"
			},
			{
				"countryName": "Chile",
				"name"       : "Maule",
				"state_code" : "ML"
			},
			{
				"countryName": "Chile",
				"name"       : "Ñuble",
				"state_code" : "NB"
			},
			{
				"countryName": "Chile",
				"name"       : "Región Metropolitana de Santiago",
				"state_code" : "RM"
			},
			{
				"countryName": "Chile",
				"name"       : "Tarapacá",
				"state_code" : "TA"
			},
			{
				"countryName": "Chile",
				"name"       : "Valparaíso",
				"state_code" : "VS"
			}
		],
		"CN": [
			{
				"countryName": "China",
				"name"       : "Anhui",
				"state_code" : "AH"
			},
			{
				"countryName": "China",
				"name"       : "Beijing",
				"state_code" : "BJ"
			},
			{
				"countryName": "China",
				"name"       : "Chongqing",
				"state_code" : "CQ"
			},
			{
				"countryName": "China",
				"name"       : "Fujian",
				"state_code" : "FJ"
			},
			{
				"countryName": "China",
				"name"       : "Gansu",
				"state_code" : "GS"
			},
			{
				"countryName": "China",
				"name"       : "Guangdong",
				"state_code" : "GD"
			},
			{
				"countryName": "China",
				"name"       : "Guangxi Zhuang",
				"state_code" : "GX"
			},
			{
				"countryName": "China",
				"name"       : "Guizhou",
				"state_code" : "GZ"
			},
			{
				"countryName": "China",
				"name"       : "Hainan",
				"state_code" : "HI"
			},
			{
				"countryName": "China",
				"name"       : "Hebei",
				"state_code" : "HE"
			},
			{
				"countryName": "China",
				"name"       : "Heilongjiang",
				"state_code" : "HL"
			},
			{
				"countryName": "China",
				"name"       : "Henan",
				"state_code" : "HA"
			},
			{
				"countryName": "China",
				"name"       : "Hong Kong SAR",
				"state_code" : "HK"
			},
			{
				"countryName": "China",
				"name"       : "Hubei",
				"state_code" : "HB"
			},
			{
				"countryName": "China",
				"name"       : "Hunan",
				"state_code" : "HN"
			},
			{
				"countryName": "China",
				"name"       : "Inner Mongolia",
				"state_code" : "NM"
			},
			{
				"countryName": "China",
				"name"       : "Jiangsu",
				"state_code" : "JS"
			},
			{
				"countryName": "China",
				"name"       : "Jiangxi",
				"state_code" : "JX"
			},
			{
				"countryName": "China",
				"name"       : "Jilin",
				"state_code" : "JL"
			},
			{
				"countryName": "China",
				"name"       : "Liaoning",
				"state_code" : "LN"
			},
			{
				"countryName": "China",
				"name"       : "Macau SAR",
				"state_code" : "MO"
			},
			{
				"countryName": "China",
				"name"       : "Ningxia Huizu",
				"state_code" : "NX"
			},
			{
				"countryName": "China",
				"name"       : "Qinghai",
				"state_code" : "QH"
			},
			{
				"countryName": "China",
				"name"       : "Shaanxi",
				"state_code" : "SN"
			},
			{
				"countryName": "China",
				"name"       : "Shandong",
				"state_code" : "SD"
			},
			{
				"countryName": "China",
				"name"       : "Shanghai",
				"state_code" : "SH"
			},
			{
				"countryName": "China",
				"name"       : "Shanxi",
				"state_code" : "SX"
			},
			{
				"countryName": "China",
				"name"       : "Sichuan",
				"state_code" : "SC"
			},
			{
				"countryName": "China",
				"name"       : "Taiwan",
				"state_code" : "TW"
			},
			{
				"countryName": "China",
				"name"       : "Tianjin",
				"state_code" : "TJ"
			},
			{
				"countryName": "China",
				"name"       : "Xinjiang",
				"state_code" : "XJ"
			},
			{
				"countryName": "China",
				"name"       : "Xizang",
				"state_code" : "XZ"
			},
			{
				"countryName": "China",
				"name"       : "Yunnan",
				"state_code" : "YN"
			},
			{
				"countryName": "China",
				"name"       : "Zhejiang",
				"state_code" : "ZJ"
			}
		],
		"CX": [],
		"CC": [],
		"CO": [
			{
				"countryName": "Colombia",
				"name"       : "Amazonas",
				"state_code" : "AMA"
			},
			{
				"countryName": "Colombia",
				"name"       : "Antioquia",
				"state_code" : "ANT"
			},
			{
				"countryName": "Colombia",
				"name"       : "Arauca",
				"state_code" : "ARA"
			},
			{
				"countryName": "Colombia",
				"name"       : "Archipiélago de San Andrés, Providencia y Santa Catalina",
				"state_code" : "SAP"
			},
			{
				"countryName": "Colombia",
				"name"       : "Atlántico",
				"state_code" : "ATL"
			},
			{
				"countryName": "Colombia",
				"name"       : "Bogotá D.C.",
				"state_code" : "DC"
			},
			{
				"countryName": "Colombia",
				"name"       : "Bolívar",
				"state_code" : "BOL"
			},
			{
				"countryName": "Colombia",
				"name"       : "Boyacá",
				"state_code" : "BOY"
			},
			{
				"countryName": "Colombia",
				"name"       : "Caldas",
				"state_code" : "CAL"
			},
			{
				"countryName": "Colombia",
				"name"       : "Caquetá",
				"state_code" : "CAQ"
			},
			{
				"countryName": "Colombia",
				"name"       : "Casanare",
				"state_code" : "CAS"
			},
			{
				"countryName": "Colombia",
				"name"       : "Cauca",
				"state_code" : "CAU"
			},
			{
				"countryName": "Colombia",
				"name"       : "Cesar",
				"state_code" : "CES"
			},
			{
				"countryName": "Colombia",
				"name"       : "Chocó",
				"state_code" : "CHO"
			},
			{
				"countryName": "Colombia",
				"name"       : "Córdoba",
				"state_code" : "COR"
			},
			{
				"countryName": "Colombia",
				"name"       : "Cundinamarca",
				"state_code" : "CUN"
			},
			{
				"countryName": "Colombia",
				"name"       : "Guainía",
				"state_code" : "GUA"
			},
			{
				"countryName": "Colombia",
				"name"       : "Guaviare",
				"state_code" : "GUV"
			},
			{
				"countryName": "Colombia",
				"name"       : "Huila",
				"state_code" : "HUI"
			},
			{
				"countryName": "Colombia",
				"name"       : "La Guajira",
				"state_code" : "LAG"
			},
			{
				"countryName": "Colombia",
				"name"       : "Magdalena",
				"state_code" : "MAG"
			},
			{
				"countryName": "Colombia",
				"name"       : "Meta",
				"state_code" : "MET"
			},
			{
				"countryName": "Colombia",
				"name"       : "Nariño",
				"state_code" : "NAR"
			},
			{
				"countryName": "Colombia",
				"name"       : "Norte de Santander",
				"state_code" : "NSA"
			},
			{
				"countryName": "Colombia",
				"name"       : "Putumayo",
				"state_code" : "PUT"
			},
			{
				"countryName": "Colombia",
				"name"       : "Quindío",
				"state_code" : "QUI"
			},
			{
				"countryName": "Colombia",
				"name"       : "Risaralda",
				"state_code" : "RIS"
			},
			{
				"countryName": "Colombia",
				"name"       : "Santander",
				"state_code" : "SAN"
			},
			{
				"countryName": "Colombia",
				"name"       : "Sucre",
				"state_code" : "SUC"
			},
			{
				"countryName": "Colombia",
				"name"       : "Tolima",
				"state_code" : "TOL"
			},
			{
				"countryName": "Colombia",
				"name"       : "Valle del Cauca",
				"state_code" : "VAC"
			},
			{
				"countryName": "Colombia",
				"name"       : "Vaupés",
				"state_code" : "VAU"
			},
			{
				"countryName": "Colombia",
				"name"       : "Vichada",
				"state_code" : "VID"
			}
		],
		"KM": [
			{
				"countryName": "Comoros",
				"name"       : "Anjouan",
				"state_code" : "A"
			},
			{
				"countryName": "Comoros",
				"name"       : "Grande Comore",
				"state_code" : "G"
			},
			{
				"countryName": "Comoros",
				"name"       : "Mohéli",
				"state_code" : "M"
			}
		],
		"CG": [
			{
				"countryName": "Congo",
				"name"       : "Bouenza Department",
				"state_code" : "11"
			},
			{
				"countryName": "Congo",
				"name"       : "Brazzaville",
				"state_code" : "BZV"
			},
			{
				"countryName": "Congo",
				"name"       : "Cuvette Department",
				"state_code" : "8"
			},
			{
				"countryName": "Congo",
				"name"       : "Cuvette-Ouest Department",
				"state_code" : "15"
			},
			{
				"countryName": "Congo",
				"name"       : "Kouilou Department",
				"state_code" : "5"
			},
			{
				"countryName": "Congo",
				"name"       : "Lékoumou Department",
				"state_code" : "2"
			},
			{
				"countryName": "Congo",
				"name"       : "Likouala Department",
				"state_code" : "7"
			},
			{
				"countryName": "Congo",
				"name"       : "Niari Department",
				"state_code" : "9"
			},
			{
				"countryName": "Congo",
				"name"       : "Plateaux Department",
				"state_code" : "14"
			},
			{
				"countryName": "Congo",
				"name"       : "Pointe-Noire",
				"state_code" : "16"
			},
			{
				"countryName": "Congo",
				"name"       : "Pool Department",
				"state_code" : "12"
			},
			{
				"countryName": "Congo",
				"name"       : "Sangha Department",
				"state_code" : "13"
			}
		],
		"CK": [],
		"CR": [
			{
				"countryName": "Costa Rica",
				"name"       : "Alajuela Province",
				"state_code" : "A"
			},
			{
				"countryName": "Costa Rica",
				"name"       : "Guanacaste Province",
				"state_code" : "G"
			},
			{
				"countryName": "Costa Rica",
				"name"       : "Heredia Province",
				"state_code" : "H"
			},
			{
				"countryName": "Costa Rica",
				"name"       : "Limón Province",
				"state_code" : "L"
			},
			{
				"countryName": "Costa Rica",
				"name"       : "Provincia de Cartago",
				"state_code" : "C"
			},
			{
				"countryName": "Costa Rica",
				"name"       : "Puntarenas Province",
				"state_code" : "P"
			},
			{
				"countryName": "Costa Rica",
				"name"       : "San José Province",
				"state_code" : "SJ"
			}
		],
		"CI": [
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Abidjan",
				"state_code" : "AB"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Agnéby",
				"state_code" : "16"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Bafing Region",
				"state_code" : "17"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Bas-Sassandra District",
				"state_code" : "BS"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Bas-Sassandra Region",
				"state_code" : "09"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Comoé District",
				"state_code" : "CM"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Denguélé District",
				"state_code" : "DN"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Denguélé Region",
				"state_code" : "10"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Dix-Huit Montagnes",
				"state_code" : "06"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Fromager",
				"state_code" : "18"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Gôh-Djiboua District",
				"state_code" : "GD"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Haut-Sassandra",
				"state_code" : "02"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Lacs District",
				"state_code" : "LC"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Lacs Region",
				"state_code" : "07"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Lagunes District",
				"state_code" : "LG"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Lagunes region",
				"state_code" : "01"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Marahoué Region",
				"state_code" : "12"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Montagnes District",
				"state_code" : "MG"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Moyen-Cavally",
				"state_code" : "19"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Moyen-Comoé",
				"state_code" : "05"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "N'zi-Comoé",
				"state_code" : "11"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Sassandra-Marahoué District",
				"state_code" : "SM"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Savanes Region",
				"state_code" : "03"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Sud-Bandama",
				"state_code" : "15"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Sud-Comoé",
				"state_code" : "13"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Vallée du Bandama District",
				"state_code" : "VB"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Vallée du Bandama Region",
				"state_code" : "04"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Woroba District",
				"state_code" : "WR"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Worodougou",
				"state_code" : "14"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Yamoussoukro",
				"state_code" : "YM"
			},
			{
				"countryName": "Cote D'Ivoire (Ivory Coast)",
				"name"       : "Zanzan Region",
				"state_code" : "ZZ"
			}
		],
		"HR": [
			{
				"countryName": "Croatia",
				"name"       : "Bjelovar-Bilogora",
				"state_code" : "07"
			},
			{
				"countryName": "Croatia",
				"name"       : "Brod-Posavina",
				"state_code" : "12"
			},
			{
				"countryName": "Croatia",
				"name"       : "Dubrovnik-Neretva",
				"state_code" : "19"
			},
			{
				"countryName": "Croatia",
				"name"       : "Istria",
				"state_code" : "18"
			},
			{
				"countryName": "Croatia",
				"name"       : "Karlovac",
				"state_code" : "04"
			},
			{
				"countryName": "Croatia",
				"name"       : "Koprivnica-Križevci",
				"state_code" : "06"
			},
			{
				"countryName": "Croatia",
				"name"       : "Krapina-Zagorje",
				"state_code" : "02"
			},
			{
				"countryName": "Croatia",
				"name"       : "Lika-Senj",
				"state_code" : "09"
			},
			{
				"countryName": "Croatia",
				"name"       : "Međimurje",
				"state_code" : "20"
			},
			{
				"countryName": "Croatia",
				"name"       : "Osijek-Baranja",
				"state_code" : "14"
			},
			{
				"countryName": "Croatia",
				"name"       : "Požega-Slavonia",
				"state_code" : "11"
			},
			{
				"countryName": "Croatia",
				"name"       : "Primorje-Gorski Kotar",
				"state_code" : "08"
			},
			{
				"countryName": "Croatia",
				"name"       : "Šibenik-Knin",
				"state_code" : "15"
			},
			{
				"countryName": "Croatia",
				"name"       : "Sisak-Moslavina",
				"state_code" : "03"
			},
			{
				"countryName": "Croatia",
				"name"       : "Split-Dalmatia",
				"state_code" : "17"
			},
			{
				"countryName": "Croatia",
				"name"       : "Varaždin",
				"state_code" : "05"
			},
			{
				"countryName": "Croatia",
				"name"       : "Virovitica-Podravina",
				"state_code" : "10"
			},
			{
				"countryName": "Croatia",
				"name"       : "Vukovar-Syrmia",
				"state_code" : "16"
			},
			{
				"countryName": "Croatia",
				"name"       : "Zadar",
				"state_code" : "13"
			},
			{
				"countryName": "Croatia",
				"name"       : "Zagreb",
				"state_code" : "01"
			},
			{
				"countryName": "Croatia",
				"name"       : "Zagreb",
				"state_code" : "21"
			}
		],
		"CU": [
			{
				"countryName": "Cuba",
				"name"       : "Artemisa Province",
				"state_code" : "15"
			},
			{
				"countryName": "Cuba",
				"name"       : "Camagüey Province",
				"state_code" : "09"
			},
			{
				"countryName": "Cuba",
				"name"       : "Ciego de Ávila Province",
				"state_code" : "08"
			},
			{
				"countryName": "Cuba",
				"name"       : "Cienfuegos Province",
				"state_code" : "06"
			},
			{
				"countryName": "Cuba",
				"name"       : "Granma Province",
				"state_code" : "12"
			},
			{
				"countryName": "Cuba",
				"name"       : "Guantánamo Province",
				"state_code" : "14"
			},
			{
				"countryName": "Cuba",
				"name"       : "Havana Province",
				"state_code" : "03"
			},
			{
				"countryName": "Cuba",
				"name"       : "Holguín Province",
				"state_code" : "11"
			},
			{
				"countryName": "Cuba",
				"name"       : "Isla de la Juventud",
				"state_code" : "99"
			},
			{
				"countryName": "Cuba",
				"name"       : "Las Tunas Province",
				"state_code" : "10"
			},
			{
				"countryName": "Cuba",
				"name"       : "Matanzas Province",
				"state_code" : "04"
			},
			{
				"countryName": "Cuba",
				"name"       : "Mayabeque Province",
				"state_code" : "16"
			},
			{
				"countryName": "Cuba",
				"name"       : "Pinar del Río Province",
				"state_code" : "01"
			},
			{
				"countryName": "Cuba",
				"name"       : "Sancti Spíritus Province",
				"state_code" : "07"
			},
			{
				"countryName": "Cuba",
				"name"       : "Santiago de Cuba Province",
				"state_code" : "13"
			},
			{
				"countryName": "Cuba",
				"name"       : "Villa Clara Province",
				"state_code" : "05"
			}
		],
		"CW": [],
		"CY": [
			{
				"countryName": "Cyprus",
				"name"       : "Famagusta District (Mağusa)",
				"state_code" : "04"
			},
			{
				"countryName": "Cyprus",
				"name"       : "Kyrenia District (Keryneia)",
				"state_code" : "06"
			},
			{
				"countryName": "Cyprus",
				"name"       : "Larnaca District (Larnaka)",
				"state_code" : "03"
			},
			{
				"countryName": "Cyprus",
				"name"       : "Limassol District (Leymasun)",
				"state_code" : "02"
			},
			{
				"countryName": "Cyprus",
				"name"       : "Nicosia District (Lefkoşa)",
				"state_code" : "01"
			},
			{
				"countryName": "Cyprus",
				"name"       : "Paphos District (Pafos)",
				"state_code" : "05"
			}
		],
		"CZ": [
			{
				"countryName": "Czech Republic",
				"name"       : "Benešov",
				"state_code" : "201"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Beroun",
				"state_code" : "202"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Blansko",
				"state_code" : "641"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Břeclav",
				"state_code" : "644"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Brno-město",
				"state_code" : "642"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Brno-venkov",
				"state_code" : "643"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Bruntál",
				"state_code" : "801"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Česká Lípa",
				"state_code" : "511"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "České Budějovice",
				"state_code" : "311"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Český Krumlov",
				"state_code" : "312"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Cheb",
				"state_code" : "411"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Chomutov",
				"state_code" : "422"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Chrudim",
				"state_code" : "531"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Děčín",
				"state_code" : "421"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Domažlice",
				"state_code" : "321"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Frýdek-Místek",
				"state_code" : "802"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Havlíčkův Brod",
				"state_code" : "631"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Hodonín",
				"state_code" : "645"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Hradec Králové",
				"state_code" : "521"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Jablonec nad Nisou",
				"state_code" : "512"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Jeseník",
				"state_code" : "711"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Jičín",
				"state_code" : "522"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Jihlava",
				"state_code" : "632"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Jihočeský kraj",
				"state_code" : "31"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Jihomoravský kraj",
				"state_code" : "64"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Jindřichův Hradec",
				"state_code" : "313"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Karlovarský kraj",
				"state_code" : "41"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Karlovy Vary",
				"state_code" : "412"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Karviná",
				"state_code" : "803"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Kladno",
				"state_code" : "203"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Klatovy",
				"state_code" : "322"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Kolín",
				"state_code" : "204"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Kraj Vysočina",
				"state_code" : "63"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Královéhradecký kraj",
				"state_code" : "52"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Kroměříž",
				"state_code" : "721"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Kutná Hora",
				"state_code" : "205"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Liberec",
				"state_code" : "513"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Liberecký kraj",
				"state_code" : "51"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Litoměřice",
				"state_code" : "423"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Louny",
				"state_code" : "424"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Mělník",
				"state_code" : "206"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Mladá Boleslav",
				"state_code" : "207"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Moravskoslezský kraj",
				"state_code" : "80"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Most",
				"state_code" : "425"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Náchod",
				"state_code" : "523"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Nový Jičín",
				"state_code" : "804"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Nymburk",
				"state_code" : "208"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Olomouc",
				"state_code" : "712"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Olomoucký kraj",
				"state_code" : "71"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Opava",
				"state_code" : "805"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Ostrava-město",
				"state_code" : "806"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Pardubice",
				"state_code" : "532"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Pardubický kraj",
				"state_code" : "53"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Pelhřimov",
				"state_code" : "633"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Písek",
				"state_code" : "314"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Plzeň-jih",
				"state_code" : "324"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Plzeň-město",
				"state_code" : "323"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Plzeň-sever",
				"state_code" : "325"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Plzeňský kraj",
				"state_code" : "32"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Prachatice",
				"state_code" : "315"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Praha-východ",
				"state_code" : "209"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Praha-západ",
				"state_code" : "20A"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Praha, Hlavní město",
				"state_code" : "10"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Přerov",
				"state_code" : "714"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Příbram",
				"state_code" : "20B"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Prostějov",
				"state_code" : "713"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Rakovník",
				"state_code" : "20C"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Rokycany",
				"state_code" : "326"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Rychnov nad Kněžnou",
				"state_code" : "524"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Semily",
				"state_code" : "514"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Sokolov",
				"state_code" : "413"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Strakonice",
				"state_code" : "316"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Středočeský kraj",
				"state_code" : "20"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Šumperk",
				"state_code" : "715"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Svitavy",
				"state_code" : "533"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Tábor",
				"state_code" : "317"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Tachov",
				"state_code" : "327"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Teplice",
				"state_code" : "426"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Třebíč",
				"state_code" : "634"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Trutnov",
				"state_code" : "525"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Uherské Hradiště",
				"state_code" : "722"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Ústecký kraj",
				"state_code" : "42"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Ústí nad Labem",
				"state_code" : "427"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Ústí nad Orlicí",
				"state_code" : "534"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Vsetín",
				"state_code" : "723"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Vyškov",
				"state_code" : "646"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Žďár nad Sázavou",
				"state_code" : "635"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Zlín",
				"state_code" : "724"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Zlínský kraj",
				"state_code" : "72"
			},
			{
				"countryName": "Czech Republic",
				"name"       : "Znojmo",
				"state_code" : "647"
			}
		],
		"CD": [
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Bas-Uélé",
				"state_code" : "BU"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Équateur",
				"state_code" : "EQ"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Haut-Katanga",
				"state_code" : "HK"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Haut-Lomami",
				"state_code" : "HL"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Haut-Uélé",
				"state_code" : "HU"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Ituri",
				"state_code" : "IT"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Kasaï",
				"state_code" : "KS"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Kasaï Central",
				"state_code" : "KC"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Kasaï Oriental",
				"state_code" : "KE"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Kinshasa",
				"state_code" : "KN"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Kongo Central",
				"state_code" : "BC"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Kwango",
				"state_code" : "KG"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Kwilu",
				"state_code" : "KL"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Lomami",
				"state_code" : "LO"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Lualaba",
				"state_code" : "LU"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Mai-Ndombe",
				"state_code" : "MN"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Maniema",
				"state_code" : "MA"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Mongala",
				"state_code" : "MO"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Nord-Kivu",
				"state_code" : "NK"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Nord-Ubangi",
				"state_code" : "NU"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Sankuru",
				"state_code" : "SA"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Sud-Kivu",
				"state_code" : "SK"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Sud-Ubangi",
				"state_code" : "SU"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Tanganyika",
				"state_code" : "TA"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Tshopo",
				"state_code" : "TO"
			},
			{
				"countryName": "Democratic Republic of the Congo",
				"name"       : "Tshuapa",
				"state_code" : "TU"
			}
		],
		"DK": [
			{
				"countryName": "Denmark",
				"name"       : "Capital Region of Denmark",
				"state_code" : "84"
			},
			{
				"countryName": "Denmark",
				"name"       : "Central Denmark Region",
				"state_code" : "82"
			},
			{
				"countryName": "Denmark",
				"name"       : "North Denmark Region",
				"state_code" : "81"
			},
			{
				"countryName": "Denmark",
				"name"       : "Region of Southern Denmark",
				"state_code" : "83"
			},
			{
				"countryName": "Denmark",
				"name"       : "Region Zealand",
				"state_code" : "85"
			}
		],
		"DJ": [
			{
				"countryName": "Djibouti",
				"name"       : "Ali Sabieh Region",
				"state_code" : "AS"
			},
			{
				"countryName": "Djibouti",
				"name"       : "Arta Region",
				"state_code" : "AR"
			},
			{
				"countryName": "Djibouti",
				"name"       : "Dikhil Region",
				"state_code" : "DI"
			},
			{
				"countryName": "Djibouti",
				"name"       : "Djibouti",
				"state_code" : "DJ"
			},
			{
				"countryName": "Djibouti",
				"name"       : "Obock Region",
				"state_code" : "OB"
			},
			{
				"countryName": "Djibouti",
				"name"       : "Tadjourah Region",
				"state_code" : "TA"
			}
		],
		"DM": [
			{
				"countryName": "Dominica",
				"name"       : "Saint Andrew Parish",
				"state_code" : "02"
			},
			{
				"countryName": "Dominica",
				"name"       : "Saint David Parish",
				"state_code" : "03"
			},
			{
				"countryName": "Dominica",
				"name"       : "Saint George Parish",
				"state_code" : "04"
			},
			{
				"countryName": "Dominica",
				"name"       : "Saint John Parish",
				"state_code" : "05"
			},
			{
				"countryName": "Dominica",
				"name"       : "Saint Joseph Parish",
				"state_code" : "06"
			},
			{
				"countryName": "Dominica",
				"name"       : "Saint Luke Parish",
				"state_code" : "07"
			},
			{
				"countryName": "Dominica",
				"name"       : "Saint Mark Parish",
				"state_code" : "08"
			},
			{
				"countryName": "Dominica",
				"name"       : "Saint Patrick Parish",
				"state_code" : "09"
			},
			{
				"countryName": "Dominica",
				"name"       : "Saint Paul Parish",
				"state_code" : "10"
			},
			{
				"countryName": "Dominica",
				"name"       : "Saint Peter Parish",
				"state_code" : "11"
			}
		],
		"DO": [
			{
				"countryName": "Dominican Republic",
				"name"       : "Azua Province",
				"state_code" : "02"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Baoruco Province",
				"state_code" : "03"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Barahona Province",
				"state_code" : "04"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Dajabón Province",
				"state_code" : "05"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Distrito Nacional",
				"state_code" : "01"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Duarte Province",
				"state_code" : "06"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "El Seibo Province",
				"state_code" : "08"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Espaillat Province",
				"state_code" : "09"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Hato Mayor Province",
				"state_code" : "30"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Hermanas Mirabal Province",
				"state_code" : "19"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Independencia",
				"state_code" : "10"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "La Altagracia Province",
				"state_code" : "11"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "La Romana Province",
				"state_code" : "12"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "La Vega Province",
				"state_code" : "13"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "María Trinidad Sánchez Province",
				"state_code" : "14"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Monseñor Nouel Province",
				"state_code" : "28"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Monte Cristi Province",
				"state_code" : "15"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Monte Plata Province",
				"state_code" : "29"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Pedernales Province",
				"state_code" : "16"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Peravia Province",
				"state_code" : "17"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Puerto Plata Province",
				"state_code" : "18"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Samaná Province",
				"state_code" : "20"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "San Cristóbal Province",
				"state_code" : "21"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "San José de Ocoa Province",
				"state_code" : "31"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "San Juan Province",
				"state_code" : "22"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "San Pedro de Macorís",
				"state_code" : "23"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Sánchez Ramírez Province",
				"state_code" : "24"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Santiago Province",
				"state_code" : "25"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Santiago Rodríguez Province",
				"state_code" : "26"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Santo Domingo Province",
				"state_code" : "32"
			},
			{
				"countryName": "Dominican Republic",
				"name"       : "Valverde Province",
				"state_code" : "27"
			}
		],
		"TL": [
			{
				"countryName": "East Timor",
				"name"       : "Aileu municipality",
				"state_code" : "AL"
			},
			{
				"countryName": "East Timor",
				"name"       : "Ainaro Municipality",
				"state_code" : "AN"
			},
			{
				"countryName": "East Timor",
				"name"       : "Baucau Municipality",
				"state_code" : "BA"
			},
			{
				"countryName": "East Timor",
				"name"       : "Bobonaro Municipality",
				"state_code" : "BO"
			},
			{
				"countryName": "East Timor",
				"name"       : "Cova Lima Municipality",
				"state_code" : "CO"
			},
			{
				"countryName": "East Timor",
				"name"       : "Dili municipality",
				"state_code" : "DI"
			},
			{
				"countryName": "East Timor",
				"name"       : "Ermera District",
				"state_code" : "ER"
			},
			{
				"countryName": "East Timor",
				"name"       : "Lautém Municipality",
				"state_code" : "LA"
			},
			{
				"countryName": "East Timor",
				"name"       : "Liquiçá Municipality",
				"state_code" : "LI"
			},
			{
				"countryName": "East Timor",
				"name"       : "Manatuto District",
				"state_code" : "MT"
			},
			{
				"countryName": "East Timor",
				"name"       : "Manufahi Municipality",
				"state_code" : "MF"
			},
			{
				"countryName": "East Timor",
				"name"       : "Viqueque Municipality",
				"state_code" : "VI"
			}
		],
		"EC": [
			{
				"countryName": "Ecuador",
				"name"       : "Azuay",
				"state_code" : "A"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Bolívar",
				"state_code" : "B"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Cañar",
				"state_code" : "F"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Carchi",
				"state_code" : "C"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Chimborazo",
				"state_code" : "H"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Cotopaxi",
				"state_code" : "X"
			},
			{
				"countryName": "Ecuador",
				"name"       : "El Oro",
				"state_code" : "O"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Esmeraldas",
				"state_code" : "E"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Galápagos",
				"state_code" : "W"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Guayas",
				"state_code" : "G"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Imbabura",
				"state_code" : "I"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Loja",
				"state_code" : "L"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Los Ríos",
				"state_code" : "R"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Manabí",
				"state_code" : "M"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Morona-Santiago",
				"state_code" : "S"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Napo",
				"state_code" : "N"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Orellana",
				"state_code" : "D"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Pastaza",
				"state_code" : "Y"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Pichincha",
				"state_code" : "P"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Santa Elena",
				"state_code" : "SE"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Santo Domingo de los Tsáchilas",
				"state_code" : "SD"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Sucumbíos",
				"state_code" : "U"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Tungurahua",
				"state_code" : "T"
			},
			{
				"countryName": "Ecuador",
				"name"       : "Zamora Chinchipe",
				"state_code" : "Z"
			}
		],
		"EG": [
			{
				"countryName": "Egypt",
				"name"       : "Alexandria",
				"state_code" : "ALX"
			},
			{
				"countryName": "Egypt",
				"name"       : "Aswan",
				"state_code" : "ASN"
			},
			{
				"countryName": "Egypt",
				"name"       : "Asyut",
				"state_code" : "AST"
			},
			{
				"countryName": "Egypt",
				"name"       : "Beheira",
				"state_code" : "BH"
			},
			{
				"countryName": "Egypt",
				"name"       : "Beni Suef",
				"state_code" : "BNS"
			},
			{
				"countryName": "Egypt",
				"name"       : "Cairo",
				"state_code" : "C"
			},
			{
				"countryName": "Egypt",
				"name"       : "Dakahlia",
				"state_code" : "DK"
			},
			{
				"countryName": "Egypt",
				"name"       : "Damietta",
				"state_code" : "DT"
			},
			{
				"countryName": "Egypt",
				"name"       : "Faiyum",
				"state_code" : "FYM"
			},
			{
				"countryName": "Egypt",
				"name"       : "Gharbia",
				"state_code" : "GH"
			},
			{
				"countryName": "Egypt",
				"name"       : "Giza",
				"state_code" : "GZ"
			},
			{
				"countryName": "Egypt",
				"name"       : "Ismailia",
				"state_code" : "IS"
			},
			{
				"countryName": "Egypt",
				"name"       : "Kafr el-Sheikh",
				"state_code" : "KFS"
			},
			{
				"countryName": "Egypt",
				"name"       : "Luxor",
				"state_code" : "LX"
			},
			{
				"countryName": "Egypt",
				"name"       : "Matrouh",
				"state_code" : "MT"
			},
			{
				"countryName": "Egypt",
				"name"       : "Minya",
				"state_code" : "MN"
			},
			{
				"countryName": "Egypt",
				"name"       : "Monufia",
				"state_code" : "MNF"
			},
			{
				"countryName": "Egypt",
				"name"       : "New Valley",
				"state_code" : "WAD"
			},
			{
				"countryName": "Egypt",
				"name"       : "North Sinai",
				"state_code" : "SIN"
			},
			{
				"countryName": "Egypt",
				"name"       : "Port Said",
				"state_code" : "PTS"
			},
			{
				"countryName": "Egypt",
				"name"       : "Qalyubia",
				"state_code" : "KB"
			},
			{
				"countryName": "Egypt",
				"name"       : "Qena",
				"state_code" : "KN"
			},
			{
				"countryName": "Egypt",
				"name"       : "Red Sea",
				"state_code" : "BA"
			},
			{
				"countryName": "Egypt",
				"name"       : "Sharqia",
				"state_code" : "SHR"
			},
			{
				"countryName": "Egypt",
				"name"       : "Sohag",
				"state_code" : "SHG"
			},
			{
				"countryName": "Egypt",
				"name"       : "South Sinai",
				"state_code" : "JS"
			},
			{
				"countryName": "Egypt",
				"name"       : "Suez",
				"state_code" : "SUZ"
			}
		],
		"SV": [
			{
				"countryName": "El Salvador",
				"name"       : "Ahuachapán Department",
				"state_code" : "AH"
			},
			{
				"countryName": "El Salvador",
				"name"       : "Cabañas Department",
				"state_code" : "CA"
			},
			{
				"countryName": "El Salvador",
				"name"       : "Chalatenango Department",
				"state_code" : "CH"
			},
			{
				"countryName": "El Salvador",
				"name"       : "Cuscatlán Department",
				"state_code" : "CU"
			},
			{
				"countryName": "El Salvador",
				"name"       : "La Libertad Department",
				"state_code" : "LI"
			},
			{
				"countryName": "El Salvador",
				"name"       : "La Paz Department",
				"state_code" : "PA"
			},
			{
				"countryName": "El Salvador",
				"name"       : "La Unión Department",
				"state_code" : "UN"
			},
			{
				"countryName": "El Salvador",
				"name"       : "Morazán Department",
				"state_code" : "MO"
			},
			{
				"countryName": "El Salvador",
				"name"       : "San Miguel Department",
				"state_code" : "SM"
			},
			{
				"countryName": "El Salvador",
				"name"       : "San Salvador Department",
				"state_code" : "SS"
			},
			{
				"countryName": "El Salvador",
				"name"       : "San Vicente Department",
				"state_code" : "SV"
			},
			{
				"countryName": "El Salvador",
				"name"       : "Santa Ana Department",
				"state_code" : "SA"
			},
			{
				"countryName": "El Salvador",
				"name"       : "Sonsonate Department",
				"state_code" : "SO"
			},
			{
				"countryName": "El Salvador",
				"name"       : "Usulután Department",
				"state_code" : "US"
			}
		],
		"GQ": [
			{
				"countryName": "Equatorial Guinea",
				"name"       : "Annobón Province",
				"state_code" : "AN"
			},
			{
				"countryName": "Equatorial Guinea",
				"name"       : "Bioko Norte Province",
				"state_code" : "BN"
			},
			{
				"countryName": "Equatorial Guinea",
				"name"       : "Bioko Sur Province",
				"state_code" : "BS"
			},
			{
				"countryName": "Equatorial Guinea",
				"name"       : "Centro Sur Province",
				"state_code" : "CS"
			},
			{
				"countryName": "Equatorial Guinea",
				"name"       : "Insular Region",
				"state_code" : "I"
			},
			{
				"countryName": "Equatorial Guinea",
				"name"       : "Kié-Ntem Province",
				"state_code" : "KN"
			},
			{
				"countryName": "Equatorial Guinea",
				"name"       : "Litoral Province",
				"state_code" : "LI"
			},
			{
				"countryName": "Equatorial Guinea",
				"name"       : "Río Muni",
				"state_code" : "C"
			},
			{
				"countryName": "Equatorial Guinea",
				"name"       : "Wele-Nzas Province",
				"state_code" : "WN"
			}
		],
		"ER": [
			{
				"countryName": "Eritrea",
				"name"       : "Anseba Region",
				"state_code" : "AN"
			},
			{
				"countryName": "Eritrea",
				"name"       : "Debub Region",
				"state_code" : "DU"
			},
			{
				"countryName": "Eritrea",
				"name"       : "Gash-Barka Region",
				"state_code" : "GB"
			},
			{
				"countryName": "Eritrea",
				"name"       : "Maekel Region",
				"state_code" : "MA"
			},
			{
				"countryName": "Eritrea",
				"name"       : "Northern Red Sea Region",
				"state_code" : "SK"
			},
			{
				"countryName": "Eritrea",
				"name"       : "Southern Red Sea Region",
				"state_code" : "DK"
			}
		],
		"EE": [
			{
				"countryName": "Estonia",
				"name"       : "Harju County",
				"state_code" : "37"
			},
			{
				"countryName": "Estonia",
				"name"       : "Hiiu County",
				"state_code" : "39"
			},
			{
				"countryName": "Estonia",
				"name"       : "Ida-Viru County",
				"state_code" : "44"
			},
			{
				"countryName": "Estonia",
				"name"       : "Järva County",
				"state_code" : "51"
			},
			{
				"countryName": "Estonia",
				"name"       : "Jõgeva County",
				"state_code" : "49"
			},
			{
				"countryName": "Estonia",
				"name"       : "Lääne County",
				"state_code" : "57"
			},
			{
				"countryName": "Estonia",
				"name"       : "Lääne-Viru County",
				"state_code" : "59"
			},
			{
				"countryName": "Estonia",
				"name"       : "Pärnu County",
				"state_code" : "67"
			},
			{
				"countryName": "Estonia",
				"name"       : "Põlva County",
				"state_code" : "65"
			},
			{
				"countryName": "Estonia",
				"name"       : "Rapla County",
				"state_code" : "70"
			},
			{
				"countryName": "Estonia",
				"name"       : "Saare County",
				"state_code" : "74"
			},
			{
				"countryName": "Estonia",
				"name"       : "Tartu County",
				"state_code" : "78"
			},
			{
				"countryName": "Estonia",
				"name"       : "Valga County",
				"state_code" : "82"
			},
			{
				"countryName": "Estonia",
				"name"       : "Viljandi County",
				"state_code" : "84"
			},
			{
				"countryName": "Estonia",
				"name"       : "Võru County",
				"state_code" : "86"
			}
		],
		"ET": [
			{
				"countryName": "Ethiopia",
				"name"       : "Addis Ababa",
				"state_code" : "AA"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Afar Region",
				"state_code" : "AF"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Amhara Region",
				"state_code" : "AM"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Benishangul-Gumuz Region",
				"state_code" : "BE"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Dire Dawa",
				"state_code" : "DD"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Gambela Region",
				"state_code" : "GA"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Harari Region",
				"state_code" : "HA"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Oromia Region",
				"state_code" : "OR"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Somali Region",
				"state_code" : "SO"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Southern Nations, Nationalities, and Peoples' Region",
				"state_code" : "SN"
			},
			{
				"countryName": "Ethiopia",
				"name"       : "Tigray Region",
				"state_code" : "TI"
			}
		],
		"FK": [],
		"FO": [],
		"FJ": [
			{
				"countryName": "Fiji Islands",
				"name"       : "Ba",
				"state_code" : "01"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Bua",
				"state_code" : "02"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Cakaudrove",
				"state_code" : "03"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Central Division",
				"state_code" : "C"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Eastern Division",
				"state_code" : "E"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Kadavu",
				"state_code" : "04"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Lau",
				"state_code" : "05"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Lomaiviti",
				"state_code" : "06"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Macuata",
				"state_code" : "07"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Nadroga-Navosa",
				"state_code" : "08"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Naitasiri",
				"state_code" : "09"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Namosi",
				"state_code" : "10"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Northern Division",
				"state_code" : "N"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Ra",
				"state_code" : "11"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Rewa",
				"state_code" : "12"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Rotuma",
				"state_code" : "R"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Serua",
				"state_code" : "13"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Tailevu",
				"state_code" : "14"
			},
			{
				"countryName": "Fiji Islands",
				"name"       : "Western Division",
				"state_code" : "W"
			}
		],
		"FI": [
			{
				"countryName": "Finland",
				"name"       : "Åland Islands",
				"state_code" : "01"
			},
			{
				"countryName": "Finland",
				"name"       : "Central Finland",
				"state_code" : "08"
			},
			{
				"countryName": "Finland",
				"name"       : "Central Ostrobothnia",
				"state_code" : "07"
			},
			{
				"countryName": "Finland",
				"name"       : "Finland Proper",
				"state_code" : "19"
			},
			{
				"countryName": "Finland",
				"name"       : "Kainuu",
				"state_code" : "05"
			},
			{
				"countryName": "Finland",
				"name"       : "Kymenlaakso",
				"state_code" : "09"
			},
			{
				"countryName": "Finland",
				"name"       : "Lapland",
				"state_code" : "10"
			},
			{
				"countryName": "Finland",
				"name"       : "North Karelia",
				"state_code" : "13"
			},
			{
				"countryName": "Finland",
				"name"       : "Northern Ostrobothnia",
				"state_code" : "14"
			},
			{
				"countryName": "Finland",
				"name"       : "Northern Savonia",
				"state_code" : "15"
			},
			{
				"countryName": "Finland",
				"name"       : "Ostrobothnia",
				"state_code" : "12"
			},
			{
				"countryName": "Finland",
				"name"       : "Päijänne Tavastia",
				"state_code" : "16"
			},
			{
				"countryName": "Finland",
				"name"       : "Pirkanmaa",
				"state_code" : "11"
			},
			{
				"countryName": "Finland",
				"name"       : "Satakunta",
				"state_code" : "17"
			},
			{
				"countryName": "Finland",
				"name"       : "South Karelia",
				"state_code" : "02"
			},
			{
				"countryName": "Finland",
				"name"       : "Southern Ostrobothnia",
				"state_code" : "03"
			},
			{
				"countryName": "Finland",
				"name"       : "Southern Savonia",
				"state_code" : "04"
			},
			{
				"countryName": "Finland",
				"name"       : "Tavastia Proper",
				"state_code" : "06"
			},
			{
				"countryName": "Finland",
				"name"       : "Uusimaa",
				"state_code" : "18"
			}
		],
		"FR": [
			{
				"countryName": "France",
				"name"       : "Ain",
				"state_code" : "01"
			},
			{
				"countryName": "France",
				"name"       : "Aisne",
				"state_code" : "02"
			},
			{
				"countryName": "France",
				"name"       : "Allier",
				"state_code" : "03"
			},
			{
				"countryName": "France",
				"name"       : "Alpes-de-Haute-Provence",
				"state_code" : "04"
			},
			{
				"countryName": "France",
				"name"       : "Alpes-Maritimes",
				"state_code" : "06"
			},
			{
				"countryName": "France",
				"name"       : "Alsace",
				"state_code" : "6AE"
			},
			{
				"countryName": "France",
				"name"       : "Ardèche",
				"state_code" : "07"
			},
			{
				"countryName": "France",
				"name"       : "Ardennes",
				"state_code" : "08"
			},
			{
				"countryName": "France",
				"name"       : "Ariège",
				"state_code" : "09"
			},
			{
				"countryName": "France",
				"name"       : "Aube",
				"state_code" : "10"
			},
			{
				"countryName": "France",
				"name"       : "Aude",
				"state_code" : "11"
			},
			{
				"countryName": "France",
				"name"       : "Auvergne-Rhône-Alpes",
				"state_code" : "ARA"
			},
			{
				"countryName": "France",
				"name"       : "Aveyron",
				"state_code" : "12"
			},
			{
				"countryName": "France",
				"name"       : "Bas-Rhin",
				"state_code" : "67"
			},
			{
				"countryName": "France",
				"name"       : "Bouches-du-Rhône",
				"state_code" : "13"
			},
			{
				"countryName": "France",
				"name"       : "Bourgogne-Franche-Comté",
				"state_code" : "BFC"
			},
			{
				"countryName": "France",
				"name"       : "Bretagne",
				"state_code" : "BRE"
			},
			{
				"countryName": "France",
				"name"       : "Calvados",
				"state_code" : "14"
			},
			{
				"countryName": "France",
				"name"       : "Cantal",
				"state_code" : "15"
			},
			{
				"countryName": "France",
				"name"       : "Centre-Val de Loire",
				"state_code" : "CVL"
			},
			{
				"countryName": "France",
				"name"       : "Charente",
				"state_code" : "16"
			},
			{
				"countryName": "France",
				"name"       : "Charente-Maritime",
				"state_code" : "17"
			},
			{
				"countryName": "France",
				"name"       : "Cher",
				"state_code" : "18"
			},
			{
				"countryName": "France",
				"name"       : "Clipperton",
				"state_code" : "CP"
			},
			{
				"countryName": "France",
				"name"       : "Corrèze",
				"state_code" : "19"
			},
			{
				"countryName": "France",
				"name"       : "Corse",
				"state_code" : "20R"
			},
			{
				"countryName": "France",
				"name"       : "Corse-du-Sud",
				"state_code" : "2A"
			},
			{
				"countryName": "France",
				"name"       : "Côte-d'Or",
				"state_code" : "21"
			},
			{
				"countryName": "France",
				"name"       : "Côtes-d'Armor",
				"state_code" : "22"
			},
			{
				"countryName": "France",
				"name"       : "Creuse",
				"state_code" : "23"
			},
			{
				"countryName": "France",
				"name"       : "Deux-Sèvres",
				"state_code" : "79"
			},
			{
				"countryName": "France",
				"name"       : "Dordogne",
				"state_code" : "24"
			},
			{
				"countryName": "France",
				"name"       : "Doubs",
				"state_code" : "25"
			},
			{
				"countryName": "France",
				"name"       : "Drôme",
				"state_code" : "26"
			},
			{
				"countryName": "France",
				"name"       : "Essonne",
				"state_code" : "91"
			},
			{
				"countryName": "France",
				"name"       : "Eure",
				"state_code" : "27"
			},
			{
				"countryName": "France",
				"name"       : "Eure-et-Loir",
				"state_code" : "28"
			},
			{
				"countryName": "France",
				"name"       : "Finistère",
				"state_code" : "29"
			},
			{
				"countryName": "France",
				"name"       : "French Guiana",
				"state_code" : "973"
			},
			{
				"countryName": "France",
				"name"       : "French Polynesia",
				"state_code" : "PF"
			},
			{
				"countryName": "France",
				"name"       : "French Southern and Antarctic Lands",
				"state_code" : "TF"
			},
			{
				"countryName": "France",
				"name"       : "Gard",
				"state_code" : "30"
			},
			{
				"countryName": "France",
				"name"       : "Gers",
				"state_code" : "32"
			},
			{
				"countryName": "France",
				"name"       : "Gironde",
				"state_code" : "33"
			},
			{
				"countryName": "France",
				"name"       : "Grand-Est",
				"state_code" : "GES"
			},
			{
				"countryName": "France",
				"name"       : "Guadeloupe",
				"state_code" : "971"
			},
			{
				"countryName": "France",
				"name"       : "Haut-Rhin",
				"state_code" : "68"
			},
			{
				"countryName": "France",
				"name"       : "Haute-Corse",
				"state_code" : "2B"
			},
			{
				"countryName": "France",
				"name"       : "Haute-Garonne",
				"state_code" : "31"
			},
			{
				"countryName": "France",
				"name"       : "Haute-Loire",
				"state_code" : "43"
			},
			{
				"countryName": "France",
				"name"       : "Haute-Marne",
				"state_code" : "52"
			},
			{
				"countryName": "France",
				"name"       : "Haute-Saône",
				"state_code" : "70"
			},
			{
				"countryName": "France",
				"name"       : "Haute-Savoie",
				"state_code" : "74"
			},
			{
				"countryName": "France",
				"name"       : "Haute-Vienne",
				"state_code" : "87"
			},
			{
				"countryName": "France",
				"name"       : "Hautes-Alpes",
				"state_code" : "05"
			},
			{
				"countryName": "France",
				"name"       : "Hautes-Pyrénées",
				"state_code" : "65"
			},
			{
				"countryName": "France",
				"name"       : "Hauts-de-France",
				"state_code" : "HDF"
			},
			{
				"countryName": "France",
				"name"       : "Hauts-de-Seine",
				"state_code" : "92"
			},
			{
				"countryName": "France",
				"name"       : "Hérault",
				"state_code" : "34"
			},
			{
				"countryName": "France",
				"name"       : "Île-de-France",
				"state_code" : "IDF"
			},
			{
				"countryName": "France",
				"name"       : "Ille-et-Vilaine",
				"state_code" : "35"
			},
			{
				"countryName": "France",
				"name"       : "Indre",
				"state_code" : "36"
			},
			{
				"countryName": "France",
				"name"       : "Indre-et-Loire",
				"state_code" : "37"
			},
			{
				"countryName": "France",
				"name"       : "Isère",
				"state_code" : "38"
			},
			{
				"countryName": "France",
				"name"       : "Jura",
				"state_code" : "39"
			},
			{
				"countryName": "France",
				"name"       : "La Réunion",
				"state_code" : "974"
			},
			{
				"countryName": "France",
				"name"       : "Landes",
				"state_code" : "40"
			},
			{
				"countryName": "France",
				"name"       : "Loir-et-Cher",
				"state_code" : "41"
			},
			{
				"countryName": "France",
				"name"       : "Loire",
				"state_code" : "42"
			},
			{
				"countryName": "France",
				"name"       : "Loire-Atlantique",
				"state_code" : "44"
			},
			{
				"countryName": "France",
				"name"       : "Loiret",
				"state_code" : "45"
			},
			{
				"countryName": "France",
				"name"       : "Lot",
				"state_code" : "46"
			},
			{
				"countryName": "France",
				"name"       : "Lot-et-Garonne",
				"state_code" : "47"
			},
			{
				"countryName": "France",
				"name"       : "Lozère",
				"state_code" : "48"
			},
			{
				"countryName": "France",
				"name"       : "Maine-et-Loire",
				"state_code" : "49"
			},
			{
				"countryName": "France",
				"name"       : "Manche",
				"state_code" : "50"
			},
			{
				"countryName": "France",
				"name"       : "Marne",
				"state_code" : "51"
			},
			{
				"countryName": "France",
				"name"       : "Martinique",
				"state_code" : "972"
			},
			{
				"countryName": "France",
				"name"       : "Mayenne",
				"state_code" : "53"
			},
			{
				"countryName": "France",
				"name"       : "Mayotte",
				"state_code" : "976"
			},
			{
				"countryName": "France",
				"name"       : "Métropole de Lyon",
				"state_code" : "69M"
			},
			{
				"countryName": "France",
				"name"       : "Meurthe-et-Moselle",
				"state_code" : "54"
			},
			{
				"countryName": "France",
				"name"       : "Meuse",
				"state_code" : "55"
			},
			{
				"countryName": "France",
				"name"       : "Morbihan",
				"state_code" : "56"
			},
			{
				"countryName": "France",
				"name"       : "Moselle",
				"state_code" : "57"
			},
			{
				"countryName": "France",
				"name"       : "Nièvre",
				"state_code" : "58"
			},
			{
				"countryName": "France",
				"name"       : "Nord",
				"state_code" : "59"
			},
			{
				"countryName": "France",
				"name"       : "Normandie",
				"state_code" : "NOR"
			},
			{
				"countryName": "France",
				"name"       : "Nouvelle-Aquitaine",
				"state_code" : "NAQ"
			},
			{
				"countryName": "France",
				"name"       : "Occitanie",
				"state_code" : "OCC"
			},
			{
				"countryName": "France",
				"name"       : "Oise",
				"state_code" : "60"
			},
			{
				"countryName": "France",
				"name"       : "Orne",
				"state_code" : "61"
			},
			{
				"countryName": "France",
				"name"       : "Paris",
				"state_code" : "75C"
			},
			{
				"countryName": "France",
				"name"       : "Pas-de-Calais",
				"state_code" : "62"
			},
			{
				"countryName": "France",
				"name"       : "Pays-de-la-Loire",
				"state_code" : "PDL"
			},
			{
				"countryName": "France",
				"name"       : "Provence-Alpes-Côte-d’Azur",
				"state_code" : "PAC"
			},
			{
				"countryName": "France",
				"name"       : "Puy-de-Dôme",
				"state_code" : "63"
			},
			{
				"countryName": "France",
				"name"       : "Pyrénées-Atlantiques",
				"state_code" : "64"
			},
			{
				"countryName": "France",
				"name"       : "Pyrénées-Orientales",
				"state_code" : "66"
			},
			{
				"countryName": "France",
				"name"       : "Rhône",
				"state_code" : "69"
			},
			{
				"countryName": "France",
				"name"       : "Saint Pierre and Miquelon",
				"state_code" : "PM"
			},
			{
				"countryName": "France",
				"name"       : "Saint-Barthélemy",
				"state_code" : "BL"
			},
			{
				"countryName": "France",
				"name"       : "Saint-Martin",
				"state_code" : "MF"
			},
			{
				"countryName": "France",
				"name"       : "Saône-et-Loire",
				"state_code" : "71"
			},
			{
				"countryName": "France",
				"name"       : "Sarthe",
				"state_code" : "72"
			},
			{
				"countryName": "France",
				"name"       : "Savoie",
				"state_code" : "73"
			},
			{
				"countryName": "France",
				"name"       : "Seine-et-Marne",
				"state_code" : "77"
			},
			{
				"countryName": "France",
				"name"       : "Seine-Maritime",
				"state_code" : "76"
			},
			{
				"countryName": "France",
				"name"       : "Seine-Saint-Denis",
				"state_code" : "93"
			},
			{
				"countryName": "France",
				"name"       : "Somme",
				"state_code" : "80"
			},
			{
				"countryName": "France",
				"name"       : "Tarn",
				"state_code" : "81"
			},
			{
				"countryName": "France",
				"name"       : "Tarn-et-Garonne",
				"state_code" : "82"
			},
			{
				"countryName": "France",
				"name"       : "Territoire de Belfort",
				"state_code" : "90"
			},
			{
				"countryName": "France",
				"name"       : "Val-d'Oise",
				"state_code" : "95"
			},
			{
				"countryName": "France",
				"name"       : "Val-de-Marne",
				"state_code" : "94"
			},
			{
				"countryName": "France",
				"name"       : "Var",
				"state_code" : "83"
			},
			{
				"countryName": "France",
				"name"       : "Vaucluse",
				"state_code" : "84"
			},
			{
				"countryName": "France",
				"name"       : "Vendée",
				"state_code" : "85"
			},
			{
				"countryName": "France",
				"name"       : "Vienne",
				"state_code" : "86"
			},
			{
				"countryName": "France",
				"name"       : "Vosges",
				"state_code" : "88"
			},
			{
				"countryName": "France",
				"name"       : "Wallis and Futuna",
				"state_code" : "WF"
			},
			{
				"countryName": "France",
				"name"       : "Yonne",
				"state_code" : "89"
			},
			{
				"countryName": "France",
				"name"       : "Yvelines",
				"state_code" : "78"
			}
		],
		"GF": [],
		"PF": [],
		"TF": [],
		"GA": [
			{
				"countryName": "Gabon",
				"name"       : "Estuaire Province",
				"state_code" : "1"
			},
			{
				"countryName": "Gabon",
				"name"       : "Haut-Ogooué Province",
				"state_code" : "2"
			},
			{
				"countryName": "Gabon",
				"name"       : "Moyen-Ogooué Province",
				"state_code" : "3"
			},
			{
				"countryName": "Gabon",
				"name"       : "Ngounié Province",
				"state_code" : "4"
			},
			{
				"countryName": "Gabon",
				"name"       : "Nyanga Province",
				"state_code" : "5"
			},
			{
				"countryName": "Gabon",
				"name"       : "Ogooué-Ivindo Province",
				"state_code" : "6"
			},
			{
				"countryName": "Gabon",
				"name"       : "Ogooué-Lolo Province",
				"state_code" : "7"
			},
			{
				"countryName": "Gabon",
				"name"       : "Ogooué-Maritime Province",
				"state_code" : "8"
			},
			{
				"countryName": "Gabon",
				"name"       : "Woleu-Ntem Province",
				"state_code" : "9"
			}
		],
		"GM": [
			{
				"countryName": "Gambia The",
				"name"       : "Banjul",
				"state_code" : "B"
			},
			{
				"countryName": "Gambia The",
				"name"       : "Central River Division",
				"state_code" : "M"
			},
			{
				"countryName": "Gambia The",
				"name"       : "Lower River Division",
				"state_code" : "L"
			},
			{
				"countryName": "Gambia The",
				"name"       : "North Bank Division",
				"state_code" : "N"
			},
			{
				"countryName": "Gambia The",
				"name"       : "Upper River Division",
				"state_code" : "U"
			},
			{
				"countryName": "Gambia The",
				"name"       : "West Coast Division",
				"state_code" : "W"
			}
		],
		"GE": [
			{
				"countryName": "Georgia",
				"name"       : "Adjara",
				"state_code" : "AJ"
			},
			{
				"countryName": "Georgia",
				"name"       : "Autonomous Republic of Abkhazia",
				"state_code" : "AB"
			},
			{
				"countryName": "Georgia",
				"name"       : "Guria",
				"state_code" : "GU"
			},
			{
				"countryName": "Georgia",
				"name"       : "Imereti",
				"state_code" : "IM"
			},
			{
				"countryName": "Georgia",
				"name"       : "Kakheti",
				"state_code" : "KA"
			},
			{
				"countryName": "Georgia",
				"name"       : "Khelvachauri Municipality",
				"state_code" : "29"
			},
			{
				"countryName": "Georgia",
				"name"       : "Kvemo Kartli",
				"state_code" : "KK"
			},
			{
				"countryName": "Georgia",
				"name"       : "Mtskheta-Mtianeti",
				"state_code" : "MM"
			},
			{
				"countryName": "Georgia",
				"name"       : "Racha-Lechkhumi and Kvemo Svaneti",
				"state_code" : "RL"
			},
			{
				"countryName": "Georgia",
				"name"       : "Samegrelo-Zemo Svaneti",
				"state_code" : "SZ"
			},
			{
				"countryName": "Georgia",
				"name"       : "Samtskhe-Javakheti",
				"state_code" : "SJ"
			},
			{
				"countryName": "Georgia",
				"name"       : "Senaki Municipality",
				"state_code" : "50"
			},
			{
				"countryName": "Georgia",
				"name"       : "Shida Kartli",
				"state_code" : "SK"
			},
			{
				"countryName": "Georgia",
				"name"       : "Tbilisi",
				"state_code" : "TB"
			}
		],
		"DE": [
			{
				"countryName": "Germany",
				"name"       : "Baden-Württemberg",
				"state_code" : "BW"
			},
			{
				"countryName": "Germany",
				"name"       : "Bavaria",
				"state_code" : "BY"
			},
			{
				"countryName": "Germany",
				"name"       : "Berlin",
				"state_code" : "BE"
			},
			{
				"countryName": "Germany",
				"name"       : "Brandenburg",
				"state_code" : "BB"
			},
			{
				"countryName": "Germany",
				"name"       : "Bremen",
				"state_code" : "HB"
			},
			{
				"countryName": "Germany",
				"name"       : "Hamburg",
				"state_code" : "HH"
			},
			{
				"countryName": "Germany",
				"name"       : "Hesse",
				"state_code" : "HE"
			},
			{
				"countryName": "Germany",
				"name"       : "Lower Saxony",
				"state_code" : "NI"
			},
			{
				"countryName": "Germany",
				"name"       : "Mecklenburg-Vorpommern",
				"state_code" : "MV"
			},
			{
				"countryName": "Germany",
				"name"       : "North Rhine-Westphalia",
				"state_code" : "NW"
			},
			{
				"countryName": "Germany",
				"name"       : "Rhineland-Palatinate",
				"state_code" : "RP"
			},
			{
				"countryName": "Germany",
				"name"       : "Saarland",
				"state_code" : "SL"
			},
			{
				"countryName": "Germany",
				"name"       : "Saxony",
				"state_code" : "SN"
			},
			{
				"countryName": "Germany",
				"name"       : "Saxony-Anhalt",
				"state_code" : "ST"
			},
			{
				"countryName": "Germany",
				"name"       : "Schleswig-Holstein",
				"state_code" : "SH"
			},
			{
				"countryName": "Germany",
				"name"       : "Thuringia",
				"state_code" : "TH"
			}
		],
		"GH": [
			{
				"countryName": "Ghana",
				"name"       : "Ahafo",
				"state_code" : "AF"
			},
			{
				"countryName": "Ghana",
				"name"       : "Ashanti",
				"state_code" : "AH"
			},
			{
				"countryName": "Ghana",
				"name"       : "Bono",
				"state_code" : "BO"
			},
			{
				"countryName": "Ghana",
				"name"       : "Bono East",
				"state_code" : "BE"
			},
			{
				"countryName": "Ghana",
				"name"       : "Central",
				"state_code" : "CP"
			},
			{
				"countryName": "Ghana",
				"name"       : "Eastern",
				"state_code" : "EP"
			},
			{
				"countryName": "Ghana",
				"name"       : "Greater Accra",
				"state_code" : "AA"
			},
			{
				"countryName": "Ghana",
				"name"       : "North East",
				"state_code" : "NE"
			},
			{
				"countryName": "Ghana",
				"name"       : "Northern",
				"state_code" : "NP"
			},
			{
				"countryName": "Ghana",
				"name"       : "Oti",
				"state_code" : "OT"
			},
			{
				"countryName": "Ghana",
				"name"       : "Savannah",
				"state_code" : "SV"
			},
			{
				"countryName": "Ghana",
				"name"       : "Upper East",
				"state_code" : "UE"
			},
			{
				"countryName": "Ghana",
				"name"       : "Upper West",
				"state_code" : "UW"
			},
			{
				"countryName": "Ghana",
				"name"       : "Volta",
				"state_code" : "TV"
			},
			{
				"countryName": "Ghana",
				"name"       : "Western",
				"state_code" : "WP"
			},
			{
				"countryName": "Ghana",
				"name"       : "Western North",
				"state_code" : "WN"
			}
		],
		"GI": [],
		"GR": [
			{
				"countryName": "Greece",
				"name"       : "Achaea Regional Unit",
				"state_code" : "13"
			},
			{
				"countryName": "Greece",
				"name"       : "Aetolia-Acarnania Regional Unit",
				"state_code" : "01"
			},
			{
				"countryName": "Greece",
				"name"       : "Arcadia Prefecture",
				"state_code" : "12"
			},
			{
				"countryName": "Greece",
				"name"       : "Argolis Regional Unit",
				"state_code" : "11"
			},
			{
				"countryName": "Greece",
				"name"       : "Attica Region",
				"state_code" : "I"
			},
			{
				"countryName": "Greece",
				"name"       : "Boeotia Regional Unit",
				"state_code" : "03"
			},
			{
				"countryName": "Greece",
				"name"       : "Central Greece Region",
				"state_code" : "H"
			},
			{
				"countryName": "Greece",
				"name"       : "Central Macedonia",
				"state_code" : "B"
			},
			{
				"countryName": "Greece",
				"name"       : "Chania Regional Unit",
				"state_code" : "94"
			},
			{
				"countryName": "Greece",
				"name"       : "Corfu Prefecture",
				"state_code" : "22"
			},
			{
				"countryName": "Greece",
				"name"       : "Corinthia Regional Unit",
				"state_code" : "15"
			},
			{
				"countryName": "Greece",
				"name"       : "Crete Region",
				"state_code" : "M"
			},
			{
				"countryName": "Greece",
				"name"       : "Drama Regional Unit",
				"state_code" : "52"
			},
			{
				"countryName": "Greece",
				"name"       : "East Attica Regional Unit",
				"state_code" : "A2"
			},
			{
				"countryName": "Greece",
				"name"       : "East Macedonia and Thrace",
				"state_code" : "A"
			},
			{
				"countryName": "Greece",
				"name"       : "Epirus Region",
				"state_code" : "D"
			},
			{
				"countryName": "Greece",
				"name"       : "Euboea",
				"state_code" : "04"
			},
			{
				"countryName": "Greece",
				"name"       : "Grevena Prefecture",
				"state_code" : "51"
			},
			{
				"countryName": "Greece",
				"name"       : "Imathia Regional Unit",
				"state_code" : "53"
			},
			{
				"countryName": "Greece",
				"name"       : "Ioannina Regional Unit",
				"state_code" : "33"
			},
			{
				"countryName": "Greece",
				"name"       : "Ionian Islands Region",
				"state_code" : "F"
			},
			{
				"countryName": "Greece",
				"name"       : "Karditsa Regional Unit",
				"state_code" : "41"
			},
			{
				"countryName": "Greece",
				"name"       : "Kastoria Regional Unit",
				"state_code" : "56"
			},
			{
				"countryName": "Greece",
				"name"       : "Kefalonia Prefecture",
				"state_code" : "23"
			},
			{
				"countryName": "Greece",
				"name"       : "Kilkis Regional Unit",
				"state_code" : "57"
			},
			{
				"countryName": "Greece",
				"name"       : "Kozani Prefecture",
				"state_code" : "58"
			},
			{
				"countryName": "Greece",
				"name"       : "Laconia",
				"state_code" : "16"
			},
			{
				"countryName": "Greece",
				"name"       : "Larissa Prefecture",
				"state_code" : "42"
			},
			{
				"countryName": "Greece",
				"name"       : "Lefkada Regional Unit",
				"state_code" : "24"
			},
			{
				"countryName": "Greece",
				"name"       : "Pella Regional Unit",
				"state_code" : "59"
			},
			{
				"countryName": "Greece",
				"name"       : "Peloponnese Region",
				"state_code" : "J"
			},
			{
				"countryName": "Greece",
				"name"       : "Phthiotis Prefecture",
				"state_code" : "06"
			},
			{
				"countryName": "Greece",
				"name"       : "Preveza Prefecture",
				"state_code" : "34"
			},
			{
				"countryName": "Greece",
				"name"       : "Serres Prefecture",
				"state_code" : "62"
			},
			{
				"countryName": "Greece",
				"name"       : "South Aegean",
				"state_code" : "L"
			},
			{
				"countryName": "Greece",
				"name"       : "Thessaloniki Regional Unit",
				"state_code" : "54"
			},
			{
				"countryName": "Greece",
				"name"       : "West Greece Region",
				"state_code" : "G"
			},
			{
				"countryName": "Greece",
				"name"       : "West Macedonia Region",
				"state_code" : "C"
			}
		],
		"GL": [],
		"GD": [
			{
				"countryName": "Grenada",
				"name"       : "Carriacou and Petite Martinique",
				"state_code" : "10"
			},
			{
				"countryName": "Grenada",
				"name"       : "Saint Andrew Parish",
				"state_code" : "01"
			},
			{
				"countryName": "Grenada",
				"name"       : "Saint David Parish",
				"state_code" : "02"
			},
			{
				"countryName": "Grenada",
				"name"       : "Saint George Parish",
				"state_code" : "03"
			},
			{
				"countryName": "Grenada",
				"name"       : "Saint John Parish",
				"state_code" : "04"
			},
			{
				"countryName": "Grenada",
				"name"       : "Saint Mark Parish",
				"state_code" : "05"
			},
			{
				"countryName": "Grenada",
				"name"       : "Saint Patrick Parish",
				"state_code" : "06"
			}
		],
		"GP": [],
		"GU": [],
		"GT": [
			{
				"countryName": "Guatemala",
				"name"       : "Alta Verapaz Department",
				"state_code" : "AV"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Baja Verapaz Department",
				"state_code" : "BV"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Chimaltenango Department",
				"state_code" : "CM"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Chiquimula Department",
				"state_code" : "CQ"
			},
			{
				"countryName": "Guatemala",
				"name"       : "El Progreso Department",
				"state_code" : "PR"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Escuintla Department",
				"state_code" : "ES"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Guatemala Department",
				"state_code" : "GU"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Huehuetenango Department",
				"state_code" : "HU"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Izabal Department",
				"state_code" : "IZ"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Jalapa Department",
				"state_code" : "JA"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Jutiapa Department",
				"state_code" : "JU"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Petén Department",
				"state_code" : "PE"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Quetzaltenango Department",
				"state_code" : "QZ"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Quiché Department",
				"state_code" : "QC"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Retalhuleu Department",
				"state_code" : "RE"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Sacatepéquez Department",
				"state_code" : "SA"
			},
			{
				"countryName": "Guatemala",
				"name"       : "San Marcos Department",
				"state_code" : "SM"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Santa Rosa Department",
				"state_code" : "SR"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Sololá Department",
				"state_code" : "SO"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Suchitepéquez Department",
				"state_code" : "SU"
			},
			{
				"countryName": "Guatemala",
				"name"       : "Totonicapán Department",
				"state_code" : "TO"
			}
		],
		"GG": [],
		"GN": [
			{
				"countryName": "Guinea",
				"name"       : "Beyla Prefecture",
				"state_code" : "BE"
			},
			{
				"countryName": "Guinea",
				"name"       : "Boffa Prefecture",
				"state_code" : "BF"
			},
			{
				"countryName": "Guinea",
				"name"       : "Boké Prefecture",
				"state_code" : "BK"
			},
			{
				"countryName": "Guinea",
				"name"       : "Boké Region",
				"state_code" : "B"
			},
			{
				"countryName": "Guinea",
				"name"       : "Conakry",
				"state_code" : "C"
			},
			{
				"countryName": "Guinea",
				"name"       : "Coyah Prefecture",
				"state_code" : "CO"
			},
			{
				"countryName": "Guinea",
				"name"       : "Dabola Prefecture",
				"state_code" : "DB"
			},
			{
				"countryName": "Guinea",
				"name"       : "Dalaba Prefecture",
				"state_code" : "DL"
			},
			{
				"countryName": "Guinea",
				"name"       : "Dinguiraye Prefecture",
				"state_code" : "DI"
			},
			{
				"countryName": "Guinea",
				"name"       : "Dubréka Prefecture",
				"state_code" : "DU"
			},
			{
				"countryName": "Guinea",
				"name"       : "Faranah Prefecture",
				"state_code" : "FA"
			},
			{
				"countryName": "Guinea",
				"name"       : "Forécariah Prefecture",
				"state_code" : "FO"
			},
			{
				"countryName": "Guinea",
				"name"       : "Fria Prefecture",
				"state_code" : "FR"
			},
			{
				"countryName": "Guinea",
				"name"       : "Gaoual Prefecture",
				"state_code" : "GA"
			},
			{
				"countryName": "Guinea",
				"name"       : "Guéckédou Prefecture",
				"state_code" : "GU"
			},
			{
				"countryName": "Guinea",
				"name"       : "Kankan Prefecture",
				"state_code" : "KA"
			},
			{
				"countryName": "Guinea",
				"name"       : "Kankan Region",
				"state_code" : "K"
			},
			{
				"countryName": "Guinea",
				"name"       : "Kérouané Prefecture",
				"state_code" : "KE"
			},
			{
				"countryName": "Guinea",
				"name"       : "Kindia Prefecture",
				"state_code" : "KD"
			},
			{
				"countryName": "Guinea",
				"name"       : "Kindia Region",
				"state_code" : "D"
			},
			{
				"countryName": "Guinea",
				"name"       : "Kissidougou Prefecture",
				"state_code" : "KS"
			},
			{
				"countryName": "Guinea",
				"name"       : "Koubia Prefecture",
				"state_code" : "KB"
			},
			{
				"countryName": "Guinea",
				"name"       : "Koundara Prefecture",
				"state_code" : "KN"
			},
			{
				"countryName": "Guinea",
				"name"       : "Kouroussa Prefecture",
				"state_code" : "KO"
			},
			{
				"countryName": "Guinea",
				"name"       : "Labé Prefecture",
				"state_code" : "LA"
			},
			{
				"countryName": "Guinea",
				"name"       : "Labé Region",
				"state_code" : "L"
			},
			{
				"countryName": "Guinea",
				"name"       : "Lélouma Prefecture",
				"state_code" : "LE"
			},
			{
				"countryName": "Guinea",
				"name"       : "Lola Prefecture",
				"state_code" : "LO"
			},
			{
				"countryName": "Guinea",
				"name"       : "Macenta Prefecture",
				"state_code" : "MC"
			},
			{
				"countryName": "Guinea",
				"name"       : "Mali Prefecture",
				"state_code" : "ML"
			},
			{
				"countryName": "Guinea",
				"name"       : "Mamou Prefecture",
				"state_code" : "MM"
			},
			{
				"countryName": "Guinea",
				"name"       : "Mamou Region",
				"state_code" : "M"
			},
			{
				"countryName": "Guinea",
				"name"       : "Mandiana Prefecture",
				"state_code" : "MD"
			},
			{
				"countryName": "Guinea",
				"name"       : "Nzérékoré Prefecture",
				"state_code" : "NZ"
			},
			{
				"countryName": "Guinea",
				"name"       : "Nzérékoré Region",
				"state_code" : "N"
			},
			{
				"countryName": "Guinea",
				"name"       : "Pita Prefecture",
				"state_code" : "PI"
			},
			{
				"countryName": "Guinea",
				"name"       : "Siguiri Prefecture",
				"state_code" : "SI"
			},
			{
				"countryName": "Guinea",
				"name"       : "Télimélé Prefecture",
				"state_code" : "TE"
			},
			{
				"countryName": "Guinea",
				"name"       : "Tougué Prefecture",
				"state_code" : "TO"
			},
			{
				"countryName": "Guinea",
				"name"       : "Yomou Prefecture",
				"state_code" : "YO"
			}
		],
		"GW": [
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Bafatá",
				"state_code" : "BA"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Biombo Region",
				"state_code" : "BM"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Bolama Region",
				"state_code" : "BL"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Cacheu Region",
				"state_code" : "CA"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Gabú Region",
				"state_code" : "GA"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Leste Province",
				"state_code" : "L"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Norte Province",
				"state_code" : "N"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Oio Region",
				"state_code" : "OI"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Quinara Region",
				"state_code" : "QU"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Sul Province",
				"state_code" : "S"
			},
			{
				"countryName": "Guinea-Bissau",
				"name"       : "Tombali Region",
				"state_code" : "TO"
			}
		],
		"GY": [
			{
				"countryName": "Guyana",
				"name"       : "Barima-Waini",
				"state_code" : "BA"
			},
			{
				"countryName": "Guyana",
				"name"       : "Cuyuni-Mazaruni",
				"state_code" : "CU"
			},
			{
				"countryName": "Guyana",
				"name"       : "Demerara-Mahaica",
				"state_code" : "DE"
			},
			{
				"countryName": "Guyana",
				"name"       : "East Berbice-Corentyne",
				"state_code" : "EB"
			},
			{
				"countryName": "Guyana",
				"name"       : "Essequibo Islands-West Demerara",
				"state_code" : "ES"
			},
			{
				"countryName": "Guyana",
				"name"       : "Mahaica-Berbice",
				"state_code" : "MA"
			},
			{
				"countryName": "Guyana",
				"name"       : "Pomeroon-Supenaam",
				"state_code" : "PM"
			},
			{
				"countryName": "Guyana",
				"name"       : "Potaro-Siparuni",
				"state_code" : "PT"
			},
			{
				"countryName": "Guyana",
				"name"       : "Upper Demerara-Berbice",
				"state_code" : "UD"
			},
			{
				"countryName": "Guyana",
				"name"       : "Upper Takutu-Upper Essequibo",
				"state_code" : "UT"
			}
		],
		"HT": [
			{
				"countryName": "Haiti",
				"name"       : "Artibonite",
				"state_code" : "AR"
			},
			{
				"countryName": "Haiti",
				"name"       : "Centre",
				"state_code" : "CE"
			},
			{
				"countryName": "Haiti",
				"name"       : "Grand'Anse",
				"state_code" : "GA"
			},
			{
				"countryName": "Haiti",
				"name"       : "Nippes",
				"state_code" : "NI"
			},
			{
				"countryName": "Haiti",
				"name"       : "Nord",
				"state_code" : "ND"
			},
			{
				"countryName": "Haiti",
				"name"       : "Nord-Est",
				"state_code" : "NE"
			},
			{
				"countryName": "Haiti",
				"name"       : "Nord-Ouest",
				"state_code" : "NO"
			},
			{
				"countryName": "Haiti",
				"name"       : "Ouest",
				"state_code" : "OU"
			},
			{
				"countryName": "Haiti",
				"name"       : "Sud",
				"state_code" : "SD"
			},
			{
				"countryName": "Haiti",
				"name"       : "Sud-Est",
				"state_code" : "SE"
			}
		],
		"HM": [],
		"HN": [
			{
				"countryName": "Honduras",
				"name"       : "Atlántida Department",
				"state_code" : "AT"
			},
			{
				"countryName": "Honduras",
				"name"       : "Bay Islands Department",
				"state_code" : "IB"
			},
			{
				"countryName": "Honduras",
				"name"       : "Choluteca Department",
				"state_code" : "CH"
			},
			{
				"countryName": "Honduras",
				"name"       : "Colón Department",
				"state_code" : "CL"
			},
			{
				"countryName": "Honduras",
				"name"       : "Comayagua Department",
				"state_code" : "CM"
			},
			{
				"countryName": "Honduras",
				"name"       : "Copán Department",
				"state_code" : "CP"
			},
			{
				"countryName": "Honduras",
				"name"       : "Cortés Department",
				"state_code" : "CR"
			},
			{
				"countryName": "Honduras",
				"name"       : "El Paraíso Department",
				"state_code" : "EP"
			},
			{
				"countryName": "Honduras",
				"name"       : "Francisco Morazán Department",
				"state_code" : "FM"
			},
			{
				"countryName": "Honduras",
				"name"       : "Gracias a Dios Department",
				"state_code" : "GD"
			},
			{
				"countryName": "Honduras",
				"name"       : "Intibucá Department",
				"state_code" : "IN"
			},
			{
				"countryName": "Honduras",
				"name"       : "La Paz Department",
				"state_code" : "LP"
			},
			{
				"countryName": "Honduras",
				"name"       : "Lempira Department",
				"state_code" : "LE"
			},
			{
				"countryName": "Honduras",
				"name"       : "Ocotepeque Department",
				"state_code" : "OC"
			},
			{
				"countryName": "Honduras",
				"name"       : "Olancho Department",
				"state_code" : "OL"
			},
			{
				"countryName": "Honduras",
				"name"       : "Santa Bárbara Department",
				"state_code" : "SB"
			},
			{
				"countryName": "Honduras",
				"name"       : "Valle Department",
				"state_code" : "VA"
			},
			{
				"countryName": "Honduras",
				"name"       : "Yoro Department",
				"state_code" : "YO"
			}
		],
		"HK": [
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Central and Western District",
				"state_code" : "HCW"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Eastern",
				"state_code" : "HEA"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Islands District",
				"state_code" : "NIS"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Kowloon City",
				"state_code" : "KKC"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Kwai Tsing",
				"state_code" : "NKT"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Kwun Tong",
				"state_code" : "KKT"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "North",
				"state_code" : "NNO"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Sai Kung District",
				"state_code" : "NSK"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Sha Tin",
				"state_code" : "NST"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Sham Shui Po",
				"state_code" : "KSS"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Southern",
				"state_code" : "HSO"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Tai Po District",
				"state_code" : "NTP"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Tsuen Wan District",
				"state_code" : "NTW"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Tuen Mun",
				"state_code" : "NTM"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Wan Chai",
				"state_code" : "HWC"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Wong Tai Sin",
				"state_code" : "KWT"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Yau Tsim Mong",
				"state_code" : "KYT"
			},
			{
				"countryName": "Hong Kong S.A.R.",
				"name"       : "Yuen Long District",
				"state_code" : "NYL"
			}
		],
		"HU": [
			{
				"countryName": "Hungary",
				"name"       : "Bács-Kiskun",
				"state_code" : "BK"
			},
			{
				"countryName": "Hungary",
				"name"       : "Baranya",
				"state_code" : "BA"
			},
			{
				"countryName": "Hungary",
				"name"       : "Békés",
				"state_code" : "BE"
			},
			{
				"countryName": "Hungary",
				"name"       : "Békéscsaba",
				"state_code" : "BC"
			},
			{
				"countryName": "Hungary",
				"name"       : "Borsod-Abaúj-Zemplén",
				"state_code" : "BZ"
			},
			{
				"countryName": "Hungary",
				"name"       : "Budapest",
				"state_code" : "BU"
			},
			{
				"countryName": "Hungary",
				"name"       : "Csongrád County",
				"state_code" : "CS"
			},
			{
				"countryName": "Hungary",
				"name"       : "Debrecen",
				"state_code" : "DE"
			},
			{
				"countryName": "Hungary",
				"name"       : "Dunaújváros",
				"state_code" : "DU"
			},
			{
				"countryName": "Hungary",
				"name"       : "Eger",
				"state_code" : "EG"
			},
			{
				"countryName": "Hungary",
				"name"       : "Érd",
				"state_code" : "ER"
			},
			{
				"countryName": "Hungary",
				"name"       : "Fejér County",
				"state_code" : "FE"
			},
			{
				"countryName": "Hungary",
				"name"       : "Győr",
				"state_code" : "GY"
			},
			{
				"countryName": "Hungary",
				"name"       : "Győr-Moson-Sopron County",
				"state_code" : "GS"
			},
			{
				"countryName": "Hungary",
				"name"       : "Hajdú-Bihar County",
				"state_code" : "HB"
			},
			{
				"countryName": "Hungary",
				"name"       : "Heves County",
				"state_code" : "HE"
			},
			{
				"countryName": "Hungary",
				"name"       : "Hódmezővásárhely",
				"state_code" : "HV"
			},
			{
				"countryName": "Hungary",
				"name"       : "Jász-Nagykun-Szolnok County",
				"state_code" : "JN"
			},
			{
				"countryName": "Hungary",
				"name"       : "Kaposvár",
				"state_code" : "KV"
			},
			{
				"countryName": "Hungary",
				"name"       : "Kecskemét",
				"state_code" : "KM"
			},
			{
				"countryName": "Hungary",
				"name"       : "Komárom-Esztergom",
				"state_code" : "KE"
			},
			{
				"countryName": "Hungary",
				"name"       : "Miskolc",
				"state_code" : "MI"
			},
			{
				"countryName": "Hungary",
				"name"       : "Nagykanizsa",
				"state_code" : "NK"
			},
			{
				"countryName": "Hungary",
				"name"       : "Nógrád County",
				"state_code" : "NO"
			},
			{
				"countryName": "Hungary",
				"name"       : "Nyíregyháza",
				"state_code" : "NY"
			},
			{
				"countryName": "Hungary",
				"name"       : "Pécs",
				"state_code" : "PS"
			},
			{
				"countryName": "Hungary",
				"name"       : "Pest County",
				"state_code" : "PE"
			},
			{
				"countryName": "Hungary",
				"name"       : "Salgótarján",
				"state_code" : "ST"
			},
			{
				"countryName": "Hungary",
				"name"       : "Somogy County",
				"state_code" : "SO"
			},
			{
				"countryName": "Hungary",
				"name"       : "Sopron",
				"state_code" : "SN"
			},
			{
				"countryName": "Hungary",
				"name"       : "Szabolcs-Szatmár-Bereg County",
				"state_code" : "SZ"
			},
			{
				"countryName": "Hungary",
				"name"       : "Szeged",
				"state_code" : "SD"
			},
			{
				"countryName": "Hungary",
				"name"       : "Székesfehérvár",
				"state_code" : "SF"
			},
			{
				"countryName": "Hungary",
				"name"       : "Szekszárd",
				"state_code" : "SS"
			},
			{
				"countryName": "Hungary",
				"name"       : "Szolnok",
				"state_code" : "SK"
			},
			{
				"countryName": "Hungary",
				"name"       : "Szombathely",
				"state_code" : "SH"
			},
			{
				"countryName": "Hungary",
				"name"       : "Tatabánya",
				"state_code" : "TB"
			},
			{
				"countryName": "Hungary",
				"name"       : "Tolna County",
				"state_code" : "TO"
			},
			{
				"countryName": "Hungary",
				"name"       : "Vas County",
				"state_code" : "VA"
			},
			{
				"countryName": "Hungary",
				"name"       : "Veszprém",
				"state_code" : "VM"
			},
			{
				"countryName": "Hungary",
				"name"       : "Veszprém County",
				"state_code" : "VE"
			},
			{
				"countryName": "Hungary",
				"name"       : "Zala County",
				"state_code" : "ZA"
			},
			{
				"countryName": "Hungary",
				"name"       : "Zalaegerszeg",
				"state_code" : "ZE"
			}
		],
		"IS": [
			{
				"countryName": "Iceland",
				"name"       : "Capital Region",
				"state_code" : "1"
			},
			{
				"countryName": "Iceland",
				"name"       : "Eastern Region",
				"state_code" : "7"
			},
			{
				"countryName": "Iceland",
				"name"       : "Northeastern Region",
				"state_code" : "6"
			},
			{
				"countryName": "Iceland",
				"name"       : "Northwestern Region",
				"state_code" : "5"
			},
			{
				"countryName": "Iceland",
				"name"       : "Southern Peninsula Region",
				"state_code" : "2"
			},
			{
				"countryName": "Iceland",
				"name"       : "Southern Region",
				"state_code" : "8"
			},
			{
				"countryName": "Iceland",
				"name"       : "Western Region",
				"state_code" : "3"
			},
			{
				"countryName": "Iceland",
				"name"       : "Westfjords",
				"state_code" : "4"
			}
		],
		"IN": [
			{
				"countryName": "India",
				"name"       : "Andaman and Nicobar Islands",
				"state_code" : "AN"
			},
			{
				"countryName": "India",
				"name"       : "Andhra Pradesh",
				"state_code" : "AP"
			},
			{
				"countryName": "India",
				"name"       : "Arunachal Pradesh",
				"state_code" : "AR"
			},
			{
				"countryName": "India",
				"name"       : "Assam",
				"state_code" : "AS"
			},
			{
				"countryName": "India",
				"name"       : "Bihar",
				"state_code" : "BR"
			},
			{
				"countryName": "India",
				"name"       : "Chandigarh",
				"state_code" : "CH"
			},
			{
				"countryName": "India",
				"name"       : "Chhattisgarh",
				"state_code" : "CT"
			},
			{
				"countryName": "India",
				"name"       : "Dadra and Nagar Haveli and Daman and Diu",
				"state_code" : "DH"
			},
			{
				"countryName": "India",
				"name"       : "Delhi",
				"state_code" : "DL"
			},
			{
				"countryName": "India",
				"name"       : "Goa",
				"state_code" : "GA"
			},
			{
				"countryName": "India",
				"name"       : "Gujarat",
				"state_code" : "GJ"
			},
			{
				"countryName": "India",
				"name"       : "Haryana",
				"state_code" : "HR"
			},
			{
				"countryName": "India",
				"name"       : "Himachal Pradesh",
				"state_code" : "HP"
			},
			{
				"countryName": "India",
				"name"       : "Jammu and Kashmir",
				"state_code" : "JK"
			},
			{
				"countryName": "India",
				"name"       : "Jharkhand",
				"state_code" : "JH"
			},
			{
				"countryName": "India",
				"name"       : "Karnataka",
				"state_code" : "KA"
			},
			{
				"countryName": "India",
				"name"       : "Kerala",
				"state_code" : "KL"
			},
			{
				"countryName": "India",
				"name"       : "Ladakh",
				"state_code" : "LA"
			},
			{
				"countryName": "India",
				"name"       : "Lakshadweep",
				"state_code" : "LD"
			},
			{
				"countryName": "India",
				"name"       : "Madhya Pradesh",
				"state_code" : "MP"
			},
			{
				"countryName": "India",
				"name"       : "Maharashtra",
				"state_code" : "MH"
			},
			{
				"countryName": "India",
				"name"       : "Manipur",
				"state_code" : "MN"
			},
			{
				"countryName": "India",
				"name"       : "Meghalaya",
				"state_code" : "ML"
			},
			{
				"countryName": "India",
				"name"       : "Mizoram",
				"state_code" : "MZ"
			},
			{
				"countryName": "India",
				"name"       : "Nagaland",
				"state_code" : "NL"
			},
			{
				"countryName": "India",
				"name"       : "Odisha",
				"state_code" : "OR"
			},
			{
				"countryName": "India",
				"name"       : "Puducherry",
				"state_code" : "PY"
			},
			{
				"countryName": "India",
				"name"       : "Punjab",
				"state_code" : "PB"
			},
			{
				"countryName": "India",
				"name"       : "Rajasthan",
				"state_code" : "RJ"
			},
			{
				"countryName": "India",
				"name"       : "Sikkim",
				"state_code" : "SK"
			},
			{
				"countryName": "India",
				"name"       : "Tamil Nadu",
				"state_code" : "TN"
			},
			{
				"countryName": "India",
				"name"       : "Telangana",
				"state_code" : "TG"
			},
			{
				"countryName": "India",
				"name"       : "Tripura",
				"state_code" : "TR"
			},
			{
				"countryName": "India",
				"name"       : "Uttar Pradesh",
				"state_code" : "UP"
			},
			{
				"countryName": "India",
				"name"       : "Uttarakhand",
				"state_code" : "UT"
			},
			{
				"countryName": "India",
				"name"       : "West Bengal",
				"state_code" : "WB"
			}
		],
		"ID": [
			{
				"countryName": "Indonesia",
				"name"       : "Aceh",
				"state_code" : "AC"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Bali",
				"state_code" : "BA"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Banten",
				"state_code" : "BT"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Bengkulu",
				"state_code" : "BE"
			},
			{
				"countryName": "Indonesia",
				"name"       : "DI Yogyakarta",
				"state_code" : "YO"
			},
			{
				"countryName": "Indonesia",
				"name"       : "DKI Jakarta",
				"state_code" : "JK"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Gorontalo",
				"state_code" : "GO"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Jambi",
				"state_code" : "JA"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Jawa Barat",
				"state_code" : "JB"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Jawa Tengah",
				"state_code" : "JT"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Jawa Timur",
				"state_code" : "JI"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Kalimantan Barat",
				"state_code" : "KB"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Kalimantan Selatan",
				"state_code" : "KS"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Kalimantan Tengah",
				"state_code" : "KT"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Kalimantan Timur",
				"state_code" : "KI"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Kalimantan Utara",
				"state_code" : "KU"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Kepulauan Bangka Belitung",
				"state_code" : "BB"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Kepulauan Riau",
				"state_code" : "KR"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Lampung",
				"state_code" : "LA"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Maluku",
				"state_code" : "MA"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Maluku Utara",
				"state_code" : "MU"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Nusa Tenggara Barat",
				"state_code" : "NB"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Nusa Tenggara Timur",
				"state_code" : "NT"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Papua",
				"state_code" : "PA"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Papua Barat",
				"state_code" : "PB"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Riau",
				"state_code" : "RI"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Sulawesi Barat",
				"state_code" : "SR"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Sulawesi Selatan",
				"state_code" : "SN"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Sulawesi Tengah",
				"state_code" : "ST"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Sulawesi Tenggara",
				"state_code" : "SG"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Sulawesi Utara",
				"state_code" : "SA"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Sumatera Barat",
				"state_code" : "SB"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Sumatera Selatan",
				"state_code" : "SS"
			},
			{
				"countryName": "Indonesia",
				"name"       : "Sumatera Utara",
				"state_code" : "SU"
			}
		],
		"IR": [
			{
				"countryName": "Iran",
				"name"       : "Alborz",
				"state_code" : "30"
			},
			{
				"countryName": "Iran",
				"name"       : "Ardabil",
				"state_code" : "24"
			},
			{
				"countryName": "Iran",
				"name"       : "Bushehr",
				"state_code" : "18"
			},
			{
				"countryName": "Iran",
				"name"       : "Chaharmahal and Bakhtiari",
				"state_code" : "14"
			},
			{
				"countryName": "Iran",
				"name"       : "East Azerbaijan",
				"state_code" : "03"
			},
			{
				"countryName": "Iran",
				"name"       : "Fars",
				"state_code" : "07"
			},
			{
				"countryName": "Iran",
				"name"       : "Gilan",
				"state_code" : "01"
			},
			{
				"countryName": "Iran",
				"name"       : "Golestan",
				"state_code" : "27"
			},
			{
				"countryName": "Iran",
				"name"       : "Hamadan",
				"state_code" : "13"
			},
			{
				"countryName": "Iran",
				"name"       : "Hormozgan",
				"state_code" : "22"
			},
			{
				"countryName": "Iran",
				"name"       : "Ilam",
				"state_code" : "16"
			},
			{
				"countryName": "Iran",
				"name"       : "Isfahan",
				"state_code" : "10"
			},
			{
				"countryName": "Iran",
				"name"       : "Kerman",
				"state_code" : "08"
			},
			{
				"countryName": "Iran",
				"name"       : "Kermanshah",
				"state_code" : "05"
			},
			{
				"countryName": "Iran",
				"name"       : "Khuzestan",
				"state_code" : "06"
			},
			{
				"countryName": "Iran",
				"name"       : "Kohgiluyeh and Boyer-Ahmad",
				"state_code" : "17"
			},
			{
				"countryName": "Iran",
				"name"       : "Kurdistan",
				"state_code" : "12"
			},
			{
				"countryName": "Iran",
				"name"       : "Lorestan",
				"state_code" : "15"
			},
			{
				"countryName": "Iran",
				"name"       : "Markazi",
				"state_code" : "00"
			},
			{
				"countryName": "Iran",
				"name"       : "Mazandaran",
				"state_code" : "02"
			},
			{
				"countryName": "Iran",
				"name"       : "North Khorasan",
				"state_code" : "28"
			},
			{
				"countryName": "Iran",
				"name"       : "Qazvin",
				"state_code" : "26"
			},
			{
				"countryName": "Iran",
				"name"       : "Qom",
				"state_code" : "25"
			},
			{
				"countryName": "Iran",
				"name"       : "Razavi Khorasan",
				"state_code" : "09"
			},
			{
				"countryName": "Iran",
				"name"       : "Semnan",
				"state_code" : "20"
			},
			{
				"countryName": "Iran",
				"name"       : "Sistan and Baluchestan",
				"state_code" : "11"
			},
			{
				"countryName": "Iran",
				"name"       : "South Khorasan",
				"state_code" : "29"
			},
			{
				"countryName": "Iran",
				"name"       : "Tehran",
				"state_code" : "23"
			},
			{
				"countryName": "Iran",
				"name"       : "West Azarbaijan",
				"state_code" : "04"
			},
			{
				"countryName": "Iran",
				"name"       : "Yazd",
				"state_code" : "21"
			},
			{
				"countryName": "Iran",
				"name"       : "Zanjan",
				"state_code" : "19"
			}
		],
		"IQ": [
			{
				"countryName": "Iraq",
				"name"       : "Al Anbar",
				"state_code" : "AN"
			},
			{
				"countryName": "Iraq",
				"name"       : "Al Muthanna",
				"state_code" : "MU"
			},
			{
				"countryName": "Iraq",
				"name"       : "Al-Qādisiyyah",
				"state_code" : "QA"
			},
			{
				"countryName": "Iraq",
				"name"       : "Babylon",
				"state_code" : "BB"
			},
			{
				"countryName": "Iraq",
				"name"       : "Baghdad",
				"state_code" : "BG"
			},
			{
				"countryName": "Iraq",
				"name"       : "Basra",
				"state_code" : "BA"
			},
			{
				"countryName": "Iraq",
				"name"       : "Dhi Qar",
				"state_code" : "DQ"
			},
			{
				"countryName": "Iraq",
				"name"       : "Diyala",
				"state_code" : "DI"
			},
			{
				"countryName": "Iraq",
				"name"       : "Dohuk",
				"state_code" : "DA"
			},
			{
				"countryName": "Iraq",
				"name"       : "Erbil",
				"state_code" : "AR"
			},
			{
				"countryName": "Iraq",
				"name"       : "Karbala",
				"state_code" : "KA"
			},
			{
				"countryName": "Iraq",
				"name"       : "Kirkuk",
				"state_code" : "KI"
			},
			{
				"countryName": "Iraq",
				"name"       : "Maysan",
				"state_code" : "MA"
			},
			{
				"countryName": "Iraq",
				"name"       : "Najaf",
				"state_code" : "NA"
			},
			{
				"countryName": "Iraq",
				"name"       : "Nineveh",
				"state_code" : "NI"
			},
			{
				"countryName": "Iraq",
				"name"       : "Saladin",
				"state_code" : "SD"
			},
			{
				"countryName": "Iraq",
				"name"       : "Sulaymaniyah",
				"state_code" : "SU"
			},
			{
				"countryName": "Iraq",
				"name"       : "Wasit",
				"state_code" : "WA"
			}
		],
		"IE": [
			{
				"countryName": "Ireland",
				"name"       : "Carlow",
				"state_code" : "CW"
			},
			{
				"countryName": "Ireland",
				"name"       : "Cavan",
				"state_code" : "CN"
			},
			{
				"countryName": "Ireland",
				"name"       : "Clare",
				"state_code" : "CE"
			},
			{
				"countryName": "Ireland",
				"name"       : "Connacht",
				"state_code" : "C"
			},
			{
				"countryName": "Ireland",
				"name"       : "Cork",
				"state_code" : "CO"
			},
			{
				"countryName": "Ireland",
				"name"       : "Donegal",
				"state_code" : "DL"
			},
			{
				"countryName": "Ireland",
				"name"       : "Dublin",
				"state_code" : "D"
			},
			{
				"countryName": "Ireland",
				"name"       : "Galway",
				"state_code" : "G"
			},
			{
				"countryName": "Ireland",
				"name"       : "Kerry",
				"state_code" : "KY"
			},
			{
				"countryName": "Ireland",
				"name"       : "Kildare",
				"state_code" : "KE"
			},
			{
				"countryName": "Ireland",
				"name"       : "Kilkenny",
				"state_code" : "KK"
			},
			{
				"countryName": "Ireland",
				"name"       : "Laois",
				"state_code" : "LS"
			},
			{
				"countryName": "Ireland",
				"name"       : "Leinster",
				"state_code" : "L"
			},
			{
				"countryName": "Ireland",
				"name"       : "Limerick",
				"state_code" : "LK"
			},
			{
				"countryName": "Ireland",
				"name"       : "Longford",
				"state_code" : "LD"
			},
			{
				"countryName": "Ireland",
				"name"       : "Louth",
				"state_code" : "LH"
			},
			{
				"countryName": "Ireland",
				"name"       : "Mayo",
				"state_code" : "MO"
			},
			{
				"countryName": "Ireland",
				"name"       : "Meath",
				"state_code" : "MH"
			},
			{
				"countryName": "Ireland",
				"name"       : "Monaghan",
				"state_code" : "MN"
			},
			{
				"countryName": "Ireland",
				"name"       : "Munster",
				"state_code" : "M"
			},
			{
				"countryName": "Ireland",
				"name"       : "Offaly",
				"state_code" : "OY"
			},
			{
				"countryName": "Ireland",
				"name"       : "Roscommon",
				"state_code" : "RN"
			},
			{
				"countryName": "Ireland",
				"name"       : "Sligo",
				"state_code" : "SO"
			},
			{
				"countryName": "Ireland",
				"name"       : "Tipperary",
				"state_code" : "TA"
			},
			{
				"countryName": "Ireland",
				"name"       : "Ulster",
				"state_code" : "U"
			},
			{
				"countryName": "Ireland",
				"name"       : "Waterford",
				"state_code" : "WD"
			},
			{
				"countryName": "Ireland",
				"name"       : "Westmeath",
				"state_code" : "WH"
			},
			{
				"countryName": "Ireland",
				"name"       : "Wexford",
				"state_code" : "WX"
			},
			{
				"countryName": "Ireland",
				"name"       : "Wicklow",
				"state_code" : "WW"
			}
		],
		"IL": [
			{
				"countryName": "Israel",
				"name"       : "Central District",
				"state_code" : "M"
			},
			{
				"countryName": "Israel",
				"name"       : "Haifa District",
				"state_code" : "HA"
			},
			{
				"countryName": "Israel",
				"name"       : "Jerusalem District",
				"state_code" : "JM"
			},
			{
				"countryName": "Israel",
				"name"       : "Northern District",
				"state_code" : "Z"
			},
			{
				"countryName": "Israel",
				"name"       : "Southern District",
				"state_code" : "D"
			},
			{
				"countryName": "Israel",
				"name"       : "Tel Aviv District",
				"state_code" : "TA"
			}
		],
		"IT": [
			{
				"countryName": "Italy",
				"name"       : "Abruzzo",
				"state_code" : "65"
			},
			{
				"countryName": "Italy",
				"name"       : "Agrigento",
				"state_code" : "AG"
			},
			{
				"countryName": "Italy",
				"name"       : "Alessandria",
				"state_code" : "AL"
			},
			{
				"countryName": "Italy",
				"name"       : "Ancona",
				"state_code" : "AN"
			},
			{
				"countryName": "Italy",
				"name"       : "Aosta Valley",
				"state_code" : "23"
			},
			{
				"countryName": "Italy",
				"name"       : "Apulia",
				"state_code" : "75"
			},
			{
				"countryName": "Italy",
				"name"       : "Ascoli Piceno",
				"state_code" : "AP"
			},
			{
				"countryName": "Italy",
				"name"       : "Asti",
				"state_code" : "AT"
			},
			{
				"countryName": "Italy",
				"name"       : "Avellino",
				"state_code" : "AV"
			},
			{
				"countryName": "Italy",
				"name"       : "Bari",
				"state_code" : "BA"
			},
			{
				"countryName": "Italy",
				"name"       : "Barletta-Andria-Trani",
				"state_code" : "BT"
			},
			{
				"countryName": "Italy",
				"name"       : "Basilicata",
				"state_code" : "77"
			},
			{
				"countryName": "Italy",
				"name"       : "Belluno",
				"state_code" : "BL"
			},
			{
				"countryName": "Italy",
				"name"       : "Benevento",
				"state_code" : "BN"
			},
			{
				"countryName": "Italy",
				"name"       : "Bergamo",
				"state_code" : "BG"
			},
			{
				"countryName": "Italy",
				"name"       : "Biella",
				"state_code" : "BI"
			},
			{
				"countryName": "Italy",
				"name"       : "Bologna",
				"state_code" : "BO"
			},
			{
				"countryName": "Italy",
				"name"       : "Brescia",
				"state_code" : "BS"
			},
			{
				"countryName": "Italy",
				"name"       : "Brindisi",
				"state_code" : "BR"
			},
			{
				"countryName": "Italy",
				"name"       : "Cagliari",
				"state_code" : "CA"
			},
			{
				"countryName": "Italy",
				"name"       : "Calabria",
				"state_code" : "78"
			},
			{
				"countryName": "Italy",
				"name"       : "Caltanissetta",
				"state_code" : "CL"
			},
			{
				"countryName": "Italy",
				"name"       : "Campania",
				"state_code" : "72"
			},
			{
				"countryName": "Italy",
				"name"       : "Campobasso",
				"state_code" : "CB"
			},
			{
				"countryName": "Italy",
				"name"       : "Caserta",
				"state_code" : "CE"
			},
			{
				"countryName": "Italy",
				"name"       : "Catania",
				"state_code" : "CT"
			},
			{
				"countryName": "Italy",
				"name"       : "Catanzaro",
				"state_code" : "CZ"
			},
			{
				"countryName": "Italy",
				"name"       : "Chieti",
				"state_code" : "CH"
			},
			{
				"countryName": "Italy",
				"name"       : "Como",
				"state_code" : "CO"
			},
			{
				"countryName": "Italy",
				"name"       : "Cosenza",
				"state_code" : "CS"
			},
			{
				"countryName": "Italy",
				"name"       : "Cremona",
				"state_code" : "CR"
			},
			{
				"countryName": "Italy",
				"name"       : "Crotone",
				"state_code" : "KR"
			},
			{
				"countryName": "Italy",
				"name"       : "Cuneo",
				"state_code" : "CN"
			},
			{
				"countryName": "Italy",
				"name"       : "Emilia-Romagna",
				"state_code" : "45"
			},
			{
				"countryName": "Italy",
				"name"       : "Enna",
				"state_code" : "EN"
			},
			{
				"countryName": "Italy",
				"name"       : "Fermo",
				"state_code" : "FM"
			},
			{
				"countryName": "Italy",
				"name"       : "Ferrara",
				"state_code" : "FE"
			},
			{
				"countryName": "Italy",
				"name"       : "Florence",
				"state_code" : "FI"
			},
			{
				"countryName": "Italy",
				"name"       : "Foggia",
				"state_code" : "FG"
			},
			{
				"countryName": "Italy",
				"name"       : "Forlì-Cesena",
				"state_code" : "FC"
			},
			{
				"countryName": "Italy",
				"name"       : "Friuli–Venezia Giulia",
				"state_code" : "36"
			},
			{
				"countryName": "Italy",
				"name"       : "Frosinone",
				"state_code" : "FR"
			},
			{
				"countryName": "Italy",
				"name"       : "Genoa",
				"state_code" : "GE"
			},
			{
				"countryName": "Italy",
				"name"       : "Gorizia",
				"state_code" : "GO"
			},
			{
				"countryName": "Italy",
				"name"       : "Grosseto",
				"state_code" : "GR"
			},
			{
				"countryName": "Italy",
				"name"       : "Imperia",
				"state_code" : "IM"
			},
			{
				"countryName": "Italy",
				"name"       : "Isernia",
				"state_code" : "IS"
			},
			{
				"countryName": "Italy",
				"name"       : "L'Aquila",
				"state_code" : "AQ"
			},
			{
				"countryName": "Italy",
				"name"       : "La Spezia",
				"state_code" : "SP"
			},
			{
				"countryName": "Italy",
				"name"       : "Latina",
				"state_code" : "LT"
			},
			{
				"countryName": "Italy",
				"name"       : "Lazio",
				"state_code" : "62"
			},
			{
				"countryName": "Italy",
				"name"       : "Lecce",
				"state_code" : "LE"
			},
			{
				"countryName": "Italy",
				"name"       : "Lecco",
				"state_code" : "LC"
			},
			{
				"countryName": "Italy",
				"name"       : "Liguria",
				"state_code" : "42"
			},
			{
				"countryName": "Italy",
				"name"       : "Livorno",
				"state_code" : "LI"
			},
			{
				"countryName": "Italy",
				"name"       : "Lodi",
				"state_code" : "LO"
			},
			{
				"countryName": "Italy",
				"name"       : "Lombardy",
				"state_code" : "25"
			},
			{
				"countryName": "Italy",
				"name"       : "Lucca",
				"state_code" : "LU"
			},
			{
				"countryName": "Italy",
				"name"       : "Macerata",
				"state_code" : "MC"
			},
			{
				"countryName": "Italy",
				"name"       : "Mantua",
				"state_code" : "MN"
			},
			{
				"countryName": "Italy",
				"name"       : "Marche",
				"state_code" : "57"
			},
			{
				"countryName": "Italy",
				"name"       : "Massa and Carrara",
				"state_code" : "MS"
			},
			{
				"countryName": "Italy",
				"name"       : "Matera",
				"state_code" : "MT"
			},
			{
				"countryName": "Italy",
				"name"       : "Medio Campidano",
				"state_code" : "VS"
			},
			{
				"countryName": "Italy",
				"name"       : "Messina",
				"state_code" : "ME"
			},
			{
				"countryName": "Italy",
				"name"       : "Milan",
				"state_code" : "MI"
			},
			{
				"countryName": "Italy",
				"name"       : "Modena",
				"state_code" : "MO"
			},
			{
				"countryName": "Italy",
				"name"       : "Molise",
				"state_code" : "67"
			},
			{
				"countryName": "Italy",
				"name"       : "Monza and Brianza",
				"state_code" : "MB"
			},
			{
				"countryName": "Italy",
				"name"       : "Naples",
				"state_code" : "NA"
			},
			{
				"countryName": "Italy",
				"name"       : "Novara",
				"state_code" : "NO"
			},
			{
				"countryName": "Italy",
				"name"       : "Nuoro",
				"state_code" : "NU"
			},
			{
				"countryName": "Italy",
				"name"       : "Oristano",
				"state_code" : "OR"
			},
			{
				"countryName": "Italy",
				"name"       : "Padua",
				"state_code" : "PD"
			},
			{
				"countryName": "Italy",
				"name"       : "Palermo",
				"state_code" : "PA"
			},
			{
				"countryName": "Italy",
				"name"       : "Parma",
				"state_code" : "PR"
			},
			{
				"countryName": "Italy",
				"name"       : "Pavia",
				"state_code" : "PV"
			},
			{
				"countryName": "Italy",
				"name"       : "Perugia",
				"state_code" : "PG"
			},
			{
				"countryName": "Italy",
				"name"       : "Pesaro and Urbino",
				"state_code" : "PU"
			},
			{
				"countryName": "Italy",
				"name"       : "Pescara",
				"state_code" : "PE"
			},
			{
				"countryName": "Italy",
				"name"       : "Piacenza",
				"state_code" : "PC"
			},
			{
				"countryName": "Italy",
				"name"       : "Piedmont",
				"state_code" : "21"
			},
			{
				"countryName": "Italy",
				"name"       : "Pisa",
				"state_code" : "PI"
			},
			{
				"countryName": "Italy",
				"name"       : "Pistoia",
				"state_code" : "PT"
			},
			{
				"countryName": "Italy",
				"name"       : "Pordenone",
				"state_code" : "PN"
			},
			{
				"countryName": "Italy",
				"name"       : "Potenza",
				"state_code" : "PZ"
			},
			{
				"countryName": "Italy",
				"name"       : "Prato",
				"state_code" : "PO"
			},
			{
				"countryName": "Italy",
				"name"       : "Ragusa",
				"state_code" : "RG"
			},
			{
				"countryName": "Italy",
				"name"       : "Ravenna",
				"state_code" : "RA"
			},
			{
				"countryName": "Italy",
				"name"       : "Reggio Calabria",
				"state_code" : "RC"
			},
			{
				"countryName": "Italy",
				"name"       : "Reggio Emilia",
				"state_code" : "RE"
			},
			{
				"countryName": "Italy",
				"name"       : "Rieti",
				"state_code" : "RI"
			},
			{
				"countryName": "Italy",
				"name"       : "Rimini",
				"state_code" : "RN"
			},
			{
				"countryName": "Italy",
				"name"       : "Rome",
				"state_code" : "RM"
			},
			{
				"countryName": "Italy",
				"name"       : "Rovigo",
				"state_code" : "RO"
			},
			{
				"countryName": "Italy",
				"name"       : "Salerno",
				"state_code" : "SA"
			},
			{
				"countryName": "Italy",
				"name"       : "Sardinia",
				"state_code" : "88"
			},
			{
				"countryName": "Italy",
				"name"       : "Sassari",
				"state_code" : "SS"
			},
			{
				"countryName": "Italy",
				"name"       : "Savona",
				"state_code" : "SV"
			},
			{
				"countryName": "Italy",
				"name"       : "Sicily",
				"state_code" : "82"
			},
			{
				"countryName": "Italy",
				"name"       : "Siena",
				"state_code" : "SI"
			},
			{
				"countryName": "Italy",
				"name"       : "Siracusa",
				"state_code" : "SR"
			},
			{
				"countryName": "Italy",
				"name"       : "Sondrio",
				"state_code" : "SO"
			},
			{
				"countryName": "Italy",
				"name"       : "South Sardinia",
				"state_code" : "SU"
			},
			{
				"countryName": "Italy",
				"name"       : "South Tyrol",
				"state_code" : "BZ"
			},
			{
				"countryName": "Italy",
				"name"       : "Taranto",
				"state_code" : "TA"
			},
			{
				"countryName": "Italy",
				"name"       : "Teramo",
				"state_code" : "TE"
			},
			{
				"countryName": "Italy",
				"name"       : "Terni",
				"state_code" : "TR"
			},
			{
				"countryName": "Italy",
				"name"       : "Trapani",
				"state_code" : "TP"
			},
			{
				"countryName": "Italy",
				"name"       : "Trentino",
				"state_code" : "TN"
			},
			{
				"countryName": "Italy",
				"name"       : "Trentino-South Tyrol",
				"state_code" : "32"
			},
			{
				"countryName": "Italy",
				"name"       : "Treviso",
				"state_code" : "TV"
			},
			{
				"countryName": "Italy",
				"name"       : "Trieste",
				"state_code" : "TS"
			},
			{
				"countryName": "Italy",
				"name"       : "Turin",
				"state_code" : "TO"
			},
			{
				"countryName": "Italy",
				"name"       : "Tuscany",
				"state_code" : "52"
			},
			{
				"countryName": "Italy",
				"name"       : "Udine",
				"state_code" : "UD"
			},
			{
				"countryName": "Italy",
				"name"       : "Umbria",
				"state_code" : "55"
			},
			{
				"countryName": "Italy",
				"name"       : "Varese",
				"state_code" : "VA"
			},
			{
				"countryName": "Italy",
				"name"       : "Veneto",
				"state_code" : "34"
			},
			{
				"countryName": "Italy",
				"name"       : "Venice",
				"state_code" : "VE"
			},
			{
				"countryName": "Italy",
				"name"       : "Verbano-Cusio-Ossola",
				"state_code" : "VB"
			},
			{
				"countryName": "Italy",
				"name"       : "Vercelli",
				"state_code" : "VC"
			},
			{
				"countryName": "Italy",
				"name"       : "Verona",
				"state_code" : "VR"
			},
			{
				"countryName": "Italy",
				"name"       : "Vibo Valentia",
				"state_code" : "VV"
			},
			{
				"countryName": "Italy",
				"name"       : "Vicenza",
				"state_code" : "VI"
			},
			{
				"countryName": "Italy",
				"name"       : "Viterbo",
				"state_code" : "VT"
			}
		],
		"JM": [
			{
				"countryName": "Jamaica",
				"name"       : "Clarendon Parish",
				"state_code" : "13"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Hanover Parish",
				"state_code" : "09"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Kingston Parish",
				"state_code" : "01"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Manchester Parish",
				"state_code" : "12"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Portland Parish",
				"state_code" : "04"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Saint Andrew",
				"state_code" : "02"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Saint Ann Parish",
				"state_code" : "06"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Saint Catherine Parish",
				"state_code" : "14"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Saint Elizabeth Parish",
				"state_code" : "11"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Saint James Parish",
				"state_code" : "08"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Saint Mary Parish",
				"state_code" : "05"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Saint Thomas Parish",
				"state_code" : "03"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Trelawny Parish",
				"state_code" : "07"
			},
			{
				"countryName": "Jamaica",
				"name"       : "Westmoreland Parish",
				"state_code" : "10"
			}
		],
		"JP": [
			{
				"countryName": "Japan",
				"name"       : "Aichi Prefecture",
				"state_code" : "23"
			},
			{
				"countryName": "Japan",
				"name"       : "Akita Prefecture",
				"state_code" : "05"
			},
			{
				"countryName": "Japan",
				"name"       : "Aomori Prefecture",
				"state_code" : "02"
			},
			{
				"countryName": "Japan",
				"name"       : "Chiba Prefecture",
				"state_code" : "12"
			},
			{
				"countryName": "Japan",
				"name"       : "Ehime Prefecture",
				"state_code" : "38"
			},
			{
				"countryName": "Japan",
				"name"       : "Fukui Prefecture",
				"state_code" : "18"
			},
			{
				"countryName": "Japan",
				"name"       : "Fukuoka Prefecture",
				"state_code" : "40"
			},
			{
				"countryName": "Japan",
				"name"       : "Fukushima Prefecture",
				"state_code" : "07"
			},
			{
				"countryName": "Japan",
				"name"       : "Gifu Prefecture",
				"state_code" : "21"
			},
			{
				"countryName": "Japan",
				"name"       : "Gunma Prefecture",
				"state_code" : "10"
			},
			{
				"countryName": "Japan",
				"name"       : "Hiroshima Prefecture",
				"state_code" : "34"
			},
			{
				"countryName": "Japan",
				"name"       : "Hokkaidō Prefecture",
				"state_code" : "01"
			},
			{
				"countryName": "Japan",
				"name"       : "Hyōgo Prefecture",
				"state_code" : "28"
			},
			{
				"countryName": "Japan",
				"name"       : "Ibaraki Prefecture",
				"state_code" : "08"
			},
			{
				"countryName": "Japan",
				"name"       : "Ishikawa Prefecture",
				"state_code" : "17"
			},
			{
				"countryName": "Japan",
				"name"       : "Iwate Prefecture",
				"state_code" : "03"
			},
			{
				"countryName": "Japan",
				"name"       : "Kagawa Prefecture",
				"state_code" : "37"
			},
			{
				"countryName": "Japan",
				"name"       : "Kagoshima Prefecture",
				"state_code" : "46"
			},
			{
				"countryName": "Japan",
				"name"       : "Kanagawa Prefecture",
				"state_code" : "14"
			},
			{
				"countryName": "Japan",
				"name"       : "Kōchi Prefecture",
				"state_code" : "39"
			},
			{
				"countryName": "Japan",
				"name"       : "Kumamoto Prefecture",
				"state_code" : "43"
			},
			{
				"countryName": "Japan",
				"name"       : "Kyōto Prefecture",
				"state_code" : "26"
			},
			{
				"countryName": "Japan",
				"name"       : "Mie Prefecture",
				"state_code" : "24"
			},
			{
				"countryName": "Japan",
				"name"       : "Miyagi Prefecture",
				"state_code" : "04"
			},
			{
				"countryName": "Japan",
				"name"       : "Miyazaki Prefecture",
				"state_code" : "45"
			},
			{
				"countryName": "Japan",
				"name"       : "Nagano Prefecture",
				"state_code" : "20"
			},
			{
				"countryName": "Japan",
				"name"       : "Nagasaki Prefecture",
				"state_code" : "42"
			},
			{
				"countryName": "Japan",
				"name"       : "Nara Prefecture",
				"state_code" : "29"
			},
			{
				"countryName": "Japan",
				"name"       : "Niigata Prefecture",
				"state_code" : "15"
			},
			{
				"countryName": "Japan",
				"name"       : "Ōita Prefecture",
				"state_code" : "44"
			},
			{
				"countryName": "Japan",
				"name"       : "Okayama Prefecture",
				"state_code" : "33"
			},
			{
				"countryName": "Japan",
				"name"       : "Okinawa Prefecture",
				"state_code" : "47"
			},
			{
				"countryName": "Japan",
				"name"       : "Ōsaka Prefecture",
				"state_code" : "27"
			},
			{
				"countryName": "Japan",
				"name"       : "Saga Prefecture",
				"state_code" : "41"
			},
			{
				"countryName": "Japan",
				"name"       : "Saitama Prefecture",
				"state_code" : "11"
			},
			{
				"countryName": "Japan",
				"name"       : "Shiga Prefecture",
				"state_code" : "25"
			},
			{
				"countryName": "Japan",
				"name"       : "Shimane Prefecture",
				"state_code" : "32"
			},
			{
				"countryName": "Japan",
				"name"       : "Shizuoka Prefecture",
				"state_code" : "22"
			},
			{
				"countryName": "Japan",
				"name"       : "Tochigi Prefecture",
				"state_code" : "09"
			},
			{
				"countryName": "Japan",
				"name"       : "Tokushima Prefecture",
				"state_code" : "36"
			},
			{
				"countryName": "Japan",
				"name"       : "Tokyo",
				"state_code" : "13"
			},
			{
				"countryName": "Japan",
				"name"       : "Tottori Prefecture",
				"state_code" : "31"
			},
			{
				"countryName": "Japan",
				"name"       : "Toyama Prefecture",
				"state_code" : "16"
			},
			{
				"countryName": "Japan",
				"name"       : "Wakayama Prefecture",
				"state_code" : "30"
			},
			{
				"countryName": "Japan",
				"name"       : "Yamagata Prefecture",
				"state_code" : "06"
			},
			{
				"countryName": "Japan",
				"name"       : "Yamaguchi Prefecture",
				"state_code" : "35"
			},
			{
				"countryName": "Japan",
				"name"       : "Yamanashi Prefecture",
				"state_code" : "19"
			}
		],
		"JE": [],
		"JO": [
			{
				"countryName": "Jordan",
				"name"       : "Ajloun",
				"state_code" : "AJ"
			},
			{
				"countryName": "Jordan",
				"name"       : "Amman",
				"state_code" : "AM"
			},
			{
				"countryName": "Jordan",
				"name"       : "Aqaba",
				"state_code" : "AQ"
			},
			{
				"countryName": "Jordan",
				"name"       : "Balqa",
				"state_code" : "BA"
			},
			{
				"countryName": "Jordan",
				"name"       : "Irbid",
				"state_code" : "IR"
			},
			{
				"countryName": "Jordan",
				"name"       : "Jerash",
				"state_code" : "JA"
			},
			{
				"countryName": "Jordan",
				"name"       : "Karak",
				"state_code" : "KA"
			},
			{
				"countryName": "Jordan",
				"name"       : "Ma'an",
				"state_code" : "MN"
			},
			{
				"countryName": "Jordan",
				"name"       : "Madaba",
				"state_code" : "MD"
			},
			{
				"countryName": "Jordan",
				"name"       : "Mafraq",
				"state_code" : "MA"
			},
			{
				"countryName": "Jordan",
				"name"       : "Tafilah",
				"state_code" : "AT"
			},
			{
				"countryName": "Jordan",
				"name"       : "Zarqa",
				"state_code" : "AZ"
			}
		],
		"KZ": [
			{
				"countryName": "Kazakhstan",
				"name"       : "Akmola Region",
				"state_code" : "AKM"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Aktobe Region",
				"state_code" : "AKT"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Almaty",
				"state_code" : "ALA"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Almaty Region",
				"state_code" : "ALM"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Atyrau Region",
				"state_code" : "ATY"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Baikonur",
				"state_code" : "BAY"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "East Kazakhstan Region",
				"state_code" : "VOS"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Jambyl Region",
				"state_code" : "ZHA"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Karaganda Region",
				"state_code" : "KAR"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Kostanay Region",
				"state_code" : "KUS"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Kyzylorda Region",
				"state_code" : "KZY"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Mangystau Region",
				"state_code" : "MAN"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "North Kazakhstan Region",
				"state_code" : "SEV"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Nur-Sultan",
				"state_code" : "AST"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Pavlodar Region",
				"state_code" : "PAV"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "Turkestan Region",
				"state_code" : "YUZ"
			},
			{
				"countryName": "Kazakhstan",
				"name"       : "West Kazakhstan Province",
				"state_code" : "ZAP"
			}
		],
		"KE": [
			{
				"countryName": "Kenya",
				"name"       : "Baringo",
				"state_code" : "01"
			},
			{
				"countryName": "Kenya",
				"name"       : "Bomet",
				"state_code" : "02"
			},
			{
				"countryName": "Kenya",
				"name"       : "Bungoma",
				"state_code" : "03"
			},
			{
				"countryName": "Kenya",
				"name"       : "Busia",
				"state_code" : "04"
			},
			{
				"countryName": "Kenya",
				"name"       : "Elgeyo-Marakwet",
				"state_code" : "05"
			},
			{
				"countryName": "Kenya",
				"name"       : "Embu",
				"state_code" : "06"
			},
			{
				"countryName": "Kenya",
				"name"       : "Garissa",
				"state_code" : "07"
			},
			{
				"countryName": "Kenya",
				"name"       : "Homa Bay",
				"state_code" : "08"
			},
			{
				"countryName": "Kenya",
				"name"       : "Isiolo",
				"state_code" : "09"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kajiado",
				"state_code" : "10"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kakamega",
				"state_code" : "11"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kericho",
				"state_code" : "12"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kiambu",
				"state_code" : "13"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kilifi",
				"state_code" : "14"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kirinyaga",
				"state_code" : "15"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kisii",
				"state_code" : "16"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kisumu",
				"state_code" : "17"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kitui",
				"state_code" : "18"
			},
			{
				"countryName": "Kenya",
				"name"       : "Kwale",
				"state_code" : "19"
			},
			{
				"countryName": "Kenya",
				"name"       : "Laikipia",
				"state_code" : "20"
			},
			{
				"countryName": "Kenya",
				"name"       : "Lamu",
				"state_code" : "21"
			},
			{
				"countryName": "Kenya",
				"name"       : "Machakos",
				"state_code" : "22"
			},
			{
				"countryName": "Kenya",
				"name"       : "Makueni",
				"state_code" : "23"
			},
			{
				"countryName": "Kenya",
				"name"       : "Mandera",
				"state_code" : "24"
			},
			{
				"countryName": "Kenya",
				"name"       : "Marsabit",
				"state_code" : "25"
			},
			{
				"countryName": "Kenya",
				"name"       : "Meru",
				"state_code" : "26"
			},
			{
				"countryName": "Kenya",
				"name"       : "Migori",
				"state_code" : "27"
			},
			{
				"countryName": "Kenya",
				"name"       : "Mombasa",
				"state_code" : "28"
			},
			{
				"countryName": "Kenya",
				"name"       : "Murang'a",
				"state_code" : "29"
			},
			{
				"countryName": "Kenya",
				"name"       : "Nairobi City",
				"state_code" : "30"
			},
			{
				"countryName": "Kenya",
				"name"       : "Nakuru",
				"state_code" : "31"
			},
			{
				"countryName": "Kenya",
				"name"       : "Nandi",
				"state_code" : "32"
			},
			{
				"countryName": "Kenya",
				"name"       : "Narok",
				"state_code" : "33"
			},
			{
				"countryName": "Kenya",
				"name"       : "Nyamira",
				"state_code" : "34"
			},
			{
				"countryName": "Kenya",
				"name"       : "Nyandarua",
				"state_code" : "35"
			},
			{
				"countryName": "Kenya",
				"name"       : "Nyeri",
				"state_code" : "36"
			},
			{
				"countryName": "Kenya",
				"name"       : "Samburu",
				"state_code" : "37"
			},
			{
				"countryName": "Kenya",
				"name"       : "Siaya",
				"state_code" : "38"
			},
			{
				"countryName": "Kenya",
				"name"       : "Taita–Taveta",
				"state_code" : "39"
			},
			{
				"countryName": "Kenya",
				"name"       : "Tana River",
				"state_code" : "40"
			},
			{
				"countryName": "Kenya",
				"name"       : "Tharaka-Nithi",
				"state_code" : "41"
			},
			{
				"countryName": "Kenya",
				"name"       : "Trans Nzoia",
				"state_code" : "42"
			},
			{
				"countryName": "Kenya",
				"name"       : "Turkana",
				"state_code" : "43"
			},
			{
				"countryName": "Kenya",
				"name"       : "Uasin Gishu",
				"state_code" : "44"
			},
			{
				"countryName": "Kenya",
				"name"       : "Vihiga",
				"state_code" : "45"
			},
			{
				"countryName": "Kenya",
				"name"       : "Wajir",
				"state_code" : "46"
			},
			{
				"countryName": "Kenya",
				"name"       : "West Pokot",
				"state_code" : "47"
			}
		],
		"KI": [
			{
				"countryName": "Kiribati",
				"name"       : "Gilbert Islands",
				"state_code" : "G"
			},
			{
				"countryName": "Kiribati",
				"name"       : "Line Islands",
				"state_code" : "L"
			},
			{
				"countryName": "Kiribati",
				"name"       : "Phoenix Islands",
				"state_code" : "P"
			}
		],
		"XK": [
			{
				"countryName": "Kosovo",
				"name"       : "Đakovica District (Gjakove)",
				"state_code" : "XDG"
			},
			{
				"countryName": "Kosovo",
				"name"       : "Gjilan District",
				"state_code" : "XGJ"
			},
			{
				"countryName": "Kosovo",
				"name"       : "Kosovska Mitrovica District",
				"state_code" : "XKM"
			},
			{
				"countryName": "Kosovo",
				"name"       : "Peć District",
				"state_code" : "XPE"
			},
			{
				"countryName": "Kosovo",
				"name"       : "Pristina (Priştine)",
				"state_code" : "XPI"
			},
			{
				"countryName": "Kosovo",
				"name"       : "Prizren District",
				"state_code" : "XPR"
			},
			{
				"countryName": "Kosovo",
				"name"       : "Uroševac District (Ferizaj)",
				"state_code" : "XUF"
			}
		],
		"KW": [
			{
				"countryName": "Kuwait",
				"name"       : "Al Ahmadi",
				"state_code" : "AH"
			},
			{
				"countryName": "Kuwait",
				"name"       : "Al Farwaniyah",
				"state_code" : "FA"
			},
			{
				"countryName": "Kuwait",
				"name"       : "Al Jahra",
				"state_code" : "JA"
			},
			{
				"countryName": "Kuwait",
				"name"       : "Capital",
				"state_code" : "KU"
			},
			{
				"countryName": "Kuwait",
				"name"       : "Hawalli",
				"state_code" : "HA"
			},
			{
				"countryName": "Kuwait",
				"name"       : "Mubarak Al-Kabeer",
				"state_code" : "MU"
			}
		],
		"KG": [
			{
				"countryName": "Kyrgyzstan",
				"name"       : "Batken Region",
				"state_code" : "B"
			},
			{
				"countryName": "Kyrgyzstan",
				"name"       : "Bishkek",
				"state_code" : "GB"
			},
			{
				"countryName": "Kyrgyzstan",
				"name"       : "Chuy Region",
				"state_code" : "C"
			},
			{
				"countryName": "Kyrgyzstan",
				"name"       : "Issyk-Kul Region",
				"state_code" : "Y"
			},
			{
				"countryName": "Kyrgyzstan",
				"name"       : "Jalal-Abad Region",
				"state_code" : "J"
			},
			{
				"countryName": "Kyrgyzstan",
				"name"       : "Naryn Region",
				"state_code" : "N"
			},
			{
				"countryName": "Kyrgyzstan",
				"name"       : "Osh",
				"state_code" : "GO"
			},
			{
				"countryName": "Kyrgyzstan",
				"name"       : "Osh Region",
				"state_code" : "O"
			},
			{
				"countryName": "Kyrgyzstan",
				"name"       : "Talas Region",
				"state_code" : "T"
			}
		],
		"LA": [
			{
				"countryName": "Laos",
				"name"       : "Attapeu Province",
				"state_code" : "AT"
			},
			{
				"countryName": "Laos",
				"name"       : "Bokeo Province",
				"state_code" : "BK"
			},
			{
				"countryName": "Laos",
				"name"       : "Bolikhamsai Province",
				"state_code" : "BL"
			},
			{
				"countryName": "Laos",
				"name"       : "Champasak Province",
				"state_code" : "CH"
			},
			{
				"countryName": "Laos",
				"name"       : "Houaphanh Province",
				"state_code" : "HO"
			},
			{
				"countryName": "Laos",
				"name"       : "Khammouane Province",
				"state_code" : "KH"
			},
			{
				"countryName": "Laos",
				"name"       : "Luang Namtha Province",
				"state_code" : "LM"
			},
			{
				"countryName": "Laos",
				"name"       : "Luang Prabang Province",
				"state_code" : "LP"
			},
			{
				"countryName": "Laos",
				"name"       : "Oudomxay Province",
				"state_code" : "OU"
			},
			{
				"countryName": "Laos",
				"name"       : "Phongsaly Province",
				"state_code" : "PH"
			},
			{
				"countryName": "Laos",
				"name"       : "Sainyabuli Province",
				"state_code" : "XA"
			},
			{
				"countryName": "Laos",
				"name"       : "Salavan Province",
				"state_code" : "SL"
			},
			{
				"countryName": "Laos",
				"name"       : "Savannakhet Province",
				"state_code" : "SV"
			},
			{
				"countryName": "Laos",
				"name"       : "Sekong Province",
				"state_code" : "XE"
			},
			{
				"countryName": "Laos",
				"name"       : "Vientiane Prefecture",
				"state_code" : "VT"
			},
			{
				"countryName": "Laos",
				"name"       : "Vientiane Province",
				"state_code" : "VI"
			},
			{
				"countryName": "Laos",
				"name"       : "Xaisomboun",
				"state_code" : "XN"
			},
			{
				"countryName": "Laos",
				"name"       : "Xaisomboun Province",
				"state_code" : "XS"
			},
			{
				"countryName": "Laos",
				"name"       : "Xiangkhouang Province",
				"state_code" : "XI"
			}
		],
		"LV": [
			{
				"countryName": "Latvia",
				"name"       : "Aglona Municipality",
				"state_code" : "001"
			},
			{
				"countryName": "Latvia",
				"name"       : "Aizkraukle Municipality",
				"state_code" : "002"
			},
			{
				"countryName": "Latvia",
				"name"       : "Aizpute Municipality",
				"state_code" : "003"
			},
			{
				"countryName": "Latvia",
				"name"       : "Aknīste Municipality",
				"state_code" : "004"
			},
			{
				"countryName": "Latvia",
				"name"       : "Aloja Municipality",
				"state_code" : "005"
			},
			{
				"countryName": "Latvia",
				"name"       : "Alsunga Municipality",
				"state_code" : "006"
			},
			{
				"countryName": "Latvia",
				"name"       : "Alūksne Municipality",
				"state_code" : "007"
			},
			{
				"countryName": "Latvia",
				"name"       : "Amata Municipality",
				"state_code" : "008"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ape Municipality",
				"state_code" : "009"
			},
			{
				"countryName": "Latvia",
				"name"       : "Auce Municipality",
				"state_code" : "010"
			},
			{
				"countryName": "Latvia",
				"name"       : "Babīte Municipality",
				"state_code" : "012"
			},
			{
				"countryName": "Latvia",
				"name"       : "Baldone Municipality",
				"state_code" : "013"
			},
			{
				"countryName": "Latvia",
				"name"       : "Baltinava Municipality",
				"state_code" : "014"
			},
			{
				"countryName": "Latvia",
				"name"       : "Balvi Municipality",
				"state_code" : "015"
			},
			{
				"countryName": "Latvia",
				"name"       : "Bauska Municipality",
				"state_code" : "016"
			},
			{
				"countryName": "Latvia",
				"name"       : "Beverīna Municipality",
				"state_code" : "017"
			},
			{
				"countryName": "Latvia",
				"name"       : "Brocēni Municipality",
				"state_code" : "018"
			},
			{
				"countryName": "Latvia",
				"name"       : "Burtnieki Municipality",
				"state_code" : "019"
			},
			{
				"countryName": "Latvia",
				"name"       : "Carnikava Municipality",
				"state_code" : "020"
			},
			{
				"countryName": "Latvia",
				"name"       : "Cēsis Municipality",
				"state_code" : "022"
			},
			{
				"countryName": "Latvia",
				"name"       : "Cesvaine Municipality",
				"state_code" : "021"
			},
			{
				"countryName": "Latvia",
				"name"       : "Cibla Municipality",
				"state_code" : "023"
			},
			{
				"countryName": "Latvia",
				"name"       : "Dagda Municipality",
				"state_code" : "024"
			},
			{
				"countryName": "Latvia",
				"name"       : "Daugavpils",
				"state_code" : "DGV"
			},
			{
				"countryName": "Latvia",
				"name"       : "Daugavpils Municipality",
				"state_code" : "025"
			},
			{
				"countryName": "Latvia",
				"name"       : "Dobele Municipality",
				"state_code" : "026"
			},
			{
				"countryName": "Latvia",
				"name"       : "Dundaga Municipality",
				"state_code" : "027"
			},
			{
				"countryName": "Latvia",
				"name"       : "Durbe Municipality",
				"state_code" : "028"
			},
			{
				"countryName": "Latvia",
				"name"       : "Engure Municipality",
				"state_code" : "029"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ērgļi Municipality",
				"state_code" : "030"
			},
			{
				"countryName": "Latvia",
				"name"       : "Garkalne Municipality",
				"state_code" : "031"
			},
			{
				"countryName": "Latvia",
				"name"       : "Grobiņa Municipality",
				"state_code" : "032"
			},
			{
				"countryName": "Latvia",
				"name"       : "Gulbene Municipality",
				"state_code" : "033"
			},
			{
				"countryName": "Latvia",
				"name"       : "Iecava Municipality",
				"state_code" : "034"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ikšķile Municipality",
				"state_code" : "035"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ilūkste Municipality",
				"state_code" : "036"
			},
			{
				"countryName": "Latvia",
				"name"       : "Inčukalns Municipality",
				"state_code" : "037"
			},
			{
				"countryName": "Latvia",
				"name"       : "Jaunjelgava Municipality",
				"state_code" : "038"
			},
			{
				"countryName": "Latvia",
				"name"       : "Jaunpiebalga Municipality",
				"state_code" : "039"
			},
			{
				"countryName": "Latvia",
				"name"       : "Jaunpils Municipality",
				"state_code" : "040"
			},
			{
				"countryName": "Latvia",
				"name"       : "Jēkabpils",
				"state_code" : "JKB"
			},
			{
				"countryName": "Latvia",
				"name"       : "Jēkabpils Municipality",
				"state_code" : "042"
			},
			{
				"countryName": "Latvia",
				"name"       : "Jelgava",
				"state_code" : "JEL"
			},
			{
				"countryName": "Latvia",
				"name"       : "Jelgava Municipality",
				"state_code" : "041"
			},
			{
				"countryName": "Latvia",
				"name"       : "Jūrmala",
				"state_code" : "JUR"
			},
			{
				"countryName": "Latvia",
				"name"       : "Kandava Municipality",
				"state_code" : "043"
			},
			{
				"countryName": "Latvia",
				"name"       : "Kārsava Municipality",
				"state_code" : "044"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ķegums Municipality",
				"state_code" : "051"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ķekava Municipality",
				"state_code" : "052"
			},
			{
				"countryName": "Latvia",
				"name"       : "Kocēni Municipality",
				"state_code" : "045"
			},
			{
				"countryName": "Latvia",
				"name"       : "Koknese Municipality",
				"state_code" : "046"
			},
			{
				"countryName": "Latvia",
				"name"       : "Krāslava Municipality",
				"state_code" : "047"
			},
			{
				"countryName": "Latvia",
				"name"       : "Krimulda Municipality",
				"state_code" : "048"
			},
			{
				"countryName": "Latvia",
				"name"       : "Krustpils Municipality",
				"state_code" : "049"
			},
			{
				"countryName": "Latvia",
				"name"       : "Kuldīga Municipality",
				"state_code" : "050"
			},
			{
				"countryName": "Latvia",
				"name"       : "Lielvārde Municipality",
				"state_code" : "053"
			},
			{
				"countryName": "Latvia",
				"name"       : "Liepāja",
				"state_code" : "LPX"
			},
			{
				"countryName": "Latvia",
				"name"       : "Līgatne Municipality",
				"state_code" : "055"
			},
			{
				"countryName": "Latvia",
				"name"       : "Limbaži Municipality",
				"state_code" : "054"
			},
			{
				"countryName": "Latvia",
				"name"       : "Līvāni Municipality",
				"state_code" : "056"
			},
			{
				"countryName": "Latvia",
				"name"       : "Lubāna Municipality",
				"state_code" : "057"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ludza Municipality",
				"state_code" : "058"
			},
			{
				"countryName": "Latvia",
				"name"       : "Madona Municipality",
				"state_code" : "059"
			},
			{
				"countryName": "Latvia",
				"name"       : "Mālpils Municipality",
				"state_code" : "061"
			},
			{
				"countryName": "Latvia",
				"name"       : "Mārupe Municipality",
				"state_code" : "062"
			},
			{
				"countryName": "Latvia",
				"name"       : "Mazsalaca Municipality",
				"state_code" : "060"
			},
			{
				"countryName": "Latvia",
				"name"       : "Mērsrags Municipality",
				"state_code" : "063"
			},
			{
				"countryName": "Latvia",
				"name"       : "Naukšēni Municipality",
				"state_code" : "064"
			},
			{
				"countryName": "Latvia",
				"name"       : "Nereta Municipality",
				"state_code" : "065"
			},
			{
				"countryName": "Latvia",
				"name"       : "Nīca Municipality",
				"state_code" : "066"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ogre Municipality",
				"state_code" : "067"
			},
			{
				"countryName": "Latvia",
				"name"       : "Olaine Municipality",
				"state_code" : "068"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ozolnieki Municipality",
				"state_code" : "069"
			},
			{
				"countryName": "Latvia",
				"name"       : "Pārgauja Municipality",
				"state_code" : "070"
			},
			{
				"countryName": "Latvia",
				"name"       : "Pāvilosta Municipality",
				"state_code" : "071"
			},
			{
				"countryName": "Latvia",
				"name"       : "Pļaviņas Municipality",
				"state_code" : "072"
			},
			{
				"countryName": "Latvia",
				"name"       : "Preiļi Municipality",
				"state_code" : "073"
			},
			{
				"countryName": "Latvia",
				"name"       : "Priekule Municipality",
				"state_code" : "074"
			},
			{
				"countryName": "Latvia",
				"name"       : "Priekuļi Municipality",
				"state_code" : "075"
			},
			{
				"countryName": "Latvia",
				"name"       : "Rauna Municipality",
				"state_code" : "076"
			},
			{
				"countryName": "Latvia",
				"name"       : "Rēzekne",
				"state_code" : "REZ"
			},
			{
				"countryName": "Latvia",
				"name"       : "Rēzekne Municipality",
				"state_code" : "077"
			},
			{
				"countryName": "Latvia",
				"name"       : "Riebiņi Municipality",
				"state_code" : "078"
			},
			{
				"countryName": "Latvia",
				"name"       : "Riga",
				"state_code" : "RIX"
			},
			{
				"countryName": "Latvia",
				"name"       : "Roja Municipality",
				"state_code" : "079"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ropaži Municipality",
				"state_code" : "080"
			},
			{
				"countryName": "Latvia",
				"name"       : "Rucava Municipality",
				"state_code" : "081"
			},
			{
				"countryName": "Latvia",
				"name"       : "Rugāji Municipality",
				"state_code" : "082"
			},
			{
				"countryName": "Latvia",
				"name"       : "Rūjiena Municipality",
				"state_code" : "084"
			},
			{
				"countryName": "Latvia",
				"name"       : "Rundāle Municipality",
				"state_code" : "083"
			},
			{
				"countryName": "Latvia",
				"name"       : "Sala Municipality",
				"state_code" : "085"
			},
			{
				"countryName": "Latvia",
				"name"       : "Salacgrīva Municipality",
				"state_code" : "086"
			},
			{
				"countryName": "Latvia",
				"name"       : "Salaspils Municipality",
				"state_code" : "087"
			},
			{
				"countryName": "Latvia",
				"name"       : "Saldus Municipality",
				"state_code" : "088"
			},
			{
				"countryName": "Latvia",
				"name"       : "Saulkrasti Municipality",
				"state_code" : "089"
			},
			{
				"countryName": "Latvia",
				"name"       : "Sēja Municipality",
				"state_code" : "090"
			},
			{
				"countryName": "Latvia",
				"name"       : "Sigulda Municipality",
				"state_code" : "091"
			},
			{
				"countryName": "Latvia",
				"name"       : "Skrīveri Municipality",
				"state_code" : "092"
			},
			{
				"countryName": "Latvia",
				"name"       : "Skrunda Municipality",
				"state_code" : "093"
			},
			{
				"countryName": "Latvia",
				"name"       : "Smiltene Municipality",
				"state_code" : "094"
			},
			{
				"countryName": "Latvia",
				"name"       : "Stopiņi Municipality",
				"state_code" : "095"
			},
			{
				"countryName": "Latvia",
				"name"       : "Strenči Municipality",
				"state_code" : "096"
			},
			{
				"countryName": "Latvia",
				"name"       : "Talsi Municipality",
				"state_code" : "097"
			},
			{
				"countryName": "Latvia",
				"name"       : "Tērvete Municipality",
				"state_code" : "098"
			},
			{
				"countryName": "Latvia",
				"name"       : "Tukums Municipality",
				"state_code" : "099"
			},
			{
				"countryName": "Latvia",
				"name"       : "Vaiņode Municipality",
				"state_code" : "100"
			},
			{
				"countryName": "Latvia",
				"name"       : "Valka Municipality",
				"state_code" : "101"
			},
			{
				"countryName": "Latvia",
				"name"       : "Valmiera",
				"state_code" : "VMR"
			},
			{
				"countryName": "Latvia",
				"name"       : "Varakļāni Municipality",
				"state_code" : "102"
			},
			{
				"countryName": "Latvia",
				"name"       : "Vārkava Municipality",
				"state_code" : "103"
			},
			{
				"countryName": "Latvia",
				"name"       : "Vecpiebalga Municipality",
				"state_code" : "104"
			},
			{
				"countryName": "Latvia",
				"name"       : "Vecumnieki Municipality",
				"state_code" : "105"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ventspils",
				"state_code" : "VEN"
			},
			{
				"countryName": "Latvia",
				"name"       : "Ventspils Municipality",
				"state_code" : "106"
			},
			{
				"countryName": "Latvia",
				"name"       : "Viesīte Municipality",
				"state_code" : "107"
			},
			{
				"countryName": "Latvia",
				"name"       : "Viļaka Municipality",
				"state_code" : "108"
			},
			{
				"countryName": "Latvia",
				"name"       : "Viļāni Municipality",
				"state_code" : "109"
			},
			{
				"countryName": "Latvia",
				"name"       : "Zilupe Municipality",
				"state_code" : "110"
			}
		],
		"LB": [
			{
				"countryName": "Lebanon",
				"name"       : "Akkar",
				"state_code" : "AK"
			},
			{
				"countryName": "Lebanon",
				"name"       : "Baalbek-Hermel",
				"state_code" : "BH"
			},
			{
				"countryName": "Lebanon",
				"name"       : "Beirut",
				"state_code" : "BA"
			},
			{
				"countryName": "Lebanon",
				"name"       : "Beqaa",
				"state_code" : "BI"
			},
			{
				"countryName": "Lebanon",
				"name"       : "Mount Lebanon",
				"state_code" : "JL"
			},
			{
				"countryName": "Lebanon",
				"name"       : "Nabatieh",
				"state_code" : "NA"
			},
			{
				"countryName": "Lebanon",
				"name"       : "North",
				"state_code" : "AS"
			},
			{
				"countryName": "Lebanon",
				"name"       : "South",
				"state_code" : "JA"
			}
		],
		"LS": [
			{
				"countryName": "Lesotho",
				"name"       : "Berea District",
				"state_code" : "D"
			},
			{
				"countryName": "Lesotho",
				"name"       : "Butha-Buthe District",
				"state_code" : "B"
			},
			{
				"countryName": "Lesotho",
				"name"       : "Leribe District",
				"state_code" : "C"
			},
			{
				"countryName": "Lesotho",
				"name"       : "Mafeteng District",
				"state_code" : "E"
			},
			{
				"countryName": "Lesotho",
				"name"       : "Maseru District",
				"state_code" : "A"
			},
			{
				"countryName": "Lesotho",
				"name"       : "Mohale's Hoek District",
				"state_code" : "F"
			},
			{
				"countryName": "Lesotho",
				"name"       : "Mokhotlong District",
				"state_code" : "J"
			},
			{
				"countryName": "Lesotho",
				"name"       : "Qacha's Nek District",
				"state_code" : "H"
			},
			{
				"countryName": "Lesotho",
				"name"       : "Quthing District",
				"state_code" : "G"
			},
			{
				"countryName": "Lesotho",
				"name"       : "Thaba-Tseka District",
				"state_code" : "K"
			}
		],
		"LR": [
			{
				"countryName": "Liberia",
				"name"       : "Bomi County",
				"state_code" : "BM"
			},
			{
				"countryName": "Liberia",
				"name"       : "Bong County",
				"state_code" : "BG"
			},
			{
				"countryName": "Liberia",
				"name"       : "Gbarpolu County",
				"state_code" : "GP"
			},
			{
				"countryName": "Liberia",
				"name"       : "Grand Bassa County",
				"state_code" : "GB"
			},
			{
				"countryName": "Liberia",
				"name"       : "Grand Cape Mount County",
				"state_code" : "CM"
			},
			{
				"countryName": "Liberia",
				"name"       : "Grand Gedeh County",
				"state_code" : "GG"
			},
			{
				"countryName": "Liberia",
				"name"       : "Grand Kru County",
				"state_code" : "GK"
			},
			{
				"countryName": "Liberia",
				"name"       : "Lofa County",
				"state_code" : "LO"
			},
			{
				"countryName": "Liberia",
				"name"       : "Margibi County",
				"state_code" : "MG"
			},
			{
				"countryName": "Liberia",
				"name"       : "Maryland County",
				"state_code" : "MY"
			},
			{
				"countryName": "Liberia",
				"name"       : "Montserrado County",
				"state_code" : "MO"
			},
			{
				"countryName": "Liberia",
				"name"       : "Nimba",
				"state_code" : "NI"
			},
			{
				"countryName": "Liberia",
				"name"       : "River Cess County",
				"state_code" : "RI"
			},
			{
				"countryName": "Liberia",
				"name"       : "River Gee County",
				"state_code" : "RG"
			},
			{
				"countryName": "Liberia",
				"name"       : "Sinoe County",
				"state_code" : "SI"
			}
		],
		"LY": [
			{
				"countryName": "Libya",
				"name"       : "Al Wahat District",
				"state_code" : "WA"
			},
			{
				"countryName": "Libya",
				"name"       : "Benghazi",
				"state_code" : "BA"
			},
			{
				"countryName": "Libya",
				"name"       : "Derna District",
				"state_code" : "DR"
			},
			{
				"countryName": "Libya",
				"name"       : "Ghat District",
				"state_code" : "GT"
			},
			{
				"countryName": "Libya",
				"name"       : "Jabal al Akhdar",
				"state_code" : "JA"
			},
			{
				"countryName": "Libya",
				"name"       : "Jabal al Gharbi District",
				"state_code" : "JG"
			},
			{
				"countryName": "Libya",
				"name"       : "Jafara",
				"state_code" : "JI"
			},
			{
				"countryName": "Libya",
				"name"       : "Jufra",
				"state_code" : "JU"
			},
			{
				"countryName": "Libya",
				"name"       : "Kufra District",
				"state_code" : "KF"
			},
			{
				"countryName": "Libya",
				"name"       : "Marj District",
				"state_code" : "MJ"
			},
			{
				"countryName": "Libya",
				"name"       : "Misrata District",
				"state_code" : "MI"
			},
			{
				"countryName": "Libya",
				"name"       : "Murqub",
				"state_code" : "MB"
			},
			{
				"countryName": "Libya",
				"name"       : "Murzuq District",
				"state_code" : "MQ"
			},
			{
				"countryName": "Libya",
				"name"       : "Nalut District",
				"state_code" : "NL"
			},
			{
				"countryName": "Libya",
				"name"       : "Nuqat al Khams",
				"state_code" : "NQ"
			},
			{
				"countryName": "Libya",
				"name"       : "Sabha District",
				"state_code" : "SB"
			},
			{
				"countryName": "Libya",
				"name"       : "Sirte District",
				"state_code" : "SR"
			},
			{
				"countryName": "Libya",
				"name"       : "Tripoli District",
				"state_code" : "TB"
			},
			{
				"countryName": "Libya",
				"name"       : "Wadi al Hayaa District",
				"state_code" : "WD"
			},
			{
				"countryName": "Libya",
				"name"       : "Wadi al Shatii District",
				"state_code" : "WS"
			},
			{
				"countryName": "Libya",
				"name"       : "Zawiya District",
				"state_code" : "ZA"
			}
		],
		"LI": [
			{
				"countryName": "Liechtenstein",
				"name"       : "Balzers",
				"state_code" : "01"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Eschen",
				"state_code" : "02"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Gamprin",
				"state_code" : "03"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Mauren",
				"state_code" : "04"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Planken",
				"state_code" : "05"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Ruggell",
				"state_code" : "06"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Schaan",
				"state_code" : "07"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Schellenberg",
				"state_code" : "08"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Triesen",
				"state_code" : "09"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Triesenberg",
				"state_code" : "10"
			},
			{
				"countryName": "Liechtenstein",
				"name"       : "Vaduz",
				"state_code" : "11"
			}
		],
		"LT": [
			{
				"countryName": "Lithuania",
				"name"       : "Akmenė District Municipality",
				"state_code" : "01"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Alytus City Municipality",
				"state_code" : "02"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Alytus County",
				"state_code" : "AL"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Alytus District Municipality",
				"state_code" : "03"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Birštonas Municipality",
				"state_code" : "05"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Biržai District Municipality",
				"state_code" : "06"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Druskininkai municipality",
				"state_code" : "07"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Elektrėnai municipality",
				"state_code" : "08"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Ignalina District Municipality",
				"state_code" : "09"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Jonava District Municipality",
				"state_code" : "10"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Joniškis District Municipality",
				"state_code" : "11"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Jurbarkas District Municipality",
				"state_code" : "12"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kaišiadorys District Municipality",
				"state_code" : "13"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kalvarija municipality",
				"state_code" : "14"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kaunas City Municipality",
				"state_code" : "15"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kaunas County",
				"state_code" : "KU"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kaunas District Municipality",
				"state_code" : "16"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kazlų Rūda municipality",
				"state_code" : "17"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kėdainiai District Municipality",
				"state_code" : "18"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kelmė District Municipality",
				"state_code" : "19"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Klaipeda City Municipality",
				"state_code" : "20"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Klaipėda County",
				"state_code" : "KL"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Klaipėda District Municipality",
				"state_code" : "21"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kretinga District Municipality",
				"state_code" : "22"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Kupiškis District Municipality",
				"state_code" : "23"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Lazdijai District Municipality",
				"state_code" : "24"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Marijampolė County",
				"state_code" : "MR"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Marijampolė Municipality",
				"state_code" : "25"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Mažeikiai District Municipality",
				"state_code" : "26"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Molėtai District Municipality",
				"state_code" : "27"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Neringa Municipality",
				"state_code" : "28"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Pagėgiai municipality",
				"state_code" : "29"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Pakruojis District Municipality",
				"state_code" : "30"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Palanga City Municipality",
				"state_code" : "31"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Panevėžys City Municipality",
				"state_code" : "32"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Panevėžys County",
				"state_code" : "PN"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Panevėžys District Municipality",
				"state_code" : "33"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Pasvalys District Municipality",
				"state_code" : "34"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Plungė District Municipality",
				"state_code" : "35"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Prienai District Municipality",
				"state_code" : "36"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Radviliškis District Municipality",
				"state_code" : "37"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Raseiniai District Municipality",
				"state_code" : "38"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Rietavas municipality",
				"state_code" : "39"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Rokiškis District Municipality",
				"state_code" : "40"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Šakiai District Municipality",
				"state_code" : "41"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Šalčininkai District Municipality",
				"state_code" : "42"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Šiauliai City Municipality",
				"state_code" : "43"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Šiauliai County",
				"state_code" : "SA"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Šiauliai District Municipality",
				"state_code" : "44"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Šilalė District Municipality",
				"state_code" : "45"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Šilutė District Municipality",
				"state_code" : "46"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Širvintos District Municipality",
				"state_code" : "47"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Skuodas District Municipality",
				"state_code" : "48"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Švenčionys District Municipality",
				"state_code" : "49"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Tauragė County",
				"state_code" : "TA"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Tauragė District Municipality",
				"state_code" : "50"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Telšiai County",
				"state_code" : "TE"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Telšiai District Municipality",
				"state_code" : "51"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Trakai District Municipality",
				"state_code" : "52"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Ukmergė District Municipality",
				"state_code" : "53"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Utena County",
				"state_code" : "UT"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Utena District Municipality",
				"state_code" : "54"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Varėna District Municipality",
				"state_code" : "55"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Vilkaviškis District Municipality",
				"state_code" : "56"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Vilnius City Municipality",
				"state_code" : "57"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Vilnius County",
				"state_code" : "VL"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Vilnius District Municipality",
				"state_code" : "58"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Visaginas Municipality",
				"state_code" : "59"
			},
			{
				"countryName": "Lithuania",
				"name"       : "Zarasai District Municipality",
				"state_code" : "60"
			}
		],
		"LU": [
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Capellen",
				"state_code" : "CA"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Clervaux",
				"state_code" : "CL"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Diekirch",
				"state_code" : "DI"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Echternach",
				"state_code" : "EC"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Esch-sur-Alzette",
				"state_code" : "ES"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Grevenmacher",
				"state_code" : "GR"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Luxembourg",
				"state_code" : "LU"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Mersch",
				"state_code" : "ME"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Redange",
				"state_code" : "RD"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Remich",
				"state_code" : "RM"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Vianden",
				"state_code" : "VD"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Canton of Wiltz",
				"state_code" : "WI"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Diekirch District",
				"state_code" : "D"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Grevenmacher District",
				"state_code" : "G"
			},
			{
				"countryName": "Luxembourg",
				"name"       : "Luxembourg District",
				"state_code" : "L"
			}
		],
		"MO": [],
		"MK": [
			{
				"countryName": "Macedonia",
				"name"       : "Aerodrom Municipality",
				"state_code" : "01"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Aračinovo Municipality",
				"state_code" : "02"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Berovo Municipality",
				"state_code" : "03"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Bitola Municipality",
				"state_code" : "04"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Bogdanci Municipality",
				"state_code" : "05"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Bogovinje Municipality",
				"state_code" : "06"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Bosilovo Municipality",
				"state_code" : "07"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Brvenica Municipality",
				"state_code" : "08"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Butel Municipality",
				"state_code" : "09"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Čair Municipality",
				"state_code" : "79"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Čaška Municipality",
				"state_code" : "80"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Centar Municipality",
				"state_code" : "77"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Centar Župa Municipality",
				"state_code" : "78"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Češinovo-Obleševo Municipality",
				"state_code" : "81"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Čučer-Sandevo Municipality",
				"state_code" : "82"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Debarca Municipality",
				"state_code" : "22"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Delčevo Municipality",
				"state_code" : "23"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Demir Hisar Municipality",
				"state_code" : "25"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Demir Kapija Municipality",
				"state_code" : "24"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Dojran Municipality",
				"state_code" : "26"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Dolneni Municipality",
				"state_code" : "27"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Drugovo Municipality",
				"state_code" : "28"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Gazi Baba Municipality",
				"state_code" : "17"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Gevgelija Municipality",
				"state_code" : "18"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Gjorče Petrov Municipality",
				"state_code" : "29"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Gostivar Municipality",
				"state_code" : "19"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Gradsko Municipality",
				"state_code" : "20"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Greater Skopje",
				"state_code" : "85"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Ilinden Municipality",
				"state_code" : "34"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Jegunovce Municipality",
				"state_code" : "35"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Karbinci",
				"state_code" : "37"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Karpoš Municipality",
				"state_code" : "38"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Kavadarci Municipality",
				"state_code" : "36"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Kičevo Municipality",
				"state_code" : "40"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Kisela Voda Municipality",
				"state_code" : "39"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Kočani Municipality",
				"state_code" : "42"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Konče Municipality",
				"state_code" : "41"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Kratovo Municipality",
				"state_code" : "43"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Kriva Palanka Municipality",
				"state_code" : "44"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Krivogaštani Municipality",
				"state_code" : "45"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Kruševo Municipality",
				"state_code" : "46"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Kumanovo Municipality",
				"state_code" : "47"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Lipkovo Municipality",
				"state_code" : "48"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Lozovo Municipality",
				"state_code" : "49"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Makedonska Kamenica Municipality",
				"state_code" : "51"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Makedonski Brod Municipality",
				"state_code" : "52"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Mavrovo and Rostuša Municipality",
				"state_code" : "50"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Mogila Municipality",
				"state_code" : "53"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Negotino Municipality",
				"state_code" : "54"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Novaci Municipality",
				"state_code" : "55"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Novo Selo Municipality",
				"state_code" : "56"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Ohrid Municipality",
				"state_code" : "58"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Oslomej Municipality",
				"state_code" : "57"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Pehčevo Municipality",
				"state_code" : "60"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Petrovec Municipality",
				"state_code" : "59"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Plasnica Municipality",
				"state_code" : "61"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Prilep Municipality",
				"state_code" : "62"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Probištip Municipality",
				"state_code" : "63"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Radoviš Municipality",
				"state_code" : "64"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Rankovce Municipality",
				"state_code" : "65"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Resen Municipality",
				"state_code" : "66"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Rosoman Municipality",
				"state_code" : "67"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Saraj Municipality",
				"state_code" : "68"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Sopište Municipality",
				"state_code" : "70"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Staro Nagoričane Municipality",
				"state_code" : "71"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Štip Municipality",
				"state_code" : "83"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Struga Municipality",
				"state_code" : "72"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Strumica Municipality",
				"state_code" : "73"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Studeničani Municipality",
				"state_code" : "74"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Šuto Orizari Municipality",
				"state_code" : "84"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Sveti Nikole Municipality",
				"state_code" : "69"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Tearce Municipality",
				"state_code" : "75"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Tetovo Municipality",
				"state_code" : "76"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Valandovo Municipality",
				"state_code" : "10"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Vasilevo Municipality",
				"state_code" : "11"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Veles Municipality",
				"state_code" : "13"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Vevčani Municipality",
				"state_code" : "12"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Vinica Municipality",
				"state_code" : "14"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Vraneštica Municipality",
				"state_code" : "15"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Vrapčište Municipality",
				"state_code" : "16"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Zajas Municipality",
				"state_code" : "31"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Zelenikovo Municipality",
				"state_code" : "32"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Želino Municipality",
				"state_code" : "30"
			},
			{
				"countryName": "Macedonia",
				"name"       : "Zrnovci Municipality",
				"state_code" : "33"
			}
		],
		"MG": [
			{
				"countryName": "Madagascar",
				"name"       : "Antananarivo Province",
				"state_code" : "T"
			},
			{
				"countryName": "Madagascar",
				"name"       : "Antsiranana Province",
				"state_code" : "D"
			},
			{
				"countryName": "Madagascar",
				"name"       : "Fianarantsoa Province",
				"state_code" : "F"
			},
			{
				"countryName": "Madagascar",
				"name"       : "Mahajanga Province",
				"state_code" : "M"
			},
			{
				"countryName": "Madagascar",
				"name"       : "Toamasina Province",
				"state_code" : "A"
			},
			{
				"countryName": "Madagascar",
				"name"       : "Toliara Province",
				"state_code" : "U"
			}
		],
		"MW": [
			{
				"countryName": "Malawi",
				"name"       : "Balaka District",
				"state_code" : "BA"
			},
			{
				"countryName": "Malawi",
				"name"       : "Blantyre District",
				"state_code" : "BL"
			},
			{
				"countryName": "Malawi",
				"name"       : "Central Region",
				"state_code" : "C"
			},
			{
				"countryName": "Malawi",
				"name"       : "Chikwawa District",
				"state_code" : "CK"
			},
			{
				"countryName": "Malawi",
				"name"       : "Chiradzulu District",
				"state_code" : "CR"
			},
			{
				"countryName": "Malawi",
				"name"       : "Chitipa district",
				"state_code" : "CT"
			},
			{
				"countryName": "Malawi",
				"name"       : "Dedza District",
				"state_code" : "DE"
			},
			{
				"countryName": "Malawi",
				"name"       : "Dowa District",
				"state_code" : "DO"
			},
			{
				"countryName": "Malawi",
				"name"       : "Karonga District",
				"state_code" : "KR"
			},
			{
				"countryName": "Malawi",
				"name"       : "Kasungu District",
				"state_code" : "KS"
			},
			{
				"countryName": "Malawi",
				"name"       : "Likoma District",
				"state_code" : "LK"
			},
			{
				"countryName": "Malawi",
				"name"       : "Lilongwe District",
				"state_code" : "LI"
			},
			{
				"countryName": "Malawi",
				"name"       : "Machinga District",
				"state_code" : "MH"
			},
			{
				"countryName": "Malawi",
				"name"       : "Mangochi District",
				"state_code" : "MG"
			},
			{
				"countryName": "Malawi",
				"name"       : "Mchinji District",
				"state_code" : "MC"
			},
			{
				"countryName": "Malawi",
				"name"       : "Mulanje District",
				"state_code" : "MU"
			},
			{
				"countryName": "Malawi",
				"name"       : "Mwanza District",
				"state_code" : "MW"
			},
			{
				"countryName": "Malawi",
				"name"       : "Mzimba District",
				"state_code" : "MZ"
			},
			{
				"countryName": "Malawi",
				"name"       : "Nkhata Bay District",
				"state_code" : "NB"
			},
			{
				"countryName": "Malawi",
				"name"       : "Nkhotakota District",
				"state_code" : "NK"
			},
			{
				"countryName": "Malawi",
				"name"       : "Northern Region",
				"state_code" : "N"
			},
			{
				"countryName": "Malawi",
				"name"       : "Nsanje District",
				"state_code" : "NS"
			},
			{
				"countryName": "Malawi",
				"name"       : "Ntcheu District",
				"state_code" : "NU"
			},
			{
				"countryName": "Malawi",
				"name"       : "Ntchisi District",
				"state_code" : "NI"
			},
			{
				"countryName": "Malawi",
				"name"       : "Phalombe District",
				"state_code" : "PH"
			},
			{
				"countryName": "Malawi",
				"name"       : "Rumphi District",
				"state_code" : "RU"
			},
			{
				"countryName": "Malawi",
				"name"       : "Salima District",
				"state_code" : "SA"
			},
			{
				"countryName": "Malawi",
				"name"       : "Southern Region",
				"state_code" : "S"
			},
			{
				"countryName": "Malawi",
				"name"       : "Thyolo District",
				"state_code" : "TH"
			},
			{
				"countryName": "Malawi",
				"name"       : "Zomba District",
				"state_code" : "ZO"
			}
		],
		"MY": [
			{
				"countryName": "Malaysia",
				"name"       : "Johor",
				"state_code" : "01"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Kedah",
				"state_code" : "02"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Kelantan",
				"state_code" : "03"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Kuala Lumpur",
				"state_code" : "14"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Labuan",
				"state_code" : "15"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Malacca",
				"state_code" : "04"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Negeri Sembilan",
				"state_code" : "05"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Pahang",
				"state_code" : "06"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Penang",
				"state_code" : "07"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Perak",
				"state_code" : "08"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Perlis",
				"state_code" : "09"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Putrajaya",
				"state_code" : "16"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Sabah",
				"state_code" : "12"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Sarawak",
				"state_code" : "13"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Selangor",
				"state_code" : "10"
			},
			{
				"countryName": "Malaysia",
				"name"       : "Terengganu",
				"state_code" : "11"
			}
		],
		"MV": [
			{
				"countryName": "Maldives",
				"name"       : "Addu Atoll",
				"state_code" : "01"
			},
			{
				"countryName": "Maldives",
				"name"       : "Alif Alif Atoll",
				"state_code" : "02"
			},
			{
				"countryName": "Maldives",
				"name"       : "Alif Dhaal Atoll",
				"state_code" : "00"
			},
			{
				"countryName": "Maldives",
				"name"       : "Central Province",
				"state_code" : "CE"
			},
			{
				"countryName": "Maldives",
				"name"       : "Dhaalu Atoll",
				"state_code" : "17"
			},
			{
				"countryName": "Maldives",
				"name"       : "Faafu Atoll",
				"state_code" : "14"
			},
			{
				"countryName": "Maldives",
				"name"       : "Gaafu Alif Atoll",
				"state_code" : "27"
			},
			{
				"countryName": "Maldives",
				"name"       : "Gaafu Dhaalu Atoll",
				"state_code" : "28"
			},
			{
				"countryName": "Maldives",
				"name"       : "Gnaviyani Atoll",
				"state_code" : "29"
			},
			{
				"countryName": "Maldives",
				"name"       : "Haa Alif Atoll",
				"state_code" : "07"
			},
			{
				"countryName": "Maldives",
				"name"       : "Haa Dhaalu Atoll",
				"state_code" : "23"
			},
			{
				"countryName": "Maldives",
				"name"       : "Kaafu Atoll",
				"state_code" : "26"
			},
			{
				"countryName": "Maldives",
				"name"       : "Laamu Atoll",
				"state_code" : "05"
			},
			{
				"countryName": "Maldives",
				"name"       : "Lhaviyani Atoll",
				"state_code" : "03"
			},
			{
				"countryName": "Maldives",
				"name"       : "Malé",
				"state_code" : "MLE"
			},
			{
				"countryName": "Maldives",
				"name"       : "Meemu Atoll",
				"state_code" : "12"
			},
			{
				"countryName": "Maldives",
				"name"       : "Noonu Atoll",
				"state_code" : "25"
			},
			{
				"countryName": "Maldives",
				"name"       : "North Central Province",
				"state_code" : "NC"
			},
			{
				"countryName": "Maldives",
				"name"       : "North Province",
				"state_code" : "NO"
			},
			{
				"countryName": "Maldives",
				"name"       : "Raa Atoll",
				"state_code" : "13"
			},
			{
				"countryName": "Maldives",
				"name"       : "Shaviyani Atoll",
				"state_code" : "24"
			},
			{
				"countryName": "Maldives",
				"name"       : "South Central Province",
				"state_code" : "SC"
			},
			{
				"countryName": "Maldives",
				"name"       : "South Province",
				"state_code" : "SU"
			},
			{
				"countryName": "Maldives",
				"name"       : "Thaa Atoll",
				"state_code" : "08"
			},
			{
				"countryName": "Maldives",
				"name"       : "Upper South Province",
				"state_code" : "US"
			},
			{
				"countryName": "Maldives",
				"name"       : "Vaavu Atoll",
				"state_code" : "04"
			}
		],
		"ML": [
			{
				"countryName": "Mali",
				"name"       : "Bamako",
				"state_code" : "BKO"
			},
			{
				"countryName": "Mali",
				"name"       : "Gao Region",
				"state_code" : "7"
			},
			{
				"countryName": "Mali",
				"name"       : "Kayes Region",
				"state_code" : "1"
			},
			{
				"countryName": "Mali",
				"name"       : "Kidal Region",
				"state_code" : "8"
			},
			{
				"countryName": "Mali",
				"name"       : "Koulikoro Region",
				"state_code" : "2"
			},
			{
				"countryName": "Mali",
				"name"       : "Ménaka Region",
				"state_code" : "9"
			},
			{
				"countryName": "Mali",
				"name"       : "Mopti Region",
				"state_code" : "5"
			},
			{
				"countryName": "Mali",
				"name"       : "Ségou Region",
				"state_code" : "4"
			},
			{
				"countryName": "Mali",
				"name"       : "Sikasso Region",
				"state_code" : "3"
			},
			{
				"countryName": "Mali",
				"name"       : "Taoudénit Region",
				"state_code" : "10"
			},
			{
				"countryName": "Mali",
				"name"       : "Tombouctou Region",
				"state_code" : "6"
			}
		],
		"MT": [
			{
				"countryName": "Malta",
				"name"       : "Attard",
				"state_code" : "01"
			},
			{
				"countryName": "Malta",
				"name"       : "Balzan",
				"state_code" : "02"
			},
			{
				"countryName": "Malta",
				"name"       : "Birgu",
				"state_code" : "03"
			},
			{
				"countryName": "Malta",
				"name"       : "Birkirkara",
				"state_code" : "04"
			},
			{
				"countryName": "Malta",
				"name"       : "Birżebbuġa",
				"state_code" : "05"
			},
			{
				"countryName": "Malta",
				"name"       : "Cospicua",
				"state_code" : "06"
			},
			{
				"countryName": "Malta",
				"name"       : "Dingli",
				"state_code" : "07"
			},
			{
				"countryName": "Malta",
				"name"       : "Fgura",
				"state_code" : "08"
			},
			{
				"countryName": "Malta",
				"name"       : "Floriana",
				"state_code" : "09"
			},
			{
				"countryName": "Malta",
				"name"       : "Fontana",
				"state_code" : "10"
			},
			{
				"countryName": "Malta",
				"name"       : "Għajnsielem",
				"state_code" : "13"
			},
			{
				"countryName": "Malta",
				"name"       : "Għarb",
				"state_code" : "14"
			},
			{
				"countryName": "Malta",
				"name"       : "Għargħur",
				"state_code" : "15"
			},
			{
				"countryName": "Malta",
				"name"       : "Għasri",
				"state_code" : "16"
			},
			{
				"countryName": "Malta",
				"name"       : "Għaxaq",
				"state_code" : "17"
			},
			{
				"countryName": "Malta",
				"name"       : "Gudja",
				"state_code" : "11"
			},
			{
				"countryName": "Malta",
				"name"       : "Gżira",
				"state_code" : "12"
			},
			{
				"countryName": "Malta",
				"name"       : "Ħamrun",
				"state_code" : "18"
			},
			{
				"countryName": "Malta",
				"name"       : "Iklin",
				"state_code" : "19"
			},
			{
				"countryName": "Malta",
				"name"       : "Kalkara",
				"state_code" : "21"
			},
			{
				"countryName": "Malta",
				"name"       : "Kerċem",
				"state_code" : "22"
			},
			{
				"countryName": "Malta",
				"name"       : "Kirkop",
				"state_code" : "23"
			},
			{
				"countryName": "Malta",
				"name"       : "Lija",
				"state_code" : "24"
			},
			{
				"countryName": "Malta",
				"name"       : "Luqa",
				"state_code" : "25"
			},
			{
				"countryName": "Malta",
				"name"       : "Marsa",
				"state_code" : "26"
			},
			{
				"countryName": "Malta",
				"name"       : "Marsaskala",
				"state_code" : "27"
			},
			{
				"countryName": "Malta",
				"name"       : "Marsaxlokk",
				"state_code" : "28"
			},
			{
				"countryName": "Malta",
				"name"       : "Mdina",
				"state_code" : "29"
			},
			{
				"countryName": "Malta",
				"name"       : "Mellieħa",
				"state_code" : "30"
			},
			{
				"countryName": "Malta",
				"name"       : "Mġarr",
				"state_code" : "31"
			},
			{
				"countryName": "Malta",
				"name"       : "Mosta",
				"state_code" : "32"
			},
			{
				"countryName": "Malta",
				"name"       : "Mqabba",
				"state_code" : "33"
			},
			{
				"countryName": "Malta",
				"name"       : "Msida",
				"state_code" : "34"
			},
			{
				"countryName": "Malta",
				"name"       : "Mtarfa",
				"state_code" : "35"
			},
			{
				"countryName": "Malta",
				"name"       : "Munxar",
				"state_code" : "36"
			},
			{
				"countryName": "Malta",
				"name"       : "Nadur",
				"state_code" : "37"
			},
			{
				"countryName": "Malta",
				"name"       : "Naxxar",
				"state_code" : "38"
			},
			{
				"countryName": "Malta",
				"name"       : "Paola",
				"state_code" : "39"
			},
			{
				"countryName": "Malta",
				"name"       : "Pembroke",
				"state_code" : "40"
			},
			{
				"countryName": "Malta",
				"name"       : "Pietà",
				"state_code" : "41"
			},
			{
				"countryName": "Malta",
				"name"       : "Qala",
				"state_code" : "42"
			},
			{
				"countryName": "Malta",
				"name"       : "Qormi",
				"state_code" : "43"
			},
			{
				"countryName": "Malta",
				"name"       : "Qrendi",
				"state_code" : "44"
			},
			{
				"countryName": "Malta",
				"name"       : "Rabat",
				"state_code" : "46"
			},
			{
				"countryName": "Malta",
				"name"       : "Saint Lawrence",
				"state_code" : "50"
			},
			{
				"countryName": "Malta",
				"name"       : "San Ġwann",
				"state_code" : "49"
			},
			{
				"countryName": "Malta",
				"name"       : "Sannat",
				"state_code" : "52"
			},
			{
				"countryName": "Malta",
				"name"       : "Santa Luċija",
				"state_code" : "53"
			},
			{
				"countryName": "Malta",
				"name"       : "Santa Venera",
				"state_code" : "54"
			},
			{
				"countryName": "Malta",
				"name"       : "Senglea",
				"state_code" : "20"
			},
			{
				"countryName": "Malta",
				"name"       : "Siġġiewi",
				"state_code" : "55"
			},
			{
				"countryName": "Malta",
				"name"       : "Sliema",
				"state_code" : "56"
			},
			{
				"countryName": "Malta",
				"name"       : "St. Julian's",
				"state_code" : "48"
			},
			{
				"countryName": "Malta",
				"name"       : "St. Paul's Bay",
				"state_code" : "51"
			},
			{
				"countryName": "Malta",
				"name"       : "Swieqi",
				"state_code" : "57"
			},
			{
				"countryName": "Malta",
				"name"       : "Ta' Xbiex",
				"state_code" : "58"
			},
			{
				"countryName": "Malta",
				"name"       : "Tarxien",
				"state_code" : "59"
			},
			{
				"countryName": "Malta",
				"name"       : "Valletta",
				"state_code" : "60"
			},
			{
				"countryName": "Malta",
				"name"       : "Victoria",
				"state_code" : "45"
			},
			{
				"countryName": "Malta",
				"name"       : "Xagħra",
				"state_code" : "61"
			},
			{
				"countryName": "Malta",
				"name"       : "Xewkija",
				"state_code" : "62"
			},
			{
				"countryName": "Malta",
				"name"       : "Xgħajra",
				"state_code" : "63"
			},
			{
				"countryName": "Malta",
				"name"       : "Żabbar",
				"state_code" : "64"
			},
			{
				"countryName": "Malta",
				"name"       : "Żebbuġ Gozo",
				"state_code" : "65"
			},
			{
				"countryName": "Malta",
				"name"       : "Żebbuġ Malta",
				"state_code" : "66"
			},
			{
				"countryName": "Malta",
				"name"       : "Żejtun",
				"state_code" : "67"
			},
			{
				"countryName": "Malta",
				"name"       : "Żurrieq",
				"state_code" : "68"
			}
		],
		"IM": [],
		"MH": [
			{
				"countryName": "Marshall Islands",
				"name"       : "Ralik Chain",
				"state_code" : "L"
			},
			{
				"countryName": "Marshall Islands",
				"name"       : "Ratak Chain",
				"state_code" : "T"
			}
		],
		"MQ": [],
		"MR": [
			{
				"countryName": "Mauritania",
				"name"       : "Adrar",
				"state_code" : "07"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Assaba",
				"state_code" : "03"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Brakna",
				"state_code" : "05"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Dakhlet Nouadhibou",
				"state_code" : "08"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Gorgol",
				"state_code" : "04"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Guidimaka",
				"state_code" : "10"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Hodh Ech Chargui",
				"state_code" : "01"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Hodh El Gharbi",
				"state_code" : "02"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Inchiri",
				"state_code" : "12"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Nouakchott-Nord",
				"state_code" : "14"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Nouakchott-Ouest",
				"state_code" : "13"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Nouakchott-Sud",
				"state_code" : "15"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Tagant",
				"state_code" : "09"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Tiris Zemmour",
				"state_code" : "11"
			},
			{
				"countryName": "Mauritania",
				"name"       : "Trarza",
				"state_code" : "06"
			}
		],
		"MU": [
			{
				"countryName": "Mauritius",
				"name"       : "Agaléga",
				"state_code" : "AG"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Beau Bassin-Rose Hill",
				"state_code" : "BR"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Cargados Carajos",
				"state_code" : "CC"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Curepipe",
				"state_code" : "CU"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Flacq District",
				"state_code" : "FL"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Grand Port District",
				"state_code" : "GP"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Moka District",
				"state_code" : "MO"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Pamplemousses District",
				"state_code" : "PA"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Plaines Wilhems District",
				"state_code" : "PW"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Port Louis",
				"state_code" : "PU"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Port Louis District",
				"state_code" : "PL"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Quatre Bornes",
				"state_code" : "QB"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Rivière du Rempart District",
				"state_code" : "RR"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Rivière Noire District",
				"state_code" : "BL"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Rodrigues",
				"state_code" : "RO"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Savanne District",
				"state_code" : "SA"
			},
			{
				"countryName": "Mauritius",
				"name"       : "Vacoas-Phoenix",
				"state_code" : "VP"
			}
		],
		"YT": [],
		"MX": [
			{
				"countryName": "Mexico",
				"name"       : "Aguascalientes",
				"state_code" : "AGU"
			},
			{
				"countryName": "Mexico",
				"name"       : "Baja California",
				"state_code" : "BCN"
			},
			{
				"countryName": "Mexico",
				"name"       : "Baja California Sur",
				"state_code" : "BCS"
			},
			{
				"countryName": "Mexico",
				"name"       : "Campeche",
				"state_code" : "CAM"
			},
			{
				"countryName": "Mexico",
				"name"       : "Chiapas",
				"state_code" : "CHP"
			},
			{
				"countryName": "Mexico",
				"name"       : "Chihuahua",
				"state_code" : "CHH"
			},
			{
				"countryName": "Mexico",
				"name"       : "Ciudad de México",
				"state_code" : "CDMX"
			},
			{
				"countryName": "Mexico",
				"name"       : "Coahuila de Zaragoza",
				"state_code" : "COA"
			},
			{
				"countryName": "Mexico",
				"name"       : "Colima",
				"state_code" : "COL"
			},
			{
				"countryName": "Mexico",
				"name"       : "Durango",
				"state_code" : "DUR"
			},
			{
				"countryName": "Mexico",
				"name"       : "Estado de México",
				"state_code" : "MEX"
			},
			{
				"countryName": "Mexico",
				"name"       : "Guanajuato",
				"state_code" : "GUA"
			},
			{
				"countryName": "Mexico",
				"name"       : "Guerrero",
				"state_code" : "GRO"
			},
			{
				"countryName": "Mexico",
				"name"       : "Hidalgo",
				"state_code" : "HID"
			},
			{
				"countryName": "Mexico",
				"name"       : "Jalisco",
				"state_code" : "JAL"
			},
			{
				"countryName": "Mexico",
				"name"       : "Michoacán de Ocampo",
				"state_code" : "MIC"
			},
			{
				"countryName": "Mexico",
				"name"       : "Morelos",
				"state_code" : "MOR"
			},
			{
				"countryName": "Mexico",
				"name"       : "Nayarit",
				"state_code" : "NAY"
			},
			{
				"countryName": "Mexico",
				"name"       : "Nuevo León",
				"state_code" : "NLE"
			},
			{
				"countryName": "Mexico",
				"name"       : "Oaxaca",
				"state_code" : "OAX"
			},
			{
				"countryName": "Mexico",
				"name"       : "Puebla",
				"state_code" : "PUE"
			},
			{
				"countryName": "Mexico",
				"name"       : "Querétaro",
				"state_code" : "QUE"
			},
			{
				"countryName": "Mexico",
				"name"       : "Quintana Roo",
				"state_code" : "ROO"
			},
			{
				"countryName": "Mexico",
				"name"       : "San Luis Potosí",
				"state_code" : "SLP"
			},
			{
				"countryName": "Mexico",
				"name"       : "Sinaloa",
				"state_code" : "SIN"
			},
			{
				"countryName": "Mexico",
				"name"       : "Sonora",
				"state_code" : "SON"
			},
			{
				"countryName": "Mexico",
				"name"       : "Tabasco",
				"state_code" : "TAB"
			},
			{
				"countryName": "Mexico",
				"name"       : "Tamaulipas",
				"state_code" : "TAM"
			},
			{
				"countryName": "Mexico",
				"name"       : "Tlaxcala",
				"state_code" : "TLA"
			},
			{
				"countryName": "Mexico",
				"name"       : "Veracruz de Ignacio de la Llave",
				"state_code" : "VER"
			},
			{
				"countryName": "Mexico",
				"name"       : "Yucatán",
				"state_code" : "YUC"
			},
			{
				"countryName": "Mexico",
				"name"       : "Zacatecas",
				"state_code" : "ZAC"
			}
		],
		"FM": [
			{
				"countryName": "Micronesia",
				"name"       : "Chuuk State",
				"state_code" : "TRK"
			},
			{
				"countryName": "Micronesia",
				"name"       : "Kosrae State",
				"state_code" : "KSA"
			},
			{
				"countryName": "Micronesia",
				"name"       : "Pohnpei State",
				"state_code" : "PNI"
			},
			{
				"countryName": "Micronesia",
				"name"       : "Yap State",
				"state_code" : "YAP"
			}
		],
		"MD": [
			{
				"countryName": "Moldova",
				"name"       : "Anenii Noi District",
				"state_code" : "AN"
			},
			{
				"countryName": "Moldova",
				"name"       : "Bălți Municipality",
				"state_code" : "BA"
			},
			{
				"countryName": "Moldova",
				"name"       : "Basarabeasca District",
				"state_code" : "BS"
			},
			{
				"countryName": "Moldova",
				"name"       : "Bender Municipality",
				"state_code" : "BD"
			},
			{
				"countryName": "Moldova",
				"name"       : "Briceni District",
				"state_code" : "BR"
			},
			{
				"countryName": "Moldova",
				"name"       : "Cahul District",
				"state_code" : "CA"
			},
			{
				"countryName": "Moldova",
				"name"       : "Călărași District",
				"state_code" : "CL"
			},
			{
				"countryName": "Moldova",
				"name"       : "Cantemir District",
				"state_code" : "CT"
			},
			{
				"countryName": "Moldova",
				"name"       : "Căușeni District",
				"state_code" : "CS"
			},
			{
				"countryName": "Moldova",
				"name"       : "Chișinău Municipality",
				"state_code" : "CU"
			},
			{
				"countryName": "Moldova",
				"name"       : "Cimișlia District",
				"state_code" : "CM"
			},
			{
				"countryName": "Moldova",
				"name"       : "Criuleni District",
				"state_code" : "CR"
			},
			{
				"countryName": "Moldova",
				"name"       : "Dondușeni District",
				"state_code" : "DO"
			},
			{
				"countryName": "Moldova",
				"name"       : "Drochia District",
				"state_code" : "DR"
			},
			{
				"countryName": "Moldova",
				"name"       : "Dubăsari District",
				"state_code" : "DU"
			},
			{
				"countryName": "Moldova",
				"name"       : "Edineț District",
				"state_code" : "ED"
			},
			{
				"countryName": "Moldova",
				"name"       : "Fălești District",
				"state_code" : "FA"
			},
			{
				"countryName": "Moldova",
				"name"       : "Florești District",
				"state_code" : "FL"
			},
			{
				"countryName": "Moldova",
				"name"       : "Gagauzia",
				"state_code" : "GA"
			},
			{
				"countryName": "Moldova",
				"name"       : "Glodeni District",
				"state_code" : "GL"
			},
			{
				"countryName": "Moldova",
				"name"       : "Hîncești District",
				"state_code" : "HI"
			},
			{
				"countryName": "Moldova",
				"name"       : "Ialoveni District",
				"state_code" : "IA"
			},
			{
				"countryName": "Moldova",
				"name"       : "Nisporeni District",
				"state_code" : "NI"
			},
			{
				"countryName": "Moldova",
				"name"       : "Ocnița District",
				"state_code" : "OC"
			},
			{
				"countryName": "Moldova",
				"name"       : "Orhei District",
				"state_code" : "OR"
			},
			{
				"countryName": "Moldova",
				"name"       : "Rezina District",
				"state_code" : "RE"
			},
			{
				"countryName": "Moldova",
				"name"       : "Rîșcani District",
				"state_code" : "RI"
			},
			{
				"countryName": "Moldova",
				"name"       : "Sîngerei District",
				"state_code" : "SI"
			},
			{
				"countryName": "Moldova",
				"name"       : "Șoldănești District",
				"state_code" : "SD"
			},
			{
				"countryName": "Moldova",
				"name"       : "Soroca District",
				"state_code" : "SO"
			},
			{
				"countryName": "Moldova",
				"name"       : "Ștefan Vodă District",
				"state_code" : "SV"
			},
			{
				"countryName": "Moldova",
				"name"       : "Strășeni District",
				"state_code" : "ST"
			},
			{
				"countryName": "Moldova",
				"name"       : "Taraclia District",
				"state_code" : "TA"
			},
			{
				"countryName": "Moldova",
				"name"       : "Telenești District",
				"state_code" : "TE"
			},
			{
				"countryName": "Moldova",
				"name"       : "Transnistria autonomous territorial unit",
				"state_code" : "SN"
			},
			{
				"countryName": "Moldova",
				"name"       : "Ungheni District",
				"state_code" : "UN"
			}
		],
		"MC": [
			{
				"countryName": "Monaco",
				"name"       : "La Colle",
				"state_code" : "CL"
			},
			{
				"countryName": "Monaco",
				"name"       : "La Condamine",
				"state_code" : "CO"
			},
			{
				"countryName": "Monaco",
				"name"       : "Moneghetti",
				"state_code" : "MG"
			}
		],
		"MN": [
			{
				"countryName": "Mongolia",
				"name"       : "Arkhangai Province",
				"state_code" : "073"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Bayan-Ölgii Province",
				"state_code" : "071"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Bayankhongor Province",
				"state_code" : "069"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Bulgan Province",
				"state_code" : "067"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Darkhan-Uul Province",
				"state_code" : "037"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Dornod Province",
				"state_code" : "061"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Dornogovi Province",
				"state_code" : "063"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Dundgovi Province",
				"state_code" : "059"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Govi-Altai Province",
				"state_code" : "065"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Govisümber Province",
				"state_code" : "064"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Khentii Province",
				"state_code" : "039"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Khovd Province",
				"state_code" : "043"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Khövsgöl Province",
				"state_code" : "041"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Ömnögovi Province",
				"state_code" : "053"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Orkhon Province",
				"state_code" : "035"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Övörkhangai Province",
				"state_code" : "055"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Selenge Province",
				"state_code" : "049"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Sükhbaatar Province",
				"state_code" : "051"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Töv Province",
				"state_code" : "047"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Uvs Province",
				"state_code" : "046"
			},
			{
				"countryName": "Mongolia",
				"name"       : "Zavkhan Province",
				"state_code" : "057"
			}
		],
		"ME": [
			{
				"countryName": "Montenegro",
				"name"       : "Andrijevica Municipality",
				"state_code" : "01"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Bar Municipality",
				"state_code" : "02"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Berane Municipality",
				"state_code" : "03"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Bijelo Polje Municipality",
				"state_code" : "04"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Budva Municipality",
				"state_code" : "05"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Danilovgrad Municipality",
				"state_code" : "07"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Gusinje Municipality",
				"state_code" : "22"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Kolašin Municipality",
				"state_code" : "09"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Kotor Municipality",
				"state_code" : "10"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Mojkovac Municipality",
				"state_code" : "11"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Nikšić Municipality",
				"state_code" : "12"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Old Royal Capital Cetinje",
				"state_code" : "06"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Petnjica Municipality",
				"state_code" : "23"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Plav Municipality",
				"state_code" : "13"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Pljevlja Municipality",
				"state_code" : "14"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Plužine Municipality",
				"state_code" : "15"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Podgorica Municipality",
				"state_code" : "16"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Rožaje Municipality",
				"state_code" : "17"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Šavnik Municipality",
				"state_code" : "18"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Tivat Municipality",
				"state_code" : "19"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Ulcinj Municipality",
				"state_code" : "20"
			},
			{
				"countryName": "Montenegro",
				"name"       : "Žabljak Municipality",
				"state_code" : "21"
			}
		],
		"MS": [],
		"MA": [
			{
				"countryName": "Morocco",
				"name"       : "Agadir-Ida-Ou-Tanane",
				"state_code" : "AGD"
			},
			{
				"countryName": "Morocco",
				"name"       : "Al Haouz",
				"state_code" : "HAO"
			},
			{
				"countryName": "Morocco",
				"name"       : "Al Hoceïma",
				"state_code" : "HOC"
			},
			{
				"countryName": "Morocco",
				"name"       : "Aousserd (EH)",
				"state_code" : "AOU"
			},
			{
				"countryName": "Morocco",
				"name"       : "Assa-Zag (EH-partial)",
				"state_code" : "ASZ"
			},
			{
				"countryName": "Morocco",
				"name"       : "Azilal",
				"state_code" : "AZI"
			},
			{
				"countryName": "Morocco",
				"name"       : "Béni Mellal",
				"state_code" : "BEM"
			},
			{
				"countryName": "Morocco",
				"name"       : "Béni Mellal-Khénifra",
				"state_code" : "05"
			},
			{
				"countryName": "Morocco",
				"name"       : "Benslimane",
				"state_code" : "BES"
			},
			{
				"countryName": "Morocco",
				"name"       : "Berkane",
				"state_code" : "BER"
			},
			{
				"countryName": "Morocco",
				"name"       : "Berrechid",
				"state_code" : "BRR"
			},
			{
				"countryName": "Morocco",
				"name"       : "Boujdour (EH)",
				"state_code" : "BOD"
			},
			{
				"countryName": "Morocco",
				"name"       : "Boulemane",
				"state_code" : "BOM"
			},
			{
				"countryName": "Morocco",
				"name"       : "Casablanca",
				"state_code" : "CAS"
			},
			{
				"countryName": "Morocco",
				"name"       : "Casablanca-Settat",
				"state_code" : "06"
			},
			{
				"countryName": "Morocco",
				"name"       : "Chefchaouen",
				"state_code" : "CHE"
			},
			{
				"countryName": "Morocco",
				"name"       : "Chichaoua",
				"state_code" : "CHI"
			},
			{
				"countryName": "Morocco",
				"name"       : "Chtouka-Ait Baha",
				"state_code" : "CHT"
			},
			{
				"countryName": "Morocco",
				"name"       : "Dakhla-Oued Ed-Dahab (EH)",
				"state_code" : "12"
			},
			{
				"countryName": "Morocco",
				"name"       : "Drâa-Tafilalet",
				"state_code" : "08"
			},
			{
				"countryName": "Morocco",
				"name"       : "Driouch",
				"state_code" : "DRI"
			},
			{
				"countryName": "Morocco",
				"name"       : "El Hajeb",
				"state_code" : "HAJ"
			},
			{
				"countryName": "Morocco",
				"name"       : "El Jadida",
				"state_code" : "JDI"
			},
			{
				"countryName": "Morocco",
				"name"       : "El Kelâa des Sraghna",
				"state_code" : "KES"
			},
			{
				"countryName": "Morocco",
				"name"       : "Errachidia",
				"state_code" : "ERR"
			},
			{
				"countryName": "Morocco",
				"name"       : "Es-Semara (EH-partial)",
				"state_code" : "ESM"
			},
			{
				"countryName": "Morocco",
				"name"       : "Essaouira",
				"state_code" : "ESI"
			},
			{
				"countryName": "Morocco",
				"name"       : "Fahs-Anjra",
				"state_code" : "FAH"
			},
			{
				"countryName": "Morocco",
				"name"       : "Fès",
				"state_code" : "FES"
			},
			{
				"countryName": "Morocco",
				"name"       : "Fès-Meknès",
				"state_code" : "03"
			},
			{
				"countryName": "Morocco",
				"name"       : "Figuig",
				"state_code" : "FIG"
			},
			{
				"countryName": "Morocco",
				"name"       : "Fquih Ben Salah",
				"state_code" : "FQH"
			},
			{
				"countryName": "Morocco",
				"name"       : "Guelmim",
				"state_code" : "GUE"
			},
			{
				"countryName": "Morocco",
				"name"       : "Guelmim-Oued Noun (EH-partial)",
				"state_code" : "10"
			},
			{
				"countryName": "Morocco",
				"name"       : "Guercif",
				"state_code" : "GUF"
			},
			{
				"countryName": "Morocco",
				"name"       : "Ifrane",
				"state_code" : "IFR"
			},
			{
				"countryName": "Morocco",
				"name"       : "Inezgane-Ait Melloul",
				"state_code" : "INE"
			},
			{
				"countryName": "Morocco",
				"name"       : "Jerada",
				"state_code" : "JRA"
			},
			{
				"countryName": "Morocco",
				"name"       : "Kénitra",
				"state_code" : "KEN"
			},
			{
				"countryName": "Morocco",
				"name"       : "Khémisset",
				"state_code" : "KHE"
			},
			{
				"countryName": "Morocco",
				"name"       : "Khénifra",
				"state_code" : "KHN"
			},
			{
				"countryName": "Morocco",
				"name"       : "Khouribga",
				"state_code" : "KHO"
			},
			{
				"countryName": "Morocco",
				"name"       : "L'Oriental",
				"state_code" : "02"
			},
			{
				"countryName": "Morocco",
				"name"       : "Laâyoune (EH)",
				"state_code" : "LAA"
			},
			{
				"countryName": "Morocco",
				"name"       : "Laâyoune-Sakia El Hamra (EH-partial)",
				"state_code" : "11"
			},
			{
				"countryName": "Morocco",
				"name"       : "Larache",
				"state_code" : "LAR"
			},
			{
				"countryName": "Morocco",
				"name"       : "M’diq-Fnideq",
				"state_code" : "MDF"
			},
			{
				"countryName": "Morocco",
				"name"       : "Marrakech",
				"state_code" : "MAR"
			},
			{
				"countryName": "Morocco",
				"name"       : "Marrakesh-Safi",
				"state_code" : "07"
			},
			{
				"countryName": "Morocco",
				"name"       : "Médiouna",
				"state_code" : "MED"
			},
			{
				"countryName": "Morocco",
				"name"       : "Meknès",
				"state_code" : "MEK"
			},
			{
				"countryName": "Morocco",
				"name"       : "Midelt",
				"state_code" : "MID"
			},
			{
				"countryName": "Morocco",
				"name"       : "Mohammadia",
				"state_code" : "MOH"
			},
			{
				"countryName": "Morocco",
				"name"       : "Moulay Yacoub",
				"state_code" : "MOU"
			},
			{
				"countryName": "Morocco",
				"name"       : "Nador",
				"state_code" : "NAD"
			},
			{
				"countryName": "Morocco",
				"name"       : "Nouaceur",
				"state_code" : "NOU"
			},
			{
				"countryName": "Morocco",
				"name"       : "Ouarzazate",
				"state_code" : "OUA"
			},
			{
				"countryName": "Morocco",
				"name"       : "Oued Ed-Dahab (EH)",
				"state_code" : "OUD"
			},
			{
				"countryName": "Morocco",
				"name"       : "Ouezzane",
				"state_code" : "OUZ"
			},
			{
				"countryName": "Morocco",
				"name"       : "Oujda-Angad",
				"state_code" : "OUJ"
			},
			{
				"countryName": "Morocco",
				"name"       : "Rabat",
				"state_code" : "RAB"
			},
			{
				"countryName": "Morocco",
				"name"       : "Rabat-Salé-Kénitra",
				"state_code" : "04"
			},
			{
				"countryName": "Morocco",
				"name"       : "Rehamna",
				"state_code" : "REH"
			},
			{
				"countryName": "Morocco",
				"name"       : "Safi",
				"state_code" : "SAF"
			},
			{
				"countryName": "Morocco",
				"name"       : "Salé",
				"state_code" : "SAL"
			},
			{
				"countryName": "Morocco",
				"name"       : "Sefrou",
				"state_code" : "SEF"
			},
			{
				"countryName": "Morocco",
				"name"       : "Settat",
				"state_code" : "SET"
			},
			{
				"countryName": "Morocco",
				"name"       : "Sidi Bennour",
				"state_code" : "SIB"
			},
			{
				"countryName": "Morocco",
				"name"       : "Sidi Ifni",
				"state_code" : "SIF"
			},
			{
				"countryName": "Morocco",
				"name"       : "Sidi Kacem",
				"state_code" : "SIK"
			},
			{
				"countryName": "Morocco",
				"name"       : "Sidi Slimane",
				"state_code" : "SIL"
			},
			{
				"countryName": "Morocco",
				"name"       : "Skhirate-Témara",
				"state_code" : "SKH"
			},
			{
				"countryName": "Morocco",
				"name"       : "Souss-Massa",
				"state_code" : "09"
			},
			{
				"countryName": "Morocco",
				"name"       : "Tan-Tan (EH-partial)",
				"state_code" : "TNT"
			},
			{
				"countryName": "Morocco",
				"name"       : "Tanger-Assilah",
				"state_code" : "TNG"
			},
			{
				"countryName": "Morocco",
				"name"       : "Tanger-Tétouan-Al Hoceïma",
				"state_code" : "01"
			},
			{
				"countryName": "Morocco",
				"name"       : "Taounate",
				"state_code" : "TAO"
			},
			{
				"countryName": "Morocco",
				"name"       : "Taourirt",
				"state_code" : "TAI"
			},
			{
				"countryName": "Morocco",
				"name"       : "Tarfaya (EH-partial)",
				"state_code" : "TAF"
			},
			{
				"countryName": "Morocco",
				"name"       : "Taroudannt",
				"state_code" : "TAR"
			},
			{
				"countryName": "Morocco",
				"name"       : "Tata",
				"state_code" : "TAT"
			},
			{
				"countryName": "Morocco",
				"name"       : "Taza",
				"state_code" : "TAZ"
			},
			{
				"countryName": "Morocco",
				"name"       : "Tétouan",
				"state_code" : "TET"
			},
			{
				"countryName": "Morocco",
				"name"       : "Tinghir",
				"state_code" : "TIN"
			},
			{
				"countryName": "Morocco",
				"name"       : "Tiznit",
				"state_code" : "TIZ"
			},
			{
				"countryName": "Morocco",
				"name"       : "Youssoufia",
				"state_code" : "YUS"
			},
			{
				"countryName": "Morocco",
				"name"       : "Zagora",
				"state_code" : "ZAG"
			}
		],
		"MZ": [
			{
				"countryName": "Mozambique",
				"name"       : "Cabo Delgado Province",
				"state_code" : "P"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Gaza Province",
				"state_code" : "G"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Inhambane Province",
				"state_code" : "I"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Manica Province",
				"state_code" : "B"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Maputo",
				"state_code" : "MPM"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Maputo Province",
				"state_code" : "L"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Nampula Province",
				"state_code" : "N"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Niassa Province",
				"state_code" : "A"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Sofala Province",
				"state_code" : "S"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Tete Province",
				"state_code" : "T"
			},
			{
				"countryName": "Mozambique",
				"name"       : "Zambezia Province",
				"state_code" : "Q"
			}
		],
		"MM": [
			{
				"countryName": "Myanmar",
				"name"       : "Ayeyarwady Region",
				"state_code" : "07"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Bago",
				"state_code" : "02"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Chin State",
				"state_code" : "14"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Kachin State",
				"state_code" : "11"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Kayah State",
				"state_code" : "12"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Kayin State",
				"state_code" : "13"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Magway Region",
				"state_code" : "03"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Mandalay Region",
				"state_code" : "04"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Mon State",
				"state_code" : "15"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Naypyidaw Union Territory",
				"state_code" : "18"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Rakhine State",
				"state_code" : "16"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Sagaing Region",
				"state_code" : "01"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Shan State",
				"state_code" : "17"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Tanintharyi Region",
				"state_code" : "05"
			},
			{
				"countryName": "Myanmar",
				"name"       : "Yangon Region",
				"state_code" : "06"
			}
		],
		"NA": [
			{
				"countryName": "Namibia",
				"name"       : "Erongo Region",
				"state_code" : "ER"
			},
			{
				"countryName": "Namibia",
				"name"       : "Hardap Region",
				"state_code" : "HA"
			},
			{
				"countryName": "Namibia",
				"name"       : "Karas Region",
				"state_code" : "KA"
			},
			{
				"countryName": "Namibia",
				"name"       : "Kavango East Region",
				"state_code" : "KE"
			},
			{
				"countryName": "Namibia",
				"name"       : "Kavango West Region",
				"state_code" : "KW"
			},
			{
				"countryName": "Namibia",
				"name"       : "Khomas Region",
				"state_code" : "KH"
			},
			{
				"countryName": "Namibia",
				"name"       : "Kunene Region",
				"state_code" : "KU"
			},
			{
				"countryName": "Namibia",
				"name"       : "Ohangwena Region",
				"state_code" : "OW"
			},
			{
				"countryName": "Namibia",
				"name"       : "Omaheke Region",
				"state_code" : "OH"
			},
			{
				"countryName": "Namibia",
				"name"       : "Omusati Region",
				"state_code" : "OS"
			},
			{
				"countryName": "Namibia",
				"name"       : "Oshana Region",
				"state_code" : "ON"
			},
			{
				"countryName": "Namibia",
				"name"       : "Oshikoto Region",
				"state_code" : "OT"
			},
			{
				"countryName": "Namibia",
				"name"       : "Otjozondjupa Region",
				"state_code" : "OD"
			},
			{
				"countryName": "Namibia",
				"name"       : "Zambezi Region",
				"state_code" : "CA"
			}
		],
		"NR": [
			{
				"countryName": "Nauru",
				"name"       : "Aiwo District",
				"state_code" : "01"
			},
			{
				"countryName": "Nauru",
				"name"       : "Anabar District",
				"state_code" : "02"
			},
			{
				"countryName": "Nauru",
				"name"       : "Anetan District",
				"state_code" : "03"
			},
			{
				"countryName": "Nauru",
				"name"       : "Anibare District",
				"state_code" : "04"
			},
			{
				"countryName": "Nauru",
				"name"       : "Baiti District",
				"state_code" : "05"
			},
			{
				"countryName": "Nauru",
				"name"       : "Boe District",
				"state_code" : "06"
			},
			{
				"countryName": "Nauru",
				"name"       : "Buada District",
				"state_code" : "07"
			},
			{
				"countryName": "Nauru",
				"name"       : "Denigomodu District",
				"state_code" : "08"
			},
			{
				"countryName": "Nauru",
				"name"       : "Ewa District",
				"state_code" : "09"
			},
			{
				"countryName": "Nauru",
				"name"       : "Ijuw District",
				"state_code" : "10"
			},
			{
				"countryName": "Nauru",
				"name"       : "Meneng District",
				"state_code" : "11"
			},
			{
				"countryName": "Nauru",
				"name"       : "Nibok District",
				"state_code" : "12"
			},
			{
				"countryName": "Nauru",
				"name"       : "Uaboe District",
				"state_code" : "13"
			},
			{
				"countryName": "Nauru",
				"name"       : "Yaren District",
				"state_code" : "14"
			}
		],
		"NP": [
			{
				"countryName": "Nepal",
				"name"       : "Bagmati Zone",
				"state_code" : "BA"
			},
			{
				"countryName": "Nepal",
				"name"       : "Bheri Zone",
				"state_code" : "BH"
			},
			{
				"countryName": "Nepal",
				"name"       : "Central Region",
				"state_code" : "1"
			},
			{
				"countryName": "Nepal",
				"name"       : "Dhaulagiri Zone",
				"state_code" : "DH"
			},
			{
				"countryName": "Nepal",
				"name"       : "Eastern Development Region",
				"state_code" : "4"
			},
			{
				"countryName": "Nepal",
				"name"       : "Far-Western Development Region",
				"state_code" : "5"
			},
			{
				"countryName": "Nepal",
				"name"       : "Gandaki Zone",
				"state_code" : "GA"
			},
			{
				"countryName": "Nepal",
				"name"       : "Janakpur Zone",
				"state_code" : "JA"
			},
			{
				"countryName": "Nepal",
				"name"       : "Karnali Zone",
				"state_code" : "KA"
			},
			{
				"countryName": "Nepal",
				"name"       : "Kosi Zone",
				"state_code" : "KO"
			},
			{
				"countryName": "Nepal",
				"name"       : "Lumbini Zone",
				"state_code" : "LU"
			},
			{
				"countryName": "Nepal",
				"name"       : "Mahakali Zone",
				"state_code" : "MA"
			},
			{
				"countryName": "Nepal",
				"name"       : "Mechi Zone",
				"state_code" : "ME"
			},
			{
				"countryName": "Nepal",
				"name"       : "Mid-Western Region",
				"state_code" : "2"
			},
			{
				"countryName": "Nepal",
				"name"       : "Narayani Zone",
				"state_code" : "NA"
			},
			{
				"countryName": "Nepal",
				"name"       : "Rapti Zone",
				"state_code" : "RA"
			},
			{
				"countryName": "Nepal",
				"name"       : "Sagarmatha Zone",
				"state_code" : "SA"
			},
			{
				"countryName": "Nepal",
				"name"       : "Seti Zone",
				"state_code" : "SE"
			},
			{
				"countryName": "Nepal",
				"name"       : "Western Region",
				"state_code" : "3"
			}
		],
		"NL": [
			{
				"countryName": "Netherlands",
				"name"       : "Bonaire",
				"state_code" : "BQ1"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Drenthe",
				"state_code" : "DR"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Flevoland",
				"state_code" : "FL"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Friesland",
				"state_code" : "FR"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Gelderland",
				"state_code" : "GE"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Groningen",
				"state_code" : "GR"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Limburg",
				"state_code" : "LI"
			},
			{
				"countryName": "Netherlands",
				"name"       : "North Brabant",
				"state_code" : "NB"
			},
			{
				"countryName": "Netherlands",
				"name"       : "North Holland",
				"state_code" : "NH"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Overijssel",
				"state_code" : "OV"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Saba",
				"state_code" : "BQ2"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Sint Eustatius",
				"state_code" : "BQ3"
			},
			{
				"countryName": "Netherlands",
				"name"       : "South Holland",
				"state_code" : "ZH"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Utrecht",
				"state_code" : "UT"
			},
			{
				"countryName": "Netherlands",
				"name"       : "Zeeland",
				"state_code" : "ZE"
			}
		],
		"NC": [],
		"NZ": [
			{
				"countryName": "New Zealand",
				"name"       : "Auckland Region",
				"state_code" : "AUK"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Bay of Plenty Region",
				"state_code" : "BOP"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Canterbury Region",
				"state_code" : "CAN"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Chatham Islands",
				"state_code" : "CIT"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Gisborne District",
				"state_code" : "GIS"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Hawke's Bay Region",
				"state_code" : "HKB"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Manawatu-Wanganui Region",
				"state_code" : "MWT"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Marlborough Region",
				"state_code" : "MBH"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Nelson Region",
				"state_code" : "NSN"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Northland Region",
				"state_code" : "NTL"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Otago Region",
				"state_code" : "OTA"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Southland Region",
				"state_code" : "STL"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Taranaki Region",
				"state_code" : "TKI"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Tasman District",
				"state_code" : "TAS"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Waikato Region",
				"state_code" : "WKO"
			},
			{
				"countryName": "New Zealand",
				"name"       : "Wellington Region",
				"state_code" : "WGN"
			},
			{
				"countryName": "New Zealand",
				"name"       : "West Coast Region",
				"state_code" : "WTC"
			}
		],
		"NI": [
			{
				"countryName": "Nicaragua",
				"name"       : "Boaco",
				"state_code" : "BO"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Carazo",
				"state_code" : "CA"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Chinandega",
				"state_code" : "CI"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Chontales",
				"state_code" : "CO"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Estelí",
				"state_code" : "ES"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Granada",
				"state_code" : "GR"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Jinotega",
				"state_code" : "JI"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "León",
				"state_code" : "LE"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Madriz",
				"state_code" : "MD"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Managua",
				"state_code" : "MN"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Masaya",
				"state_code" : "MS"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Matagalpa",
				"state_code" : "MT"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "North Caribbean Coast",
				"state_code" : "AN"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Nueva Segovia",
				"state_code" : "NS"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Río San Juan",
				"state_code" : "SJ"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "Rivas",
				"state_code" : "RI"
			},
			{
				"countryName": "Nicaragua",
				"name"       : "South Caribbean Coast",
				"state_code" : "AS"
			}
		],
		"NE": [
			{
				"countryName": "Niger",
				"name"       : "Agadez Region",
				"state_code" : "1"
			},
			{
				"countryName": "Niger",
				"name"       : "Diffa Region",
				"state_code" : "2"
			},
			{
				"countryName": "Niger",
				"name"       : "Dosso Region",
				"state_code" : "3"
			},
			{
				"countryName": "Niger",
				"name"       : "Maradi Region",
				"state_code" : "4"
			},
			{
				"countryName": "Niger",
				"name"       : "Tahoua Region",
				"state_code" : "5"
			},
			{
				"countryName": "Niger",
				"name"       : "Tillabéri Region",
				"state_code" : "6"
			},
			{
				"countryName": "Niger",
				"name"       : "Zinder Region",
				"state_code" : "7"
			}
		],
		"NG": [
			{
				"countryName": "Nigeria",
				"name"       : "Abia",
				"state_code" : "AB"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Abuja Federal Capital Territory",
				"state_code" : "FC"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Adamawa",
				"state_code" : "AD"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Akwa Ibom",
				"state_code" : "AK"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Anambra",
				"state_code" : "AN"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Bauchi",
				"state_code" : "BA"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Bayelsa",
				"state_code" : "BY"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Benue",
				"state_code" : "BE"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Borno",
				"state_code" : "BO"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Cross River",
				"state_code" : "CR"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Delta",
				"state_code" : "DE"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Ebonyi",
				"state_code" : "EB"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Edo",
				"state_code" : "ED"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Ekiti",
				"state_code" : "EK"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Enugu",
				"state_code" : "EN"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Gombe",
				"state_code" : "GO"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Imo",
				"state_code" : "IM"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Jigawa",
				"state_code" : "JI"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Kaduna",
				"state_code" : "KD"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Kano",
				"state_code" : "KN"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Katsina",
				"state_code" : "KT"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Kebbi",
				"state_code" : "KE"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Kogi",
				"state_code" : "KO"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Kwara",
				"state_code" : "KW"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Lagos",
				"state_code" : "LA"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Nasarawa",
				"state_code" : "NA"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Niger",
				"state_code" : "NI"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Ogun",
				"state_code" : "OG"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Ondo",
				"state_code" : "ON"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Osun",
				"state_code" : "OS"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Oyo",
				"state_code" : "OY"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Plateau",
				"state_code" : "PL"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Rivers",
				"state_code" : "RI"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Sokoto",
				"state_code" : "SO"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Taraba",
				"state_code" : "TA"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Yobe",
				"state_code" : "YO"
			},
			{
				"countryName": "Nigeria",
				"name"       : "Zamfara",
				"state_code" : "ZA"
			}
		],
		"NU": [],
		"NF": [],
		"KP": [
			{
				"countryName": "North Korea",
				"name"       : "Chagang Province",
				"state_code" : "04"
			},
			{
				"countryName": "North Korea",
				"name"       : "Kangwon Province",
				"state_code" : "07"
			},
			{
				"countryName": "North Korea",
				"name"       : "North Hamgyong Province",
				"state_code" : "09"
			},
			{
				"countryName": "North Korea",
				"name"       : "North Hwanghae Province",
				"state_code" : "06"
			},
			{
				"countryName": "North Korea",
				"name"       : "North Pyongan Province",
				"state_code" : "03"
			},
			{
				"countryName": "North Korea",
				"name"       : "Pyongyang",
				"state_code" : "01"
			},
			{
				"countryName": "North Korea",
				"name"       : "Rason",
				"state_code" : "13"
			},
			{
				"countryName": "North Korea",
				"name"       : "Ryanggang Province",
				"state_code" : "10"
			},
			{
				"countryName": "North Korea",
				"name"       : "South Hamgyong Province",
				"state_code" : "08"
			},
			{
				"countryName": "North Korea",
				"name"       : "South Hwanghae Province",
				"state_code" : "05"
			},
			{
				"countryName": "North Korea",
				"name"       : "South Pyongan Province",
				"state_code" : "02"
			}
		],
		"MP": [],
		"NO": [
			{
				"countryName": "Norway",
				"name"       : "Akershus",
				"state_code" : "02"
			},
			{
				"countryName": "Norway",
				"name"       : "Buskerud",
				"state_code" : "06"
			},
			{
				"countryName": "Norway",
				"name"       : "Finnmark",
				"state_code" : "20"
			},
			{
				"countryName": "Norway",
				"name"       : "Hedmark",
				"state_code" : "04"
			},
			{
				"countryName": "Norway",
				"name"       : "Hordaland",
				"state_code" : "12"
			},
			{
				"countryName": "Norway",
				"name"       : "Jan Mayen",
				"state_code" : "22"
			},
			{
				"countryName": "Norway",
				"name"       : "Møre og Romsdal",
				"state_code" : "15"
			},
			{
				"countryName": "Norway",
				"name"       : "Nord-Trøndelag",
				"state_code" : "17"
			},
			{
				"countryName": "Norway",
				"name"       : "Nordland",
				"state_code" : "18"
			},
			{
				"countryName": "Norway",
				"name"       : "Oppland",
				"state_code" : "05"
			},
			{
				"countryName": "Norway",
				"name"       : "Oslo",
				"state_code" : "03"
			},
			{
				"countryName": "Norway",
				"name"       : "Østfold",
				"state_code" : "01"
			},
			{
				"countryName": "Norway",
				"name"       : "Rogaland",
				"state_code" : "11"
			},
			{
				"countryName": "Norway",
				"name"       : "Sogn og Fjordane",
				"state_code" : "14"
			},
			{
				"countryName": "Norway",
				"name"       : "Sør-Trøndelag",
				"state_code" : "16"
			},
			{
				"countryName": "Norway",
				"name"       : "Svalbard",
				"state_code" : "21"
			},
			{
				"countryName": "Norway",
				"name"       : "Telemark",
				"state_code" : "08"
			},
			{
				"countryName": "Norway",
				"name"       : "Troms",
				"state_code" : "19"
			},
			{
				"countryName": "Norway",
				"name"       : "Trøndelag",
				"state_code" : "50"
			},
			{
				"countryName": "Norway",
				"name"       : "Vest-Agder",
				"state_code" : "10"
			},
			{
				"countryName": "Norway",
				"name"       : "Vestfold",
				"state_code" : "07"
			}
		],
		"OM": [
			{
				"countryName": "Oman",
				"name"       : "Ad Dakhiliyah",
				"state_code" : "DA"
			},
			{
				"countryName": "Oman",
				"name"       : "Ad Dhahirah",
				"state_code" : "ZA"
			},
			{
				"countryName": "Oman",
				"name"       : "Al Batinah North",
				"state_code" : "BS"
			},
			{
				"countryName": "Oman",
				"name"       : "Al Batinah Region",
				"state_code" : "BA"
			},
			{
				"countryName": "Oman",
				"name"       : "Al Batinah South",
				"state_code" : "BJ"
			},
			{
				"countryName": "Oman",
				"name"       : "Al Buraimi",
				"state_code" : "BU"
			},
			{
				"countryName": "Oman",
				"name"       : "Al Wusta",
				"state_code" : "WU"
			},
			{
				"countryName": "Oman",
				"name"       : "Ash Sharqiyah North",
				"state_code" : "SS"
			},
			{
				"countryName": "Oman",
				"name"       : "Ash Sharqiyah Region",
				"state_code" : "SH"
			},
			{
				"countryName": "Oman",
				"name"       : "Ash Sharqiyah South",
				"state_code" : "SJ"
			},
			{
				"countryName": "Oman",
				"name"       : "Dhofar",
				"state_code" : "ZU"
			},
			{
				"countryName": "Oman",
				"name"       : "Musandam",
				"state_code" : "MU"
			},
			{
				"countryName": "Oman",
				"name"       : "Muscat",
				"state_code" : "MA"
			}
		],
		"PK": [
			{
				"countryName": "Pakistan",
				"name"       : "Azad Kashmir",
				"state_code" : "JK"
			},
			{
				"countryName": "Pakistan",
				"name"       : "Balochistan",
				"state_code" : "BA"
			},
			{
				"countryName": "Pakistan",
				"name"       : "Federally Administered Tribal Areas",
				"state_code" : "TA"
			},
			{
				"countryName": "Pakistan",
				"name"       : "Gilgit-Baltistan",
				"state_code" : "GB"
			},
			{
				"countryName": "Pakistan",
				"name"       : "Islamabad Capital Territory",
				"state_code" : "IS"
			},
			{
				"countryName": "Pakistan",
				"name"       : "Khyber Pakhtunkhwa",
				"state_code" : "KP"
			},
			{
				"countryName": "Pakistan",
				"name"       : "Punjab",
				"state_code" : "PB"
			},
			{
				"countryName": "Pakistan",
				"name"       : "Sindh",
				"state_code" : "SD"
			}
		],
		"PW": [
			{
				"countryName": "Palau",
				"name"       : "Aimeliik",
				"state_code" : "002"
			},
			{
				"countryName": "Palau",
				"name"       : "Airai",
				"state_code" : "004"
			},
			{
				"countryName": "Palau",
				"name"       : "Angaur",
				"state_code" : "010"
			},
			{
				"countryName": "Palau",
				"name"       : "Hatohobei",
				"state_code" : "050"
			},
			{
				"countryName": "Palau",
				"name"       : "Kayangel",
				"state_code" : "100"
			},
			{
				"countryName": "Palau",
				"name"       : "Koror",
				"state_code" : "150"
			},
			{
				"countryName": "Palau",
				"name"       : "Melekeok",
				"state_code" : "212"
			},
			{
				"countryName": "Palau",
				"name"       : "Ngaraard",
				"state_code" : "214"
			},
			{
				"countryName": "Palau",
				"name"       : "Ngarchelong",
				"state_code" : "218"
			},
			{
				"countryName": "Palau",
				"name"       : "Ngardmau",
				"state_code" : "222"
			},
			{
				"countryName": "Palau",
				"name"       : "Ngatpang",
				"state_code" : "224"
			},
			{
				"countryName": "Palau",
				"name"       : "Ngchesar",
				"state_code" : "226"
			},
			{
				"countryName": "Palau",
				"name"       : "Ngeremlengui",
				"state_code" : "227"
			},
			{
				"countryName": "Palau",
				"name"       : "Ngiwal",
				"state_code" : "228"
			},
			{
				"countryName": "Palau",
				"name"       : "Peleliu",
				"state_code" : "350"
			},
			{
				"countryName": "Palau",
				"name"       : "Sonsorol",
				"state_code" : "370"
			}
		],
		"PS": [],
		"PA": [
			{
				"countryName": "Panama",
				"name"       : "Bocas del Toro Province",
				"state_code" : "1"
			},
			{
				"countryName": "Panama",
				"name"       : "Chiriquí Province",
				"state_code" : "4"
			},
			{
				"countryName": "Panama",
				"name"       : "Coclé Province",
				"state_code" : "2"
			},
			{
				"countryName": "Panama",
				"name"       : "Colón Province",
				"state_code" : "3"
			},
			{
				"countryName": "Panama",
				"name"       : "Darién Province",
				"state_code" : "5"
			},
			{
				"countryName": "Panama",
				"name"       : "Emberá-Wounaan Comarca",
				"state_code" : "EM"
			},
			{
				"countryName": "Panama",
				"name"       : "Guna Yala",
				"state_code" : "KY"
			},
			{
				"countryName": "Panama",
				"name"       : "Herrera Province",
				"state_code" : "6"
			},
			{
				"countryName": "Panama",
				"name"       : "Los Santos Province",
				"state_code" : "7"
			},
			{
				"countryName": "Panama",
				"name"       : "Ngöbe-Buglé Comarca",
				"state_code" : "NB"
			},
			{
				"countryName": "Panama",
				"name"       : "Panamá Oeste Province",
				"state_code" : "10"
			},
			{
				"countryName": "Panama",
				"name"       : "Panamá Province",
				"state_code" : "8"
			},
			{
				"countryName": "Panama",
				"name"       : "Veraguas Province",
				"state_code" : "9"
			}
		],
		"PG": [
			{
				"countryName": "Papua new Guinea",
				"name"       : "Bougainville",
				"state_code" : "NSB"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Central Province",
				"state_code" : "CPM"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Chimbu Province",
				"state_code" : "CPK"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "East New Britain",
				"state_code" : "EBR"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Eastern Highlands Province",
				"state_code" : "EHG"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Enga Province",
				"state_code" : "EPW"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Gulf",
				"state_code" : "GPK"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Hela",
				"state_code" : "HLA"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Jiwaka Province",
				"state_code" : "JWK"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Madang Province",
				"state_code" : "MPM"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Manus Province",
				"state_code" : "MRL"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Milne Bay Province",
				"state_code" : "MBA"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Morobe Province",
				"state_code" : "MPL"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "New Ireland Province",
				"state_code" : "NIK"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Oro Province",
				"state_code" : "NPP"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Port Moresby",
				"state_code" : "NCD"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Sandaun Province",
				"state_code" : "SAN"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Southern Highlands Province",
				"state_code" : "SHM"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "West New Britain Province",
				"state_code" : "WBK"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Western Highlands Province",
				"state_code" : "WHM"
			},
			{
				"countryName": "Papua new Guinea",
				"name"       : "Western Province",
				"state_code" : "WPD"
			}
		],
		"PY": [
			{
				"countryName": "Paraguay",
				"name"       : "Alto Paraguay Department",
				"state_code" : "16"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Alto Paraná Department",
				"state_code" : "10"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Amambay Department",
				"state_code" : "13"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Boquerón Department",
				"state_code" : "19"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Caaguazú",
				"state_code" : "5"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Caazapá",
				"state_code" : "6"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Canindeyú",
				"state_code" : "14"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Central Department",
				"state_code" : "11"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Concepción Department",
				"state_code" : "1"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Cordillera Department",
				"state_code" : "3"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Guairá Department",
				"state_code" : "4"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Itapúa",
				"state_code" : "7"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Misiones Department",
				"state_code" : "8"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Ñeembucú Department",
				"state_code" : "12"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Paraguarí Department",
				"state_code" : "9"
			},
			{
				"countryName": "Paraguay",
				"name"       : "Presidente Hayes Department",
				"state_code" : "15"
			},
			{
				"countryName": "Paraguay",
				"name"       : "San Pedro Department",
				"state_code" : "2"
			}
		],
		"PE": [
			{
				"countryName": "Peru",
				"name"       : "Amazonas",
				"state_code" : "AMA"
			},
			{
				"countryName": "Peru",
				"name"       : "Áncash",
				"state_code" : "ANC"
			},
			{
				"countryName": "Peru",
				"name"       : "Apurímac",
				"state_code" : "APU"
			},
			{
				"countryName": "Peru",
				"name"       : "Arequipa",
				"state_code" : "ARE"
			},
			{
				"countryName": "Peru",
				"name"       : "Ayacucho",
				"state_code" : "AYA"
			},
			{
				"countryName": "Peru",
				"name"       : "Cajamarca",
				"state_code" : "CAJ"
			},
			{
				"countryName": "Peru",
				"name"       : "Callao",
				"state_code" : "CAL"
			},
			{
				"countryName": "Peru",
				"name"       : "Cusco",
				"state_code" : "CUS"
			},
			{
				"countryName": "Peru",
				"name"       : "Huancavelica",
				"state_code" : "HUV"
			},
			{
				"countryName": "Peru",
				"name"       : "Huanuco",
				"state_code" : "HUC"
			},
			{
				"countryName": "Peru",
				"name"       : "Ica",
				"state_code" : "ICA"
			},
			{
				"countryName": "Peru",
				"name"       : "Junín",
				"state_code" : "JUN"
			},
			{
				"countryName": "Peru",
				"name"       : "La Libertad",
				"state_code" : "LAL"
			},
			{
				"countryName": "Peru",
				"name"       : "Lambayeque",
				"state_code" : "LAM"
			},
			{
				"countryName": "Peru",
				"name"       : "Lima",
				"state_code" : "LIM"
			},
			{
				"countryName": "Peru",
				"name"       : "Loreto",
				"state_code" : "LOR"
			},
			{
				"countryName": "Peru",
				"name"       : "Madre de Dios",
				"state_code" : "MDD"
			},
			{
				"countryName": "Peru",
				"name"       : "Moquegua",
				"state_code" : "MOQ"
			},
			{
				"countryName": "Peru",
				"name"       : "Pasco",
				"state_code" : "PAS"
			},
			{
				"countryName": "Peru",
				"name"       : "Piura",
				"state_code" : "PIU"
			},
			{
				"countryName": "Peru",
				"name"       : "Puno",
				"state_code" : "PUN"
			},
			{
				"countryName": "Peru",
				"name"       : "San Martín",
				"state_code" : "SAM"
			},
			{
				"countryName": "Peru",
				"name"       : "Tacna",
				"state_code" : "TAC"
			},
			{
				"countryName": "Peru",
				"name"       : "Tumbes",
				"state_code" : "TUM"
			},
			{
				"countryName": "Peru",
				"name"       : "Ucayali",
				"state_code" : "UCA"
			}
		],
		"PH": [
			{
				"countryName": "Philippines",
				"name"       : "Abra",
				"state_code" : "ABR"
			},
			{
				"countryName": "Philippines",
				"name"       : "Agusan del Norte",
				"state_code" : "AGN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Agusan del Sur",
				"state_code" : "AGS"
			},
			{
				"countryName": "Philippines",
				"name"       : "Aklan",
				"state_code" : "AKL"
			},
			{
				"countryName": "Philippines",
				"name"       : "Albay",
				"state_code" : "ALB"
			},
			{
				"countryName": "Philippines",
				"name"       : "Antique",
				"state_code" : "ANT"
			},
			{
				"countryName": "Philippines",
				"name"       : "Apayao",
				"state_code" : "APA"
			},
			{
				"countryName": "Philippines",
				"name"       : "Aurora",
				"state_code" : "AUR"
			},
			{
				"countryName": "Philippines",
				"name"       : "Autonomous Region in Muslim Mindanao",
				"state_code" : "14"
			},
			{
				"countryName": "Philippines",
				"name"       : "Basilan",
				"state_code" : "BAS"
			},
			{
				"countryName": "Philippines",
				"name"       : "Bataan",
				"state_code" : "BAN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Batanes",
				"state_code" : "BTN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Batangas",
				"state_code" : "BTG"
			},
			{
				"countryName": "Philippines",
				"name"       : "Benguet",
				"state_code" : "BEN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Bicol",
				"state_code" : "05"
			},
			{
				"countryName": "Philippines",
				"name"       : "Biliran",
				"state_code" : "BIL"
			},
			{
				"countryName": "Philippines",
				"name"       : "Bohol",
				"state_code" : "BOH"
			},
			{
				"countryName": "Philippines",
				"name"       : "Bukidnon",
				"state_code" : "BUK"
			},
			{
				"countryName": "Philippines",
				"name"       : "Bulacan",
				"state_code" : "BUL"
			},
			{
				"countryName": "Philippines",
				"name"       : "Cagayan",
				"state_code" : "CAG"
			},
			{
				"countryName": "Philippines",
				"name"       : "Cagayan Valley",
				"state_code" : "02"
			},
			{
				"countryName": "Philippines",
				"name"       : "Calabarzon",
				"state_code" : "40"
			},
			{
				"countryName": "Philippines",
				"name"       : "Camarines Norte",
				"state_code" : "CAN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Camarines Sur",
				"state_code" : "CAS"
			},
			{
				"countryName": "Philippines",
				"name"       : "Camiguin",
				"state_code" : "CAM"
			},
			{
				"countryName": "Philippines",
				"name"       : "Capiz",
				"state_code" : "CAP"
			},
			{
				"countryName": "Philippines",
				"name"       : "Caraga",
				"state_code" : "13"
			},
			{
				"countryName": "Philippines",
				"name"       : "Catanduanes",
				"state_code" : "CAT"
			},
			{
				"countryName": "Philippines",
				"name"       : "Cavite",
				"state_code" : "CAV"
			},
			{
				"countryName": "Philippines",
				"name"       : "Cebu",
				"state_code" : "CEB"
			},
			{
				"countryName": "Philippines",
				"name"       : "Central Luzon",
				"state_code" : "03"
			},
			{
				"countryName": "Philippines",
				"name"       : "Central Visayas",
				"state_code" : "07"
			},
			{
				"countryName": "Philippines",
				"name"       : "Compostela Valley",
				"state_code" : "COM"
			},
			{
				"countryName": "Philippines",
				"name"       : "Cordillera Administrative",
				"state_code" : "15"
			},
			{
				"countryName": "Philippines",
				"name"       : "Cotabato",
				"state_code" : "NCO"
			},
			{
				"countryName": "Philippines",
				"name"       : "Davao",
				"state_code" : "11"
			},
			{
				"countryName": "Philippines",
				"name"       : "Davao del Norte",
				"state_code" : "DAV"
			},
			{
				"countryName": "Philippines",
				"name"       : "Davao del Sur",
				"state_code" : "DAS"
			},
			{
				"countryName": "Philippines",
				"name"       : "Davao Occidental",
				"state_code" : "DVO"
			},
			{
				"countryName": "Philippines",
				"name"       : "Davao Oriental",
				"state_code" : "DAO"
			},
			{
				"countryName": "Philippines",
				"name"       : "Dinagat Islands",
				"state_code" : "DIN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Eastern Samar",
				"state_code" : "EAS"
			},
			{
				"countryName": "Philippines",
				"name"       : "Eastern Visayas",
				"state_code" : "08"
			},
			{
				"countryName": "Philippines",
				"name"       : "Guimaras",
				"state_code" : "GUI"
			},
			{
				"countryName": "Philippines",
				"name"       : "Ifugao",
				"state_code" : "IFU"
			},
			{
				"countryName": "Philippines",
				"name"       : "Ilocos",
				"state_code" : "01"
			},
			{
				"countryName": "Philippines",
				"name"       : "Ilocos Norte",
				"state_code" : "ILN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Ilocos Sur",
				"state_code" : "ILS"
			},
			{
				"countryName": "Philippines",
				"name"       : "Iloilo",
				"state_code" : "ILI"
			},
			{
				"countryName": "Philippines",
				"name"       : "Isabela",
				"state_code" : "ISA"
			},
			{
				"countryName": "Philippines",
				"name"       : "Kalinga",
				"state_code" : "KAL"
			},
			{
				"countryName": "Philippines",
				"name"       : "La Union",
				"state_code" : "LUN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Laguna",
				"state_code" : "LAG"
			},
			{
				"countryName": "Philippines",
				"name"       : "Lanao del Norte",
				"state_code" : "LAN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Lanao del Sur",
				"state_code" : "LAS"
			},
			{
				"countryName": "Philippines",
				"name"       : "Leyte",
				"state_code" : "LEY"
			},
			{
				"countryName": "Philippines",
				"name"       : "Maguindanao",
				"state_code" : "MAG"
			},
			{
				"countryName": "Philippines",
				"name"       : "Marinduque",
				"state_code" : "MAD"
			},
			{
				"countryName": "Philippines",
				"name"       : "Masbate",
				"state_code" : "MAS"
			},
			{
				"countryName": "Philippines",
				"name"       : "Metro Manila",
				"state_code" : "NCR"
			},
			{
				"countryName": "Philippines",
				"name"       : "Mimaropa",
				"state_code" : "41"
			},
			{
				"countryName": "Philippines",
				"name"       : "Misamis Occidental",
				"state_code" : "MSC"
			},
			{
				"countryName": "Philippines",
				"name"       : "Misamis Oriental",
				"state_code" : "MSR"
			},
			{
				"countryName": "Philippines",
				"name"       : "Mountain Province",
				"state_code" : "MOU"
			},
			{
				"countryName": "Philippines",
				"name"       : "Negros Occidental",
				"state_code" : "NEC"
			},
			{
				"countryName": "Philippines",
				"name"       : "Negros Oriental",
				"state_code" : "NER"
			},
			{
				"countryName": "Philippines",
				"name"       : "Northern Mindanao",
				"state_code" : "10"
			},
			{
				"countryName": "Philippines",
				"name"       : "Northern Samar",
				"state_code" : "NSA"
			},
			{
				"countryName": "Philippines",
				"name"       : "Nueva Ecija",
				"state_code" : "NUE"
			},
			{
				"countryName": "Philippines",
				"name"       : "Nueva Vizcaya",
				"state_code" : "NUV"
			},
			{
				"countryName": "Philippines",
				"name"       : "Occidental Mindoro",
				"state_code" : "MDC"
			},
			{
				"countryName": "Philippines",
				"name"       : "Oriental Mindoro",
				"state_code" : "MDR"
			},
			{
				"countryName": "Philippines",
				"name"       : "Palawan",
				"state_code" : "PLW"
			},
			{
				"countryName": "Philippines",
				"name"       : "Pampanga",
				"state_code" : "PAM"
			},
			{
				"countryName": "Philippines",
				"name"       : "Pangasinan",
				"state_code" : "PAN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Quezon",
				"state_code" : "QUE"
			},
			{
				"countryName": "Philippines",
				"name"       : "Quirino",
				"state_code" : "QUI"
			},
			{
				"countryName": "Philippines",
				"name"       : "Rizal",
				"state_code" : "RIZ"
			},
			{
				"countryName": "Philippines",
				"name"       : "Romblon",
				"state_code" : "ROM"
			},
			{
				"countryName": "Philippines",
				"name"       : "Sarangani",
				"state_code" : "SAR"
			},
			{
				"countryName": "Philippines",
				"name"       : "Siquijor",
				"state_code" : "SIG"
			},
			{
				"countryName": "Philippines",
				"name"       : "Soccsksargen",
				"state_code" : "12"
			},
			{
				"countryName": "Philippines",
				"name"       : "Sorsogon",
				"state_code" : "SOR"
			},
			{
				"countryName": "Philippines",
				"name"       : "South Cotabato",
				"state_code" : "SCO"
			},
			{
				"countryName": "Philippines",
				"name"       : "Southern Leyte",
				"state_code" : "SLE"
			},
			{
				"countryName": "Philippines",
				"name"       : "Sultan Kudarat",
				"state_code" : "SUK"
			},
			{
				"countryName": "Philippines",
				"name"       : "Sulu",
				"state_code" : "SLU"
			},
			{
				"countryName": "Philippines",
				"name"       : "Surigao del Norte",
				"state_code" : "SUN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Surigao del Sur",
				"state_code" : "SUR"
			},
			{
				"countryName": "Philippines",
				"name"       : "Tarlac",
				"state_code" : "TAR"
			},
			{
				"countryName": "Philippines",
				"name"       : "Tawi-Tawi",
				"state_code" : "TAW"
			},
			{
				"countryName": "Philippines",
				"name"       : "Western Samar",
				"state_code" : "WSA"
			},
			{
				"countryName": "Philippines",
				"name"       : "Western Visayas",
				"state_code" : "06"
			},
			{
				"countryName": "Philippines",
				"name"       : "Zambales",
				"state_code" : "ZMB"
			},
			{
				"countryName": "Philippines",
				"name"       : "Zamboanga del Norte",
				"state_code" : "ZAN"
			},
			{
				"countryName": "Philippines",
				"name"       : "Zamboanga del Sur",
				"state_code" : "ZAS"
			},
			{
				"countryName": "Philippines",
				"name"       : "Zamboanga Peninsula",
				"state_code" : "09"
			},
			{
				"countryName": "Philippines",
				"name"       : "Zamboanga Sibugay",
				"state_code" : "ZSI"
			}
		],
		"PN": [],
		"PL": [
			{
				"countryName": "Poland",
				"name"       : "Greater Poland Voivodeship",
				"state_code" : "WP"
			},
			{
				"countryName": "Poland",
				"name"       : "Kuyavian-Pomeranian Voivodeship",
				"state_code" : "KP"
			},
			{
				"countryName": "Poland",
				"name"       : "Lesser Poland Voivodeship",
				"state_code" : "MA"
			},
			{
				"countryName": "Poland",
				"name"       : "Lower Silesian Voivodeship",
				"state_code" : "DS"
			},
			{
				"countryName": "Poland",
				"name"       : "Lublin Voivodeship",
				"state_code" : "LU"
			},
			{
				"countryName": "Poland",
				"name"       : "Lubusz Voivodeship",
				"state_code" : "LB"
			},
			{
				"countryName": "Poland",
				"name"       : "Łódź Voivodeship",
				"state_code" : "LD"
			},
			{
				"countryName": "Poland",
				"name"       : "Masovian Voivodeship",
				"state_code" : "MZ"
			},
			{
				"countryName": "Poland",
				"name"       : "Opole Voivodeship",
				"state_code" : "OP"
			},
			{
				"countryName": "Poland",
				"name"       : "Podkarpackie Voivodeship",
				"state_code" : "PK"
			},
			{
				"countryName": "Poland",
				"name"       : "Podlaskie Voivodeship",
				"state_code" : "PD"
			},
			{
				"countryName": "Poland",
				"name"       : "Pomeranian Voivodeship",
				"state_code" : "PM"
			},
			{
				"countryName": "Poland",
				"name"       : "Silesian Voivodeship",
				"state_code" : "SL"
			},
			{
				"countryName": "Poland",
				"name"       : "Świętokrzyskie Voivodeship",
				"state_code" : "SK"
			},
			{
				"countryName": "Poland",
				"name"       : "Warmian-Masurian Voivodeship",
				"state_code" : "WN"
			},
			{
				"countryName": "Poland",
				"name"       : "West Pomeranian Voivodeship",
				"state_code" : "ZP"
			}
		],
		"PT": [
			{
				"countryName": "Portugal",
				"name"       : "Açores",
				"state_code" : "20"
			},
			{
				"countryName": "Portugal",
				"name"       : "Aveiro",
				"state_code" : "01"
			},
			{
				"countryName": "Portugal",
				"name"       : "Beja",
				"state_code" : "02"
			},
			{
				"countryName": "Portugal",
				"name"       : "Braga",
				"state_code" : "03"
			},
			{
				"countryName": "Portugal",
				"name"       : "Bragança",
				"state_code" : "04"
			},
			{
				"countryName": "Portugal",
				"name"       : "Castelo Branco",
				"state_code" : "05"
			},
			{
				"countryName": "Portugal",
				"name"       : "Coimbra",
				"state_code" : "06"
			},
			{
				"countryName": "Portugal",
				"name"       : "Évora",
				"state_code" : "07"
			},
			{
				"countryName": "Portugal",
				"name"       : "Faro",
				"state_code" : "08"
			},
			{
				"countryName": "Portugal",
				"name"       : "Guarda",
				"state_code" : "09"
			},
			{
				"countryName": "Portugal",
				"name"       : "Leiria",
				"state_code" : "10"
			},
			{
				"countryName": "Portugal",
				"name"       : "Lisbon",
				"state_code" : "11"
			},
			{
				"countryName": "Portugal",
				"name"       : "Madeira",
				"state_code" : "30"
			},
			{
				"countryName": "Portugal",
				"name"       : "Portalegre",
				"state_code" : "12"
			},
			{
				"countryName": "Portugal",
				"name"       : "Porto",
				"state_code" : "13"
			},
			{
				"countryName": "Portugal",
				"name"       : "Santarém",
				"state_code" : "14"
			},
			{
				"countryName": "Portugal",
				"name"       : "Setúbal",
				"state_code" : "15"
			},
			{
				"countryName": "Portugal",
				"name"       : "Viana do Castelo",
				"state_code" : "16"
			},
			{
				"countryName": "Portugal",
				"name"       : "Vila Real",
				"state_code" : "17"
			},
			{
				"countryName": "Portugal",
				"name"       : "Viseu",
				"state_code" : "18"
			}
		],
		"PR": [
			{
				"countryName": "Puerto Rico",
				"name"       : "Arecibo",
				"state_code" : "AR"
			},
			{
				"countryName": "Puerto Rico",
				"name"       : "Bayamon",
				"state_code" : "BY"
			},
			{
				"countryName": "Puerto Rico",
				"name"       : "Caguas",
				"state_code" : "CG"
			},
			{
				"countryName": "Puerto Rico",
				"name"       : "Carolina",
				"state_code" : "CL"
			},
			{
				"countryName": "Puerto Rico",
				"name"       : "Guaynabo",
				"state_code" : "GN"
			},
			{
				"countryName": "Puerto Rico",
				"name"       : "Mayagüez",
				"state_code" : "MG"
			},
			{
				"countryName": "Puerto Rico",
				"name"       : "Ponce",
				"state_code" : "PO"
			},
			{
				"countryName": "Puerto Rico",
				"name"       : "San Juan",
				"state_code" : "SJ"
			},
			{
				"countryName": "Puerto Rico",
				"name"       : "Toa Baja",
				"state_code" : "TB"
			},
			{
				"countryName": "Puerto Rico",
				"name"       : "Trujillo Alto",
				"state_code" : "TA"
			}
		],
		"QA": [
			{
				"countryName": "Qatar",
				"name"       : "Al Daayen",
				"state_code" : "ZA"
			},
			{
				"countryName": "Qatar",
				"name"       : "Al Khor",
				"state_code" : "KH"
			},
			{
				"countryName": "Qatar",
				"name"       : "Al Rayyan Municipality",
				"state_code" : "RA"
			},
			{
				"countryName": "Qatar",
				"name"       : "Al Wakrah",
				"state_code" : "WA"
			},
			{
				"countryName": "Qatar",
				"name"       : "Al-Shahaniya",
				"state_code" : "SH"
			},
			{
				"countryName": "Qatar",
				"name"       : "Doha",
				"state_code" : "DA"
			},
			{
				"countryName": "Qatar",
				"name"       : "Madinat ash Shamal",
				"state_code" : "MS"
			},
			{
				"countryName": "Qatar",
				"name"       : "Umm Salal Municipality",
				"state_code" : "US"
			}
		],
		"RE": [],
		"RO": [
			{
				"countryName": "Romania",
				"name"       : "Alba",
				"state_code" : "AB"
			},
			{
				"countryName": "Romania",
				"name"       : "Arad County",
				"state_code" : "AR"
			},
			{
				"countryName": "Romania",
				"name"       : "Arges",
				"state_code" : "AG"
			},
			{
				"countryName": "Romania",
				"name"       : "Bacău County",
				"state_code" : "BC"
			},
			{
				"countryName": "Romania",
				"name"       : "Bihor County",
				"state_code" : "BH"
			},
			{
				"countryName": "Romania",
				"name"       : "Bistrița-Năsăud County",
				"state_code" : "BN"
			},
			{
				"countryName": "Romania",
				"name"       : "Botoșani County",
				"state_code" : "BT"
			},
			{
				"countryName": "Romania",
				"name"       : "Braila",
				"state_code" : "BR"
			},
			{
				"countryName": "Romania",
				"name"       : "Brașov County",
				"state_code" : "BV"
			},
			{
				"countryName": "Romania",
				"name"       : "Bucharest",
				"state_code" : "B"
			},
			{
				"countryName": "Romania",
				"name"       : "Buzău County",
				"state_code" : "BZ"
			},
			{
				"countryName": "Romania",
				"name"       : "Călărași County",
				"state_code" : "CL"
			},
			{
				"countryName": "Romania",
				"name"       : "Caraș-Severin County",
				"state_code" : "CS"
			},
			{
				"countryName": "Romania",
				"name"       : "Cluj County",
				"state_code" : "CJ"
			},
			{
				"countryName": "Romania",
				"name"       : "Constanța County",
				"state_code" : "CT"
			},
			{
				"countryName": "Romania",
				"name"       : "Covasna County",
				"state_code" : "CV"
			},
			{
				"countryName": "Romania",
				"name"       : "Dâmbovița County",
				"state_code" : "DB"
			},
			{
				"countryName": "Romania",
				"name"       : "Dolj County",
				"state_code" : "DJ"
			},
			{
				"countryName": "Romania",
				"name"       : "Galați County",
				"state_code" : "GL"
			},
			{
				"countryName": "Romania",
				"name"       : "Giurgiu County",
				"state_code" : "GR"
			},
			{
				"countryName": "Romania",
				"name"       : "Gorj County",
				"state_code" : "GJ"
			},
			{
				"countryName": "Romania",
				"name"       : "Harghita County",
				"state_code" : "HR"
			},
			{
				"countryName": "Romania",
				"name"       : "Hunedoara County",
				"state_code" : "HD"
			},
			{
				"countryName": "Romania",
				"name"       : "Ialomița County",
				"state_code" : "IL"
			},
			{
				"countryName": "Romania",
				"name"       : "Iași County",
				"state_code" : "IS"
			},
			{
				"countryName": "Romania",
				"name"       : "Ilfov County",
				"state_code" : "IF"
			},
			{
				"countryName": "Romania",
				"name"       : "Maramureș County",
				"state_code" : "MM"
			},
			{
				"countryName": "Romania",
				"name"       : "Mehedinți County",
				"state_code" : "MH"
			},
			{
				"countryName": "Romania",
				"name"       : "Mureș County",
				"state_code" : "MS"
			},
			{
				"countryName": "Romania",
				"name"       : "Neamț County",
				"state_code" : "NT"
			},
			{
				"countryName": "Romania",
				"name"       : "Olt County",
				"state_code" : "OT"
			},
			{
				"countryName": "Romania",
				"name"       : "Prahova County",
				"state_code" : "PH"
			},
			{
				"countryName": "Romania",
				"name"       : "Sălaj County",
				"state_code" : "SJ"
			},
			{
				"countryName": "Romania",
				"name"       : "Satu Mare County",
				"state_code" : "SM"
			},
			{
				"countryName": "Romania",
				"name"       : "Sibiu County",
				"state_code" : "SB"
			},
			{
				"countryName": "Romania",
				"name"       : "Suceava County",
				"state_code" : "SV"
			},
			{
				"countryName": "Romania",
				"name"       : "Teleorman County",
				"state_code" : "TR"
			},
			{
				"countryName": "Romania",
				"name"       : "Timiș County",
				"state_code" : "TM"
			},
			{
				"countryName": "Romania",
				"name"       : "Tulcea County",
				"state_code" : "TL"
			},
			{
				"countryName": "Romania",
				"name"       : "Vâlcea County",
				"state_code" : "VL"
			},
			{
				"countryName": "Romania",
				"name"       : "Vaslui County",
				"state_code" : "VS"
			},
			{
				"countryName": "Romania",
				"name"       : "Vrancea County",
				"state_code" : "VN"
			}
		],
		"RU": [
			{
				"countryName": "Russia",
				"name"       : "Altai Krai",
				"state_code" : "ALT"
			},
			{
				"countryName": "Russia",
				"name"       : "Altai Republic",
				"state_code" : "AL"
			},
			{
				"countryName": "Russia",
				"name"       : "Amur Oblast",
				"state_code" : "AMU"
			},
			{
				"countryName": "Russia",
				"name"       : "Arkhangelsk",
				"state_code" : "ARK"
			},
			{
				"countryName": "Russia",
				"name"       : "Astrakhan Oblast",
				"state_code" : "AST"
			},
			{
				"countryName": "Russia",
				"name"       : "Belgorod Oblast",
				"state_code" : "BEL"
			},
			{
				"countryName": "Russia",
				"name"       : "Bryansk Oblast",
				"state_code" : "BRY"
			},
			{
				"countryName": "Russia",
				"name"       : "Chechen Republic",
				"state_code" : "CE"
			},
			{
				"countryName": "Russia",
				"name"       : "Chelyabinsk Oblast",
				"state_code" : "CHE"
			},
			{
				"countryName": "Russia",
				"name"       : "Chukotka Autonomous Okrug",
				"state_code" : "CHU"
			},
			{
				"countryName": "Russia",
				"name"       : "Chuvash Republic",
				"state_code" : "CU"
			},
			{
				"countryName": "Russia",
				"name"       : "Irkutsk",
				"state_code" : "IRK"
			},
			{
				"countryName": "Russia",
				"name"       : "Ivanovo Oblast",
				"state_code" : "IVA"
			},
			{
				"countryName": "Russia",
				"name"       : "Jewish Autonomous Oblast",
				"state_code" : "YEV"
			},
			{
				"countryName": "Russia",
				"name"       : "Kabardino-Balkar Republic",
				"state_code" : "KB"
			},
			{
				"countryName": "Russia",
				"name"       : "Kaliningrad",
				"state_code" : "KGD"
			},
			{
				"countryName": "Russia",
				"name"       : "Kaluga Oblast",
				"state_code" : "KLU"
			},
			{
				"countryName": "Russia",
				"name"       : "Kamchatka Krai",
				"state_code" : "KAM"
			},
			{
				"countryName": "Russia",
				"name"       : "Karachay-Cherkess Republic",
				"state_code" : "KC"
			},
			{
				"countryName": "Russia",
				"name"       : "Kemerovo Oblast",
				"state_code" : "KEM"
			},
			{
				"countryName": "Russia",
				"name"       : "Khabarovsk Krai",
				"state_code" : "KHA"
			},
			{
				"countryName": "Russia",
				"name"       : "Khanty-Mansi Autonomous Okrug",
				"state_code" : "KHM"
			},
			{
				"countryName": "Russia",
				"name"       : "Kirov Oblast",
				"state_code" : "KIR"
			},
			{
				"countryName": "Russia",
				"name"       : "Komi Republic",
				"state_code" : "KO"
			},
			{
				"countryName": "Russia",
				"name"       : "Kostroma Oblast",
				"state_code" : "KOS"
			},
			{
				"countryName": "Russia",
				"name"       : "Krasnodar Krai",
				"state_code" : "KDA"
			},
			{
				"countryName": "Russia",
				"name"       : "Krasnoyarsk Krai",
				"state_code" : "KYA"
			},
			{
				"countryName": "Russia",
				"name"       : "Kurgan Oblast",
				"state_code" : "KGN"
			},
			{
				"countryName": "Russia",
				"name"       : "Kursk Oblast",
				"state_code" : "KRS"
			},
			{
				"countryName": "Russia",
				"name"       : "Leningrad Oblast",
				"state_code" : "LEN"
			},
			{
				"countryName": "Russia",
				"name"       : "Lipetsk Oblast",
				"state_code" : "LIP"
			},
			{
				"countryName": "Russia",
				"name"       : "Magadan Oblast",
				"state_code" : "MAG"
			},
			{
				"countryName": "Russia",
				"name"       : "Mari El Republic",
				"state_code" : "ME"
			},
			{
				"countryName": "Russia",
				"name"       : "Moscow",
				"state_code" : "MOW"
			},
			{
				"countryName": "Russia",
				"name"       : "Moscow Oblast",
				"state_code" : "MOS"
			},
			{
				"countryName": "Russia",
				"name"       : "Murmansk Oblast",
				"state_code" : "MUR"
			},
			{
				"countryName": "Russia",
				"name"       : "Nenets Autonomous Okrug",
				"state_code" : "NEN"
			},
			{
				"countryName": "Russia",
				"name"       : "Nizhny Novgorod Oblast",
				"state_code" : "NIZ"
			},
			{
				"countryName": "Russia",
				"name"       : "Novgorod Oblast",
				"state_code" : "NGR"
			},
			{
				"countryName": "Russia",
				"name"       : "Novosibirsk",
				"state_code" : "NVS"
			},
			{
				"countryName": "Russia",
				"name"       : "Omsk Oblast",
				"state_code" : "OMS"
			},
			{
				"countryName": "Russia",
				"name"       : "Orenburg Oblast",
				"state_code" : "ORE"
			},
			{
				"countryName": "Russia",
				"name"       : "Oryol Oblast",
				"state_code" : "ORL"
			},
			{
				"countryName": "Russia",
				"name"       : "Penza Oblast",
				"state_code" : "PNZ"
			},
			{
				"countryName": "Russia",
				"name"       : "Perm Krai",
				"state_code" : "PER"
			},
			{
				"countryName": "Russia",
				"name"       : "Primorsky Krai",
				"state_code" : "PRI"
			},
			{
				"countryName": "Russia",
				"name"       : "Pskov Oblast",
				"state_code" : "PSK"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Adygea",
				"state_code" : "AD"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Bashkortostan",
				"state_code" : "BA"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Buryatia",
				"state_code" : "BU"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Dagestan",
				"state_code" : "DA"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Ingushetia",
				"state_code" : "IN"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Kalmykia",
				"state_code" : "KL"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Karelia",
				"state_code" : "KR"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Khakassia",
				"state_code" : "KK"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Mordovia",
				"state_code" : "MO"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of North Ossetia-Alania",
				"state_code" : "SE"
			},
			{
				"countryName": "Russia",
				"name"       : "Republic of Tatarstan",
				"state_code" : "TA"
			},
			{
				"countryName": "Russia",
				"name"       : "Rostov Oblast",
				"state_code" : "ROS"
			},
			{
				"countryName": "Russia",
				"name"       : "Ryazan Oblast",
				"state_code" : "RYA"
			},
			{
				"countryName": "Russia",
				"name"       : "Saint Petersburg",
				"state_code" : "SPE"
			},
			{
				"countryName": "Russia",
				"name"       : "Sakha Republic",
				"state_code" : "SA"
			},
			{
				"countryName": "Russia",
				"name"       : "Sakhalin",
				"state_code" : "SAK"
			},
			{
				"countryName": "Russia",
				"name"       : "Samara Oblast",
				"state_code" : "SAM"
			},
			{
				"countryName": "Russia",
				"name"       : "Saratov Oblast",
				"state_code" : "SAR"
			},
			{
				"countryName": "Russia",
				"name"       : "Sevastopol",
				"state_code" : "UA-40"
			},
			{
				"countryName": "Russia",
				"name"       : "Smolensk Oblast",
				"state_code" : "SMO"
			},
			{
				"countryName": "Russia",
				"name"       : "Stavropol Krai",
				"state_code" : "STA"
			},
			{
				"countryName": "Russia",
				"name"       : "Sverdlovsk",
				"state_code" : "SVE"
			},
			{
				"countryName": "Russia",
				"name"       : "Tambov Oblast",
				"state_code" : "TAM"
			},
			{
				"countryName": "Russia",
				"name"       : "Tomsk Oblast",
				"state_code" : "TOM"
			},
			{
				"countryName": "Russia",
				"name"       : "Tula Oblast",
				"state_code" : "TUL"
			},
			{
				"countryName": "Russia",
				"name"       : "Tuva Republic",
				"state_code" : "TY"
			},
			{
				"countryName": "Russia",
				"name"       : "Tver Oblast",
				"state_code" : "TVE"
			},
			{
				"countryName": "Russia",
				"name"       : "Tyumen Oblast",
				"state_code" : "TYU"
			},
			{
				"countryName": "Russia",
				"name"       : "Udmurt Republic",
				"state_code" : "UD"
			},
			{
				"countryName": "Russia",
				"name"       : "Ulyanovsk Oblast",
				"state_code" : "ULY"
			},
			{
				"countryName": "Russia",
				"name"       : "Vladimir Oblast",
				"state_code" : "VLA"
			},
			{
				"countryName": "Russia",
				"name"       : "Volgograd Oblast",
				"state_code" : "VGG"
			},
			{
				"countryName": "Russia",
				"name"       : "Vologda Oblast",
				"state_code" : "VLG"
			},
			{
				"countryName": "Russia",
				"name"       : "Voronezh Oblast",
				"state_code" : "VOR"
			},
			{
				"countryName": "Russia",
				"name"       : "Yamalo-Nenets Autonomous Okrug",
				"state_code" : "YAN"
			},
			{
				"countryName": "Russia",
				"name"       : "Yaroslavl Oblast",
				"state_code" : "YAR"
			},
			{
				"countryName": "Russia",
				"name"       : "Zabaykalsky Krai",
				"state_code" : "ZAB"
			}
		],
		"RW": [
			{
				"countryName": "Rwanda",
				"name"       : "Eastern Province",
				"state_code" : "02"
			},
			{
				"countryName": "Rwanda",
				"name"       : "Kigali district",
				"state_code" : "01"
			},
			{
				"countryName": "Rwanda",
				"name"       : "Northern Province",
				"state_code" : "03"
			},
			{
				"countryName": "Rwanda",
				"name"       : "Southern Province",
				"state_code" : "05"
			},
			{
				"countryName": "Rwanda",
				"name"       : "Western Province",
				"state_code" : "04"
			}
		],
		"SH": [],
		"KN": [
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Christ Church Nichola Town Parish",
				"state_code" : "01"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Nevis",
				"state_code" : "N"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint Anne Sandy Point Parish",
				"state_code" : "02"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint George Gingerland Parish",
				"state_code" : "04"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint James Windward Parish",
				"state_code" : "05"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint John Capisterre Parish",
				"state_code" : "06"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint John Figtree Parish",
				"state_code" : "07"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint Kitts",
				"state_code" : "K"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint Mary Cayon Parish",
				"state_code" : "08"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint Paul Capisterre Parish",
				"state_code" : "09"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint Paul Charlestown Parish",
				"state_code" : "10"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint Peter Basseterre Parish",
				"state_code" : "11"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint Thomas Lowland Parish",
				"state_code" : "12"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Saint Thomas Middle Island Parish",
				"state_code" : "13"
			},
			{
				"countryName": "Saint Kitts And Nevis",
				"name"       : "Trinity Palmetto Point Parish",
				"state_code" : "15"
			}
		],
		"LC": [
			{
				"countryName": "Saint Lucia",
				"name"       : "Anse la Raye Quarter",
				"state_code" : "01"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Canaries",
				"state_code" : "12"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Castries Quarter",
				"state_code" : "02"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Choiseul Quarter",
				"state_code" : "03"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Dauphin Quarter",
				"state_code" : "04"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Dennery Quarter",
				"state_code" : "05"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Gros Islet Quarter",
				"state_code" : "06"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Laborie Quarter",
				"state_code" : "07"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Micoud Quarter",
				"state_code" : "08"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Praslin Quarter",
				"state_code" : "09"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Soufrière Quarter",
				"state_code" : "10"
			},
			{
				"countryName": "Saint Lucia",
				"name"       : "Vieux Fort Quarter",
				"state_code" : "11"
			}
		],
		"PM": [],
		"VC": [
			{
				"countryName": "Saint Vincent And The Grenadines",
				"name"       : "Charlotte Parish",
				"state_code" : "01"
			},
			{
				"countryName": "Saint Vincent And The Grenadines",
				"name"       : "Grenadines Parish",
				"state_code" : "06"
			},
			{
				"countryName": "Saint Vincent And The Grenadines",
				"name"       : "Saint Andrew Parish",
				"state_code" : "02"
			},
			{
				"countryName": "Saint Vincent And The Grenadines",
				"name"       : "Saint David Parish",
				"state_code" : "03"
			},
			{
				"countryName": "Saint Vincent And The Grenadines",
				"name"       : "Saint George Parish",
				"state_code" : "04"
			},
			{
				"countryName": "Saint Vincent And The Grenadines",
				"name"       : "Saint Patrick Parish",
				"state_code" : "05"
			}
		],
		"BL": [],
		"MF": [],
		"WS": [
			{
				"countryName": "Samoa",
				"name"       : "A'ana",
				"state_code" : "AA"
			},
			{
				"countryName": "Samoa",
				"name"       : "Aiga-i-le-Tai",
				"state_code" : "AL"
			},
			{
				"countryName": "Samoa",
				"name"       : "Atua",
				"state_code" : "AT"
			},
			{
				"countryName": "Samoa",
				"name"       : "Fa'asaleleaga",
				"state_code" : "FA"
			},
			{
				"countryName": "Samoa",
				"name"       : "Gaga'emauga",
				"state_code" : "GE"
			},
			{
				"countryName": "Samoa",
				"name"       : "Gaga'ifomauga",
				"state_code" : "GI"
			},
			{
				"countryName": "Samoa",
				"name"       : "Palauli",
				"state_code" : "PA"
			},
			{
				"countryName": "Samoa",
				"name"       : "Satupa'itea",
				"state_code" : "SA"
			},
			{
				"countryName": "Samoa",
				"name"       : "Tuamasaga",
				"state_code" : "TU"
			},
			{
				"countryName": "Samoa",
				"name"       : "Va'a-o-Fonoti",
				"state_code" : "VF"
			},
			{
				"countryName": "Samoa",
				"name"       : "Vaisigano",
				"state_code" : "VS"
			}
		],
		"SM": [
			{
				"countryName": "San Marino",
				"name"       : "Acquaviva",
				"state_code" : "01"
			},
			{
				"countryName": "San Marino",
				"name"       : "Borgo Maggiore",
				"state_code" : "06"
			},
			{
				"countryName": "San Marino",
				"name"       : "Chiesanuova",
				"state_code" : "02"
			},
			{
				"countryName": "San Marino",
				"name"       : "Domagnano",
				"state_code" : "03"
			},
			{
				"countryName": "San Marino",
				"name"       : "Faetano",
				"state_code" : "04"
			},
			{
				"countryName": "San Marino",
				"name"       : "Fiorentino",
				"state_code" : "05"
			},
			{
				"countryName": "San Marino",
				"name"       : "Montegiardino",
				"state_code" : "08"
			},
			{
				"countryName": "San Marino",
				"name"       : "San Marino",
				"state_code" : "07"
			},
			{
				"countryName": "San Marino",
				"name"       : "Serravalle",
				"state_code" : "09"
			}
		],
		"ST": [
			{
				"countryName": "Sao Tome and Principe",
				"name"       : "Príncipe Province",
				"state_code" : "P"
			},
			{
				"countryName": "Sao Tome and Principe",
				"name"       : "São Tomé Province",
				"state_code" : "S"
			}
		],
		"SA": [
			{
				"countryName": "Saudi Arabia",
				"name"       : "'Asir",
				"state_code" : "14"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Al Bahah",
				"state_code" : "11"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Al Jawf",
				"state_code" : "12"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Al Madinah",
				"state_code" : "03"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Al-Qassim",
				"state_code" : "05"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Eastern Province",
				"state_code" : "04"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Ha'il",
				"state_code" : "06"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Jizan",
				"state_code" : "09"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Makkah",
				"state_code" : "02"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Najran",
				"state_code" : "10"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Northern Borders",
				"state_code" : "08"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Riyadh",
				"state_code" : "01"
			},
			{
				"countryName": "Saudi Arabia",
				"name"       : "Tabuk",
				"state_code" : "07"
			}
		],
		"SN": [
			{
				"countryName": "Senegal",
				"name"       : "Dakar",
				"state_code" : "DK"
			},
			{
				"countryName": "Senegal",
				"name"       : "Diourbel Region",
				"state_code" : "DB"
			},
			{
				"countryName": "Senegal",
				"name"       : "Fatick",
				"state_code" : "FK"
			},
			{
				"countryName": "Senegal",
				"name"       : "Kaffrine",
				"state_code" : "KA"
			},
			{
				"countryName": "Senegal",
				"name"       : "Kaolack",
				"state_code" : "KL"
			},
			{
				"countryName": "Senegal",
				"name"       : "Kédougou",
				"state_code" : "KE"
			},
			{
				"countryName": "Senegal",
				"name"       : "Kolda",
				"state_code" : "KD"
			},
			{
				"countryName": "Senegal",
				"name"       : "Louga",
				"state_code" : "LG"
			},
			{
				"countryName": "Senegal",
				"name"       : "Matam",
				"state_code" : "MT"
			},
			{
				"countryName": "Senegal",
				"name"       : "Saint-Louis",
				"state_code" : "SL"
			},
			{
				"countryName": "Senegal",
				"name"       : "Sédhiou",
				"state_code" : "SE"
			},
			{
				"countryName": "Senegal",
				"name"       : "Tambacounda Region",
				"state_code" : "TC"
			},
			{
				"countryName": "Senegal",
				"name"       : "Thiès Region",
				"state_code" : "TH"
			},
			{
				"countryName": "Senegal",
				"name"       : "Ziguinchor",
				"state_code" : "ZG"
			}
		],
		"RS": [
			{
				"countryName": "Serbia",
				"name"       : "Belgrade",
				"state_code" : "00"
			},
			{
				"countryName": "Serbia",
				"name"       : "Bor District",
				"state_code" : "14"
			},
			{
				"countryName": "Serbia",
				"name"       : "Braničevo District",
				"state_code" : "11"
			},
			{
				"countryName": "Serbia",
				"name"       : "Central Banat District",
				"state_code" : "02"
			},
			{
				"countryName": "Serbia",
				"name"       : "Jablanica District",
				"state_code" : "23"
			},
			{
				"countryName": "Serbia",
				"name"       : "Kolubara District",
				"state_code" : "09"
			},
			{
				"countryName": "Serbia",
				"name"       : "Mačva District",
				"state_code" : "08"
			},
			{
				"countryName": "Serbia",
				"name"       : "Moravica District",
				"state_code" : "17"
			},
			{
				"countryName": "Serbia",
				"name"       : "Nišava District",
				"state_code" : "20"
			},
			{
				"countryName": "Serbia",
				"name"       : "North Bačka District",
				"state_code" : "01"
			},
			{
				"countryName": "Serbia",
				"name"       : "North Banat District",
				"state_code" : "03"
			},
			{
				"countryName": "Serbia",
				"name"       : "Pčinja District",
				"state_code" : "24"
			},
			{
				"countryName": "Serbia",
				"name"       : "Pirot District",
				"state_code" : "22"
			},
			{
				"countryName": "Serbia",
				"name"       : "Podunavlje District",
				"state_code" : "10"
			},
			{
				"countryName": "Serbia",
				"name"       : "Pomoravlje District",
				"state_code" : "13"
			},
			{
				"countryName": "Serbia",
				"name"       : "Rasina District",
				"state_code" : "19"
			},
			{
				"countryName": "Serbia",
				"name"       : "Raška District",
				"state_code" : "18"
			},
			{
				"countryName": "Serbia",
				"name"       : "South Bačka District",
				"state_code" : "06"
			},
			{
				"countryName": "Serbia",
				"name"       : "South Banat District",
				"state_code" : "04"
			},
			{
				"countryName": "Serbia",
				"name"       : "Srem District",
				"state_code" : "07"
			},
			{
				"countryName": "Serbia",
				"name"       : "Šumadija District",
				"state_code" : "12"
			},
			{
				"countryName": "Serbia",
				"name"       : "Toplica District",
				"state_code" : "21"
			},
			{
				"countryName": "Serbia",
				"name"       : "Vojvodina",
				"state_code" : "VO"
			},
			{
				"countryName": "Serbia",
				"name"       : "West Bačka District",
				"state_code" : "05"
			},
			{
				"countryName": "Serbia",
				"name"       : "Zaječar District",
				"state_code" : "15"
			},
			{
				"countryName": "Serbia",
				"name"       : "Zlatibor District",
				"state_code" : "16"
			}
		],
		"SC": [
			{
				"countryName": "Seychelles",
				"name"       : "Anse Boileau",
				"state_code" : "02"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Anse Royale",
				"state_code" : "05"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Anse-aux-Pins",
				"state_code" : "01"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Au Cap",
				"state_code" : "04"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Baie Lazare",
				"state_code" : "06"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Baie Sainte Anne",
				"state_code" : "07"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Beau Vallon",
				"state_code" : "08"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Bel Air",
				"state_code" : "09"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Bel Ombre",
				"state_code" : "10"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Cascade",
				"state_code" : "11"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Glacis",
				"state_code" : "12"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Grand'Anse Mahé",
				"state_code" : "13"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Grand'Anse Praslin",
				"state_code" : "14"
			},
			{
				"countryName": "Seychelles",
				"name"       : "La Digue",
				"state_code" : "15"
			},
			{
				"countryName": "Seychelles",
				"name"       : "La Rivière Anglaise",
				"state_code" : "16"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Les Mamelles",
				"state_code" : "24"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Mont Buxton",
				"state_code" : "17"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Mont Fleuri",
				"state_code" : "18"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Plaisance",
				"state_code" : "19"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Pointe La Rue",
				"state_code" : "20"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Port Glaud",
				"state_code" : "21"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Roche Caiman",
				"state_code" : "25"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Saint Louis",
				"state_code" : "22"
			},
			{
				"countryName": "Seychelles",
				"name"       : "Takamaka",
				"state_code" : "23"
			}
		],
		"SL": [
			{
				"countryName": "Sierra Leone",
				"name"       : "Eastern Province",
				"state_code" : "E"
			},
			{
				"countryName": "Sierra Leone",
				"name"       : "Northern Province",
				"state_code" : "N"
			},
			{
				"countryName": "Sierra Leone",
				"name"       : "Southern Province",
				"state_code" : "S"
			},
			{
				"countryName": "Sierra Leone",
				"name"       : "Western Area",
				"state_code" : "W"
			}
		],
		"SG": [
			{
				"countryName": "Singapore",
				"name"       : "Central Singapore Community Development Council",
				"state_code" : "01"
			},
			{
				"countryName": "Singapore",
				"name"       : "North East Community Development Council",
				"state_code" : "02"
			},
			{
				"countryName": "Singapore",
				"name"       : "North West Community Development Council",
				"state_code" : "03"
			},
			{
				"countryName": "Singapore",
				"name"       : "South East Community Development Council",
				"state_code" : "04"
			},
			{
				"countryName": "Singapore",
				"name"       : "South West Community Development Council",
				"state_code" : "05"
			}
		],
		"SX": [],
		"SK": [
			{
				"countryName": "Slovakia",
				"name"       : "Banská Bystrica Region",
				"state_code" : "BC"
			},
			{
				"countryName": "Slovakia",
				"name"       : "Bratislava Region",
				"state_code" : "BL"
			},
			{
				"countryName": "Slovakia",
				"name"       : "Košice Region",
				"state_code" : "KI"
			},
			{
				"countryName": "Slovakia",
				"name"       : "Nitra Region",
				"state_code" : "NI"
			},
			{
				"countryName": "Slovakia",
				"name"       : "Prešov Region",
				"state_code" : "PV"
			},
			{
				"countryName": "Slovakia",
				"name"       : "Trenčín Region",
				"state_code" : "TC"
			},
			{
				"countryName": "Slovakia",
				"name"       : "Trnava Region",
				"state_code" : "TA"
			},
			{
				"countryName": "Slovakia",
				"name"       : "Žilina Region",
				"state_code" : "ZI"
			}
		],
		"SI": [
			{
				"countryName": "Slovenia",
				"name"       : "Ajdovščina Municipality",
				"state_code" : "001"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ankaran Municipality",
				"state_code" : "213"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Beltinci Municipality",
				"state_code" : "002"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Benedikt Municipality",
				"state_code" : "148"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Bistrica ob Sotli Municipality",
				"state_code" : "149"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Bled Municipality",
				"state_code" : "003"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Bloke Municipality",
				"state_code" : "150"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Bohinj Municipality",
				"state_code" : "004"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Borovnica Municipality",
				"state_code" : "005"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Bovec Municipality",
				"state_code" : "006"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Braslovče Municipality",
				"state_code" : "151"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Brda Municipality",
				"state_code" : "007"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Brežice Municipality",
				"state_code" : "009"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Brezovica Municipality",
				"state_code" : "008"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Cankova Municipality",
				"state_code" : "152"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Cerklje na Gorenjskem Municipality",
				"state_code" : "012"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Cerknica Municipality",
				"state_code" : "013"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Cerkno Municipality",
				"state_code" : "014"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Cerkvenjak Municipality",
				"state_code" : "153"
			},
			{
				"countryName": "Slovenia",
				"name"       : "City Municipality of Celje",
				"state_code" : "011"
			},
			{
				"countryName": "Slovenia",
				"name"       : "City Municipality of Novo Mesto",
				"state_code" : "085"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Črenšovci Municipality",
				"state_code" : "015"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Črna na Koroškem Municipality",
				"state_code" : "016"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Črnomelj Municipality",
				"state_code" : "017"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Destrnik Municipality",
				"state_code" : "018"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Divača Municipality",
				"state_code" : "019"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Dobje Municipality",
				"state_code" : "154"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Dobrepolje Municipality",
				"state_code" : "020"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Dobrna Municipality",
				"state_code" : "155"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Dobrova–Polhov Gradec Municipality",
				"state_code" : "021"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Dobrovnik Municipality",
				"state_code" : "156"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Dol pri Ljubljani Municipality",
				"state_code" : "022"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Dolenjske Toplice Municipality",
				"state_code" : "157"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Domžale Municipality",
				"state_code" : "023"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Dornava Municipality",
				"state_code" : "024"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Dravograd Municipality",
				"state_code" : "025"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Duplek Municipality",
				"state_code" : "026"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Gorenja Vas–Poljane Municipality",
				"state_code" : "027"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Gorišnica Municipality",
				"state_code" : "028"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Gorje Municipality",
				"state_code" : "207"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Gornja Radgona Municipality",
				"state_code" : "029"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Gornji Grad Municipality",
				"state_code" : "030"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Gornji Petrovci Municipality",
				"state_code" : "031"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Grad Municipality",
				"state_code" : "158"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Grosuplje Municipality",
				"state_code" : "032"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Hajdina Municipality",
				"state_code" : "159"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Hoče–Slivnica Municipality",
				"state_code" : "160"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Hodoš Municipality",
				"state_code" : "161"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Horjul Municipality",
				"state_code" : "162"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Hrastnik Municipality",
				"state_code" : "034"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Hrpelje–Kozina Municipality",
				"state_code" : "035"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Idrija Municipality",
				"state_code" : "036"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ig Municipality",
				"state_code" : "037"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ivančna Gorica Municipality",
				"state_code" : "039"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Izola Municipality",
				"state_code" : "040"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Jesenice Municipality",
				"state_code" : "041"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Jezersko Municipality",
				"state_code" : "163"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Juršinci Municipality",
				"state_code" : "042"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kamnik Municipality",
				"state_code" : "043"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kanal ob Soči Municipality",
				"state_code" : "044"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kidričevo Municipality",
				"state_code" : "045"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kobarid Municipality",
				"state_code" : "046"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kobilje Municipality",
				"state_code" : "047"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kočevje Municipality",
				"state_code" : "048"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Komen Municipality",
				"state_code" : "049"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Komenda Municipality",
				"state_code" : "164"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Koper City Municipality",
				"state_code" : "050"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kostanjevica na Krki Municipality",
				"state_code" : "197"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kostel Municipality",
				"state_code" : "165"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kozje Municipality",
				"state_code" : "051"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kranj City Municipality",
				"state_code" : "052"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kranjska Gora Municipality",
				"state_code" : "053"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Križevci Municipality",
				"state_code" : "166"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kungota",
				"state_code" : "055"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Kuzma Municipality",
				"state_code" : "056"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Laško Municipality",
				"state_code" : "057"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Lenart Municipality",
				"state_code" : "058"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Lendava Municipality",
				"state_code" : "059"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Litija Municipality",
				"state_code" : "060"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ljubljana City Municipality",
				"state_code" : "061"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ljubno Municipality",
				"state_code" : "062"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ljutomer Municipality",
				"state_code" : "063"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Log–Dragomer Municipality",
				"state_code" : "208"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Logatec Municipality",
				"state_code" : "064"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Loška Dolina Municipality",
				"state_code" : "065"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Loški Potok Municipality",
				"state_code" : "066"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Lovrenc na Pohorju Municipality",
				"state_code" : "167"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Luče Municipality",
				"state_code" : "067"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Lukovica Municipality",
				"state_code" : "068"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Majšperk Municipality",
				"state_code" : "069"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Makole Municipality",
				"state_code" : "198"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Maribor City Municipality",
				"state_code" : "070"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Markovci Municipality",
				"state_code" : "168"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Medvode Municipality",
				"state_code" : "071"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Mengeš Municipality",
				"state_code" : "072"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Metlika Municipality",
				"state_code" : "073"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Mežica Municipality",
				"state_code" : "074"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Miklavž na Dravskem Polju Municipality",
				"state_code" : "169"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Miren–Kostanjevica Municipality",
				"state_code" : "075"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Mirna Municipality",
				"state_code" : "212"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Mirna Peč Municipality",
				"state_code" : "170"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Mislinja Municipality",
				"state_code" : "076"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Mokronog–Trebelno Municipality",
				"state_code" : "199"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Moravče Municipality",
				"state_code" : "077"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Moravske Toplice Municipality",
				"state_code" : "078"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Mozirje Municipality",
				"state_code" : "079"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Municipality of Apače",
				"state_code" : "195"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Municipality of Cirkulane",
				"state_code" : "196"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Municipality of Ilirska Bistrica",
				"state_code" : "038"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Municipality of Krško",
				"state_code" : "054"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Municipality of Škofljica",
				"state_code" : "123"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Murska Sobota City Municipality",
				"state_code" : "080"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Muta Municipality",
				"state_code" : "081"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Naklo Municipality",
				"state_code" : "082"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Nazarje Municipality",
				"state_code" : "083"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Nova Gorica City Municipality",
				"state_code" : "084"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Odranci Municipality",
				"state_code" : "086"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Oplotnica",
				"state_code" : "171"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ormož Municipality",
				"state_code" : "087"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Osilnica Municipality",
				"state_code" : "088"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Pesnica Municipality",
				"state_code" : "089"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Piran Municipality",
				"state_code" : "090"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Pivka Municipality",
				"state_code" : "091"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Podčetrtek Municipality",
				"state_code" : "092"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Podlehnik Municipality",
				"state_code" : "172"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Podvelka Municipality",
				"state_code" : "093"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Poljčane Municipality",
				"state_code" : "200"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Polzela Municipality",
				"state_code" : "173"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Postojna Municipality",
				"state_code" : "094"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Prebold Municipality",
				"state_code" : "174"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Preddvor Municipality",
				"state_code" : "095"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Prevalje Municipality",
				"state_code" : "175"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ptuj City Municipality",
				"state_code" : "096"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Puconci Municipality",
				"state_code" : "097"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Rače–Fram Municipality",
				"state_code" : "098"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Radeče Municipality",
				"state_code" : "099"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Radenci Municipality",
				"state_code" : "100"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Radlje ob Dravi Municipality",
				"state_code" : "101"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Radovljica Municipality",
				"state_code" : "102"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ravne na Koroškem Municipality",
				"state_code" : "103"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Razkrižje Municipality",
				"state_code" : "176"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Rečica ob Savinji Municipality",
				"state_code" : "209"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Renče–Vogrsko Municipality",
				"state_code" : "201"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ribnica Municipality",
				"state_code" : "104"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ribnica na Pohorju Municipality",
				"state_code" : "177"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Rogaška Slatina Municipality",
				"state_code" : "106"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Rogašovci Municipality",
				"state_code" : "105"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Rogatec Municipality",
				"state_code" : "107"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Ruše Municipality",
				"state_code" : "108"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šalovci Municipality",
				"state_code" : "033"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Selnica ob Dravi Municipality",
				"state_code" : "178"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Semič Municipality",
				"state_code" : "109"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šempeter–Vrtojba Municipality",
				"state_code" : "183"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šenčur Municipality",
				"state_code" : "117"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šentilj Municipality",
				"state_code" : "118"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šentjernej Municipality",
				"state_code" : "119"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šentjur Municipality",
				"state_code" : "120"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šentrupert Municipality",
				"state_code" : "211"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Sevnica Municipality",
				"state_code" : "110"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Sežana Municipality",
				"state_code" : "111"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Škocjan Municipality",
				"state_code" : "121"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Škofja Loka Municipality",
				"state_code" : "122"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Slovenj Gradec City Municipality",
				"state_code" : "112"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Slovenska Bistrica Municipality",
				"state_code" : "113"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Slovenske Konjice Municipality",
				"state_code" : "114"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šmarje pri Jelšah Municipality",
				"state_code" : "124"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šmarješke Toplice Municipality",
				"state_code" : "206"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šmartno ob Paki Municipality",
				"state_code" : "125"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šmartno pri Litiji Municipality",
				"state_code" : "194"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Sodražica Municipality",
				"state_code" : "179"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Solčava Municipality",
				"state_code" : "180"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Šoštanj Municipality",
				"state_code" : "126"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Središče ob Dravi",
				"state_code" : "202"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Starše Municipality",
				"state_code" : "115"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Štore Municipality",
				"state_code" : "127"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Straža Municipality",
				"state_code" : "203"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Sveta Ana Municipality",
				"state_code" : "181"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Sveta Trojica v Slovenskih Goricah Municipality",
				"state_code" : "204"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Sveti Andraž v Slovenskih Goricah Municipality",
				"state_code" : "182"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Sveti Jurij ob Ščavnici Municipality",
				"state_code" : "116"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Sveti Jurij v Slovenskih Goricah Municipality",
				"state_code" : "210"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Sveti Tomaž Municipality",
				"state_code" : "205"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Tabor Municipality",
				"state_code" : "184"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Tišina Municipality",
				"state_code" : "010"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Tolmin Municipality",
				"state_code" : "128"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Trbovlje Municipality",
				"state_code" : "129"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Trebnje Municipality",
				"state_code" : "130"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Trnovska Vas Municipality",
				"state_code" : "185"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Tržič Municipality",
				"state_code" : "131"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Trzin Municipality",
				"state_code" : "186"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Turnišče Municipality",
				"state_code" : "132"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Velika Polana Municipality",
				"state_code" : "187"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Velike Lašče Municipality",
				"state_code" : "134"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Veržej Municipality",
				"state_code" : "188"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Videm Municipality",
				"state_code" : "135"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Vipava Municipality",
				"state_code" : "136"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Vitanje Municipality",
				"state_code" : "137"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Vodice Municipality",
				"state_code" : "138"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Vojnik Municipality",
				"state_code" : "139"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Vransko Municipality",
				"state_code" : "189"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Vrhnika Municipality",
				"state_code" : "140"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Vuzenica Municipality",
				"state_code" : "141"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Zagorje ob Savi Municipality",
				"state_code" : "142"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Žalec Municipality",
				"state_code" : "190"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Zavrč Municipality",
				"state_code" : "143"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Železniki Municipality",
				"state_code" : "146"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Žetale Municipality",
				"state_code" : "191"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Žiri Municipality",
				"state_code" : "147"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Žirovnica Municipality",
				"state_code" : "192"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Zreče Municipality",
				"state_code" : "144"
			},
			{
				"countryName": "Slovenia",
				"name"       : "Žužemberk Municipality",
				"state_code" : "193"
			}
		],
		"SB": [
			{
				"countryName": "Solomon Islands",
				"name"       : "Central Province",
				"state_code" : "CE"
			},
			{
				"countryName": "Solomon Islands",
				"name"       : "Choiseul Province",
				"state_code" : "CH"
			},
			{
				"countryName": "Solomon Islands",
				"name"       : "Guadalcanal Province",
				"state_code" : "GU"
			},
			{
				"countryName": "Solomon Islands",
				"name"       : "Honiara",
				"state_code" : "CT"
			},
			{
				"countryName": "Solomon Islands",
				"name"       : "Isabel Province",
				"state_code" : "IS"
			},
			{
				"countryName": "Solomon Islands",
				"name"       : "Makira-Ulawa Province",
				"state_code" : "MK"
			},
			{
				"countryName": "Solomon Islands",
				"name"       : "Malaita Province",
				"state_code" : "ML"
			},
			{
				"countryName": "Solomon Islands",
				"name"       : "Rennell and Bellona Province",
				"state_code" : "RB"
			},
			{
				"countryName": "Solomon Islands",
				"name"       : "Temotu Province",
				"state_code" : "TE"
			},
			{
				"countryName": "Solomon Islands",
				"name"       : "Western Province",
				"state_code" : "WE"
			}
		],
		"SO": [
			{
				"countryName": "Somalia",
				"name"       : "Awdal Region",
				"state_code" : "AW"
			},
			{
				"countryName": "Somalia",
				"name"       : "Bakool",
				"state_code" : "BK"
			},
			{
				"countryName": "Somalia",
				"name"       : "Banaadir",
				"state_code" : "BN"
			},
			{
				"countryName": "Somalia",
				"name"       : "Bari",
				"state_code" : "BR"
			},
			{
				"countryName": "Somalia",
				"name"       : "Bay",
				"state_code" : "BY"
			},
			{
				"countryName": "Somalia",
				"name"       : "Galguduud",
				"state_code" : "GA"
			},
			{
				"countryName": "Somalia",
				"name"       : "Gedo",
				"state_code" : "GE"
			},
			{
				"countryName": "Somalia",
				"name"       : "Hiran",
				"state_code" : "HI"
			},
			{
				"countryName": "Somalia",
				"name"       : "Lower Juba",
				"state_code" : "JH"
			},
			{
				"countryName": "Somalia",
				"name"       : "Lower Shebelle",
				"state_code" : "SH"
			},
			{
				"countryName": "Somalia",
				"name"       : "Middle Juba",
				"state_code" : "JD"
			},
			{
				"countryName": "Somalia",
				"name"       : "Middle Shebelle",
				"state_code" : "SD"
			},
			{
				"countryName": "Somalia",
				"name"       : "Mudug",
				"state_code" : "MU"
			},
			{
				"countryName": "Somalia",
				"name"       : "Nugal",
				"state_code" : "NU"
			},
			{
				"countryName": "Somalia",
				"name"       : "Sanaag Region",
				"state_code" : "SA"
			},
			{
				"countryName": "Somalia",
				"name"       : "Togdheer Region",
				"state_code" : "TO"
			}
		],
		"ZA": [
			{
				"countryName": "South Africa",
				"name"       : "Eastern Cape",
				"state_code" : "EC"
			},
			{
				"countryName": "South Africa",
				"name"       : "Free State",
				"state_code" : "FS"
			},
			{
				"countryName": "South Africa",
				"name"       : "Gauteng",
				"state_code" : "GP"
			},
			{
				"countryName": "South Africa",
				"name"       : "KwaZulu-Natal",
				"state_code" : "KZN"
			},
			{
				"countryName": "South Africa",
				"name"       : "Limpopo",
				"state_code" : "LP"
			},
			{
				"countryName": "South Africa",
				"name"       : "Mpumalanga",
				"state_code" : "MP"
			},
			{
				"countryName": "South Africa",
				"name"       : "North West",
				"state_code" : "NW"
			},
			{
				"countryName": "South Africa",
				"name"       : "Northern Cape",
				"state_code" : "NC"
			},
			{
				"countryName": "South Africa",
				"name"       : "Western Cape",
				"state_code" : "WC"
			}
		],
		"GS": [],
		"KR": [
			{
				"countryName": "South Korea",
				"name"       : "Busan",
				"state_code" : "26"
			},
			{
				"countryName": "South Korea",
				"name"       : "Daegu",
				"state_code" : "27"
			},
			{
				"countryName": "South Korea",
				"name"       : "Daejeon",
				"state_code" : "30"
			},
			{
				"countryName": "South Korea",
				"name"       : "Gangwon Province",
				"state_code" : "42"
			},
			{
				"countryName": "South Korea",
				"name"       : "Gwangju",
				"state_code" : "29"
			},
			{
				"countryName": "South Korea",
				"name"       : "Gyeonggi Province",
				"state_code" : "41"
			},
			{
				"countryName": "South Korea",
				"name"       : "Incheon",
				"state_code" : "28"
			},
			{
				"countryName": "South Korea",
				"name"       : "Jeju",
				"state_code" : "49"
			},
			{
				"countryName": "South Korea",
				"name"       : "North Chungcheong Province",
				"state_code" : "43"
			},
			{
				"countryName": "South Korea",
				"name"       : "North Gyeongsang Province",
				"state_code" : "47"
			},
			{
				"countryName": "South Korea",
				"name"       : "North Jeolla Province",
				"state_code" : "45"
			},
			{
				"countryName": "South Korea",
				"name"       : "Sejong City",
				"state_code" : "50"
			},
			{
				"countryName": "South Korea",
				"name"       : "Seoul",
				"state_code" : "11"
			},
			{
				"countryName": "South Korea",
				"name"       : "South Chungcheong Province",
				"state_code" : "44"
			},
			{
				"countryName": "South Korea",
				"name"       : "South Gyeongsang Province",
				"state_code" : "48"
			},
			{
				"countryName": "South Korea",
				"name"       : "South Jeolla Province",
				"state_code" : "46"
			},
			{
				"countryName": "South Korea",
				"name"       : "Ulsan",
				"state_code" : "31"
			}
		],
		"SS": [
			{
				"countryName": "South Sudan",
				"name"       : "Central Equatoria",
				"state_code" : "EC"
			},
			{
				"countryName": "South Sudan",
				"name"       : "Eastern Equatoria",
				"state_code" : "EE"
			},
			{
				"countryName": "South Sudan",
				"name"       : "Jonglei State",
				"state_code" : "JG"
			},
			{
				"countryName": "South Sudan",
				"name"       : "Lakes",
				"state_code" : "LK"
			},
			{
				"countryName": "South Sudan",
				"name"       : "Northern Bahr el Ghazal",
				"state_code" : "BN"
			},
			{
				"countryName": "South Sudan",
				"name"       : "Unity",
				"state_code" : "UY"
			},
			{
				"countryName": "South Sudan",
				"name"       : "Upper Nile",
				"state_code" : "NU"
			},
			{
				"countryName": "South Sudan",
				"name"       : "Warrap",
				"state_code" : "WR"
			},
			{
				"countryName": "South Sudan",
				"name"       : "Western Bahr el Ghazal",
				"state_code" : "BW"
			},
			{
				"countryName": "South Sudan",
				"name"       : "Western Equatoria",
				"state_code" : "EW"
			}
		],
		"ES": [
			{
				"countryName": "Spain",
				"name"       : "A Coruña",
				"state_code" : "C"
			},
			{
				"countryName": "Spain",
				"name"       : "Albacete",
				"state_code" : "AB"
			},
			{
				"countryName": "Spain",
				"name"       : "Alicante",
				"state_code" : "A"
			},
			{
				"countryName": "Spain",
				"name"       : "Almeria",
				"state_code" : "AL"
			},
			{
				"countryName": "Spain",
				"name"       : "Araba",
				"state_code" : "VI"
			},
			{
				"countryName": "Spain",
				"name"       : "Asturias",
				"state_code" : "O"
			},
			{
				"countryName": "Spain",
				"name"       : "Ávila",
				"state_code" : "AV"
			},
			{
				"countryName": "Spain",
				"name"       : "Badajoz",
				"state_code" : "BA"
			},
			{
				"countryName": "Spain",
				"name"       : "Balearic Islands",
				"state_code" : "PM"
			},
			{
				"countryName": "Spain",
				"name"       : "Barcelona",
				"state_code" : "B"
			},
			{
				"countryName": "Spain",
				"name"       : "Bizkaia",
				"state_code" : "BI"
			},
			{
				"countryName": "Spain",
				"name"       : "Burgos",
				"state_code" : "BU"
			},
			{
				"countryName": "Spain",
				"name"       : "Caceres",
				"state_code" : "CC"
			},
			{
				"countryName": "Spain",
				"name"       : "Cádiz",
				"state_code" : "CA"
			},
			{
				"countryName": "Spain",
				"name"       : "Cantabria",
				"state_code" : "S"
			},
			{
				"countryName": "Spain",
				"name"       : "Castellón",
				"state_code" : "CS"
			},
			{
				"countryName": "Spain",
				"name"       : "Ciudad Real",
				"state_code" : "CR"
			},
			{
				"countryName": "Spain",
				"name"       : "Córdoba",
				"state_code" : "CO"
			},
			{
				"countryName": "Spain",
				"name"       : "Cuenca",
				"state_code" : "CU"
			},
			{
				"countryName": "Spain",
				"name"       : "Gipuzkoa",
				"state_code" : "SS"
			},
			{
				"countryName": "Spain",
				"name"       : "Girona",
				"state_code" : "GI"
			},
			{
				"countryName": "Spain",
				"name"       : "Granada",
				"state_code" : "GR"
			},
			{
				"countryName": "Spain",
				"name"       : "Guadalajara",
				"state_code" : "GU"
			},
			{
				"countryName": "Spain",
				"name"       : "Huelva",
				"state_code" : "H"
			},
			{
				"countryName": "Spain",
				"name"       : "Huesca",
				"state_code" : "HU"
			},
			{
				"countryName": "Spain",
				"name"       : "Jaén",
				"state_code" : "J"
			},
			{
				"countryName": "Spain",
				"name"       : "La Rioja",
				"state_code" : "LO"
			},
			{
				"countryName": "Spain",
				"name"       : "Las Palmas",
				"state_code" : "GC"
			},
			{
				"countryName": "Spain",
				"name"       : "Léon",
				"state_code" : "LE"
			},
			{
				"countryName": "Spain",
				"name"       : "Lleida",
				"state_code" : "L"
			},
			{
				"countryName": "Spain",
				"name"       : "Lugo",
				"state_code" : "LU"
			},
			{
				"countryName": "Spain",
				"name"       : "Madrid",
				"state_code" : "M"
			},
			{
				"countryName": "Spain",
				"name"       : "Málaga",
				"state_code" : "MA"
			},
			{
				"countryName": "Spain",
				"name"       : "Murcia",
				"state_code" : "MU"
			},
			{
				"countryName": "Spain",
				"name"       : "Navarra",
				"state_code" : "NA"
			},
			{
				"countryName": "Spain",
				"name"       : "Ourense",
				"state_code" : "OR"
			},
			{
				"countryName": "Spain",
				"name"       : "Palencia",
				"state_code" : "P"
			},
			{
				"countryName": "Spain",
				"name"       : "Pontevedra",
				"state_code" : "PO"
			},
			{
				"countryName": "Spain",
				"name"       : "Salamanca",
				"state_code" : "SA"
			},
			{
				"countryName": "Spain",
				"name"       : "Santa Cruz de Tenerife",
				"state_code" : "TF"
			},
			{
				"countryName": "Spain",
				"name"       : "Segovia",
				"state_code" : "SG"
			},
			{
				"countryName": "Spain",
				"name"       : "Sevilla",
				"state_code" : "SE"
			},
			{
				"countryName": "Spain",
				"name"       : "Soria",
				"state_code" : "SO"
			},
			{
				"countryName": "Spain",
				"name"       : "Tarragona",
				"state_code" : "T"
			},
			{
				"countryName": "Spain",
				"name"       : "Teruel",
				"state_code" : "TE"
			},
			{
				"countryName": "Spain",
				"name"       : "Toledo",
				"state_code" : "TO"
			},
			{
				"countryName": "Spain",
				"name"       : "Valencia",
				"state_code" : "V"
			},
			{
				"countryName": "Spain",
				"name"       : "Valladolid",
				"state_code" : "VA"
			},
			{
				"countryName": "Spain",
				"name"       : "Zamora",
				"state_code" : "ZA"
			},
			{
				"countryName": "Spain",
				"name"       : "Zaragoza",
				"state_code" : "Z"
			}
		],
		"LK": [
			{
				"countryName": "Sri Lanka",
				"name"       : "Ampara District",
				"state_code" : "52"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Anuradhapura District",
				"state_code" : "71"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Badulla District",
				"state_code" : "81"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Batticaloa District",
				"state_code" : "51"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Central Province",
				"state_code" : "2"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Colombo District",
				"state_code" : "11"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Eastern Province",
				"state_code" : "5"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Galle District",
				"state_code" : "31"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Gampaha District",
				"state_code" : "12"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Hambantota District",
				"state_code" : "33"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Jaffna District",
				"state_code" : "41"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Kalutara District",
				"state_code" : "13"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Kandy District",
				"state_code" : "21"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Kegalle District",
				"state_code" : "92"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Kilinochchi District",
				"state_code" : "42"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Mannar District",
				"state_code" : "43"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Matale District",
				"state_code" : "22"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Matara District",
				"state_code" : "32"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Monaragala District",
				"state_code" : "82"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Mullaitivu District",
				"state_code" : "45"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "North Central Province",
				"state_code" : "7"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "North Western Province",
				"state_code" : "6"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Northern Province",
				"state_code" : "4"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Nuwara Eliya District",
				"state_code" : "23"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Polonnaruwa District",
				"state_code" : "72"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Puttalam District",
				"state_code" : "62"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Ratnapura district",
				"state_code" : "91"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Sabaragamuwa Province",
				"state_code" : "9"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Southern Province",
				"state_code" : "3"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Trincomalee District",
				"state_code" : "53"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Uva Province",
				"state_code" : "8"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Vavuniya District",
				"state_code" : "44"
			},
			{
				"countryName": "Sri Lanka",
				"name"       : "Western Province",
				"state_code" : "1"
			}
		],
		"SD": [
			{
				"countryName": "Sudan",
				"name"       : "Al Jazirah",
				"state_code" : "GZ"
			},
			{
				"countryName": "Sudan",
				"name"       : "Al Qadarif",
				"state_code" : "GD"
			},
			{
				"countryName": "Sudan",
				"name"       : "Blue Nile",
				"state_code" : "NB"
			},
			{
				"countryName": "Sudan",
				"name"       : "Central Darfur",
				"state_code" : "DC"
			},
			{
				"countryName": "Sudan",
				"name"       : "East Darfur",
				"state_code" : "DE"
			},
			{
				"countryName": "Sudan",
				"name"       : "Kassala",
				"state_code" : "KA"
			},
			{
				"countryName": "Sudan",
				"name"       : "Khartoum",
				"state_code" : "KH"
			},
			{
				"countryName": "Sudan",
				"name"       : "North Darfur",
				"state_code" : "DN"
			},
			{
				"countryName": "Sudan",
				"name"       : "North Kordofan",
				"state_code" : "KN"
			},
			{
				"countryName": "Sudan",
				"name"       : "Northern",
				"state_code" : "NO"
			},
			{
				"countryName": "Sudan",
				"name"       : "Red Sea",
				"state_code" : "RS"
			},
			{
				"countryName": "Sudan",
				"name"       : "River Nile",
				"state_code" : "NR"
			},
			{
				"countryName": "Sudan",
				"name"       : "Sennar",
				"state_code" : "SI"
			},
			{
				"countryName": "Sudan",
				"name"       : "South Darfur",
				"state_code" : "DS"
			},
			{
				"countryName": "Sudan",
				"name"       : "South Kordofan",
				"state_code" : "KS"
			},
			{
				"countryName": "Sudan",
				"name"       : "West Darfur",
				"state_code" : "DW"
			},
			{
				"countryName": "Sudan",
				"name"       : "West Kordofan",
				"state_code" : "GK"
			},
			{
				"countryName": "Sudan",
				"name"       : "White Nile",
				"state_code" : "NW"
			}
		],
		"SR": [
			{
				"countryName": "Suriname",
				"name"       : "Brokopondo District",
				"state_code" : "BR"
			},
			{
				"countryName": "Suriname",
				"name"       : "Commewijne District",
				"state_code" : "CM"
			},
			{
				"countryName": "Suriname",
				"name"       : "Coronie District",
				"state_code" : "CR"
			},
			{
				"countryName": "Suriname",
				"name"       : "Marowijne District",
				"state_code" : "MA"
			},
			{
				"countryName": "Suriname",
				"name"       : "Nickerie District",
				"state_code" : "NI"
			},
			{
				"countryName": "Suriname",
				"name"       : "Para District",
				"state_code" : "PR"
			},
			{
				"countryName": "Suriname",
				"name"       : "Paramaribo District",
				"state_code" : "PM"
			},
			{
				"countryName": "Suriname",
				"name"       : "Saramacca District",
				"state_code" : "SA"
			},
			{
				"countryName": "Suriname",
				"name"       : "Sipaliwini District",
				"state_code" : "SI"
			},
			{
				"countryName": "Suriname",
				"name"       : "Wanica District",
				"state_code" : "WA"
			}
		],
		"SJ": [],
		"SZ": [
			{
				"countryName": "Swaziland",
				"name"       : "Hhohho District",
				"state_code" : "HH"
			},
			{
				"countryName": "Swaziland",
				"name"       : "Lubombo District",
				"state_code" : "LU"
			},
			{
				"countryName": "Swaziland",
				"name"       : "Manzini District",
				"state_code" : "MA"
			},
			{
				"countryName": "Swaziland",
				"name"       : "Shiselweni District",
				"state_code" : "SH"
			}
		],
		"SE": [
			{
				"countryName": "Sweden",
				"name"       : "Blekinge",
				"state_code" : "K"
			},
			{
				"countryName": "Sweden",
				"name"       : "Dalarna County",
				"state_code" : "W"
			},
			{
				"countryName": "Sweden",
				"name"       : "Gävleborg County",
				"state_code" : "X"
			},
			{
				"countryName": "Sweden",
				"name"       : "Gotland County",
				"state_code" : "I"
			},
			{
				"countryName": "Sweden",
				"name"       : "Halland County",
				"state_code" : "N"
			},
			{
				"countryName": "Sweden",
				"name"       : "Jönköping County",
				"state_code" : "F"
			},
			{
				"countryName": "Sweden",
				"name"       : "Kalmar County",
				"state_code" : "H"
			},
			{
				"countryName": "Sweden",
				"name"       : "Kronoberg County",
				"state_code" : "G"
			},
			{
				"countryName": "Sweden",
				"name"       : "Norrbotten County",
				"state_code" : "BD"
			},
			{
				"countryName": "Sweden",
				"name"       : "Örebro County",
				"state_code" : "T"
			},
			{
				"countryName": "Sweden",
				"name"       : "Östergötland County",
				"state_code" : "E"
			},
			{
				"countryName": "Sweden",
				"name"       : "Skåne County",
				"state_code" : "M"
			},
			{
				"countryName": "Sweden",
				"name"       : "Södermanland County",
				"state_code" : "D"
			},
			{
				"countryName": "Sweden",
				"name"       : "Stockholm County",
				"state_code" : "AB"
			},
			{
				"countryName": "Sweden",
				"name"       : "Uppsala County",
				"state_code" : "C"
			},
			{
				"countryName": "Sweden",
				"name"       : "Värmland County",
				"state_code" : "S"
			},
			{
				"countryName": "Sweden",
				"name"       : "Västerbotten County",
				"state_code" : "AC"
			},
			{
				"countryName": "Sweden",
				"name"       : "Västernorrland County",
				"state_code" : "Y"
			},
			{
				"countryName": "Sweden",
				"name"       : "Västmanland County",
				"state_code" : "U"
			},
			{
				"countryName": "Sweden",
				"name"       : "Västra Götaland County",
				"state_code" : "O"
			}
		],
		"CH": [
			{
				"countryName": "Switzerland",
				"name"       : "Aargau",
				"state_code" : "AG"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Appenzell Ausserrhoden",
				"state_code" : "AR"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Appenzell Innerrhoden",
				"state_code" : "AI"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Basel-Land",
				"state_code" : "BL"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Basel-Stadt",
				"state_code" : "BS"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Bern",
				"state_code" : "BE"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Fribourg",
				"state_code" : "FR"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Geneva",
				"state_code" : "GE"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Glarus",
				"state_code" : "GL"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Graubünden",
				"state_code" : "GR"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Jura",
				"state_code" : "JU"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Lucerne",
				"state_code" : "LU"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Neuchâtel",
				"state_code" : "NE"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Nidwalden",
				"state_code" : "NW"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Obwalden",
				"state_code" : "OW"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Schaffhausen",
				"state_code" : "SH"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Schwyz",
				"state_code" : "SZ"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Solothurn",
				"state_code" : "SO"
			},
			{
				"countryName": "Switzerland",
				"name"       : "St. Gallen",
				"state_code" : "SG"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Thurgau",
				"state_code" : "TG"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Ticino",
				"state_code" : "TI"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Uri",
				"state_code" : "UR"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Valais",
				"state_code" : "VS"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Vaud",
				"state_code" : "VD"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Zug",
				"state_code" : "ZG"
			},
			{
				"countryName": "Switzerland",
				"name"       : "Zürich",
				"state_code" : "ZH"
			}
		],
		"SY": [
			{
				"countryName": "Syria",
				"name"       : "Al-Hasakah",
				"state_code" : "HA"
			},
			{
				"countryName": "Syria",
				"name"       : "Al-Raqqah",
				"state_code" : "RA"
			},
			{
				"countryName": "Syria",
				"name"       : "Aleppo",
				"state_code" : "HL"
			},
			{
				"countryName": "Syria",
				"name"       : "As-Suwayda",
				"state_code" : "SU"
			},
			{
				"countryName": "Syria",
				"name"       : "Damascus",
				"state_code" : "DI"
			},
			{
				"countryName": "Syria",
				"name"       : "Daraa",
				"state_code" : "DR"
			},
			{
				"countryName": "Syria",
				"name"       : "Deir ez-Zor",
				"state_code" : "DY"
			},
			{
				"countryName": "Syria",
				"name"       : "Hama",
				"state_code" : "HM"
			},
			{
				"countryName": "Syria",
				"name"       : "Homs",
				"state_code" : "HI"
			},
			{
				"countryName": "Syria",
				"name"       : "Idlib",
				"state_code" : "ID"
			},
			{
				"countryName": "Syria",
				"name"       : "Latakia",
				"state_code" : "LA"
			},
			{
				"countryName": "Syria",
				"name"       : "Quneitra",
				"state_code" : "QU"
			},
			{
				"countryName": "Syria",
				"name"       : "Rif Dimashq",
				"state_code" : "RD"
			},
			{
				"countryName": "Syria",
				"name"       : "Tartus",
				"state_code" : "TA"
			}
		],
		"TW": [
			{
				"countryName": "Taiwan",
				"name"       : "Changhua",
				"state_code" : "CHA"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Chiayi",
				"state_code" : "CYI"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Chiayi",
				"state_code" : "CYQ"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Hsinchu",
				"state_code" : "HSQ"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Hsinchu",
				"state_code" : "HSZ"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Hualien",
				"state_code" : "HUA"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Kaohsiung",
				"state_code" : "KHH"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Keelung",
				"state_code" : "KEE"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Kinmen",
				"state_code" : "KIN"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Lienchiang",
				"state_code" : "LIE"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Miaoli",
				"state_code" : "MIA"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Nantou",
				"state_code" : "NAN"
			},
			{
				"countryName": "Taiwan",
				"name"       : "New Taipei",
				"state_code" : "NWT"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Penghu",
				"state_code" : "PEN"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Pingtung",
				"state_code" : "PIF"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Taichung",
				"state_code" : "TXG"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Tainan",
				"state_code" : "TNN"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Taipei",
				"state_code" : "TPE"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Taitung",
				"state_code" : "TTT"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Taoyuan",
				"state_code" : "TAO"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Yilan",
				"state_code" : "ILA"
			},
			{
				"countryName": "Taiwan",
				"name"       : "Yunlin",
				"state_code" : "YUN"
			}
		],
		"TJ": [
			{
				"countryName": "Tajikistan",
				"name"       : "districts of Republican Subordination",
				"state_code" : "RA"
			},
			{
				"countryName": "Tajikistan",
				"name"       : "Gorno-Badakhshan Autonomous Province",
				"state_code" : "GB"
			},
			{
				"countryName": "Tajikistan",
				"name"       : "Khatlon Province",
				"state_code" : "KT"
			},
			{
				"countryName": "Tajikistan",
				"name"       : "Sughd Province",
				"state_code" : "SU"
			}
		],
		"TZ": [
			{
				"countryName": "Tanzania",
				"name"       : "Arusha",
				"state_code" : "01"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Dar es Salaam",
				"state_code" : "02"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Dodoma",
				"state_code" : "03"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Geita",
				"state_code" : "27"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Iringa",
				"state_code" : "04"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Kagera",
				"state_code" : "05"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Katavi",
				"state_code" : "28"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Kigoma",
				"state_code" : "08"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Kilimanjaro",
				"state_code" : "09"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Lindi",
				"state_code" : "12"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Manyara",
				"state_code" : "26"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Mara",
				"state_code" : "13"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Mbeya",
				"state_code" : "14"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Morogoro",
				"state_code" : "16"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Mtwara",
				"state_code" : "17"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Mwanza",
				"state_code" : "18"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Njombe",
				"state_code" : "29"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Pemba North",
				"state_code" : "06"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Pemba South",
				"state_code" : "10"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Pwani",
				"state_code" : "19"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Rukwa",
				"state_code" : "20"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Ruvuma",
				"state_code" : "21"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Shinyanga",
				"state_code" : "22"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Simiyu",
				"state_code" : "30"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Singida",
				"state_code" : "23"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Songwe",
				"state_code" : "31"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Tabora",
				"state_code" : "24"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Tanga",
				"state_code" : "25"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Zanzibar North",
				"state_code" : "07"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Zanzibar South",
				"state_code" : "11"
			},
			{
				"countryName": "Tanzania",
				"name"       : "Zanzibar West",
				"state_code" : "15"
			}
		],
		"TH": [
			{
				"countryName": "Thailand",
				"name"       : "Amnat Charoen",
				"state_code" : "37"
			},
			{
				"countryName": "Thailand",
				"name"       : "Ang Thong",
				"state_code" : "15"
			},
			{
				"countryName": "Thailand",
				"name"       : "Bangkok",
				"state_code" : "10"
			},
			{
				"countryName": "Thailand",
				"name"       : "Bueng Kan",
				"state_code" : "38"
			},
			{
				"countryName": "Thailand",
				"name"       : "Buri Ram",
				"state_code" : "31"
			},
			{
				"countryName": "Thailand",
				"name"       : "Chachoengsao",
				"state_code" : "24"
			},
			{
				"countryName": "Thailand",
				"name"       : "Chai Nat",
				"state_code" : "18"
			},
			{
				"countryName": "Thailand",
				"name"       : "Chaiyaphum",
				"state_code" : "36"
			},
			{
				"countryName": "Thailand",
				"name"       : "Chanthaburi",
				"state_code" : "22"
			},
			{
				"countryName": "Thailand",
				"name"       : "Chiang Mai",
				"state_code" : "50"
			},
			{
				"countryName": "Thailand",
				"name"       : "Chiang Rai",
				"state_code" : "57"
			},
			{
				"countryName": "Thailand",
				"name"       : "Chon Buri",
				"state_code" : "20"
			},
			{
				"countryName": "Thailand",
				"name"       : "Chumphon",
				"state_code" : "86"
			},
			{
				"countryName": "Thailand",
				"name"       : "Kalasin",
				"state_code" : "46"
			},
			{
				"countryName": "Thailand",
				"name"       : "Kamphaeng Phet",
				"state_code" : "62"
			},
			{
				"countryName": "Thailand",
				"name"       : "Kanchanaburi",
				"state_code" : "71"
			},
			{
				"countryName": "Thailand",
				"name"       : "Khon Kaen",
				"state_code" : "40"
			},
			{
				"countryName": "Thailand",
				"name"       : "Krabi",
				"state_code" : "81"
			},
			{
				"countryName": "Thailand",
				"name"       : "Lampang",
				"state_code" : "52"
			},
			{
				"countryName": "Thailand",
				"name"       : "Lamphun",
				"state_code" : "51"
			},
			{
				"countryName": "Thailand",
				"name"       : "Loei",
				"state_code" : "42"
			},
			{
				"countryName": "Thailand",
				"name"       : "Lop Buri",
				"state_code" : "16"
			},
			{
				"countryName": "Thailand",
				"name"       : "Mae Hong Son",
				"state_code" : "58"
			},
			{
				"countryName": "Thailand",
				"name"       : "Maha Sarakham",
				"state_code" : "44"
			},
			{
				"countryName": "Thailand",
				"name"       : "Mukdahan",
				"state_code" : "49"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nakhon Nayok",
				"state_code" : "26"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nakhon Pathom",
				"state_code" : "73"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nakhon Phanom",
				"state_code" : "48"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nakhon Ratchasima",
				"state_code" : "30"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nakhon Sawan",
				"state_code" : "60"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nakhon Si Thammarat",
				"state_code" : "80"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nan",
				"state_code" : "55"
			},
			{
				"countryName": "Thailand",
				"name"       : "Narathiwat",
				"state_code" : "96"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nong Bua Lam Phu",
				"state_code" : "39"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nong Khai",
				"state_code" : "43"
			},
			{
				"countryName": "Thailand",
				"name"       : "Nonthaburi",
				"state_code" : "12"
			},
			{
				"countryName": "Thailand",
				"name"       : "Pathum Thani",
				"state_code" : "13"
			},
			{
				"countryName": "Thailand",
				"name"       : "Pattani",
				"state_code" : "94"
			},
			{
				"countryName": "Thailand",
				"name"       : "Pattaya",
				"state_code" : "S"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phangnga",
				"state_code" : "82"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phatthalung",
				"state_code" : "93"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phayao",
				"state_code" : "56"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phetchabun",
				"state_code" : "67"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phetchaburi",
				"state_code" : "76"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phichit",
				"state_code" : "66"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phitsanulok",
				"state_code" : "65"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phra Nakhon Si Ayutthaya",
				"state_code" : "14"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phrae",
				"state_code" : "54"
			},
			{
				"countryName": "Thailand",
				"name"       : "Phuket",
				"state_code" : "83"
			},
			{
				"countryName": "Thailand",
				"name"       : "Prachin Buri",
				"state_code" : "25"
			},
			{
				"countryName": "Thailand",
				"name"       : "Prachuap Khiri Khan",
				"state_code" : "77"
			},
			{
				"countryName": "Thailand",
				"name"       : "Ranong",
				"state_code" : "85"
			},
			{
				"countryName": "Thailand",
				"name"       : "Ratchaburi",
				"state_code" : "70"
			},
			{
				"countryName": "Thailand",
				"name"       : "Rayong",
				"state_code" : "21"
			},
			{
				"countryName": "Thailand",
				"name"       : "Roi Et",
				"state_code" : "45"
			},
			{
				"countryName": "Thailand",
				"name"       : "Sa Kaeo",
				"state_code" : "27"
			},
			{
				"countryName": "Thailand",
				"name"       : "Sakon Nakhon",
				"state_code" : "47"
			},
			{
				"countryName": "Thailand",
				"name"       : "Samut Prakan",
				"state_code" : "11"
			},
			{
				"countryName": "Thailand",
				"name"       : "Samut Sakhon",
				"state_code" : "74"
			},
			{
				"countryName": "Thailand",
				"name"       : "Samut Songkhram",
				"state_code" : "75"
			},
			{
				"countryName": "Thailand",
				"name"       : "Saraburi",
				"state_code" : "19"
			},
			{
				"countryName": "Thailand",
				"name"       : "Satun",
				"state_code" : "91"
			},
			{
				"countryName": "Thailand",
				"name"       : "Si Sa Ket",
				"state_code" : "33"
			},
			{
				"countryName": "Thailand",
				"name"       : "Sing Buri",
				"state_code" : "17"
			},
			{
				"countryName": "Thailand",
				"name"       : "Songkhla",
				"state_code" : "90"
			},
			{
				"countryName": "Thailand",
				"name"       : "Sukhothai",
				"state_code" : "64"
			},
			{
				"countryName": "Thailand",
				"name"       : "Suphan Buri",
				"state_code" : "72"
			},
			{
				"countryName": "Thailand",
				"name"       : "Surat Thani",
				"state_code" : "84"
			},
			{
				"countryName": "Thailand",
				"name"       : "Surin",
				"state_code" : "32"
			},
			{
				"countryName": "Thailand",
				"name"       : "Tak",
				"state_code" : "63"
			},
			{
				"countryName": "Thailand",
				"name"       : "Trang",
				"state_code" : "92"
			},
			{
				"countryName": "Thailand",
				"name"       : "Trat",
				"state_code" : "23"
			},
			{
				"countryName": "Thailand",
				"name"       : "Ubon Ratchathani",
				"state_code" : "34"
			},
			{
				"countryName": "Thailand",
				"name"       : "Udon Thani",
				"state_code" : "41"
			},
			{
				"countryName": "Thailand",
				"name"       : "Uthai Thani",
				"state_code" : "61"
			},
			{
				"countryName": "Thailand",
				"name"       : "Uttaradit",
				"state_code" : "53"
			},
			{
				"countryName": "Thailand",
				"name"       : "Yala",
				"state_code" : "95"
			},
			{
				"countryName": "Thailand",
				"name"       : "Yasothon",
				"state_code" : "35"
			}
		],
		"BS": [
			{
				"countryName": "The Bahamas",
				"name"       : "Acklins",
				"state_code" : "AK"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Acklins and Crooked Islands",
				"state_code" : "AC"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Berry Islands",
				"state_code" : "BY"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Bimini",
				"state_code" : "BI"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Black Point",
				"state_code" : "BP"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Cat Island",
				"state_code" : "CI"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Central Abaco",
				"state_code" : "CO"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Central Andros",
				"state_code" : "CS"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Central Eleuthera",
				"state_code" : "CE"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Crooked Island",
				"state_code" : "CK"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "East Grand Bahama",
				"state_code" : "EG"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Exuma",
				"state_code" : "EX"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Freeport",
				"state_code" : "FP"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Fresh Creek",
				"state_code" : "FC"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Governor's Harbour",
				"state_code" : "GH"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Grand Cay",
				"state_code" : "GC"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Green Turtle Cay",
				"state_code" : "GT"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Harbour Island",
				"state_code" : "HI"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "High Rock",
				"state_code" : "HR"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Hope Town",
				"state_code" : "HT"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Inagua",
				"state_code" : "IN"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Kemps Bay",
				"state_code" : "KB"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Long Island",
				"state_code" : "LI"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Mangrove Cay",
				"state_code" : "MC"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Marsh Harbour",
				"state_code" : "MH"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Mayaguana District",
				"state_code" : "MG"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "New Providence",
				"state_code" : "NP"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Nichollstown and Berry Islands",
				"state_code" : "NB"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "North Abaco",
				"state_code" : "NO"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "North Andros",
				"state_code" : "NS"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "North Eleuthera",
				"state_code" : "NE"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Ragged Island",
				"state_code" : "RI"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Rock Sound",
				"state_code" : "RS"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Rum Cay District",
				"state_code" : "RC"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "San Salvador and Rum Cay",
				"state_code" : "SR"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "San Salvador Island",
				"state_code" : "SS"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Sandy Point",
				"state_code" : "SP"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "South Abaco",
				"state_code" : "SO"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "South Andros",
				"state_code" : "SA"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "South Eleuthera",
				"state_code" : "SE"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "Spanish Wells",
				"state_code" : "SW"
			},
			{
				"countryName": "The Bahamas",
				"name"       : "West Grand Bahama",
				"state_code" : "WG"
			}
		],
		"TG": [
			{
				"countryName": "Togo",
				"name"       : "Centrale Region",
				"state_code" : "C"
			},
			{
				"countryName": "Togo",
				"name"       : "Kara Region",
				"state_code" : "K"
			},
			{
				"countryName": "Togo",
				"name"       : "Maritime",
				"state_code" : "M"
			},
			{
				"countryName": "Togo",
				"name"       : "Plateaux Region",
				"state_code" : "P"
			},
			{
				"countryName": "Togo",
				"name"       : "Savanes Region",
				"state_code" : "S"
			}
		],
		"TK": [],
		"TO": [
			{
				"countryName": "Tonga",
				"name"       : "Haʻapai",
				"state_code" : "02"
			},
			{
				"countryName": "Tonga",
				"name"       : "ʻEua",
				"state_code" : "01"
			},
			{
				"countryName": "Tonga",
				"name"       : "Niuas",
				"state_code" : "03"
			},
			{
				"countryName": "Tonga",
				"name"       : "Tongatapu",
				"state_code" : "04"
			},
			{
				"countryName": "Tonga",
				"name"       : "Vavaʻu",
				"state_code" : "05"
			}
		],
		"TT": [
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Arima",
				"state_code" : "ARI"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Chaguanas",
				"state_code" : "CHA"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Couva-Tabaquite-Talparo Regional Corporation",
				"state_code" : "CTT"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Diego Martin Regional Corporation",
				"state_code" : "DMN"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Eastern Tobago",
				"state_code" : "ETO"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Penal-Debe Regional Corporation",
				"state_code" : "PED"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Point Fortin",
				"state_code" : "PTF"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Port of Spain",
				"state_code" : "POS"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Princes Town Regional Corporation",
				"state_code" : "PRT"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Rio Claro-Mayaro Regional Corporation",
				"state_code" : "MRC"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "San Fernando",
				"state_code" : "SFO"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "San Juan-Laventille Regional Corporation",
				"state_code" : "SJL"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Sangre Grande Regional Corporation",
				"state_code" : "SGE"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Siparia Regional Corporation",
				"state_code" : "SIP"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Tunapuna-Piarco Regional Corporation",
				"state_code" : "TUP"
			},
			{
				"countryName": "Trinidad And Tobago",
				"name"       : "Western Tobago",
				"state_code" : "WTO"
			}
		],
		"TN": [
			{
				"countryName": "Tunisia",
				"name"       : "Ariana",
				"state_code" : "12"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Ben Arous",
				"state_code" : "13"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Bizerte",
				"state_code" : "23"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Gabès",
				"state_code" : "81"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Gafsa",
				"state_code" : "71"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Jendouba",
				"state_code" : "32"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Kairouan",
				"state_code" : "41"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Kasserine",
				"state_code" : "42"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Kassrine",
				"state_code" : "31"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Kebili",
				"state_code" : "73"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Kef",
				"state_code" : "33"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Mahdia",
				"state_code" : "53"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Manouba",
				"state_code" : "14"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Medenine",
				"state_code" : "82"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Monastir",
				"state_code" : "52"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Nabeul",
				"state_code" : "21"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Sfax",
				"state_code" : "61"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Sidi Bouzid",
				"state_code" : "43"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Siliana",
				"state_code" : "34"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Sousse",
				"state_code" : "51"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Tataouine",
				"state_code" : "83"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Tozeur",
				"state_code" : "72"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Tunis",
				"state_code" : "11"
			},
			{
				"countryName": "Tunisia",
				"name"       : "Zaghouan",
				"state_code" : "22"
			}
		],
		"TR": [
			{
				"countryName": "Turkey",
				"name"       : "Adana",
				"state_code" : "01"
			},
			{
				"countryName": "Turkey",
				"name"       : "Adıyaman",
				"state_code" : "02"
			},
			{
				"countryName": "Turkey",
				"name"       : "Afyonkarahisar",
				"state_code" : "03"
			},
			{
				"countryName": "Turkey",
				"name"       : "Ağrı",
				"state_code" : "04"
			},
			{
				"countryName": "Turkey",
				"name"       : "Aksaray",
				"state_code" : "68"
			},
			{
				"countryName": "Turkey",
				"name"       : "Amasya",
				"state_code" : "05"
			},
			{
				"countryName": "Turkey",
				"name"       : "Ankara",
				"state_code" : "06"
			},
			{
				"countryName": "Turkey",
				"name"       : "Antalya",
				"state_code" : "07"
			},
			{
				"countryName": "Turkey",
				"name"       : "Ardahan",
				"state_code" : "75"
			},
			{
				"countryName": "Turkey",
				"name"       : "Artvin",
				"state_code" : "08"
			},
			{
				"countryName": "Turkey",
				"name"       : "Aydın",
				"state_code" : "09"
			},
			{
				"countryName": "Turkey",
				"name"       : "Balıkesir",
				"state_code" : "10"
			},
			{
				"countryName": "Turkey",
				"name"       : "Bartın",
				"state_code" : "74"
			},
			{
				"countryName": "Turkey",
				"name"       : "Batman",
				"state_code" : "72"
			},
			{
				"countryName": "Turkey",
				"name"       : "Bayburt",
				"state_code" : "69"
			},
			{
				"countryName": "Turkey",
				"name"       : "Bilecik",
				"state_code" : "11"
			},
			{
				"countryName": "Turkey",
				"name"       : "Bingöl",
				"state_code" : "12"
			},
			{
				"countryName": "Turkey",
				"name"       : "Bitlis",
				"state_code" : "13"
			},
			{
				"countryName": "Turkey",
				"name"       : "Bolu",
				"state_code" : "14"
			},
			{
				"countryName": "Turkey",
				"name"       : "Burdur",
				"state_code" : "15"
			},
			{
				"countryName": "Turkey",
				"name"       : "Bursa",
				"state_code" : "16"
			},
			{
				"countryName": "Turkey",
				"name"       : "Çanakkale",
				"state_code" : "17"
			},
			{
				"countryName": "Turkey",
				"name"       : "Çankırı",
				"state_code" : "18"
			},
			{
				"countryName": "Turkey",
				"name"       : "Çorum",
				"state_code" : "19"
			},
			{
				"countryName": "Turkey",
				"name"       : "Denizli",
				"state_code" : "20"
			},
			{
				"countryName": "Turkey",
				"name"       : "Diyarbakır",
				"state_code" : "21"
			},
			{
				"countryName": "Turkey",
				"name"       : "Düzce",
				"state_code" : "81"
			},
			{
				"countryName": "Turkey",
				"name"       : "Edirne",
				"state_code" : "22"
			},
			{
				"countryName": "Turkey",
				"name"       : "Elazığ",
				"state_code" : "23"
			},
			{
				"countryName": "Turkey",
				"name"       : "Erzincan",
				"state_code" : "24"
			},
			{
				"countryName": "Turkey",
				"name"       : "Erzurum",
				"state_code" : "25"
			},
			{
				"countryName": "Turkey",
				"name"       : "Eskişehir",
				"state_code" : "26"
			},
			{
				"countryName": "Turkey",
				"name"       : "Gaziantep",
				"state_code" : "27"
			},
			{
				"countryName": "Turkey",
				"name"       : "Giresun",
				"state_code" : "28"
			},
			{
				"countryName": "Turkey",
				"name"       : "Gümüşhane",
				"state_code" : "29"
			},
			{
				"countryName": "Turkey",
				"name"       : "Hakkâri",
				"state_code" : "30"
			},
			{
				"countryName": "Turkey",
				"name"       : "Hatay",
				"state_code" : "31"
			},
			{
				"countryName": "Turkey",
				"name"       : "Iğdır",
				"state_code" : "76"
			},
			{
				"countryName": "Turkey",
				"name"       : "Isparta",
				"state_code" : "32"
			},
			{
				"countryName": "Turkey",
				"name"       : "Istanbul",
				"state_code" : "34"
			},
			{
				"countryName": "Turkey",
				"name"       : "İzmir",
				"state_code" : "35"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kahramanmaraş",
				"state_code" : "46"
			},
			{
				"countryName": "Turkey",
				"name"       : "Karabük",
				"state_code" : "78"
			},
			{
				"countryName": "Turkey",
				"name"       : "Karaman",
				"state_code" : "70"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kars",
				"state_code" : "36"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kastamonu",
				"state_code" : "37"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kayseri",
				"state_code" : "38"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kilis",
				"state_code" : "79"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kırıkkale",
				"state_code" : "71"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kırklareli",
				"state_code" : "39"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kırşehir",
				"state_code" : "40"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kocaeli",
				"state_code" : "41"
			},
			{
				"countryName": "Turkey",
				"name"       : "Konya",
				"state_code" : "42"
			},
			{
				"countryName": "Turkey",
				"name"       : "Kütahya",
				"state_code" : "43"
			},
			{
				"countryName": "Turkey",
				"name"       : "Malatya",
				"state_code" : "44"
			},
			{
				"countryName": "Turkey",
				"name"       : "Manisa",
				"state_code" : "45"
			},
			{
				"countryName": "Turkey",
				"name"       : "Mardin",
				"state_code" : "47"
			},
			{
				"countryName": "Turkey",
				"name"       : "Mersin",
				"state_code" : "33"
			},
			{
				"countryName": "Turkey",
				"name"       : "Muğla",
				"state_code" : "48"
			},
			{
				"countryName": "Turkey",
				"name"       : "Muş",
				"state_code" : "49"
			},
			{
				"countryName": "Turkey",
				"name"       : "Nevşehir",
				"state_code" : "50"
			},
			{
				"countryName": "Turkey",
				"name"       : "Niğde",
				"state_code" : "51"
			},
			{
				"countryName": "Turkey",
				"name"       : "Ordu",
				"state_code" : "52"
			},
			{
				"countryName": "Turkey",
				"name"       : "Osmaniye",
				"state_code" : "80"
			},
			{
				"countryName": "Turkey",
				"name"       : "Rize",
				"state_code" : "53"
			},
			{
				"countryName": "Turkey",
				"name"       : "Sakarya",
				"state_code" : "54"
			},
			{
				"countryName": "Turkey",
				"name"       : "Samsun",
				"state_code" : "55"
			},
			{
				"countryName": "Turkey",
				"name"       : "Şanlıurfa",
				"state_code" : "63"
			},
			{
				"countryName": "Turkey",
				"name"       : "Siirt",
				"state_code" : "56"
			},
			{
				"countryName": "Turkey",
				"name"       : "Sinop",
				"state_code" : "57"
			},
			{
				"countryName": "Turkey",
				"name"       : "Sivas",
				"state_code" : "58"
			},
			{
				"countryName": "Turkey",
				"name"       : "Şırnak",
				"state_code" : "73"
			},
			{
				"countryName": "Turkey",
				"name"       : "Tekirdağ",
				"state_code" : "59"
			},
			{
				"countryName": "Turkey",
				"name"       : "Tokat",
				"state_code" : "60"
			},
			{
				"countryName": "Turkey",
				"name"       : "Trabzon",
				"state_code" : "61"
			},
			{
				"countryName": "Turkey",
				"name"       : "Tunceli",
				"state_code" : "62"
			},
			{
				"countryName": "Turkey",
				"name"       : "Uşak",
				"state_code" : "64"
			},
			{
				"countryName": "Turkey",
				"name"       : "Van",
				"state_code" : "65"
			},
			{
				"countryName": "Turkey",
				"name"       : "Yalova",
				"state_code" : "77"
			},
			{
				"countryName": "Turkey",
				"name"       : "Yozgat",
				"state_code" : "66"
			},
			{
				"countryName": "Turkey",
				"name"       : "Zonguldak",
				"state_code" : "67"
			}
		],
		"TM": [
			{
				"countryName": "Turkmenistan",
				"name"       : "Ahal Region",
				"state_code" : "A"
			},
			{
				"countryName": "Turkmenistan",
				"name"       : "Ashgabat",
				"state_code" : "S"
			},
			{
				"countryName": "Turkmenistan",
				"name"       : "Balkan Region",
				"state_code" : "B"
			},
			{
				"countryName": "Turkmenistan",
				"name"       : "Daşoguz Region",
				"state_code" : "D"
			},
			{
				"countryName": "Turkmenistan",
				"name"       : "Lebap Region",
				"state_code" : "L"
			},
			{
				"countryName": "Turkmenistan",
				"name"       : "Mary Region",
				"state_code" : "M"
			}
		],
		"TC": [],
		"TV": [
			{
				"countryName": "Tuvalu",
				"name"       : "Funafuti",
				"state_code" : "FUN"
			},
			{
				"countryName": "Tuvalu",
				"name"       : "Nanumanga",
				"state_code" : "NMG"
			},
			{
				"countryName": "Tuvalu",
				"name"       : "Nanumea",
				"state_code" : "NMA"
			},
			{
				"countryName": "Tuvalu",
				"name"       : "Niutao Island Council",
				"state_code" : "NIT"
			},
			{
				"countryName": "Tuvalu",
				"name"       : "Nui",
				"state_code" : "NUI"
			},
			{
				"countryName": "Tuvalu",
				"name"       : "Nukufetau",
				"state_code" : "NKF"
			},
			{
				"countryName": "Tuvalu",
				"name"       : "Nukulaelae",
				"state_code" : "NKL"
			},
			{
				"countryName": "Tuvalu",
				"name"       : "Vaitupu",
				"state_code" : "VAI"
			}
		],
		"UG": [
			{
				"countryName": "Uganda",
				"name"       : "Abim District",
				"state_code" : "314"
			},
			{
				"countryName": "Uganda",
				"name"       : "Adjumani District",
				"state_code" : "301"
			},
			{
				"countryName": "Uganda",
				"name"       : "Agago District",
				"state_code" : "322"
			},
			{
				"countryName": "Uganda",
				"name"       : "Alebtong District",
				"state_code" : "323"
			},
			{
				"countryName": "Uganda",
				"name"       : "Amolatar District",
				"state_code" : "315"
			},
			{
				"countryName": "Uganda",
				"name"       : "Amudat District",
				"state_code" : "324"
			},
			{
				"countryName": "Uganda",
				"name"       : "Amuria District",
				"state_code" : "216"
			},
			{
				"countryName": "Uganda",
				"name"       : "Amuru District",
				"state_code" : "316"
			},
			{
				"countryName": "Uganda",
				"name"       : "Apac District",
				"state_code" : "302"
			},
			{
				"countryName": "Uganda",
				"name"       : "Arua District",
				"state_code" : "303"
			},
			{
				"countryName": "Uganda",
				"name"       : "Budaka District",
				"state_code" : "217"
			},
			{
				"countryName": "Uganda",
				"name"       : "Bududa District",
				"state_code" : "218"
			},
			{
				"countryName": "Uganda",
				"name"       : "Bugiri District",
				"state_code" : "201"
			},
			{
				"countryName": "Uganda",
				"name"       : "Buhweju District",
				"state_code" : "420"
			},
			{
				"countryName": "Uganda",
				"name"       : "Buikwe District",
				"state_code" : "117"
			},
			{
				"countryName": "Uganda",
				"name"       : "Bukedea District",
				"state_code" : "219"
			},
			{
				"countryName": "Uganda",
				"name"       : "Bukomansimbi District",
				"state_code" : "118"
			},
			{
				"countryName": "Uganda",
				"name"       : "Bukwo District",
				"state_code" : "220"
			},
			{
				"countryName": "Uganda",
				"name"       : "Bulambuli District",
				"state_code" : "225"
			},
			{
				"countryName": "Uganda",
				"name"       : "Buliisa District",
				"state_code" : "416"
			},
			{
				"countryName": "Uganda",
				"name"       : "Bundibugyo District",
				"state_code" : "401"
			},
			{
				"countryName": "Uganda",
				"name"       : "Bunyangabu District",
				"state_code" : "430"
			},
			{
				"countryName": "Uganda",
				"name"       : "Bushenyi District",
				"state_code" : "402"
			},
			{
				"countryName": "Uganda",
				"name"       : "Busia District",
				"state_code" : "202"
			},
			{
				"countryName": "Uganda",
				"name"       : "Butaleja District",
				"state_code" : "221"
			},
			{
				"countryName": "Uganda",
				"name"       : "Butambala District",
				"state_code" : "119"
			},
			{
				"countryName": "Uganda",
				"name"       : "Butebo District",
				"state_code" : "233"
			},
			{
				"countryName": "Uganda",
				"name"       : "Buvuma District",
				"state_code" : "120"
			},
			{
				"countryName": "Uganda",
				"name"       : "Buyende District",
				"state_code" : "226"
			},
			{
				"countryName": "Uganda",
				"name"       : "Central Region",
				"state_code" : "C"
			},
			{
				"countryName": "Uganda",
				"name"       : "Dokolo District",
				"state_code" : "317"
			},
			{
				"countryName": "Uganda",
				"name"       : "Eastern Region",
				"state_code" : "E"
			},
			{
				"countryName": "Uganda",
				"name"       : "Gomba District",
				"state_code" : "121"
			},
			{
				"countryName": "Uganda",
				"name"       : "Gulu District",
				"state_code" : "304"
			},
			{
				"countryName": "Uganda",
				"name"       : "Ibanda District",
				"state_code" : "417"
			},
			{
				"countryName": "Uganda",
				"name"       : "Iganga District",
				"state_code" : "203"
			},
			{
				"countryName": "Uganda",
				"name"       : "Isingiro District",
				"state_code" : "418"
			},
			{
				"countryName": "Uganda",
				"name"       : "Jinja District",
				"state_code" : "204"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kaabong District",
				"state_code" : "318"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kabale District",
				"state_code" : "404"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kabarole District",
				"state_code" : "405"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kaberamaido District",
				"state_code" : "213"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kagadi District",
				"state_code" : "427"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kakumiro District",
				"state_code" : "428"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kalangala District",
				"state_code" : "101"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kaliro District",
				"state_code" : "222"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kalungu District",
				"state_code" : "122"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kampala District",
				"state_code" : "102"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kamuli District",
				"state_code" : "205"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kamwenge District",
				"state_code" : "413"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kanungu District",
				"state_code" : "414"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kapchorwa District",
				"state_code" : "206"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kasese District",
				"state_code" : "406"
			},
			{
				"countryName": "Uganda",
				"name"       : "Katakwi District",
				"state_code" : "207"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kayunga District",
				"state_code" : "112"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kibaale District",
				"state_code" : "407"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kiboga District",
				"state_code" : "103"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kibuku District",
				"state_code" : "227"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kiruhura District",
				"state_code" : "419"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kiryandongo District",
				"state_code" : "421"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kisoro District",
				"state_code" : "408"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kitgum District",
				"state_code" : "305"
			},
			{
				"countryName": "Uganda",
				"name"       : "Koboko District",
				"state_code" : "319"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kole District",
				"state_code" : "325"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kotido District",
				"state_code" : "306"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kumi District",
				"state_code" : "208"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kween District",
				"state_code" : "228"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kyankwanzi District",
				"state_code" : "123"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kyegegwa District",
				"state_code" : "422"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kyenjojo District",
				"state_code" : "415"
			},
			{
				"countryName": "Uganda",
				"name"       : "Kyotera District",
				"state_code" : "125"
			},
			{
				"countryName": "Uganda",
				"name"       : "Lamwo District",
				"state_code" : "326"
			},
			{
				"countryName": "Uganda",
				"name"       : "Lira District",
				"state_code" : "307"
			},
			{
				"countryName": "Uganda",
				"name"       : "Luuka District",
				"state_code" : "229"
			},
			{
				"countryName": "Uganda",
				"name"       : "Luwero District",
				"state_code" : "104"
			},
			{
				"countryName": "Uganda",
				"name"       : "Lwengo District",
				"state_code" : "124"
			},
			{
				"countryName": "Uganda",
				"name"       : "Lyantonde District",
				"state_code" : "114"
			},
			{
				"countryName": "Uganda",
				"name"       : "Manafwa District",
				"state_code" : "223"
			},
			{
				"countryName": "Uganda",
				"name"       : "Maracha District",
				"state_code" : "320"
			},
			{
				"countryName": "Uganda",
				"name"       : "Masaka District",
				"state_code" : "105"
			},
			{
				"countryName": "Uganda",
				"name"       : "Masindi District",
				"state_code" : "409"
			},
			{
				"countryName": "Uganda",
				"name"       : "Mayuge District",
				"state_code" : "214"
			},
			{
				"countryName": "Uganda",
				"name"       : "Mbale District",
				"state_code" : "209"
			},
			{
				"countryName": "Uganda",
				"name"       : "Mbarara District",
				"state_code" : "410"
			},
			{
				"countryName": "Uganda",
				"name"       : "Mitooma District",
				"state_code" : "423"
			},
			{
				"countryName": "Uganda",
				"name"       : "Mityana District",
				"state_code" : "115"
			},
			{
				"countryName": "Uganda",
				"name"       : "Moroto District",
				"state_code" : "308"
			},
			{
				"countryName": "Uganda",
				"name"       : "Moyo District",
				"state_code" : "309"
			},
			{
				"countryName": "Uganda",
				"name"       : "Mpigi District",
				"state_code" : "106"
			},
			{
				"countryName": "Uganda",
				"name"       : "Mubende District",
				"state_code" : "107"
			},
			{
				"countryName": "Uganda",
				"name"       : "Mukono District",
				"state_code" : "108"
			},
			{
				"countryName": "Uganda",
				"name"       : "Nakapiripirit District",
				"state_code" : "311"
			},
			{
				"countryName": "Uganda",
				"name"       : "Nakaseke District",
				"state_code" : "116"
			},
			{
				"countryName": "Uganda",
				"name"       : "Nakasongola District",
				"state_code" : "109"
			},
			{
				"countryName": "Uganda",
				"name"       : "Namayingo District",
				"state_code" : "230"
			},
			{
				"countryName": "Uganda",
				"name"       : "Namisindwa District",
				"state_code" : "234"
			},
			{
				"countryName": "Uganda",
				"name"       : "Namutumba District",
				"state_code" : "224"
			},
			{
				"countryName": "Uganda",
				"name"       : "Napak District",
				"state_code" : "327"
			},
			{
				"countryName": "Uganda",
				"name"       : "Nebbi District",
				"state_code" : "310"
			},
			{
				"countryName": "Uganda",
				"name"       : "Ngora District",
				"state_code" : "231"
			},
			{
				"countryName": "Uganda",
				"name"       : "Northern Region",
				"state_code" : "N"
			},
			{
				"countryName": "Uganda",
				"name"       : "Ntoroko District",
				"state_code" : "424"
			},
			{
				"countryName": "Uganda",
				"name"       : "Ntungamo District",
				"state_code" : "411"
			},
			{
				"countryName": "Uganda",
				"name"       : "Nwoya District",
				"state_code" : "328"
			},
			{
				"countryName": "Uganda",
				"name"       : "Omoro District",
				"state_code" : "331"
			},
			{
				"countryName": "Uganda",
				"name"       : "Otuke District",
				"state_code" : "329"
			},
			{
				"countryName": "Uganda",
				"name"       : "Oyam District",
				"state_code" : "321"
			},
			{
				"countryName": "Uganda",
				"name"       : "Pader District",
				"state_code" : "312"
			},
			{
				"countryName": "Uganda",
				"name"       : "Pakwach District",
				"state_code" : "332"
			},
			{
				"countryName": "Uganda",
				"name"       : "Pallisa District",
				"state_code" : "210"
			},
			{
				"countryName": "Uganda",
				"name"       : "Rakai District",
				"state_code" : "110"
			},
			{
				"countryName": "Uganda",
				"name"       : "Rubanda District",
				"state_code" : "429"
			},
			{
				"countryName": "Uganda",
				"name"       : "Rubirizi District",
				"state_code" : "425"
			},
			{
				"countryName": "Uganda",
				"name"       : "Rukiga District",
				"state_code" : "431"
			},
			{
				"countryName": "Uganda",
				"name"       : "Rukungiri District",
				"state_code" : "412"
			},
			{
				"countryName": "Uganda",
				"name"       : "Sembabule District",
				"state_code" : "111"
			},
			{
				"countryName": "Uganda",
				"name"       : "Serere District",
				"state_code" : "232"
			},
			{
				"countryName": "Uganda",
				"name"       : "Sheema District",
				"state_code" : "426"
			},
			{
				"countryName": "Uganda",
				"name"       : "Sironko District",
				"state_code" : "215"
			},
			{
				"countryName": "Uganda",
				"name"       : "Soroti District",
				"state_code" : "211"
			},
			{
				"countryName": "Uganda",
				"name"       : "Tororo District",
				"state_code" : "212"
			},
			{
				"countryName": "Uganda",
				"name"       : "Wakiso District",
				"state_code" : "113"
			},
			{
				"countryName": "Uganda",
				"name"       : "Western Region",
				"state_code" : "W"
			},
			{
				"countryName": "Uganda",
				"name"       : "Yumbe District",
				"state_code" : "313"
			},
			{
				"countryName": "Uganda",
				"name"       : "Zombo District",
				"state_code" : "330"
			}
		],
		"UA": [
			{
				"countryName": "Ukraine",
				"name"       : "Autonomous Republic of Crimea",
				"state_code" : "43"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Cherkaska oblast",
				"state_code" : "71"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Chernihivska oblast",
				"state_code" : "74"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Chernivetska oblast",
				"state_code" : "77"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Dnipropetrovska oblast",
				"state_code" : "12"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Donetska oblast",
				"state_code" : "14"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Ivano-Frankivska oblast",
				"state_code" : "26"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Kharkivska oblast",
				"state_code" : "63"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Khersonska oblast",
				"state_code" : "65"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Khmelnytska oblast",
				"state_code" : "68"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Kirovohradska oblast",
				"state_code" : "35"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Kyiv",
				"state_code" : "30"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Kyivska oblast",
				"state_code" : "32"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Luhanska oblast",
				"state_code" : "09"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Lvivska oblast",
				"state_code" : "46"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Mykolaivska oblast",
				"state_code" : "48"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Odeska oblast",
				"state_code" : "51"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Poltavska oblast",
				"state_code" : "53"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Rivnenska oblast",
				"state_code" : "56"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Sumska oblast",
				"state_code" : "59"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Ternopilska oblast",
				"state_code" : "61"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Vinnytska oblast",
				"state_code" : "05"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Volynska oblast",
				"state_code" : "07"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Zakarpatska Oblast",
				"state_code" : "21"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Zaporizka oblast",
				"state_code" : "23"
			},
			{
				"countryName": "Ukraine",
				"name"       : "Zhytomyrska oblast",
				"state_code" : "18"
			}
		],
		"AE": [
			{
				"countryName": "United Arab Emirates",
				"name"       : "Abu Dhabi Emirate",
				"state_code" : "AZ"
			},
			{
				"countryName": "United Arab Emirates",
				"name"       : "Ajman Emirate",
				"state_code" : "AJ"
			},
			{
				"countryName": "United Arab Emirates",
				"name"       : "Dubai",
				"state_code" : "DU"
			},
			{
				"countryName": "United Arab Emirates",
				"name"       : "Fujairah",
				"state_code" : "FU"
			},
			{
				"countryName": "United Arab Emirates",
				"name"       : "Ras al-Khaimah",
				"state_code" : "RK"
			},
			{
				"countryName": "United Arab Emirates",
				"name"       : "Sharjah Emirate",
				"state_code" : "SH"
			},
			{
				"countryName": "United Arab Emirates",
				"name"       : "Umm al-Quwain",
				"state_code" : "UQ"
			}
		],
		"GB": [
			{
				"countryName": "United Kingdom",
				"name"       : "Aberdeen",
				"state_code" : "ABE"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Aberdeenshire",
				"state_code" : "ABD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Angus",
				"state_code" : "ANS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Antrim",
				"state_code" : "ANT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Antrim and Newtownabbey",
				"state_code" : "ANN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Ards",
				"state_code" : "ARD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Ards and North Down",
				"state_code" : "AND"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Argyll and Bute",
				"state_code" : "AGB"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Armagh City and District Council",
				"state_code" : "ARM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Armagh, Banbridge and Craigavon",
				"state_code" : "ABC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Ascension Island",
				"state_code" : "SH-AC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Ballymena Borough",
				"state_code" : "BLA"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Ballymoney",
				"state_code" : "BLY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Banbridge",
				"state_code" : "BNB"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Barnsley",
				"state_code" : "BNS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Bath and North East Somerset",
				"state_code" : "BAS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Bedford",
				"state_code" : "BDF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Belfast district",
				"state_code" : "BFS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Birmingham",
				"state_code" : "BIR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Blackburn with Darwen",
				"state_code" : "BBD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Blackpool",
				"state_code" : "BPL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Blaenau Gwent County Borough",
				"state_code" : "BGW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Bolton",
				"state_code" : "BOL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Bournemouth",
				"state_code" : "BMH"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Bracknell Forest",
				"state_code" : "BRC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Bradford",
				"state_code" : "BRD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Bridgend County Borough",
				"state_code" : "BGE"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Brighton and Hove",
				"state_code" : "BNH"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Buckinghamshire",
				"state_code" : "BKM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Bury",
				"state_code" : "BUR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Caerphilly County Borough",
				"state_code" : "CAY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Calderdale",
				"state_code" : "CLD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Cambridgeshire",
				"state_code" : "CAM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Carmarthenshire",
				"state_code" : "CMN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Carrickfergus Borough Council",
				"state_code" : "CKF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Castlereagh",
				"state_code" : "CSR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Causeway Coast and Glens",
				"state_code" : "CCG"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Central Bedfordshire",
				"state_code" : "CBF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Ceredigion",
				"state_code" : "CGN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Cheshire East",
				"state_code" : "CHE"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Cheshire West and Chester",
				"state_code" : "CHW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City and County of Cardiff",
				"state_code" : "CRF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City and County of Swansea",
				"state_code" : "SWA"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Bristol",
				"state_code" : "BST"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Derby",
				"state_code" : "DER"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Kingston upon Hull",
				"state_code" : "KHL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Leicester",
				"state_code" : "LCE"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of London",
				"state_code" : "LND"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Nottingham",
				"state_code" : "NGM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Peterborough",
				"state_code" : "PTE"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Plymouth",
				"state_code" : "PLY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Portsmouth",
				"state_code" : "POR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Southampton",
				"state_code" : "STH"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Stoke-on-Trent",
				"state_code" : "STE"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Sunderland",
				"state_code" : "SND"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Westminster",
				"state_code" : "WSM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of Wolverhampton",
				"state_code" : "WLV"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "City of York",
				"state_code" : "YOR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Clackmannanshire",
				"state_code" : "CLK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Coleraine Borough Council",
				"state_code" : "CLR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Conwy County Borough",
				"state_code" : "CWY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Cookstown District Council",
				"state_code" : "CKT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Cornwall",
				"state_code" : "CON"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "County Durham",
				"state_code" : "DUR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Coventry",
				"state_code" : "COV"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Craigavon Borough Council",
				"state_code" : "CGV"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Cumbria",
				"state_code" : "CMA"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Darlington",
				"state_code" : "DAL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Denbighshire",
				"state_code" : "DEN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Derbyshire",
				"state_code" : "DBY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Derry City and Strabane",
				"state_code" : "DRS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Derry City Council",
				"state_code" : "DRY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Devon",
				"state_code" : "DEV"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Doncaster",
				"state_code" : "DNC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Dorset",
				"state_code" : "DOR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Down District Council",
				"state_code" : "DOW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Dudley",
				"state_code" : "DUD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Dumfries and Galloway",
				"state_code" : "DGY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Dundee",
				"state_code" : "DND"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Dungannon and South Tyrone Borough Council",
				"state_code" : "DGN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "East Ayrshire",
				"state_code" : "EAY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "East Dunbartonshire",
				"state_code" : "EDU"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "East Lothian",
				"state_code" : "ELN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "East Renfrewshire",
				"state_code" : "ERW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "East Riding of Yorkshire",
				"state_code" : "ERY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "East Sussex",
				"state_code" : "ESX"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Edinburgh",
				"state_code" : "EDH"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "England",
				"state_code" : "ENG"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Essex",
				"state_code" : "ESS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Falkirk",
				"state_code" : "FAL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Fermanagh and Omagh",
				"state_code" : "FMO"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Fermanagh District Council",
				"state_code" : "FER"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Fife",
				"state_code" : "FIF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Flintshire",
				"state_code" : "FLN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Gateshead",
				"state_code" : "GAT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Glasgow",
				"state_code" : "GLG"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Gloucestershire",
				"state_code" : "GLS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Gwynedd",
				"state_code" : "GWN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Halton",
				"state_code" : "HAL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Hampshire",
				"state_code" : "HAM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Hartlepool",
				"state_code" : "HPL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Herefordshire",
				"state_code" : "HEF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Hertfordshire",
				"state_code" : "HRT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Highland",
				"state_code" : "HLD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Inverclyde",
				"state_code" : "IVC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Isle of Wight",
				"state_code" : "IOW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Isles of Scilly",
				"state_code" : "IOS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Kent",
				"state_code" : "KEN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Kirklees",
				"state_code" : "KIR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Knowsley",
				"state_code" : "KWL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Lancashire",
				"state_code" : "LAN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Larne Borough Council",
				"state_code" : "LRN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Leeds",
				"state_code" : "LDS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Leicestershire",
				"state_code" : "LEC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Limavady Borough Council",
				"state_code" : "LMV"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Lincolnshire",
				"state_code" : "LIN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Lisburn and Castlereagh",
				"state_code" : "LBC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Lisburn City Council",
				"state_code" : "LSB"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Liverpool",
				"state_code" : "LIV"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Barking and Dagenham",
				"state_code" : "BDG"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Barnet",
				"state_code" : "BNE"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Bexley",
				"state_code" : "BEX"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Brent",
				"state_code" : "BEN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Bromley",
				"state_code" : "BRY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Camden",
				"state_code" : "CMD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Croydon",
				"state_code" : "CRY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Ealing",
				"state_code" : "EAL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Enfield",
				"state_code" : "ENF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Hackney",
				"state_code" : "HCK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Hammersmith and Fulham",
				"state_code" : "HMF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Haringey",
				"state_code" : "HRY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Harrow",
				"state_code" : "HRW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Havering",
				"state_code" : "HAV"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Hillingdon",
				"state_code" : "HIL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Hounslow",
				"state_code" : "HNS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Islington",
				"state_code" : "ISL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Lambeth",
				"state_code" : "LBH"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Lewisham",
				"state_code" : "LEW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Merton",
				"state_code" : "MRT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Newham",
				"state_code" : "NWM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Redbridge",
				"state_code" : "RDB"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Richmond upon Thames",
				"state_code" : "RIC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Southwark",
				"state_code" : "SWK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Sutton",
				"state_code" : "STN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Tower Hamlets",
				"state_code" : "TWH"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Waltham Forest",
				"state_code" : "WFT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "London Borough of Wandsworth",
				"state_code" : "WND"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Magherafelt District Council",
				"state_code" : "MFT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Manchester",
				"state_code" : "MAN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Medway",
				"state_code" : "MDW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Merthyr Tydfil County Borough",
				"state_code" : "MTY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Metropolitan Borough of Wigan",
				"state_code" : "WGN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Mid and East Antrim",
				"state_code" : "MEA"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Mid Ulster",
				"state_code" : "MUL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Middlesbrough",
				"state_code" : "MDB"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Midlothian",
				"state_code" : "MLN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Milton Keynes",
				"state_code" : "MIK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Monmouthshire",
				"state_code" : "MON"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Moray",
				"state_code" : "MRY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Moyle District Council",
				"state_code" : "MYL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Neath Port Talbot County Borough",
				"state_code" : "NTL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Newcastle upon Tyne",
				"state_code" : "NET"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Newport",
				"state_code" : "NWP"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Newry and Mourne District Council",
				"state_code" : "NYM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Newry, Mourne and Down",
				"state_code" : "NMD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Newtownabbey Borough Council",
				"state_code" : "NTA"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Norfolk",
				"state_code" : "NFK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "North Ayrshire",
				"state_code" : "NAY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "North Down Borough Council",
				"state_code" : "NDN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "North East Lincolnshire",
				"state_code" : "NEL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "North Lanarkshire",
				"state_code" : "NLK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "North Lincolnshire",
				"state_code" : "NLN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "North Somerset",
				"state_code" : "NSM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "North Tyneside",
				"state_code" : "NTY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "North Yorkshire",
				"state_code" : "NYK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Northamptonshire",
				"state_code" : "NTH"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Northern Ireland",
				"state_code" : "NIR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Northumberland",
				"state_code" : "NBL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Nottinghamshire",
				"state_code" : "NTT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Oldham",
				"state_code" : "OLD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Omagh District Council",
				"state_code" : "OMH"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Orkney Islands",
				"state_code" : "ORK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Outer Hebrides",
				"state_code" : "ELS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Oxfordshire",
				"state_code" : "OXF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Pembrokeshire",
				"state_code" : "PEM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Perth and Kinross",
				"state_code" : "PKN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Poole",
				"state_code" : "POL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Powys",
				"state_code" : "POW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Reading",
				"state_code" : "RDG"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Redcar and Cleveland",
				"state_code" : "RCC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Renfrewshire",
				"state_code" : "RFW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Rhondda Cynon Taf",
				"state_code" : "RCT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Rochdale",
				"state_code" : "RCH"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Rotherham",
				"state_code" : "ROT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Royal Borough of Greenwich",
				"state_code" : "GRE"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Royal Borough of Kensington and Chelsea",
				"state_code" : "KEC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Royal Borough of Kingston upon Thames",
				"state_code" : "KTT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Rutland",
				"state_code" : "RUT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Saint Helena",
				"state_code" : "SH-HL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Salford",
				"state_code" : "SLF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Sandwell",
				"state_code" : "SAW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Scotland",
				"state_code" : "SCT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Scottish Borders",
				"state_code" : "SCB"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Sefton",
				"state_code" : "SFT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Sheffield",
				"state_code" : "SHF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Shetland Islands",
				"state_code" : "ZET"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Shropshire",
				"state_code" : "SHR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Slough",
				"state_code" : "SLG"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Solihull",
				"state_code" : "SOL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Somerset",
				"state_code" : "SOM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "South Ayrshire",
				"state_code" : "SAY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "South Gloucestershire",
				"state_code" : "SGC"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "South Lanarkshire",
				"state_code" : "SLK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "South Tyneside",
				"state_code" : "STY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Southend-on-Sea",
				"state_code" : "SOS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "St Helens",
				"state_code" : "SHN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Staffordshire",
				"state_code" : "STS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Stirling",
				"state_code" : "STG"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Stockport",
				"state_code" : "SKP"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Stockton-on-Tees",
				"state_code" : "STT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Strabane District Council",
				"state_code" : "STB"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Suffolk",
				"state_code" : "SFK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Surrey",
				"state_code" : "SRY"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Swindon",
				"state_code" : "SWD"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Tameside",
				"state_code" : "TAM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Telford and Wrekin",
				"state_code" : "TFW"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Thurrock",
				"state_code" : "THR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Torbay",
				"state_code" : "TOB"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Torfaen",
				"state_code" : "TOF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Trafford",
				"state_code" : "TRF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "United Kingdom",
				"state_code" : "UKM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Vale of Glamorgan",
				"state_code" : "VGL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Wakefield",
				"state_code" : "WKF"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Wales",
				"state_code" : "WLS"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Walsall",
				"state_code" : "WLL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Warrington",
				"state_code" : "WRT"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Warwickshire",
				"state_code" : "WAR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "West Berkshire",
				"state_code" : "WBK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "West Dunbartonshire",
				"state_code" : "WDU"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "West Lothian",
				"state_code" : "WLN"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "West Sussex",
				"state_code" : "WSX"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Wiltshire",
				"state_code" : "WIL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Windsor and Maidenhead",
				"state_code" : "WNM"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Wirral",
				"state_code" : "WRL"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Wokingham",
				"state_code" : "WOK"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Worcestershire",
				"state_code" : "WOR"
			},
			{
				"countryName": "United Kingdom",
				"name"       : "Wrexham County Borough",
				"state_code" : "WRX"
			}
		],
		"US": [
			{
				"countryName": "United States",
				"name"       : "Alabama",
				"state_code" : "AL"
			},
			{
				"countryName": "United States",
				"name"       : "Alaska",
				"state_code" : "AK"
			},
			{
				"countryName": "United States",
				"name"       : "American Samoa",
				"state_code" : "AS"
			},
			{
				"countryName": "United States",
				"name"       : "Arizona",
				"state_code" : "AZ"
			},
			{
				"countryName": "United States",
				"name"       : "Arkansas",
				"state_code" : "AR"
			},
			{
				"countryName": "United States",
				"name"       : "Baker Island",
				"state_code" : "UM-81"
			},
			{
				"countryName": "United States",
				"name"       : "California",
				"state_code" : "CA"
			},
			{
				"countryName": "United States",
				"name"       : "Colorado",
				"state_code" : "CO"
			},
			{
				"countryName": "United States",
				"name"       : "Connecticut",
				"state_code" : "CT"
			},
			{
				"countryName": "United States",
				"name"       : "Delaware",
				"state_code" : "DE"
			},
			{
				"countryName": "United States",
				"name"       : "District of Columbia",
				"state_code" : "DC"
			},
			{
				"countryName": "United States",
				"name"       : "Florida",
				"state_code" : "FL"
			},
			{
				"countryName": "United States",
				"name"       : "Georgia",
				"state_code" : "GA"
			},
			{
				"countryName": "United States",
				"name"       : "Guam",
				"state_code" : "GU"
			},
			{
				"countryName": "United States",
				"name"       : "Hawaii",
				"state_code" : "HI"
			},
			{
				"countryName": "United States",
				"name"       : "Howland Island",
				"state_code" : "UM-84"
			},
			{
				"countryName": "United States",
				"name"       : "Idaho",
				"state_code" : "ID"
			},
			{
				"countryName": "United States",
				"name"       : "Illinois",
				"state_code" : "IL"
			},
			{
				"countryName": "United States",
				"name"       : "Indiana",
				"state_code" : "IN"
			},
			{
				"countryName": "United States",
				"name"       : "Iowa",
				"state_code" : "IA"
			},
			{
				"countryName": "United States",
				"name"       : "Jarvis Island",
				"state_code" : "UM-86"
			},
			{
				"countryName": "United States",
				"name"       : "Johnston Atoll",
				"state_code" : "UM-67"
			},
			{
				"countryName": "United States",
				"name"       : "Kansas",
				"state_code" : "KS"
			},
			{
				"countryName": "United States",
				"name"       : "Kentucky",
				"state_code" : "KY"
			},
			{
				"countryName": "United States",
				"name"       : "Kingman Reef",
				"state_code" : "UM-89"
			},
			{
				"countryName": "United States",
				"name"       : "Louisiana",
				"state_code" : "LA"
			},
			{
				"countryName": "United States",
				"name"       : "Maine",
				"state_code" : "ME"
			},
			{
				"countryName": "United States",
				"name"       : "Maryland",
				"state_code" : "MD"
			},
			{
				"countryName": "United States",
				"name"       : "Massachusetts",
				"state_code" : "MA"
			},
			{
				"countryName": "United States",
				"name"       : "Michigan",
				"state_code" : "MI"
			},
			{
				"countryName": "United States",
				"name"       : "Midway Atoll",
				"state_code" : "UM-71"
			},
			{
				"countryName": "United States",
				"name"       : "Minnesota",
				"state_code" : "MN"
			},
			{
				"countryName": "United States",
				"name"       : "Mississippi",
				"state_code" : "MS"
			},
			{
				"countryName": "United States",
				"name"       : "Missouri",
				"state_code" : "MO"
			},
			{
				"countryName": "United States",
				"name"       : "Montana",
				"state_code" : "MT"
			},
			{
				"countryName": "United States",
				"name"       : "Navassa Island",
				"state_code" : "UM-76"
			},
			{
				"countryName": "United States",
				"name"       : "Nebraska",
				"state_code" : "NE"
			},
			{
				"countryName": "United States",
				"name"       : "Nevada",
				"state_code" : "NV"
			},
			{
				"countryName": "United States",
				"name"       : "New Hampshire",
				"state_code" : "NH"
			},
			{
				"countryName": "United States",
				"name"       : "New Jersey",
				"state_code" : "NJ"
			},
			{
				"countryName": "United States",
				"name"       : "New Mexico",
				"state_code" : "NM"
			},
			{
				"countryName": "United States",
				"name"       : "New York",
				"state_code" : "NY"
			},
			{
				"countryName": "United States",
				"name"       : "North Carolina",
				"state_code" : "NC"
			},
			{
				"countryName": "United States",
				"name"       : "North Dakota",
				"state_code" : "ND"
			},
			{
				"countryName": "United States",
				"name"       : "Northern Mariana Islands",
				"state_code" : "MP"
			},
			{
				"countryName": "United States",
				"name"       : "Ohio",
				"state_code" : "OH"
			},
			{
				"countryName": "United States",
				"name"       : "Oklahoma",
				"state_code" : "OK"
			},
			{
				"countryName": "United States",
				"name"       : "Oregon",
				"state_code" : "OR"
			},
			{
				"countryName": "United States",
				"name"       : "Palmyra Atoll",
				"state_code" : "UM-95"
			},
			{
				"countryName": "United States",
				"name"       : "Pennsylvania",
				"state_code" : "PA"
			},
			{
				"countryName": "United States",
				"name"       : "Puerto Rico",
				"state_code" : "PR"
			},
			{
				"countryName": "United States",
				"name"       : "Rhode Island",
				"state_code" : "RI"
			},
			{
				"countryName": "United States",
				"name"       : "South Carolina",
				"state_code" : "SC"
			},
			{
				"countryName": "United States",
				"name"       : "South Dakota",
				"state_code" : "SD"
			},
			{
				"countryName": "United States",
				"name"       : "Tennessee",
				"state_code" : "TN"
			},
			{
				"countryName": "United States",
				"name"       : "Texas",
				"state_code" : "TX"
			},
			{
				"countryName": "United States",
				"name"       : "United States Minor Outlying Islands",
				"state_code" : "UM"
			},
			{
				"countryName": "United States",
				"name"       : "United States Virgin Islands",
				"state_code" : "VI"
			},
			{
				"countryName": "United States",
				"name"       : "Utah",
				"state_code" : "UT"
			},
			{
				"countryName": "United States",
				"name"       : "Vermont",
				"state_code" : "VT"
			},
			{
				"countryName": "United States",
				"name"       : "Virginia",
				"state_code" : "VA"
			},
			{
				"countryName": "United States",
				"name"       : "Wake Island",
				"state_code" : "UM-79"
			},
			{
				"countryName": "United States",
				"name"       : "Washington",
				"state_code" : "WA"
			},
			{
				"countryName": "United States",
				"name"       : "West Virginia",
				"state_code" : "WV"
			},
			{
				"countryName": "United States",
				"name"       : "Wisconsin",
				"state_code" : "WI"
			},
			{
				"countryName": "United States",
				"name"       : "Wyoming",
				"state_code" : "WY"
			}
		],
		"UM": [],
		"UY": [
			{
				"countryName": "Uruguay",
				"name"       : "Artigas Department",
				"state_code" : "AR"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Canelones Department",
				"state_code" : "CA"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Cerro Largo Department",
				"state_code" : "CL"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Colonia Department",
				"state_code" : "CO"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Durazno Department",
				"state_code" : "DU"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Flores Department",
				"state_code" : "FS"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Florida Department",
				"state_code" : "FD"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Lavalleja Department",
				"state_code" : "LA"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Maldonado Department",
				"state_code" : "MA"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Montevideo Department",
				"state_code" : "MO"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Paysandú Department",
				"state_code" : "PA"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Río Negro Department",
				"state_code" : "RN"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Rivera Department",
				"state_code" : "RV"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Rocha Department",
				"state_code" : "RO"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Salto Department",
				"state_code" : "SA"
			},
			{
				"countryName": "Uruguay",
				"name"       : "San José Department",
				"state_code" : "SJ"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Soriano Department",
				"state_code" : "SO"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Tacuarembó Department",
				"state_code" : "TA"
			},
			{
				"countryName": "Uruguay",
				"name"       : "Treinta y Tres Department",
				"state_code" : "TT"
			}
		],
		"UZ": [
			{
				"countryName": "Uzbekistan",
				"name"       : "Andijan Region",
				"state_code" : "AN"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Bukhara Region",
				"state_code" : "BU"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Fergana Region",
				"state_code" : "FA"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Jizzakh Region",
				"state_code" : "JI"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Karakalpakstan",
				"state_code" : "QR"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Namangan Region",
				"state_code" : "NG"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Navoiy Region",
				"state_code" : "NW"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Qashqadaryo Region",
				"state_code" : "QA"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Samarqand Region",
				"state_code" : "SA"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Sirdaryo Region",
				"state_code" : "SI"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Surxondaryo Region",
				"state_code" : "SU"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Tashkent",
				"state_code" : "TK"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Tashkent Region",
				"state_code" : "TO"
			},
			{
				"countryName": "Uzbekistan",
				"name"       : "Xorazm Region",
				"state_code" : "XO"
			}
		],
		"VU": [
			{
				"countryName": "Vanuatu",
				"name"       : "Malampa",
				"state_code" : "MAP"
			},
			{
				"countryName": "Vanuatu",
				"name"       : "Penama",
				"state_code" : "PAM"
			},
			{
				"countryName": "Vanuatu",
				"name"       : "Sanma",
				"state_code" : "SAM"
			},
			{
				"countryName": "Vanuatu",
				"name"       : "Shefa",
				"state_code" : "SEE"
			},
			{
				"countryName": "Vanuatu",
				"name"       : "Tafea",
				"state_code" : "TAE"
			},
			{
				"countryName": "Vanuatu",
				"name"       : "Torba",
				"state_code" : "TOB"
			}
		],
		"VA": [],
		"VE": [
			{
				"countryName": "Venezuela",
				"name"       : "Amazonas",
				"state_code" : "Z"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Anzoátegui",
				"state_code" : "B"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Apure",
				"state_code" : "C"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Aragua",
				"state_code" : "D"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Barinas",
				"state_code" : "E"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Bolívar",
				"state_code" : "F"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Carabobo",
				"state_code" : "G"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Cojedes",
				"state_code" : "H"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Delta Amacuro",
				"state_code" : "Y"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Distrito Capital",
				"state_code" : "A"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Falcón",
				"state_code" : "I"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Federal Dependencies of Venezuela",
				"state_code" : "W"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Guárico",
				"state_code" : "J"
			},
			{
				"countryName": "Venezuela",
				"name"       : "La Guaira",
				"state_code" : "X"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Lara",
				"state_code" : "K"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Mérida",
				"state_code" : "L"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Miranda",
				"state_code" : "M"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Monagas",
				"state_code" : "N"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Nueva Esparta",
				"state_code" : "O"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Portuguesa",
				"state_code" : "P"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Sucre",
				"state_code" : "R"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Táchira",
				"state_code" : "S"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Trujillo",
				"state_code" : "T"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Yaracuy",
				"state_code" : "U"
			},
			{
				"countryName": "Venezuela",
				"name"       : "Zulia",
				"state_code" : "V"
			}
		],
		"VN": [
			{
				"countryName": "Vietnam",
				"name"       : "An Giang",
				"state_code" : "44"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bà Rịa-Vũng Tàu",
				"state_code" : "43"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bắc Giang",
				"state_code" : "54"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bắc Kạn",
				"state_code" : "53"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bạc Liêu",
				"state_code" : "55"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bắc Ninh",
				"state_code" : "56"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bến Tre",
				"state_code" : "50"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bình Dương",
				"state_code" : "57"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bình Định",
				"state_code" : "31"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bình Phước",
				"state_code" : "58"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Bình Thuận",
				"state_code" : "40"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Cà Mau",
				"state_code" : "59"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Cần Thơ",
				"state_code" : "CT"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Cao Bằng",
				"state_code" : "04"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Đà Nẵng",
				"state_code" : "DN"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Đắk Lắk",
				"state_code" : "33"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Đắk Nông",
				"state_code" : "72"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Điện Biên",
				"state_code" : "71"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Đồng Nai",
				"state_code" : "39"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Đồng Tháp",
				"state_code" : "45"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Gia Lai",
				"state_code" : "30"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hà Giang",
				"state_code" : "03"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hà Nam",
				"state_code" : "63"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hà Nội",
				"state_code" : "HN"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hà Tĩnh",
				"state_code" : "23"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hải Dương",
				"state_code" : "61"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hải Phòng",
				"state_code" : "HP"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hậu Giang",
				"state_code" : "73"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hồ Chí Minh",
				"state_code" : "SG"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hòa Bình",
				"state_code" : "14"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Hưng Yên",
				"state_code" : "66"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Khánh Hòa",
				"state_code" : "34"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Kiên Giang",
				"state_code" : "47"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Kon Tum",
				"state_code" : "28"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Lai Châu",
				"state_code" : "01"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Lâm Đồng",
				"state_code" : "35"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Lạng Sơn",
				"state_code" : "09"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Lào Cai",
				"state_code" : "02"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Long An",
				"state_code" : "41"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Nam Định",
				"state_code" : "67"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Nghệ An",
				"state_code" : "22"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Ninh Bình",
				"state_code" : "18"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Ninh Thuận",
				"state_code" : "36"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Phú Thọ",
				"state_code" : "68"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Phú Yên",
				"state_code" : "32"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Quảng Bình",
				"state_code" : "24"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Quảng Nam",
				"state_code" : "27"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Quảng Ngãi",
				"state_code" : "29"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Quảng Ninh",
				"state_code" : "13"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Quảng Trị",
				"state_code" : "25"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Sóc Trăng",
				"state_code" : "52"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Sơn La",
				"state_code" : "05"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Tây Ninh",
				"state_code" : "37"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Thái Bình",
				"state_code" : "20"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Thái Nguyên",
				"state_code" : "69"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Thanh Hóa",
				"state_code" : "21"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Thừa Thiên-Huế",
				"state_code" : "26"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Tiền Giang",
				"state_code" : "46"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Trà Vinh",
				"state_code" : "51"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Tuyên Quang",
				"state_code" : "07"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Vĩnh Long",
				"state_code" : "49"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Vĩnh Phúc",
				"state_code" : "70"
			},
			{
				"countryName": "Vietnam",
				"name"       : "Yên Bái",
				"state_code" : "06"
			}
		],
		"VG": [],
		"VI": [
			{
				"countryName": "Virgin Islands (US)",
				"name"       : "Saint Croix",
				"state_code" : "SC"
			},
			{
				"countryName": "Virgin Islands (US)",
				"name"       : "Saint John",
				"state_code" : "SJ"
			},
			{
				"countryName": "Virgin Islands (US)",
				"name"       : "Saint Thomas",
				"state_code" : "ST"
			}
		],
		"WF": [],
		"EH": [],
		"YE": [
			{
				"countryName": "Yemen",
				"name"       : "'Adan",
				"state_code" : "AD"
			},
			{
				"countryName": "Yemen",
				"name"       : "'Amran",
				"state_code" : "AM"
			},
			{
				"countryName": "Yemen",
				"name"       : "Abyan",
				"state_code" : "AB"
			},
			{
				"countryName": "Yemen",
				"name"       : "Al Bayda'",
				"state_code" : "BA"
			},
			{
				"countryName": "Yemen",
				"name"       : "Al Hudaydah",
				"state_code" : "HU"
			},
			{
				"countryName": "Yemen",
				"name"       : "Al Jawf",
				"state_code" : "JA"
			},
			{
				"countryName": "Yemen",
				"name"       : "Al Mahrah",
				"state_code" : "MR"
			},
			{
				"countryName": "Yemen",
				"name"       : "Al Mahwit",
				"state_code" : "MW"
			},
			{
				"countryName": "Yemen",
				"name"       : "Dhamar",
				"state_code" : "DH"
			},
			{
				"countryName": "Yemen",
				"name"       : "Hadhramaut",
				"state_code" : "HD"
			},
			{
				"countryName": "Yemen",
				"name"       : "Hajjah",
				"state_code" : "HJ"
			},
			{
				"countryName": "Yemen",
				"name"       : "Ibb",
				"state_code" : "IB"
			},
			{
				"countryName": "Yemen",
				"name"       : "Lahij",
				"state_code" : "LA"
			},
			{
				"countryName": "Yemen",
				"name"       : "Ma'rib",
				"state_code" : "MA"
			},
			{
				"countryName": "Yemen",
				"name"       : "Raymah",
				"state_code" : "RA"
			},
			{
				"countryName": "Yemen",
				"name"       : "Saada",
				"state_code" : "SD"
			},
			{
				"countryName": "Yemen",
				"name"       : "Sana'a",
				"state_code" : "SN"
			},
			{
				"countryName": "Yemen",
				"name"       : "Sana'a",
				"state_code" : "SA"
			},
			{
				"countryName": "Yemen",
				"name"       : "Shabwah",
				"state_code" : "SH"
			},
			{
				"countryName": "Yemen",
				"name"       : "Socotra",
				"state_code" : "SU"
			},
			{
				"countryName": "Yemen",
				"name"       : "Ta'izz",
				"state_code" : "TA"
			}
		],
		"ZM": [
			{
				"countryName": "Zambia",
				"name"       : "Central Province",
				"state_code" : "02"
			},
			{
				"countryName": "Zambia",
				"name"       : "Copperbelt Province",
				"state_code" : "08"
			},
			{
				"countryName": "Zambia",
				"name"       : "Eastern Province",
				"state_code" : "03"
			},
			{
				"countryName": "Zambia",
				"name"       : "Luapula Province",
				"state_code" : "04"
			},
			{
				"countryName": "Zambia",
				"name"       : "Lusaka Province",
				"state_code" : "09"
			},
			{
				"countryName": "Zambia",
				"name"       : "Muchinga Province",
				"state_code" : "10"
			},
			{
				"countryName": "Zambia",
				"name"       : "Northern Province",
				"state_code" : "05"
			},
			{
				"countryName": "Zambia",
				"name"       : "Northwestern Province",
				"state_code" : "06"
			},
			{
				"countryName": "Zambia",
				"name"       : "Southern Province",
				"state_code" : "07"
			},
			{
				"countryName": "Zambia",
				"name"       : "Western Province",
				"state_code" : "01"
			}
		],
		"ZW": [
			{
				"countryName": "Zimbabwe",
				"name"       : "Bulawayo Province",
				"state_code" : "BU"
			},
			{
				"countryName": "Zimbabwe",
				"name"       : "Harare Province",
				"state_code" : "HA"
			},
			{
				"countryName": "Zimbabwe",
				"name"       : "Manicaland",
				"state_code" : "MA"
			},
			{
				"countryName": "Zimbabwe",
				"name"       : "Mashonaland Central Province",
				"state_code" : "MC"
			},
			{
				"countryName": "Zimbabwe",
				"name"       : "Mashonaland East Province",
				"state_code" : "ME"
			},
			{
				"countryName": "Zimbabwe",
				"name"       : "Mashonaland West Province",
				"state_code" : "MW"
			},
			{
				"countryName": "Zimbabwe",
				"name"       : "Masvingo Province",
				"state_code" : "MV"
			},
			{
				"countryName": "Zimbabwe",
				"name"       : "Matabeleland North Province",
				"state_code" : "MN"
			},
			{
				"countryName": "Zimbabwe",
				"name"       : "Matabeleland South Province",
				"state_code" : "MS"
			},
			{
				"countryName": "Zimbabwe",
				"name"       : "Midlands Province",
				"state_code" : "MI"
			}
		]
	}
};

export default stateConfig;
