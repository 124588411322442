import React from "react";
import { Tooltip } from "@mui/material";
import { SxProps, Theme } from "@mui/material";

const TooltipComponent = (props: { placement: any; text: any; children: any;sx?: SxProps<Theme>; }) => {

	const { placement, text, children } = props;
  
	return (
		<>
			{text ? <Tooltip
				arrow
				title={text}
				placement={placement ? placement : "top"}
				sx = {props.sx}
			>{children && children}</Tooltip> : children}
		</>
	);

};


export default TooltipComponent;