import axiosInterceptor from "./axiosInterceptor";

const ApiRequest = async (method: any, url: any, data: any = null) => {

	return new Promise((resolve, reject) => {

		try {

			switch (method) {

			case "get":

				axiosInterceptor.get(url).then((response: any) => {

					return resolve(response.data);

				}).catch((error: any) => {

					return reject(error);

				});

				break;

			case "post":

				axiosInterceptor.post(url, data).then((response: any) => {

					return resolve(response.data);

				}).catch((error: any) => {

					return reject(error);

				});

				break;

			case "put":

				axiosInterceptor.put(url, data).then((response: any) => {

					return resolve(response.data);

				}).catch((error: any) => {

					return reject(error);

				});

				break;

			case "delete":

				axiosInterceptor.delete(url).then((response: any) => {

					return resolve(response.data);

				}).catch((error: any) => {

					return reject(error);

				});

				break;

			default:

				return reject("Invalid method");

			}


		} catch (error) {

			console.error(error);

			return reject(error);

		}

	});

};

export default ApiRequest;