import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import { Button, Container, Typography, useTheme } from "@mui/material";
import DataTable from "react-data-table-component";
import config from "../../../config/appConfig";
import Back from "../../../components/UI/Back";
// import DeleteModal from "../../../components/UI/DeleteModal";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import TooltipComponent from "../../../components/UI/Tooltip";
import { FaExclamationTriangle, FaPen, FaTrash } from "react-icons/fa";
import { FaRotateRight } from "react-icons/fa6";
import ModalComponent from "../../../components/modal";
/*
 * import { ModalTitle } from "react-bootstrap";
 * import { size } from "lodash";
 */

const ListCourses = () => {

	const [ isListCourseDeleteModelOpen, setIsListCourseDeleteModelOpen ] = useState(false);
	const [ courseOptions, setCourseOptions ] = useState<any>([]);
	const [ deleteCourseData, setDeleteCourseData ] = useState<any>({});
	const [ isLoading, setIsLoading ] = useState<boolean>(false);

	const theme: any = useTheme();
	const navigate = useNavigate();

	// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
	const { DataTableStyles } = require("../../../Utils/utils");
	
	useEffect(() => {

		fetchAllCourses();

	}, []);

	const fetchAllCourses = () => {

		setIsLoading(true);
		
		ApiRequest("get", APIUrl.getAllCourse).then((response: any) => {

			setIsLoading(false);

			if (response) {

				setCourseOptions(response);

			}

		}).catch((error: any) => {

			console.error("Error fetching data", error);
			setIsLoading(false);
			setCourseOptions([]);

		});
	
	};

	const cellRenderer = (text: any) => {

		const cellText = String(text);


		return <> {
			cellText?.length > 25 ? <>
				<TooltipComponent text={cellText} placement="top-start">
					<div>{cellText.substring(0, 25) + "..."}</div>
				</TooltipComponent>
			</> : cellText
		}
		</>;
	
	};

	const editCourse = (row: any) => {
		
		
		 navigate(config.routes.editCourse, { state: { courseData: JSON.stringify(row) } });
		
	};

	const generalSortFunction = (key: string) => (a: any, b: any) => {

		const valueA = a[key];
		const valueB = b[key];
	  
		// Handle cases where values are not present
		if (valueA === undefined || valueB === undefined) return 0;
	  
		// If both values are numbers
		if (!isNaN(valueA) && !isNaN(valueB)) {

		  return parseFloat(valueA) - parseFloat(valueB);
		
		}
	  
		// Otherwise, treat them as strings
		return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
	  
	};

	const columns: any = [
		{
			name        : "Course Name",
			selector    : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
			cell        : (row: any) => cellRenderer(row.courseName ? row.courseName : ""),
			Grow        : 1,
			sortable    : true,
			sortFunction: generalSortFunction("courseName")
		},
		{
			name        : "Module Count",
			selector    : (row:any) => row.moduleDetails && row.moduleDetails?.length ?	row.moduleDetails?.length : 0,
			center      : true,
			sortable    : true,
			sortFunction: generalSortFunction("moduleDetails")
		},
		{
			name        : "Total Lessons",
			selector    : (row:any) => row?.lessonCount,
			center      : true,
			sortable    : true,
			sortFunction: generalSortFunction("lessonCount")

		},
		{
			name        : "Total Duration",
			selector    : (row:any) => row?.totalHours,
			center      : true,
			sortable    : true,
			sortFunction: generalSortFunction("totalHours")
		},
		{
			name  : "Created At",
			center: true,
			Grow  : 1
		},
		{
			name    : "Updated At",
			selector: (row:any) => row.updatedAt ? row.updatedAt : "N/A",
			center  : true,
			Grow    : 1
		},
		{
			name: "Action",

			center: true,
			cell  : (params: any)=>
				<div className="sh-fontawesome-edit-delete">
					<TooltipComponent text="Edit Course" placement="top-start">
						<span
							className="sh-fontawesome-edit"
						>
							<FaPen size={15} onClick={()=> editCourse(params)} color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>
					<TooltipComponent text="Delete Course" placement="top-start" >
						<span
							className="sh-fontawesome-edit"
						>
							<FaTrash size={15} onClick={() => {

								setIsListCourseDeleteModelOpen(true);
								setDeleteCourseData(params);
						
							}} color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>
		 		</div>
		 
            
		}
    
	];


	const handleDelete = () => {

		toast.success(`Course Name "${deleteCourseData.courseName}" deleted successfully!`);

		setIsListCourseDeleteModelOpen(false);
	
	};

	const handleRotate = () => {

		fetchAllCourses();
	
	};
    
	return (
		<Container >
			<Row>
				<Col md={6} sm={6} xl={6} xs={6} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Back
							props={{
								route  : config.routes.dashboard,
								state  : null,
								toolTip: "Back to Admin Dashboard",
								text   : "Back to admin dashboard"
							}}
						/>
					</div>
				</Col>
				<Col
					md={6} sm={6} xl={6} xs={6}
					className="sh-refresh-rotate-icon">
					<Button style={{ backgroundColor: theme?.palette?.secondary.main, color: theme.palette.background.paper }} onClick={handleRotate} className="sh-list-lesson-refresh-icon-button">
						<FaRotateRight color={theme.palette.text.primary} />
					</Button>
				</Col>
			</Row>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}
					>
						<Typography variant="h2">
						List Courses
						</Typography>
					</div>
				</Col>
			</Row>
			<Row className="module-list-data-table" >
				<Col className="module-list-data-table-col">
					<DataTable
						columns={columns}
						progressPending={isLoading}
						progressComponent={<div className="sh-table-progress-con"><Typography variant="h4">Loading...</Typography></div>}
						data={courseOptions}
						customStyles={DataTableStyles}
						fixedHeader
						pagination
						paginationPerPage={10}
					/>
				</Col>
			</Row>
			{/* <DeleteModal
				isListCourseDeleteModelOpen = {isListCourseDeleteModelOpen}
				setIsListCourseDeleteModelOpen ={setIsListCourseDeleteModelOpen}
				handleDelete={handleDelete}
				deleteContentPlaceHolder={{
					content: deleteCourseData.courseName,
					type   : "Course"
				}}
			/> */}
			<ModalComponent
				// isListCourseDeleteModelOpen={isListCourseDeleteModelOpen}
				show={isListCourseDeleteModelOpen}
				size="lg"
				closeButton={true}
				onHide={() => setIsListCourseDeleteModelOpen(false)}
				icon={FaExclamationTriangle}
				modalTitle="Confirm Delete"
				modalContent={<Typography variant="h3" style={{ color: theme.palette.text.primary }}>
					Are you sure you want to delete this {deleteCourseData?.courseName} ?</Typography>}
				modalFooter={
					<div className="" style={{ display: "flex", flexDirection: "row" }}>
				 		<div style={{ paddingRight: "10px" }}>
							<Button variant="outlined" onClick={() => setIsListCourseDeleteModelOpen(false)} style={{ paddingRight: "10px" }}>Cancel</Button>
						</div>
						<div>
				 			<Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
				 		</div>
				 	</div>
				}

				// closeButton: true

				/*
				 * props = {{
				 * 	icon        : FaExclamationTriangle,
				 * 	modalTitle  : "Confirm Delete",
				 * 	modalContent: <Typography variant="h3" style={{ color: theme.palette.text.primary }}>
				 * 		Are you sure you want to delete this {deleteCourseData?.courseName} ?</Typography>,
				 * 	modalFooter:
				 * 	<div className="" style={{ display: "flex", flexDirection: "row" }}>
				 * 		<div style={{ paddingRight: "10px" }}>
				 * 			<Button variant="outlined" onClick={() => setIsListCourseDeleteModelOpen(false)} style={{ paddingRight: "10px" }}>Cancel</Button>
				 * 		</div>
				 * 		<div>
				 * 			<Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
				 * 		</div>
				 * 	</div>,
				 * 	backdrop   : true,
				 * 	size       : "lg"
				 * }}
				 * onHide={() => setIsListCourseDeleteModelOpen(false)}
				 */
			/>
		</Container>
        
	);

};
	

export default ListCourses;

