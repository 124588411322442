import React, { useState } from "react";
import { Typography, Button, useMediaQuery } from "@mui/material";
import { Col, Row, Progress } from "rsuite";
import ClearIcon from "@mui/icons-material/Clear";
import Select from "../../../components/UI/Select";
import config from "../../../config/appConfig";
import DropZone from "../../../components/UI/DropZone";
import { useTheme } from "@mui/system";
import Form from "react-bootstrap/Form";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import { useEffect } from "react";
import axios from "axios";
import ModalComponent from "../../../components/modal";
import MainButton from "../../../components/UI/extend/MainButton";
// import { error } from "console";

const AddModule = ({
	isAddModuleModelOpen,
	setIsAddModuleModelOpen,
	courseOptionsList
}: any) => {

	const theme: any = useTheme();

	const [ moduleName, setModuleName ] = useState<string>("");
	const [ moduleDescription, setModuleDescription ] = useState<string>("");
	const [ modulePrice, setModulePrice ] = useState<any>(null);
	const [ modulePriceInUSD, setModulePriceInUSD ] = useState<any>(null);
	const [ otherDescription, setOtherDescription ] = useState<any>("");
	const [ otherDescriptionArray, setOtherDescriptionArray ] = useState<any>([]);
	const [ isError, setIsError ] = useState<boolean>(false);
	const [ errorMessage, setErrorMessage ] = useState<string>("");
	const [ selectedCourse, setSelectedCourse ] = useState<any>(null);
	const [ courseOptions, setCourseOptions ] = useState<any>([]);
	const [ fileDetails, setFileDetails ] = useState<any>(null);
	const [ isUploadedMediaValid, setIsUploadedMediaValid ] = useState<boolean>(false);
	const [ uploadPostMediaName, setUploadPostMediaName ] = useState("");
	const [ isFileUploading, setIsFileUploading ] = useState<boolean>(false);
	const [ uploadProgress, setUploadProgress ] = useState<number>(0);
	const [ moduleId, setModuleId ] = useState<any>(null);
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));


	useEffect(() => {

		if (courseOptionsList && courseOptionsList.length > 0) {

			setCourseOptions(courseOptionsList);

		}
		//  setIsUploadedMediaValid(true);
		  setUploadPostMediaName(uploadPostMediaName);
		
	
	}, [ courseOptionsList ]);


	const handleInputChange = (e: any) => {

		setOtherDescription(e?.target?.value);

	};

	const handleKeyDown = (e: any) => {

		if(e.key === "Enter" && otherDescription?.trim() !== "") {

			setOtherDescriptionArray((prevDescriptionValues:any) =>[ ...prevDescriptionValues, otherDescription ]);
			setOtherDescription("");
			e.preventDefault();
		
		}
	
	};

	const handleDelete = (item: any) => () => {

		setOtherDescriptionArray((items: any) => items.filter((values: any, index: number) => index !== item));
	
	};

	const postMediaDrop = (acceptedFiles: any) => {

		if (acceptedFiles.length > 0) {

			if (config?.acceptedImageVideoFormats.includes(acceptedFiles[0].type)) {

				setUploadPostMediaName(acceptedFiles[0].name);
				setFileDetails(acceptedFiles[0]);
				setIsUploadedMediaValid(true);
	
			} else {

				setIsUploadedMediaValid(false);
				setUploadPostMediaName("File type not supported");
				console.error("File type not supported");

				// toast.error("Invalid file type");

			}

		}

	};

	const handleUploadModuleIcon = () => {

		setIsFileUploading(true);

		if (fileDetails) {

			const payload: any = {
				"moduleData": {
					"courseId"  : selectedCourse?.value,
					"moduleIcon": fileDetails.name,
					"iconType"  : fileDetails.type,
					"moduleName": moduleName,
					"moduleDesc": moduleDescription
				}
			};

			// if (state?._id) {

			// 	payload["moduleData"]["_id"] = state?._id;

			// }
			

			ApiRequest("post", APIUrl.getSignedUrlUploadModuleIcon, payload).then(async (response: any) => {

				if (response) {

					const link = response?.link;
					const moduleId = response?.id;

					setModuleId(moduleId);

					const onUploadProgress = (progressEvent: any) => {
	
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						setUploadProgress(percentCompleted);
		
					};

					await axios.put(link, fileDetails, { headers: { "Content-Type": fileDetails.type }, onUploadProgress: onUploadProgress });

					setIsFileUploading(false);

				}

			}).catch((error: any) => {

				setIsFileUploading(false);

				console.error("Error in uploading module icon", error);

			});

		}

	};

	const handleAddModule = () => {
		
		if(!selectedCourse?.value || moduleName === "" || modulePrice === "" || modulePriceInUSD === ""){

			setIsError(true);
			setErrorMessage("* Fill All Required Fields");
			
			return;
			
		}

		if (moduleId) {

			const payload = {
				"moduleData":
				{
					courseId        : selectedCourse?.value,
					moduleName      : moduleName,
					moduleDesc      : moduleDescription,
					modulePrice     : modulePrice,
					modulePriceInUSD: modulePriceInUSD,
					otherInfo       : { skillSet: otherDescriptionArray },
					_id             : moduleId ? moduleId : moduleId
				}
			};

			ApiRequest("post", APIUrl.updateModule, payload).then((response: any) => {

				if (response) {

					setIsAddModuleModelOpen(false);

				}

			}).catch((error: any) => {

				console.error("Error in updating module", error);

			});
			

		} else {

			const payload = {
				"moduleData":
				{
					courseId        : selectedCourse?.value,
					moduleName      : moduleName,
					moduleDesc      : moduleDescription,
					modulePrice     : modulePrice,
					modulePriceInUSD: modulePriceInUSD,
					otherInfo       : { skillSet: otherDescriptionArray }
				}
			};

			ApiRequest("post", APIUrl.addNewModule, payload).then((response: any) => {

				if (response) {

					setIsAddModuleModelOpen(false);

				}

			}).catch((error: any) => {

				console.error("Error in adding module", error);

			});

		}
		setIsError(false);
		setErrorMessage("");
		setSelectedCourse(null);
		setModuleName("");
		setModulePrice(null);
		setModulePriceInUSD(null);
		setModuleDescription("");
		setOtherDescriptionArray([]);
		setOtherDescription("");


	};

	const handleModulePrice = (e: any, currency: any) => {

		const value = e?.target?.value;

		const numericvalue = parseFloat(value);

		if (value === "" || (numericvalue > 0) && !isNaN(numericvalue)){

			if(currency === "INR"){

				setModulePrice(value);
		
			} else if(currency === "USD"){

				setModulePriceInUSD(value);
		
			}
		
		} else {
      
			setIsError(true);
			setErrorMessage("Please enter valid price");
		
		}
		

	};
	
	const handleClose = () =>{
		
		setIsError(false);
		setErrorMessage("");
		setSelectedCourse(null);
		setModuleName("");
		setModuleDescription("");
		setModulePrice(null);
		setModulePriceInUSD(null);
		setOtherDescriptionArray([]);
		setOtherDescription("");
		setOtherDescriptionArray([]);
		setUploadPostMediaName("");
		setFileDetails(null);
		setIsUploadedMediaValid(false);
		setIsFileUploading(false);
		setUploadProgress(0);
		setModuleId(null);
		setIsAddModuleModelOpen(false);
		

	};

	return (
		
		<div >
			<ModalComponent
				show = {isAddModuleModelOpen}
				closeButton = {true}
				size = "lg"
				onHide = {handleClose}
				modalTitle = {
					<Typography variant="h4" className="sh-modal-title">Create New Module</Typography>
				}
				modalContent = {
					<div style={{ maxHeight: "70vh", overflowY: "auto", padding: "20px" }}>
						<Row className="sh-add-module-select-module-con1">
							<Col className="sh-add-select-course-con">
								<Typography variant="h5" className="sh-add-module-label">Select Course <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
								<Select
									className="sh-add-module-select-course"
									// options={config?.addLessonOptions}
									options={courseOptions}
									value={selectedCourse}
									handleChange={(e: any) => setSelectedCourse(e)}
									placeholder="Select Course"
								/>
							</Col>
							<Col className="sh-add-module-name-con">
								<Typography variant="h5" className="sh-add-module-name-label"> Module Name <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span> </Typography>
								<Form.Control
						    style={{ backgroundColor: theme?.palette?.background.paper, color: theme?.palette?.text.primary, height: "39px", width: "100%" }}
									className="sh-add-module-name-text-field custom-placeholder"
									id="outlined-basic"
									placeholder="Enter Module Name"
									value={moduleName}
									onChange={(e: any) => setModuleName(e?.target?.value)}
									color="secondary"
									onFocus={(e: any) => {

										try {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "2px";
											e.target.style.fontSize = "13px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
									
										} catch (error: any) {

											console.error("Error on focus", error);

										}
							
									}}
									onBlur={(e: any) => {

										try {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";
									
										} catch (error: any) {

											console.error("Error on blur", error);

										}
							
									}}
								/>
							</Col>
						</Row>
						<Row className="sh-add-module-description-con-row">
							<Col className="sh-add-module-description-con-col">
								<Typography variant="h5" className="sh-add-module-description-label">Module Description</Typography>
								<Form.Control
							
									style={{ backgroundColor: theme?.palette?.background.paper, color: theme?.palette?.text.primary }}
									className="sh-add-module-description-text-area sh-add-module-font-size custom-placeholder"
									value={moduleDescription}
									onChange={(e: any) => {

										setModuleDescription(e?.target?.value);

									}}
									placeholder="Enter Module Description"
									onFocus={(e: any) => {

										try {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "2px";
											e.target.style.fontSize = "13px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
									
										} catch (error: any) {

											console.error("Error on focus", error);

										}
							
									}}
									onBlur={(e: any) => {

										try {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";
									
										} catch (error: any) {

											console.error("Error on blur", error);

										}
							
									}}
								/>
							</Col>
						</Row>
						<Row className="sh-add-module-multi-description-add-con sh-add-module-multi-description-pad " >
							<Typography variant="h5" className="sh-add-module-multi-description-label">Other Description Info</Typography>
							<Form.Control
								value={otherDescription}
								style={{ backgroundColor: theme?.palette?.background.paper, color: theme?.palette?.text.primary }}
								onChange={handleInputChange}
								onKeyDown={handleKeyDown}
								placeholder="Type and press enter to add description"
								color="secondary"
								className="sh-add-module-name-text-field custom-placeholder"
								id="outlined-basic"
								onFocus={(e: any) => {

									try {

										const focusColor: string = theme.palette.secondary.main;

										e.target.style.borderColor = focusColor;
										e.target.style.borderWidth = "2px";
										e.target.style.fontSize = "13px";
										e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
								
									} catch (error: any) {

										console.error("Error on focus", error);

									}
						
								}}
								onBlur={(e: any) => {

									try {

										e.target.style.borderColor = "";
										e.target.style.boxShadow = "";
								
									} catch (error: any) {

										console.error("Error on blur", error);

									}
						
								}}
						
							/>
							<div>
								{otherDescriptionArray.length > 0 && otherDescriptionArray.map((data: any, index: number) =>
									<div
										key={index}
										className="sh-add-module-multi-description-chip-con"
									>
										{data}
										<ClearIcon className="sh-add-module-multi-description-chip-close-icon" onClick={handleDelete(index)} />
									</div>
								)
								}
							</div>
						
						</Row>
						<Row className="sh-add-module-multi-description-add-con sh-add-module-pad">
							<Typography variant="h5" className="sh-add-module-multi-description-label ">Module Price <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
							<div
								className="sh-module-moduleprice">
								<Form.Control
									style={{ backgroundColor: theme?.palette?.background.paper, color: theme?.palette?.text.primary }}
									className="sh-add-module-name-text-field custom-placeholder"
									id="outlined-basic"
									placeholder="Enter Module Price"
									value={modulePrice}
									type="number"
									min="0"
									onChange={(e: any) => handleModulePrice(e, "INR")}
									color="secondary"
									onFocus={(e: any) => {

										try {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "2px";
											e.target.style.fontSize = "13px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
									
										} catch (error: any) {

											console.error("Error on focus", error);

										}
							
									}}
									onBlur={(e: any) => {

										try {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";
									
										} catch (error: any) {

											console.error("Error on blur", error);

										}
							
									}}
								/>
								<style>
									{` .custom-placeholder::placeholder {
							color: ${theme.palette.text.secondary};
							opacity: 1;
							font-size: 14px;
							}`}
								</style>
							</div>
						</Row>
						<Row className="sh-add-module-multi-description-add-con sh-add-module-pad">
							<Typography variant="h5" className="sh-add-module-multi-description-label ">Module Price in USD <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography>
							<div
								className="sh-module-moduleprice">
								<Form.Control
									style={{ backgroundColor: theme?.palette?.background.paper, color: theme?.palette?.text.primary }}
									className="sh-add-module-name-text-field custom-placeholder"
									id="outlined-basic"
									placeholder="Enter Module Price"
									value={modulePrice}
									type="number"
									min="0"
									onChange={(e: any) => handleModulePrice(e, "USD")}
									color="secondary"
									onFocus={(e: any) => {

										try {

											const focusColor: string = theme.palette.secondary.main;

											e.target.style.borderColor = focusColor;
											e.target.style.borderWidth = "2px";
											e.target.style.fontSize = "13px";
											e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;
									
										} catch (error: any) {

											console.error("Error on focus", error);

										}
							
									}}
									onBlur={(e: any) => {

										try {

											e.target.style.borderColor = "";
											e.target.style.boxShadow = "";
									
										} catch (error: any) {

											console.error("Error on blur", error);

										}
							
									}}
								/>
								<style>
									{` .custom-placeholder::placeholder {
							color: ${theme.palette.text.secondary};
							opacity: 1;
							font-size: 14px;
							}`}
								</style>
							</div>
						</Row>
						<Row>
							<Col className="sh-add-module-media-upload-dropzone-col-con " >
								<div className="sh-add-module-media-upload-dropzone-padding" >
									<DropZone
										dropMedia={postMediaDrop}
										isUploadedMediaValid={isUploadedMediaValid}
										uploadPostMediaName={ uploadPostMediaName}
										acceptFileType={config?.acceptedImageFormats}
										accept={config?.acceptedFilesImages}
										style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, height: "250px" }}
										minSize={1024}
										maxSize={52428800}
										isMultiple={false}
										disabled={!selectedCourse || moduleName === ""}
										disabledMessage={"Please select course and module name"}
										
									/>
								</div>
							</Col>
							<Row className="sh-add-lesson-upload-button" style={{ display: "flex", flexDirection: "column" }}>
								<Col style={{ width: "100%", paddingLeft: "18px" }}>
									{
												  fileDetails && isFileUploading &&
											<Progress.Line percent={uploadProgress} status={
												uploadProgress === 100 ? "success" : "active"
											}
											showInfo={true} strokeWidth={8} strokeColor={theme.palette.primary.main}/>
									}
								</Col>
								<Col>
									<Button
										onClick={() => handleUploadModuleIcon()}
										disabled={!selectedCourse || !isUploadedMediaValid || isFileUploading}
										style={{
											backgroundColor: theme?.palette?.secondary.main,
											color          : theme.palette.text.primary,
											float          : "right" }}
									>
									Upload
									</Button>
								</Col>
							</Row>
						</Row>
						<Row
						// style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
							className="sh-add-level-head-button-alignment">
							<Col className="sh-add-level-error-msg-alignment"
							// style={{ width: "50%", paddingLeft: "15px" }}
							>
								{
									isError &&
							<div className="">
								<Typography variant="subtitle1" sx={{ color: theme?.palette?.secondary.main }}> {errorMessage} </Typography>
							</div>
								}
							</Col>
							
						</Row>
						

					</div>
				}
				modalFooter={<div
					style={{
						display       : "flex",
						justifyContent: "flex-end",
						alignItems    : "center"
					}}
				>
					<MainButton
						type="scale"
						direction="bottom"
						style={{ position: "relative", marginRight: "10px" }}
						offset={matchesXS ? 0 : 20}
						scale={1.04}>
						<Button
							style={{
								borderColor    : theme.palette.secondary.main,
								color          : theme.palette.text.primary,
								backgroundColor: theme.palette.background.default
							}}
							className="sh-add-module-add-module-btn"
							color="secondary"
							onClick={handleClose}
						>
								Cancel
						</Button>
					</MainButton>
					<MainButton
						type="scale"
						direction="bottom"
						style={{ position: "relative" }}
						offset={matchesXS ? 0 : 20}
						scale={1.04}>
						<Button
							className="sh-add-module-add-module-btn"
							style={{
								backgroundColor: theme.palette.secondary.main,
								color          : theme.palette.text.primary
							}}
							variant="contained"
							color="secondary"
							onClick={handleAddModule}
							disabled={!selectedCourse || moduleName === "" || moduleDescription === "" || modulePrice === "" || otherDescriptionArray.length === 0 }
						>
                    	Add Module
						</Button>
					</MainButton>
					
				</div>}

			
			/>
		</div>
			
	);

};

export default AddModule;