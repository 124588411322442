/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material";
import { Container } from "@mui/system";
import { Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { FaCircleCheck } from "react-icons/fa6";

import Back from "../../components/UI/Back";
import config from "../../config/appConfig";

// assets
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";626;
import TwoWheelerTwoToneIcon from "@mui/icons-material/TwoWheelerTwoTone";
import AirportShuttleTwoToneIcon from "@mui/icons-material/AirportShuttleTwoTone";
import ApiRequest from "../../api/ApiRequest";
import { APIUrl } from "../../api/endPoints";
import { useSelector } from "../../store";
import ModalComponent from "../../components/modal";
import UserContext from "../../context/AuthContext";

declare global {
	interface Window {
		Razorpay: any;
	}
}

const Payment = () => {

	const theme: any = useTheme();
	const { userDetails } = useContext(UserContext);
	const { firstName, lastName, email, id } = useSelector((state: any) => state.user);
	const locationState = useLocation().state;
	const navigate = useNavigate();
	const moduleData = JSON.parse(locationState?.moduleData);
	const [ hoveredCard, setHoveredCard ] = useState<number>(-1);
	// const isModuleBased = true;
	const modulebasedId = config.courseId.moduleBasedCourseId;
	const data = [
		{
			active     : false,
			icon       : <TwoWheelerTwoToneIcon fontSize="large" color="inherit" />,
			title      : "Single Module",
			description: moduleData?.courseId === modulebasedId ? "Once you purchase this license, you will gain access to all the levels of this module with lifetime validity." : "Once you purchase this license, you will gain access to all the levels of this skill with lifetime validity.",
			price      : moduleData?.modulePrice,
			priceInUSD : moduleData?.modulePriceInUSD,
			moduleName : moduleData?.moduleName,
			permission : [
				0, 1, 2, 3
			],
			planList: [
				<div>{moduleData?.courseId === modulebasedId ? "1 module with lifetime access" : "1 skill with lifetime access" } </div>,
				<div>Access all levels of the module</div>,
				<div>{moduleData?.courseId === modulebasedId
					? `This module contains ${moduleData?.totalLessons} lessons for ${
						moduleData?.totalHours.startsWith("00:")
							? moduleData?.totalHours.replace("00:Hrs ", "")
							: moduleData?.totalHours
					}`
					: `This skill contains ${moduleData?.totalLessons} lessons for ${
						moduleData?.totalHours.startsWith("00:")
							? moduleData?.totalHours.replace("00:Hrs ", "")
							: moduleData?.totalHours
					}`}</div>
				// <div>{moduleData?.courseId === modulebasedId ? `This module contains ${moduleData?.totalLessons} lesson${moduleData?.totalLessons > 1 ? "s" : ""} for ${moduleData?.totalHours}` : `This skill contains ${moduleData?.totalLessons} lesson${moduleData?.totalLessons > 1 ? "s" : " "} for ${moduleData?.totalHours}` }</div>
				/*
				 * <div>Lifetime access to the <span style={{ color: theme.palette.secondary.main, fontWeight: "bold" }}>1 Module</span></div>
				 * <div>Downloadable resources</div>
				 */
			]
		},
		{
			active     : true,
			icon       : <AirportShuttleTwoToneIcon fontSize="large" />,
			title      : "Package of 3 Modules",
			description: "User can purchase this plan to access the 3 Modules of the course, User can access the module for lifetime.",
			price      : moduleData?.packageId?.price,
			priceInUSD : moduleData?.packageId?.priceInUSD,
			packageName: moduleData?.packageId?.packageName,
			permission : [
				0, 1, 2, 3
			],
			planList: [
				<div>Buy 3 modules at the price of 2</div>,
				<div>Access all levels of the 3 module</div>,
				<div>Lifetime access to the <span style={{ color: theme.palette.secondary.main, fontWeight: "bold" }}>3 Module</span></div>,
				<div>Downloadable resources</div>
			]
		}
	];
	const [ plans, setPlans ] = useState<any>(moduleData?.packageId == null ? [ data[0] ] : data);
	const [ isConfirmationModelOpen, setIsConfirmationModelOpen ] = useState(false);
	const [ selectedPlan, setSelectedPlan ] = useState<any>(null);
	const isCountryIndia = userDetails?.userAddress?.countryName === "India";
  
	const gridSpacing = 2;
	const priceListDisable = {
		opacity      : "0.4",
		"& >div> svg": {
			fill: theme.palette.secondary.light
		}
	};

	const handlePayment = (e: any, plan: any) => {

		let payload: any;

		if(plan.packageName) {

			payload = {
				userId          : id ? id : userDetails?.id,
				courseId        : moduleData?.courseId,
				isPackage       : true,
				purchasedModules: null,
				packageId       : moduleData?.packageId?._id
			};
		
		} else {
				
			payload = {
				userId          : id ? id : userDetails?.id,
				courseId        : moduleData?.courseId,
				isPackage       : false,
				purchasedModules: moduleData?._id
			};

		}

		const orderPayload: any = {
			"currency": isCountryIndia ? "INR" : "USD",
			"receipt" : `o-${moduleData?.courseId}`,
			"notes"   : payload

		};

		ApiRequest("post", APIUrl.createOrder, orderPayload).then((response: any) => {

			setIsConfirmationModelOpen(false);

			if (response.data) {

				const options = {
					"key"        : config.razorPayKey,
					"amount"     : response.data.amount,
					"currency"   : response.data.currency,
					"name"       : "Soul Swara",
					"description": `Order for ${moduleData?.moduleName}`,
					"image"      : "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",
					"order_id"   : response.data.id,
					handler      : async function() {
						
						navigate(config.routes.course);
					
					},
					prefill: {
						name : `${firstName} ${lastName}`,
						email: email
					},
					theme: {
						color: "#F37254"
					}
				};
				
				const rzp = new window.Razorpay(options);

				rzp.on("payment.failed", function(res: any) {
					
					console.error(res);
					alert(res.error.description);
					alert("Payment failed");

				});

				rzp.open();
				e.preventDefault();

			}

		}).catch((error: any) => {

			alert("Payment failed");

			console.error(error);

		});


	};

	useEffect(() => {

		if (!id && !userDetails?.id) {

			console.error("User data not avilable yet to proceed with payment");

			return;

		}
	
	}, [ id, userDetails?.id ]);

	useEffect(() => {

		setTimeout(() => {

			if (moduleData == null) {

				navigate(config.routes.course);

			}

		}, 1000);

	}, []);

	return (
		<Container>
			<Row>
				<Col md={12} sm={12} xl={12} xs={12} >
					<div className="admin-dash-board-back-title-con"
					// style={{ marginBottom: theme.spacing(2) }}
					>
						<Back
							props={{
								route  : config.routes.course,
								state  : null,
								toolTip: "Back to Course List",
								text   : "Back to Course"
							}}
						/>
					</div>
				</Col>
			</Row>
			<div
				className="sh-payment-page-section-con"
			>
				<div>
					<Row>
						<Col style={{
							display       : "flex",
							alignItems    : "center",
							justifyContent: "center"
						}} md={12} sm={12} xl={12} xs={12} >
							<div className="admin-dash-board-back-title-con sh-payment-pricing-toggle-btn-con"
							>
								<Typography variant="h2">
									Payment
								</Typography>
							</div>
						</Col>
					</Row>
					<Grid className="sh-payment-cards-outer-con" container spacing={gridSpacing}>
						{plans.map((plan: any, index: any) => {

							return (
								<Grid item className={`sh-payment-cards-con sh-payment-cards-con-individual-${index}`} key={index}>
									<div
										style={{
											border         : "1px solid transparent",
											borderColor    : hoveredCard == index ? theme?.palette?.secondary?.main : theme?.palette?.background?.default,
											backgroundColor: theme?.palette?.text?.primary,
											color          : theme?.palette?.background?.default,
											borderRadius   : "18px 18px 18px 18px"
										}}
										onClick={() => {

											setHoveredCard(index);
											setSelectedPlan(plan);

										}

										}
									>
										<div
										>
											<div
												className="sh-payment-page-section-package-icon-con"
												style={{
													color     : theme?.palette?.text?.primary,
													background: theme?.palette?.secondary.main
												}}
											>

												<Typography
													variant="h2"
													sx={{
														textAlign : "center",
														fontWeight: 500,
														position  : "relative",
														mb        : 1,
														color     : theme?.palette?.text?.primary
													}}
												>
													{plan.title}
												</Typography>
											</div>
											<Grid
												container
												className="sh-payment-page-section-package-details-con"
												textAlign="center"
												spacing={gridSpacing}
												sx={{
													padding        : theme.spacing(2),
													backgroundColor: hoveredCard == index ? "#00000033" : theme?.palette?.text?.primary,
													borderRadius   : "0 0 18px 18px"
												}}
											>
												<Grid
													style={{
														display       : "flex",
														alignItems    : "center",
														justifyContent: "center"
													}}
													item xs={12}>
													<Typography
														variant="body2"
														style={{
															display       : "flex",
															alignItems    : "center",
															justifyContent: "center",
															color         : theme?.palette?.background?.default
														}}
														sx={{
															fontWeight: 500,
															position  : "relative",
															minHeight : "2.5rem",
															maxHeight : "2.5rem"
														}}
													>
														{plan.packageName || plan.moduleName}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography
														sx={{
															fontSize: "0.7rem",
															color   : theme?.palette?.background?.default
														}}
														variant="body2">{plan.description}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography
														component="div"
														variant="body2"
														sx={{
															fontSize  : "2.1875rem",
															fontWeight: 700,
															color     : theme?.palette?.background?.default,
															"& > span": {
																fontSize  : "1.25rem",
																fontWeight: 500
															}
														}}
													>
														<div>
															<div>
																<sup>
																	{isCountryIndia ? "₹" : "$"}
																</sup>
																{ isCountryIndia ? plan.price : plan.priceInUSD }
																<span>/Lifetime</span>
															</div>
															{isCountryIndia && <div className="sh-payment-price-con">
																<span
																	style={{
																		fontSize: "0.7rem"
																	}}
																>
																	GST 18%
																</span>
															</div> }
														</div>
														
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<List
														sx={{
															m      : 0,
															p      : 0,
															"&> li": {
																px     : 0,
																py     : 0.625,
																"& svg": {
																	fill: theme.palette.success.dark
																}
															}
														}}
														component="ul"
													>
														{plan?.planList.map((list: any, i: any) =>
															<React.Fragment key={i}>
																<ListItem sx={!plan.permission.includes(i) ? priceListDisable : {}}>
																	<ListItemIcon>
																		<CheckTwoToneIcon sx={{ fontSize: "1.1rem" }} />
																	</ListItemIcon>
																	<ListItemText
																		color={theme.palette.background.default}
																		sx={{
																			color   : theme.palette.background.default,
																			"& span": {
																				color   : `${theme.palette.background.default} !important`,
																				fontSize: "0.7rem"
																			}
																		}}
																		primary={list}
																	/>
																</ListItem>
																<Divider />
															</React.Fragment>
														)}
													</List>
												</Grid>
												<Grid item xs={12} >
													<Button
														
														 style={{ borderRadius: "20px" }}

														  // disabled={hoveredCard == index ? false : true}
														 
														sx={{
															"&:disabled": {
																color : theme.palette.background.default,
																border: `1px solid ${theme.palette.background.default}`
																/*
																 * borderRadius: "20px"
																 * backgroundColor: theme.palette.secondary.light
																 */
																 
															}
														}}
														onClick={e => {

															e.preventDefault();

															setSelectedPlan(plan);
															setHoveredCard(index);
															setIsConfirmationModelOpen(true);
															e.stopPropagation();
															
														}}
														variant="contained"
														color="primary">Buy Now</Button>
												</Grid>
											</Grid>
										</div>
									</div>
									{/* </MainButton> */}
									{
										hoveredCard == index &&
										<div className="sh-payment-page-section-package"><FaCircleCheck
											style={{
												color       : theme?.palette?.secondary?.main,
												background  : theme?.palette?.text?.primary,
												fontSize    : 30,
												borderRadius: "50%",
												border      : "1px solid",
												borderColor : theme?.palette?.text?.primary
											}}
										/></div>
									}
								</Grid>


							);

						})}
					</Grid>
				</div>
				<div
					style={{ marginTop: theme.spacing(1) }}
					className="sh-landing-page-footer-copyright-text">
					<Typography variant="body1"
						sx={{
							fontSize: "0.6rem"
						}}
						className="sh-landing-page-text-content sh-landing-page-loation-text">
						<span> Copyright © 2024 Soul Swara. </span>&nbsp;&nbsp;|&nbsp;&nbsp;
						<span
							onClick={() => window.open(config.routes?.termsAndConditions,"_blank") }
							className="footer-content-link">Terms and Conditions</span>&nbsp;&nbsp;|&nbsp;&nbsp;
						<span className="footer-content-link" onClick={()=> window.open(config.routes?.privacyPolicy, "_blank")} >Privacy Policy</span>&nbsp;&nbsp;|&nbsp;&nbsp;
						<span onClick={() => window.open(config.routes?.refundPolicy, "_blank") } className="footer-content-link">Refund Policy</span>
					</Typography>
				</div>
			</div>
			<ModalComponent
				show={isConfirmationModelOpen}
				onHide={() => setIsConfirmationModelOpen(false)}
				modalTitle="Confirmation Payment"
				size="lg"
				modalContent={ userDetails?.userAddress?.countryName !== "" ?
					<>
						<Typography variant="h6" sx={{ color: theme.palette.grey[700] }}>
								Are you sure you want to buy <strong>{moduleData?.moduleName}</strong>, <strong>{selectedPlan?.title}</strong> at the price of {
								isCountryIndia ? `${selectedPlan?.price} + ${(selectedPlan?.price * 0.18).toFixed(2)} (GST 18%) INR` : `${selectedPlan?.priceInUSD} USD`
							}

						</Typography>
					</> : <Typography variant="h6" sx={{ color: theme.palette.grey[700] }}>
						Please update your address to proceed with the payment.
					</Typography>
				}
				modalFooter={
					<div>{
						userDetails?.userAddress?.countryName !== "" ? <>
							<Button
								variant="outlined"
								color="secondary"
								onClick={() => setIsConfirmationModelOpen(false)}>Close
							</Button>&nbsp;&nbsp;<Button
								variant="contained"
								color="secondary"
								onClick={(e: any) => {

									handlePayment(e, selectedPlan);

									setPlans(plans.map((item: any, i: any) => {

										if (i === hoveredCard) {

											item.active = true;

										} else {

											item.active = false;

										}

										return item;

									}));

								} }>Yes</Button>
						</> : <Button
							variant="contained"
							color="secondary"
							onClick={() => navigate(config.routes.userIntro)}>Update Address
						</Button>
					}
						
					</div>
				}
			/>
		</Container>
	);

};

export default Payment;
