import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";
import config from "../../config/appConfig";
import { auth } from "../../firebase";
import ApiRequest from "../../api/ApiRequest";
import { APIUrl } from "../../api/endPoints";
import UserContext from "../../context/AuthContext";
import { useDispatch } from "../../store";
import { setUser, setUserAddress } from "../../store/slices/userSlice";

const AuthMode = ({ children }: any) => {

	const [ isAuthenticated, setIsAuthenticated ] = useState(false);
	const { setUserDetails, userDetails } = useContext(UserContext);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {

		const savedUserDetails = sessionStorage.getItem("userDetails");

		if (savedUserDetails) {

			setUserDetails(JSON.parse(savedUserDetails));
			dispatch(setUser({ user: JSON.parse(savedUserDetails) }));
			dispatch(setUserAddress({ userAddress: JSON.parse(savedUserDetails).userAddress }));

		}

	}, [ dispatch, setUserDetails ]);

	useEffect(() => {

		setPersistence(auth, browserLocalPersistence).catch((error: any) => {

			console.error(error);

		});

		const unsubscribe = onAuthStateChanged(auth, user => {

			if (user) {

				if (!userDetails?.userType) {

					ApiRequest("get", APIUrl.getUserInfo).then((response: any) => {
	
						setUserDetails(response);
						sessionStorage.setItem("userDetails", JSON.stringify(response));
						dispatch(setUser({ user: response }));
						dispatch(setUserAddress({ userAddress: response.userAddress }));
			
						if (Object.keys(response.userAddress).length === 0 && response?.userAddress?.countryName == "") {
							
							navigate(config.routes.userIntro);
			
							return;
			
						}
			
					}).catch((error: any) => {
			
						console.error(error);
			
					});

				}

				setIsAuthenticated(true);

			} else {

				setIsAuthenticated(false);
				navigate(config.routes.login, { replace: true });

			}

		});

		return () => unsubscribe();

	}, [
		dispatch, navigate, setUserDetails, userDetails
	]);


	return isAuthenticated ? children : null;

};

export default AuthMode;