import { LOGIN, LOGOUT, REGISTER } from "./actions";


// Firebase Account Login Reducer

const initialState = {
	isLoggedIn   : false,
	isInitialized: false,
	user         : null
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action: any) => {
	switch (action.type) {

	case REGISTER: {

		const { user } = action.payload;

		return {
			...state,
			user
		};

	}
	case LOGIN: {

		const { user } = action.payload;

		return {
			...state,
			isLoggedIn   : true,
			isInitialized: true,
			user
		};

	}
	case LOGOUT: {

		return {
			...state,
			isInitialized: true,
			isLoggedIn   : false,
			user         : null
		};

	}
	default: {

		return { ...state };

	}

	}

};


export default accountReducer;
