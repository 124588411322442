import { memo, useEffect } from "react";

// material-ui
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import React from "react";
// import { useDispatch } from "react-redux";
import useConfig from "../../../../Hooks/useConfig";
import { HORIZONTAL_MAX_ITEM } from "../../../../config/config";
import LAYOUT_CONST from "../../../../constant";
import menu from "../../../menuList";
import NavGroup from "./NavGroup";


// Side Menu list

const MenuList = () => {

	const theme: any = useTheme();
	const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
	const { layout } = useConfig();
	// const dispatch = useDispatch();
	const menuItem: any = menu;

	useEffect(() => {

		try {

			const menuItemsFromSessionString: any =
				sessionStorage.getItem("menuItems");
			const menuItemsFromSessionObject = JSON.parse(menuItemsFromSessionString);
			if (menuItem?.items[0] && menuItemsFromSessionObject) {

				menuItem.items[0].children =
					menuItemsFromSessionObject.items[0].children;

				return;
			
			}

			if (menuItem?.items[0]?.children?.length > 0) {

				menuItem.items[0].children[0].type = "item";
			
			}
			/*
			 * if (templatesList?.length > 0 && !menuItem?.items[0]?.children) {
			 * 	const newMenuItems =
			 * 		templatesList &&
			 * 		templatesList.map((item) => ({
			 * 			id: config.pageNameWithContentName[item.template_name],
			 * 			title: item.template_name,
			 * 			type: "item",
			 * 			url: config.sideBarRoutes[item.template_type],
			 * 			breadcrumbs: false,
			 * 			templateId: item.template_id,
			 * 			templateType: item.template_type,
			 * 		}));
			 */

			/*
			 * 	menuItem.items[0].children = newMenuItems;
			 * } else if (
			 * 	files?.length > 0 &&
			 * 	menuItem?.items[0]?.children &&
			 * 	!menuItem?.items[0]?.children[0]?.length > 0
			 * ) {
			 * 	dispatch(openDrawer(false));
			 * 	menuItem.items[0].children[0].type = "collapse";
			 * 	const newMenuItems =
			 * 		files &&
			 * 		files.map((item) => ({
			 * 			id: item.content_id,
			 * 			title: item.content_title,
			 * 			type: "item",
			 * 			url: `/content-creator?cId=${item.content_id}`,
			 * 			breadcrumbs: false,
			 * 			templateId: item.template_id,
			 * 			templateType: item.template_type,
			 * 		}));
			 */

			// 	menuItem.items[0].children[0].children = newMenuItems;

			/*
			 * 	sessionStorage.setItem("menuItems", JSON.stringify(menuItem));
			 * } else if (!files?.length && menuItem?.items[0]?.children) {
			 * 	menuItem.items[0].children[0].type = "item";
			 * }
			 */
		
		} catch (e) {

			console.error(e);
		
		}
	
	}, [ menuItem.items[0]?.children ]);

	const lastItem =
		layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
			? HORIZONTAL_MAX_ITEM
			: null;

	let lastItemIndex = menuItem.items.length - 1;
	let remItems: any = [];
	let lastItemId: any = null;

	if (lastItem && lastItem < menuItem.items.length) {

		lastItemId = menuItem.items[lastItem - 1].id;
		lastItemIndex = lastItem - 1;
		remItems = menuItem.items
			.slice(lastItem - 1, menuItem.items.length)
			.map((item: any) => ({
				title   : item.title,
				elements: item.children
			}));
	
	}

	const navItems = menuItem.items
		.slice(0, lastItemIndex + 1)
		.map((item: any) => {

			switch (item.type) {

			case "group":
				return (
					<NavGroup
						key={item.id}
						item={item}
						lastItem={lastItem}
						remItems={remItems}
						lastItemId={lastItemId}
					/>
				);
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
							Menu Items Error
					</Typography>
				);
			
			}
		
		});

	return <>{navItems}</>;

};

export default memo(MenuList);
