import React from "react";
import { Modal, Box, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { Divider } from "rsuite";
import { MdClose } from "react-icons/md";
// import { IconType } from "react-icons";

const ModalComponent = (props:any) => {

	// const ModalComponent = (props : any) => {

	const theme: any = useTheme();

	const {
		modalContent,
		show,
		onHide,
		closeButton,
		backdrop,
		className,
		size,
		modalTitle,
		modalFooter,
		icon: IconComponent
	} = props;

	const backdropComponent = backdrop ?
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.3 }}
		>
			<Box
				style={{
					position  : "fixed",
					top       : 0,
					left      : 0,
					right     : 0,
					bottom    : 0,
					background: "rgb(0, 0, 0, 0.71)"
				}}
			/>
		</motion.div>
		: null;

	return (
		<Modal
			onBackdropClick={onHide}
			open={show}
			onClose={
				(event: any, reason: string) => {

					if (reason === "backdropClick" || reason === "escapeKeyDown") {

						onHide();
					
					}
				
				}
			}
			className={className}
			slots={ { backdrop: () => backdropComponent } }
			slotProps={ { backdrop: { onClick: onHide } } }
			closeAfterTransition
			
		>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 50 }}
				transition={{ duration: 0.3 }}
				style={{
					display       : "flex",
					alignItems    : "center",
					justifyContent: "center",
					height        : "100vh",
					background    : "rgb(0, 0, 0, 0.71)"
				}}
			>
				<div
					style={{
						padding     : "20px",
						background  : theme?.palette?.background.paper,
						width       : size === "lg" ? 600 : size === "sm" ? 300 : size === "md" ? 400 : size === "xl" ? 800 : 400,
						borderRadius: "10px"
					}}
				>
					<div
						style={{
							display       : "flex",
							justifyContent: "space-between",
							alignItems    : "center",
							gap           : "15px"
						}}
					>
						{modalTitle &&
              <div style={{ display: "flex", alignItems: "center" }}>
              	{IconComponent &&
                  <IconComponent
                  	size={24}
                  	color={theme?.palette?.primary.main}
                  	style={{ marginRight: "8px" }}
                  />
              	}
              	<Typography
              		variant="h4"
              		component="h2"
              		sx={{ color: theme?.palette?.grey[700] }}
              	>
              		{modalTitle}
              	</Typography>
              </div>
						}
						{closeButton && <div >
							<div onClick={onHide} style={{ cursor: "pointer" }}>
								<MdClose size={30} color={theme?.palette?.secondary.main} />
							</div>
						</div>
						}
					</div>
					<Divider style={{
						backgroundColor: theme?.palette?.background?.default
					}} />
					<Box className="modal-body-content" sx={{ mb: 2 }}>
						{modalContent}
					</Box>
					<Divider style={{
						backgroundColor: theme?.palette?.background?.default
					}} />
					{modalFooter && <div className="modal-footer">{modalFooter}</div>}
					
				</div>
			</motion.div>
		</Modal>
	);

};

export default ModalComponent;
