// material-ui
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
// import React from "react";

// project import
import { drawerWidth } from "../../../../store/constant";

const openedMixin = (theme: any) => ({
	width      : drawerWidth,
	borderRight: "none",
	zIndex     : 1099,
	background : theme.palette.background.default,
	transition : theme.transitions.create("width", {
		easing  : theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen + 200
	}),
	overflowX: "hidden",
	boxShadow: theme.palette.mode === "dark" ? theme.customShadows.z1 : "none"
});

const closedMixin = (theme: any) => ({
	borderRight: "none",
	zIndex     : 1099,
	background : theme.palette.background.default,
	transition : theme.transitions.create("width", {
		easing  : theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen + 200
	}),
	overflowX: "hidden",
	width    : 72
});

// Drawer Mini style component

const MiniDrawerStyled = styled(Drawer, { shouldForwardProp: prop => prop !== "open" })(({ theme, open }: any) => ({
	width      : drawerWidth,
	borderRight: "0px",
	flexShrink : 0,
	whiteSpace : "nowrap",
	boxSizing  : "border-box",
	...open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme)
	},
	...!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme)
	}
}));

export default MiniDrawerStyled;
