import React from "react";
import { FaAngleLeft } from "react-icons/fa6";
import { useTheme, useMediaQuery, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainButton from "../../components/UI/extend/MainButton";
import TooltipComponent from "../../components/UI/Tooltip";

const Back = ({ props }: any) => {

	const theme: any = useTheme();
	const navigate = useNavigate();
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<TooltipComponent text={props?.toolTip && props?.toolTip} placement="bottom">
			<div style={{
				width     : "fit-content",
				display   : "flex",
				alignItems: "center",
				columnGap : "6px",
				cursor    : "pointer"
			} }
			onClick={() => props?.state ? navigate(props?.route, { state: props?.state }) : navigate(props?.route)}
			>
				<MainButton
					type="scale"
					direction="bottom"
					style={{ position: "relative" }}
					offset={matchesXS ? 0 : 20}
					scale={1.1}>
					<span
						// className="sh-back-btn-component-span"
						
						// style={{ backgroundColor: theme?.palette?.secondary?.main }}
						onClick={() => props?.state ? navigate(props?.route, { state: props?.state }) : navigate(props?.route)}
					>
						<FaAngleLeft
							size={20}
							style={{ color: theme?.palette?.primary?.main }}
						/>
					</span>
				</MainButton>
				{
					props?.text && <Typography variant="h4" style={{ color: theme?.palette?.primary?.main }}>{props?.text}</Typography>
				}
			</div>
		</TooltipComponent>
	);

};

export default Back;