import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// third-party
import { IntlProvider } from "react-intl";
import useConfig from "../../Hooks/useConfig";

// load locales files
const loadLocaleData = () => {

	return import("../../assets/locals/en.json");

};

// ==============================|| LOCALIZATION ||============================== //

const Locales = ({ children }: any) => {

	const { locale } = useConfig();
	const [ messages, setMessages ] = useState();

	useEffect(() => {

		loadLocaleData().then((d: any) => {

			setMessages(d.default);
    
		});
  
	}, [ locale ]);

	return (
		<>
			{messages && <IntlProvider locale={locale} defaultLocale="en" messages={messages}> {children} </IntlProvider>}
		</>
	);

};

Locales.propTypes = {
	children: PropTypes.node
};

export default Locales;
