import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Divider,
	Chip,
	Typography
} from "@mui/material";

// assets
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
// import { signOut } from "firebase/auth";

// import { auth } from "../../firebase";

// import config from "../../config/appConfig";
import UseAuth from "./../../Hooks/useAuth";
import config from "../../config/appConfig";
import ApiRequest from "../../api/ApiRequest";
import { APIUrl } from "../../api/endPoints";
import { useDispatch } from "../../store";
import { setUser, setUserAddress } from "../../store/slices/userSlice";
import UserContext from "../../context/AuthContext";
// import toast from "react-hot-toast";

// ===============================|| JWT LOGIN ||=============================== //

const JWTLogin = ({ setLoginSuccess, ...others }: any) => {

	const theme: any = useTheme();

	const { setUserDetails } = useContext(UserContext);
	const dispatch = useDispatch();
	const [ email, setEmail ] = useState("");
	const [ password, setPassword ] = useState("");
	const [ checked, setChecked ] = useState(true);
	const [ showPassword, setShowPassword ] = useState(false);
	const [ submitError, setSubmitError ] = useState("");
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	// const [ errorStatus, setErrorStatus ] = useState<any>("");
	const { signIn, googleSignIn } = UseAuth();
	const navigate = useNavigate();

	const handleSubmit = async (event: any) => {

		event.preventDefault();

		// Custom validation logic
		if (!email.trim()) {

			setSubmitError("Email is required");

			return;

		}
		if (!password.trim()) {

			setSubmitError("Password is required");

			return;

		}

		try {

			if (email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) && password !== "") {

				setIsSubmitting(true);

				signIn(email, password).then(async (res: any) => {

					setIsSubmitting(false);

					if (res?.user?.emailVerified === true) {
           
						setLoginSuccess(true);

						setTimeout(async () => {

							setSubmitError("");
							setEmail("");
							setPassword("");
							setSubmitError("");
	
							await fetchUserDetails();
						
						}, 1000);

						return;

					} else {

						setLoginSuccess(false);
						setIsSubmitting(false);
						setSubmitError("Email not verified.  Please verify your email address.");

						// await signOut(auth);

					}
  
				}).catch(async (error: any) => {

					setIsSubmitting(false);
  
					console.error(error.message);

					if (error.message.includes("Firebase: Error (auth/invalid-credential).")) {

						setSubmitError("Invalid email or password.");

					}

					// await signOut(auth);
  
				});
  
			} else {

				// await signOut(auth);

				setSubmitError("Invalid email or password.");

			}

			// Clear form fields on successful login
			

		} catch (err: any) {

			console.error(err);
			setSubmitError(err.message);

			// await signOut(auth);

			setSubmitError("Invalid email or password.");

		}

	};

	const handleClickShowPassword = () => {

		setShowPassword(!showPassword);

	};

	const handleMouseDownPassword = (event: any) => {

		event.preventDefault();

	};

	const fetchUserDetails = async () => {

		ApiRequest("get", APIUrl.getUserInfo).then((response: any) => {

			setUserDetails(response);
			sessionStorage.setItem("userDetails", JSON.stringify(response));
			dispatch(setUser({ user: response }));
			dispatch(setUserAddress({ userAddress: response.userAddress }));

			if (Object.keys(response.userAddress).length === 0) {
				
				navigate(config.routes.userIntro);

				return;

			}
			
			navigate(config.routes.userIntro);
			setLoginSuccess(true);

		}).catch((error: any) => {

			console.error(error);

		});

	};

	const handleSignInWithGoogle = async () => {

		try {

			const res = await googleSignIn();

			if (res && res?.user?.emailVerified === true) {

				await fetchUserDetails();

				return;
        
			} else {
        
				setLoginSuccess(false);
				setSubmitError("Email not verified.  Please verify your email address.");

			}

			return;

		} catch (err: any) {

			console.error(err);

		}

	};

	return <><form noValidate onSubmit={handleSubmit} {...others}>
		<FormControl
			disabled={isSubmitting}
			fullWidth sx={{ ...theme.typography.customInput }}>
			<InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
			<OutlinedInput
				id="outlined-adornment-email-login"
				type="email"
				value={email}
				onChange={e => {
					
					setSubmitError("");
					setEmail(e.target.value);

				}}
			/>
		</FormControl>

		<FormControl
			disabled={isSubmitting}
			fullWidth sx={{ ...theme.typography.customInput }}>
			<InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
			<OutlinedInput
				id="outlined-adornment-password-login"
				type={showPassword ? "text" : "password"}
				value={password}
				onChange={e => {
					
					setSubmitError("");
					setPassword(e.target.value);
					
				}}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
							size="large"
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>

		<Grid container alignItems="center" justifyContent="space-between">
			<Grid item>
				<FormControlLabel
					disabled={isSubmitting}
					control={
						<Checkbox
							checked={checked}
							onChange={event => setChecked(event.target.checked)}
							name="checked"
							color="primary"
						/>
					}
					label="Keep me logged in"
				/>
			</Grid>
			{/* <Grid item>
				{
					errorStatus &&
					<div>
						<div>
							<FormHelperText error id="standard-weight-helper-text--register">{errorStatus}</FormHelperText>
						</div>
					</div>
				}
			</Grid> */}
			<Grid item>
				<Typography
					variant="subtitle1"
					onClick={() => navigate(config.routes.forgotPassword)}
					sx={{ color: theme.palette.primary.main, cursor: "pointer" }}>Forgot Password?
				</Typography>
			</Grid>
		</Grid>

		{submitError &&
    <Box sx={{ mt: 3 }}>
    	<FormHelperText error>{submitError}</FormHelperText>
    </Box>
		}

		<Box sx={{ mt: 2 }}>
			<Button
				disabled={isSubmitting}
				sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, borderRadius: "1.5em" }}
				color="primary" fullWidth size="large" type="submit" variant="contained">
      Sign In
			</Button>
		</Box>
	</form>
	<Divider sx={{ mt: 3, mb: 2 }} >
		<Chip sx={{ color: theme.palette.text.primary }} size="small" label="OR" />
	</Divider>
	<Box sx={{ mt: 2 }}>
		<Button
			disabled={isSubmitting}
			sx={{
				backgroundColor: theme.palette.grey[50],
				color          : theme.palette.primary.main,
				borderRadius   : "1.5em",
				"&:hover"      : { backgroundColor: theme.palette.grey[100] }
			}}
			fullWidth size="large"
			onClick={() => handleSignInWithGoogle()}
		>
			<FcGoogle size={25} style={{ marginRight: "14px" }} /> Sign in with Google
		</Button>
	</Box>
	</>
	;

};

JWTLogin.propTypes = {
	loginProp      : PropTypes.number,
	setLoginSuccess: PropTypes.func
};

export default JWTLogin;
