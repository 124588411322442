import { Link } from "react-router-dom";
import React from "react";

// material-ui
import { useTheme } from "@mui/material";
import { Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

import AuthWrapper1 from "../AuthWrapper1";
import AuthCardWrapper from "../AuthCardWrapper";
import AuthRegister from "./AuthRegister";
import appConfig from "../../../config/appConfig";
// import { auth } from "../../../firebase";

// import { onAuthStateChanged } from "firebase/auth";

const Register = () => {

	const theme = useTheme();
	const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
	// const Routes = appConfig.routes;

	// const navigate = useNavigate();

	// useEffect(() => {

	// 	const unsubscribe = onAuthStateChanged(auth, user => {

	// 		if (user) {

	// 			const lastPage = JSON.parse(sessionStorage.getItem("LastPage") || "{}");

	// 			if (lastPage) {

	// 				if (lastPage.pathname === Routes.login || lastPage.pathname === Routes.register || lastPage.pathname === Routes.landingPage) {

	// 					navigate(Routes.landingHome, { replace: true });

	// 				}

	// 				navigate(lastPage.pathname, { replace: true });

	// 			} else {
					
	// 				navigate(Routes.landingHome, { replace: true });

	// 			}
				

	// 		} else {

	// 			navigate(Routes.register, { replace: true });

	// 		}

	// 	});

	// 	return () => unsubscribe();

	// }, []);

	return <div>
		<AuthWrapper1 style={{ height: "100%" }}>
			<Grid container direction="column" justifyContent="flex-end">
				<Grid item xs={12}>
					<Grid container style={{ height: "100%" }} justifyContent="center" alignItems="center">
						<Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
							<AuthCardWrapper>
								<Grid container spacing={2} alignItems="center" justifyContent="center">
									<Grid item xs={12}>
										<Grid
											container
											direction={matchDownSM ? "column-reverse" : "row"}
											alignItems="left"
											justifyContent="left"
										>
											<Grid item>
												<Stack alignItems="left" justifyContent="center" spacing={1}>
													<Typography
														color={theme.palette.text.primary}
														gutterBottom
														variant={matchDownSM ? "h4" : "h3"}
													>
														Get started
													</Typography>
													<Typography
														variant="caption"
														fontSize="14px"
														textAlign={matchDownSM ? "center" : "inherit"}
													>
														Enter the below details to proceed
													</Typography>
												</Stack>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<AuthRegister />
									</Grid>
									<Grid item xs={12}>
										<Divider />
									</Grid>
									<Grid item xs={12}>
										<Grid item container direction="column" alignItems="center" xs={12}>
										 <span
										 style={{ color: theme.palette.text.primary }}
										 >Already have an account?&nbsp;
												<Typography
													component={Link}
													to={appConfig.routes.login}
													variant="subtitle1"
													sx={{
														textDecoration: "none",
														color         : theme.palette.secondary.main,
														"&:hover"     : { color: theme.palette.secondary.dark }
													}}
												>
												Sign in
												</Typography></span>
											
										</Grid>
									</Grid>
								</Grid>
							</AuthCardWrapper>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ m: 3, mt: 1 }}>
					{/* <AuthFooter /> */}
				</Grid>
			</Grid>
		</AuthWrapper1></div>
	;

};

export default Register;