import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import useAuth from "../../../../Hooks/useAuth";
import { useTheme } from "@mui/material/styles";
import config from "../../../../config/appConfig";
import darkLogo from "../../../../assets/logo/soulswara-primary-A.png";
import lightLogo from "../../../../assets/logo/soulswara-primary-A.png";

import { Link } from "@mui/material";

// Logo

const LogoSection = () => {

	const theme: any = useTheme();
	// const { isLoggedIn }: any = useAuth();

	return <Link
		component={RouterLink}
		to={config.routes.landingHome}
		aria-label="theme-logo"
		sx={{ textDecoration: "none" }}
	>
		{
			theme.palette.mode === "dark" ?
				<img src={darkLogo} alt="Soulswara" width={65} />
				: <img src={lightLogo} alt="Soulswara" width={65} />
		}
	</Link>;

}
;
export default LogoSection;
