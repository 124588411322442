import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, Badge } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { toast, ToastBar, Toaster } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import LogoSection from "../Logo";
import ProfileSection from "./ProfileSection";
import config from "../../../../config/appConfig";
import { useSelector, useDispatch } from "../../../../store";
import { setUser, setUserAddress, setUserProgressData } from "../../../../store/slices/userSlice";

import ApiRequest from "../../../../api/ApiRequest";

import { APIUrl } from "../../../../api/endPoints";
import UserContext from "../../../../context/AuthContext";

const Header = () => {

	const theme: any = useTheme();
	const routes = config.routes;
	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname;
	const { userType } = useSelector((state: any) => state.user);
	const dispatch = useDispatch();
	const [ isNewPostAvilable, setIsNewPostAvilable ] = useState(false);
	const { userDetails, setUserDetails, setIsAnnouncementFetch, isAnnouncementFetch } = useContext(UserContext);
	
	
	useEffect(() => {
		
		if (isAnnouncementFetch) {
			
			setIsAnnouncementFetch(false);
			
			ApiRequest("get", APIUrl.getUserInfo).then((response: any) => {
				
				setUserDetails(response);
				dispatch(setUser({ user: response }));
				dispatch(setUserProgressData(response.userProgressData));
				dispatch(setUserAddress({ userAddress: response?.userAddress }));
				sessionStorage.setItem("userDetails", JSON.stringify(response));
				
			}).catch((error: any) => {
				
				navigate(config.routes.landingHome);
				console.error(error);
			 
			});
			
		}

		ApiRequest("get", APIUrl.getLastPostId).then((response: any) => {

			if(response == null) return;

			// Check if there's a new post
			const latestPostUpdate = new Date(response?.updateAt);
			const userLastViewed = new Date(userDetails?.lastViewedPostTime);
			const isNewPostAvailable = userLastViewed < latestPostUpdate;

			if (isNewPostAvailable) {

				setIsNewPostAvilable(true);

			} else {
				
				setIsNewPostAvilable(false);

			}


		}).catch((error: any) => {
	
	 	navigate(config.routes.landingHome);
	 	console.error(error);

		});

	}, [ isAnnouncementFetch, path ]);


	return (
		<>
			{/* logo & toggler button */}
			<Box
				sx={{
					width                         : 50,
					marginRight                   : 2,
					display                       : "flex",
					[theme.breakpoints.down("md")]: {
						width: "auto"
					}
				}}
			>
				<Box component="span"
				>
					<LogoSection />
				</Box>
			</Box>
			<Toaster
				toastOptions={{
					duration: 12000,
					position: "bottom-right",
					style   : {
						borderRadius: "10px",
						background  : "#333",
						color       : "#fff"
					}
				}}
			>
				{(t: any) =>
					<ToastBar toast={t}>
						{({ icon, message }: any) =>
							<>
								{icon}
								{message}
                
								{t.type !== "loading" &&
                  <CloseIcon onClick={() => toast.dismiss(t.id)} style={{ cursor: "pointer" }} />
								}
							</>
						}
					</ToastBar>
				}
			</Toaster>

			{/* header search */}
			{/* <SearchSection /> */}
			{
				userType === config.userTypes.systemAdmin && <>
					<Box >
						<Box sx={{
							ml                            : 2,
							mr                            : 3,
							[theme.breakpoints.down("md")]: {
								mr: 2
							}
						}}>
							<Button sx={{
								color   : window.location.pathname == routes.dashboard ? theme?.palette?.primary?.main : theme?.palette?.text.primary,
								cursor  : "pointer",
								fontSize: { xs: "15px" }
							}} onClick={() => navigate(routes.dashboard)}>
            ADMIN
							</Button>
						</Box>
					</Box>
					<Box sx={{ display: { xs: "none", sm: "block" } }}></Box>
				</>
			}
			<Box >
				<Box sx={{
					ml                            : 2,
					mr                            : 3,
					[theme.breakpoints.down("md")]: {
						mr: 2
					}
				}}>
					<Typography sx={{
						color   : window.location.pathname == routes.course ? theme?.palette?.primary?.main : theme?.palette?.text.primary,
						cursor  : "pointer",
						fontSize: { xs: "15px" }
					}} variant="h5" onClick={() => navigate(routes.userIntro)}>
            COURSES
					</Typography>
				</Box>
			</Box>
			<Box sx={{ display: { xs: "none", sm: "block" } }}>
				<Box sx={{
					ml                            : 2,
					mr                            : 3,
					[theme.breakpoints.down("md")]: {
						mr: 2
					}
				}}>
					<Badge color="error" variant="dot" invisible={!isNewPostAvilable}>
						<Typography sx={{
							color   : window.location.pathname == routes.announcement ? theme?.palette?.primary?.main : theme?.palette?.text.primary,
							cursor  : "pointer",
							fontSize: { xs: "15px" }
						}} variant="h5"
						onClick={() => navigate(routes.announcement)}
						>
							COMMUNITY
						</Typography>
					</Badge>
				</Box>
			</Box>
			<Box sx={{ flexGrow: 1 }} />
			<Box sx={{ flexGrow: 1 }} />

			{/* mega-menu */}

			{/* live customization & localization */}

			<Box sx={{ display: { xs: "none", sm: "block" } }}></Box>

			{/* notification & profile */}
			{/* <NotificationSection /> */}
			<ProfileSection />

			{/* mobile header */}
		</>
	);

};

Header.propTypes = {
	handleDrawerToggle: PropTypes.func
};

export default Header;
