// import { fill } from "lodash";
import value from "../assets/scss/_themes-vars.module.scss";
import { useTheme } from "@mui/system";

const theme: any = useTheme();

// has number check
export const hasNumber = (number: any) => new RegExp(/[0-9]/).test(number);

// has mix of small and capitals letters check
export const hasMixed = (number: any) => new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);

// has special chars check
export const hasSpecial = (number: any) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);

// set color based on password strength level and return strength level
export const strengthColor = (count: any) => {

	if (count < 2) return { label: "Poor", color: value.errorMain };
	if (count < 3) return { label: "Weak", color: value.warningDark };
	if (count < 4) return { label: "Normal", color: value.orangeMain };
	if (count < 5) return { label: "Good", color: value.successMain };
	if (count < 6) return { label: "Strong", color: value.successDark };

	return { label: "Poor", color: value.errorMain };

};

// password strength indicator function based on password strength level
export const strengthIndicatorNumFunc = (number: any) => {

	let strengths = 0;
	if (number.length > 5) strengths += 1;
	if (number.length > 7) strengths += 1;
	if (hasNumber(number)) strengths += 1;
	if (hasSpecial(number)) strengths += 1;
	if (hasMixed(number)) strengths += 1;

	return strengths;

};

export const DataTableStyles: any = {
	table: {
		style: {
			color          : theme.palette.text.primary,
			minHeight      : "50vh",
			overflowX      : "hidden",
			border         : "1px solid",
			borderColor    : "transparent",
			backgroundColor: theme?.palette?.background.paper
		}
					
	},
	headRow: {
		style: {
			backgroundColor: theme?.palette?.secondary.main,
			color          : theme?.palette?.text.primary,
			fontSize       : "12px"
			// fontFamily     : "popins"
		}
	},
	cell: {
		style: {
			fontSize  : "10px",
			fontFamily: "popins",
			whitespace: "nowrap"
		}
	},
	rows: {
		style: {
			"&:nth-of-type(even)": {
				backgroundColor: theme?.palette?.background.paper,
				color          : theme?.palette?.text.primary
			},
			"&:nth-of-type(odd)": {
				backgroundColor: theme?.palette?.background.default,
				color          : theme?.palette?.text.primary
			}
		},
		highlightOnHoverStyle: {
			backgroundColor: theme?.palette?.background.default,
			color          : theme?.palette?.text.primary
		}
	},
	pagination: {
		style: {
			backgroundColor: theme?.palette?.background.paper,
			color          : theme?.palette?.text.primary,
			fill      		   : theme?.palette?.text.primary
		},

		
		pageButtonsStyle: {
			style: {
				color: theme?.palette?.text.primary,
				fill : theme?.palette?.text.primary
			},
			"& .MuiSvgIcon-root": {
				color    : theme?.palette?.text.primary,
				"&:hover": {
					color: theme?.palette?.text.primary
				}
			},
			"&:hover:not(:disabled)": {
				fill : theme?.palette?.text.primary,
				color: "white"
			}
		}
	},
	noData: {
		style: {
			backgroundColor: theme?.palette?.background.paper,
			color          : theme?.palette?.primary?.text,
			fontSize       : "1rem",
			display        : "flex",
			justifyContent : "center",
			alignItems     : "center"
		}
	},
	progress: {
		style: {
			backgroundColor: theme?.palette?.background.paper,
			color          : theme?.palette?.text.primary,
			border         : "none",
			display        : "flex",
			justifyContent : "center",
			alignItems     : "center"
		}
	}
};

export default { strengthIndicatorNumFunc, strengthColor, DataTableStyles };