import pages from "./pages";
// import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
	items: [ pages ]
};

export default menuItems;
