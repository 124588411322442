import React, { useState, useEffect, useContext } from "react";
// import userCoverPhoto from "../../assets/images/coverPhoto.jpg";

// import userImage from "../../assets/icons/user-round.svg";
import Container from "@mui/material/Container";
import { Row, Col } from "rsuite";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";
import config from "../../config/appConfig";
import { useMediaQuery, Button, Grid, Rating } from "@mui/material";
import ApiRequest from "../../api/ApiRequest";
import { APIUrl } from "../../api/endPoints";
import MainButton from "../../components/UI/extend/MainButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import DataTable from "react-data-table-component";
import TooltipComponent from "../../components/UI/Tooltip";
import { SiMinutemailer } from "react-icons/si";
import toast from "react-hot-toast";
import UserContext from "../../context/AuthContext";

const UserProfile = () => {

	const navigate = useNavigate();
	const { userDetails } = useContext(UserContext);
	const theme: any = useTheme();
	const [ profileMainTab, setProfileMainTab ] = useState("one");
	const [ profileSubTab, setProfileSubTab ] = useState("one");
	const [ moduleData, setModuleData ] = useState([]);
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ hoveredCardIndex, setHoveredCardIndex ] = useState(-1);
	// const { CompanyName } = useSelector((state: any) =);
	const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
	const [ purchaseData, setPurchaseData ] = useState([]);
	// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
	const { DataTableStyles } = require("../../Utils/utils");

	useEffect(() => {

		fetchData();

	}, []);

	const handleUserProfileOptions = (event: React.ChangeEvent<any>, newValue: string) => {

		setProfileMainTab(newValue);

	};

	const handleUserProfileCoursesOptions = (event: React.ChangeEvent<any>, newValue: string) => {

		setProfileSubTab(newValue);

	};

	const fetchData = async () => {

		const payload = {
			isFromUserPage: true
		};

		ApiRequest("post", APIUrl.listAllModuleByCourseId, payload).then((response: any) => {

			setModuleData(response);

		}).catch((error: any) => {

			setModuleData([]);

			console.error(error);


		});

		setIsLoading(true);

		ApiRequest("get", APIUrl.getPurchaseHistory).then((response: any) => {

			setIsLoading(false);

			if (response?.data) {

				setPurchaseData(response.data);

			}

		}).catch((error: any) => {

			setIsLoading(false);

			setPurchaseData([]);

			console.error(error);


		});

	};

	const cellRenderer = (text: any) => {

		const cellText = String(text);


		return <> {
			cellText?.length > 25 ? <>
				<TooltipComponent text={cellText} placement="top-start">
					<div>{cellText.substring(0, 25) + "..."}</div>
				</TooltipComponent>
			</> : cellText
		}
		</>;
	
	};

	const generalSortFunction = (key: string) => (a: any, b: any) => {

		const valueA = a[key];
		const valueB = b[key];
	  
		// Handle cases where values are not present
		if (valueA === undefined || valueB === undefined) return 0;
	  
		// If both values are numbers
		if (!isNaN(valueA) && !isNaN(valueB)) {

		  return parseFloat(valueA) - parseFloat(valueB);
		
		}
	  
		return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
	  
	};

	const columns: any = [
		{
			name        : "Course Name",
			selector    : (row:any) => row.courseName,
			cell        : (row:any) => cellRenderer(row.courseName ? row.courseName : ""),
			sortable    : true,
			center      : true,
			sortFunction: generalSortFunction("courseName"),
			grow        : 1.5
		},
		{
			name        : "Module Name",
			selector    : (row:any) => row.moduleNames,
			cell        : (row:any) => cellRenderer(row.moduleNames ? row.moduleNames : ""),
			width       : "20%",
			sortable    : true,
			sortFunction: generalSortFunction("moduleNames")
		},
		{
			name        : "Package",
			selector    : (row:any) => cellRenderer(row.isPackage ? row.isPackage : ""),
			Grow        : 0.5,
			cell        : (row:any) => cellRenderer(row.isPackage ? row.isPackage : ""),
			sortable    : true,
			sortFunction: generalSortFunction("isPackage")
            
		},
		{
			name        : "Amount",
			selector    : (row:any) => cellRenderer(row.amount ? row.amount : ""),
			Grow        : 0.5,
			cell        : (row:any) => cellRenderer(row.amount ? row.amount : ""),
			sortable    : true,
			sortFunction: generalSortFunction("amount")
			
		},
		{
			name        : "Status",
			selector    : (row:any) => cellRenderer(row.status ? row.status : ""),
			Grow        : 0.5,
			cell        : (row:any) => cellRenderer(row.status ? row.status : ""),
			eclipsed    : true,
			sortable    : true,
			sortFunction: generalSortFunction("status")
            
		},
		{
			name        : "Paid Date",
			selector    : (row:any) => row.paymentDate ? new Date(row.paymentDate).toLocaleString() : "",
			cell        : (row:any) => cellRenderer(row.paymentDate ? new Date(row.paymentDate).toLocaleString() : ""),
			sortFunction: generalSortFunction("paymentDate"),
			center      : true,
			Grow        : 1
		},
		{
			name  : "Action",
			grow  : 0.5,
			center: true,
			cell  : (params : any) =>
				<div className="sh-fontawesome-edit-delete">

					<TooltipComponent text="Email Receipt" placement="top-start" >
						<span
							style={ params?.status !== "success" ? { cursor: "not-allowed" } : { cursor: "pointer" } }
							className="sh-fontawesome-edit"
						>
							<SiMinutemailer size={25}
								onClick={()=> {

									if (params.status !== "success") return;

									const payload = {
										"_id": params.id
									};

									toast.promise(ApiRequest("post", APIUrl.emailPaymentReceipt, payload),{
										loading: "Sending Email...",
										success: () => {

											return "Email Sent Successfully";

										},
										error: (error: any) => {

											console.error(error);

											return "Error Sending Email";

										}
									});
								
								}}
								color={theme.palette.text.primary} />
						</span>
					</TooltipComponent>
					
				</div>
            
		}
    
	];

	return (
		<Container fixed>
			{/* <Row className="sh-user-profile-cover-image-con">
        {
          profileCoverPath ? <img className="sh-user-profile-cover-image" src={profileCoverPath} alt="" /> : <img className="sh-user-profile-cover-image" src={userCoverPhoto} alt="" />
        }
        
      </Row> */}
			<Row className="sh-user-profile-details-con" style={{ backgroundColor: theme?.palette?.background?.default }}>
				<Col className="sh-user-profile-image-con" style={{ backgroundColor: theme?.palette?.background?.default }}>
					{
						userDetails?.profileIconPath ? <img style={{ border: `2px solid ${theme?.palette?.secondary?.main}` }} className="sh-user-profile-image" src={userDetails?.profileIconPath} alt="" /> : <AccountCircleRoundedIcon style={{ fontSize: "5rem" }} />
					}
				</Col>
				<Col className="sh-user-profile-user-details-con">
					<Row>
						<Row className="sh-user-profile-name-type-con">
							<Col>
								<Typography variant="h4" sx={{ color: theme?.palette?.text?.dark }} style = {{ marginLeft: "6px" }}>
									{userDetails?.firstName} {userDetails?.lastName}
								</Typography>
							</Col>
						</Row>
						<Typography className="sh-user-profile-tag-name" sx={{ color: theme?.palette?.text?.disabled }} variant="subtitle1" gutterBottom>
							{ userDetails?.userType ? userDetails?.userType == config.userTypes.systemAdmin ? "system admin" : userDetails?.userType : "" }
						</Typography>
					</Row>
				</Col>
			</Row>
			<Row>
				<TabContext value={profileMainTab}>
					<TabList
						textColor={theme?.palette?.primary?.main}
						onChange={handleUserProfileOptions}
						sx={{ backgroundColor: theme?.palette?.background?.default, borderTop: `1px solid ${theme?.palette?.divider}` }}
						indicatorColor="secondary"
					>
						<Tab label="Profile" value="one" sx={{ color: theme?.palette?.text?.primary }} />
						<Tab label="Courses" value="two" sx={{ color: theme?.palette?.text?.primary }}/>
					</TabList>
					<Row>
						<TabPanel value="one" className="sh-user-profile-user-details-tabs-section" style={{ backgroundColor: theme?.palette?.background?.default }}>
							<Row className="sh-user-details-basic-info-edit-btn-con">
								<Col className="sh-user-details-basic-info-section">
									<Typography variant="h3" sx={{ color: theme?.palette?.text?.dark }}>Basic Info</Typography>
								</Col>
								<Col className="sh-user-details-edit-btn-section">
									<Button
										variant="contained"
										color="secondary"
										size={matchesXS ? "small" : "medium"}
										onClick={() => navigate(config?.routes?.editProfile)}>Edit
									</Button>
									{/* <button style={{ color: theme?.palette?.text?.dark, backgroundColor: theme?.palette?.grey[50] }} className="sh-user-profile-basic-info-edit-button" onClick={() => navigate(config?.routes?.editProfile)} >Edit</button> */}
								</Col>
							</Row>
							<Row className="sh-user-profile-tab-section-container" style={{ borderTop: `1px solid ${theme?.palette?.divider}` }}>
								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>First Name
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span>
									</Col>
									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }} >
										{userDetails?.firstName}
									</Col>
								</Typography>
								<br />
								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>Last Name
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>
									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }}>
										{userDetails?.lastName}
									</Col>
								</Typography>
								<br />

								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>Company
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>

									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }}>
										{userDetails?.userAddress?.companyName}
									</Col>
								</Typography>
								<br />

								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>Address 1
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>

									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }}>
										{userDetails?.userAddress?.addressOne}
									</Col>
								</Typography>
								<br />

								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>
									Address 2
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>

									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }}>
										{userDetails?.userAddress?.addressTwo}
									</Col>
								</Typography>
								<br />

								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>
									City
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>

									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }}>
										{userDetails?.userAddress?.cityName}
									</Col>
								</Typography>
								<br />

								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>
									Postal Code
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>

									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }}>
										{userDetails?.userAddress?.postalCode}
									</Col>
								</Typography>
								<br />

								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>
									State
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>

									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }}>
										{userDetails?.userAddress?.stateName}
									</Col>
								</Typography>
								<br />

								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>
									Country
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>

									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }}>
										{userDetails?.userAddress?.countryName}
									</Col>
								</Typography>
								<br />

								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>
									Phone Number
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>

									<Col className="sh-user-profile-data-labels-section" style={{ textTransform: "capitalize" }}>
										{userDetails?.userAddress?.phoneNo}
									</Col>
								</Typography>
								<br />
								<Typography className="sh-user-profile-tab-section-details" variant="subtitle1" sx={{ color: theme?.palette?.text?.dark }}>
									<Col className="sh-user-profile-data-labels-section sh-user-profile-data-labels-section-padding" style={{ minWidth: "8em" }}>
									Email
									</Col>
									<Col><span style={{ marginLeft: "5em" }}>:</span></Col>

									<Col className="sh-user-profile-data-labels-section">
										{userDetails?.email}
									</Col>
								</Typography>
								<br />

							</Row>
						</TabPanel>
						<TabPanel className="sh-user-profile-user-details-tabs-section" value="two">
							<TabContext value={profileSubTab}>
								<TabList
									textColor={theme?.palette?.primary?.main}
									onChange={handleUserProfileCoursesOptions}
									indicatorColor="secondary"
									// sx={{ backgroundColor: theme?.palette?.background?.default, borderTop: `1px solid ${theme?.palette?.divider}` }}
								>
									<Tab label="My Courses" value="one" sx={{ color: theme?.palette?.text?.primary }}/>
									<Tab label="Purchase History" value="three" sx={{ color: theme?.palette?.text?.primary }} />
									<Tab label="My Certificates" value="two" sx={{ color: theme?.palette?.text?.primary }} />
								</TabList>
								<TabPanel value="one">
									<div>
										<Row>
											<Col style={{ width: "100%" }} md={12} sm={12} xs={12}>
												{moduleData.length > 0 &&
													<Grid container spacing={2}>

														{moduleData && moduleData.map((course: any, index: any) => {

															return <>
																<Grid item
																	xs={12}
																	sm={6}
																	md={3}
																	lg={3}
																	xl={3}
																	key={index}
																>
																	<div
																		onMouseEnter={() => setHoveredCardIndex(index)}
																		onMouseLeave={() => setHoveredCardIndex(-1)}
																	>
																		<MainButton
																			type="scale"
																			direction="bottom"
																			style={{ position: "relative", cursor: "pointer" }}
																			offset={matchesXS ? 0 : 20}
																			scale={1.04}>

																			<div
																				style={{
																					backgroundImage   : `url(${config.appDomain}/${course.moduleIcon})`,
																					backgroundSize    : "cover",
																					backgroundPosition: "center",
																					minHeight         : "25vh",
																					width             : "100%",
																					borderRadius      : "1em",
																					border            : "1px solid #000000",
																					display           : "flex",
																					alignItems        : "flex-end",
																					position          : "relative"
																				}}
																				onClick={() => {

																					navigate(config?.routes?.video, { state: { moduleData: JSON.stringify(course) } });

																				}}
																			>
																				{
																					hoveredCardIndex === index && (!course.unlocked || course.level == 0) && <div
																						className="sh-list-modules-over-lay"
																					>
																						<Typography style={{ fontSize: "1.2em" }} variant="body1">{course.level == 0 ? "No Content" : "Unlock Now"}</Typography>
																					</div>
																				}
																				<div
																					style={{ position: "absolute", top: "2%", left: "86%" }}
																				>
																					{
																						!course.unlocked && <LockOutlinedIcon />
																					}
																				</div>
																				<div className="course-module-list-div-con">
																					<Typography className="course-desc-con" sx={{
																						// color: theme?.palette?.text?.primary
																						color: theme?.palette?.text?.primary
																					}} variant="h5">{course.moduleName}
																					</Typography>
																					<div className="sh-list-modules-lock-icon-videos-text-con">
																						<div>
																							<Typography className="course-duration-con"
																								style={{ color: theme?.palette?.text.secondary }}
																								variant="body1">
																								<span style={{ color: theme?.palette?.text.primary }}
																								>{course?.level ? course?.level : 0}</span > Videos</Typography>
																						</div>
																						<div>
																							<Rating
																								readOnly
																								defaultValue={course?.averageRating}
																								precision={0.5}
																							/>
																						</div>
																					</div>

																				</div>

																			</div>

																		</MainButton>
																	</div>
																</Grid>
															</>;

														}

														)}
													</Grid>
												}
											</Col>
										</Row>
									</div>
								</TabPanel>
								<TabPanel value="two">
									My Certificates
								</TabPanel>
								<TabPanel value="three">
									<Row className="list-level-data-table">
										<Col className="list-level-data-table-col-width">
											<DataTable
												columns={columns}
												// data={ModuleData}
												progressPending={isLoading}
												progressComponent={<div className="sh-table-progress-con"><Typography variant="h4">Loading...</Typography></div>}
												data = {purchaseData}
												customStyles={DataTableStyles}
												fixedHeader
												pagination
												paginationPerPage={10}
											/>
										</Col>
									</Row>
								</TabPanel>
							</TabContext>
						</TabPanel>
					</Row>
				</TabContext>
			</Row>
		</Container>
	);

};

export default UserProfile;