import React, { useEffect, useState } from "react";
import { Container, useTheme, Typography } from "@mui/material";
import { Row, Col, Whisper, Tooltip, Progress } from "rsuite";
import { Button } from "@mui/material";
import Back from "../../../components/UI/Back";
import config from "../../../config/appConfig";
import ApiRequest from "../../../api/ApiRequest";
import { APIUrl } from "../../../api/endPoints";
import Select from "../../../components/UI/Select";
import DropZone from "../../../components/UI/DropZone";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import AddModule from "../Module/AddModule";
import { useNavigate, useLocation } from "react-router-dom";
import AddLevel from "../Levels/AddLevel";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import axios from "axios";

const AddLesson = () => {

	const location = useLocation();
	const theme: any = useTheme();
	const navigate = useNavigate();
	const state = location.state;
	const [ lessonName, setLessonName ] = useState("");
	const [ lessonId, setLessonId ] = useState("");
	const [ lessonDescription, setLessonDescription ] = useState("");
	const [ courseOptions, setCourseOptions ] = useState([]);
	const [ selectedCourse, setSelectedCourse ] = useState<any>([]);
	const [ moduleOptions, setModuleOptions ] = useState([]);
	const [ selectedModule, setSelectedModule ] = useState<any>([]);
	const [ levelOptions, setLevelOptions ] = useState([]);
	const [ selectedLevel, setSelectedLevel ] = useState<any>([]);
	const [ uploadPostMediaName, setUploadPostMediaName ] = useState("");
	const [ isUploadedMediaValid, setIsUploadedMediaValid ] = useState(false);
	const [ isAddModuleModelOpen, setIsAddModuleModelOpen ] = useState<boolean>(false);
	const [ isAddLevelModelOpen, setIsAddLevelModelOpen ] = useState<boolean>(false);
	const [ errorMessage, setErrorMessage ] = useState<string>("");
	const [ isError, setIsError ] = useState<boolean>(false);
	const [ isEditLesson, setIsEditLesson ] = useState(false);
	const [ videoId, setVideoId ] = useState("");
	const [ isFileUploading, setIsFileUploading ] = useState(false);
	const [ isFileLoaded, setIsFileLoaded ] = useState(false);
	const [ fileDetails, setFileDetails ] = useState<any>(null);
	const [ uploadProgress, setUploadProgress ] = useState(0);
	const [ toastMessage, setToastMessage ] = useState("");
	const [ videoDuration, setVideoDuration ] = useState(0);
	
	let isDataFetched = false;

	useEffect(() => {

		if (!isDataFetched) {

			isDataFetched = true;

			if (location.hash === "#edit-lesson" && state?._id) {

				setIsEditLesson(true);
				setLessonId(state?._id);
				setLessonName(state?.lessonName);
				setLessonDescription(state?.lessonDesc);
				setVideoId(state?.lessonContent);
				setVideoDuration(state?.videoDuration || 0);

				fetchAllCourses(true);
				fetchModuleByCourseId(state?.courseId, true);
				fetchAllLevelsByModule(state.moduleId, true);

			} else {

				fetchAllCourses();

			}

		}

	}, []);

	useEffect(() => {

		if (toastMessage) {

			toast.error(toastMessage);
			setToastMessage("");
	
		}

	}, [ toastMessage ]);

	useEffect(() => {

		if (!isAddModuleModelOpen) {

			if (selectedCourse && selectedCourse.value) {

				handleCourseSelect(selectedCourse);

			}

		}

	}, [ isAddModuleModelOpen ]);

	useEffect(() => {

		if (!isAddLevelModelOpen) {

			if (selectedModule && selectedModule.value) {

				handleModuleSelect(selectedModule);

			}

		}

	}, [ isAddLevelModelOpen ]);
	

	const fetchAllCourses = (isEdit: any = false) => {

		ApiRequest("get", APIUrl.getAllCourse).then((response: any) => {

			if (response && response?.length > 0) {

				const options: any = [];
				let selectedCourse: any = {};

				response?.map((item: any) => {

					if (item._id === state?.courseId) {

						selectedCourse = { label: item.courseName, value: item._id };

					}

					options.push({ label: item.courseName, value: item._id });

				});

				setCourseOptions(options);

				if (isEdit) {

					selectedCourse && setSelectedCourse(selectedCourse);

				}

			} else {
				
				setCourseOptions([]);

			}

		}).catch((error: any) => {

			console.error("Error fetching data", error);
			setCourseOptions([]);

		});


	};

	const handleCourseSelect = async (e: any) => {

		setSelectedCourse(e);
		setSelectedModule([]);
		setSelectedLevel([]);

		ApiRequest("post", APIUrl.getAllModules, { courseId: e.value }).then((response: any) => {
			
			if (response?.data && response?.data.length > 0) {

				const module: any = [];

				response?.data?.map((item: any) => {

					module.push({ label: item.moduleName, value: item._id });

				});

				if (module && module.length) {

					setModuleOptions(module);

				} else {

					setModuleOptions([]);

				}


			} else {

				setModuleOptions([]);
				setUploadPostMediaName("");

			}


		});

	};

	const fetchModuleByCourseId = (courseId: string, isEdit: any = false) => {

		ApiRequest("post", APIUrl.getAllModules, { courseId: courseId }).then((response: any) => {

			if (response?.data && response?.data.length > 0) {

				const module: any = [];
				let selectedModule: any = {};

				response?.data?.map((item: any) => {

					if (item._id === state?.moduleId) {

						selectedModule = { label: item.moduleName, value: item._id };

					}

					module.push({ label: item.moduleName, value: item._id });

				});

				if (module && module.length) {

					setModuleOptions(module);

				} else {

					setModuleOptions([]);

				}

				if (isEdit) {

					selectedModule && setSelectedModule(selectedModule);

				}

			} else {

				return;

			}


		}).catch((error: any) => {

			console.error("Error fetching data", error);

		});
		setUploadPostMediaName("");


	};

	const handleModuleSelect = async (e: any) => {

		setSelectedModule(e);
		setLevelOptions([]);
		setSelectedLevel([]);
		fetchAllLevelsByModule(e.value);
		setUploadPostMediaName("");


	};

	const fetchAllLevelsByModule = (moduleId: string, isEdit: any = false) => {

		ApiRequest("post", APIUrl.listAllLevelByModule, { moduleId: moduleId }).then((response: any) => {

			if (response?.data && response?.data?.length > 0) {

				const levels: any = [];
				let selectedLevel: any = {};

				response?.data?.map((item: any) => {

					if (item._id === state?.levelId) {

						selectedLevel = { label: item.levelName, value: item._id };

					}

					levels.push({ label: item.levelName, value: item._id });

				});


				if (levels && levels?.length) {

					const sortedLevelOptions = levels.sort((a: any, b: any) => a.label.localeCompare(b.label));

					setLevelOptions(sortedLevelOptions);

					if (isEdit) {

						selectedLevel && setSelectedLevel(selectedLevel);

					}

				} else {

					setLevelOptions([]);

				}

			} else {

				setLevelOptions([]);

			}

		}).catch((error: any) => {

			console.error("Error fetching data", error);
			setLevelOptions([]);

		});


	};

	const handleLevelSelect = (e: any) => {

		setSelectedLevel(e);
		setUploadPostMediaName("");
		setIsUploadedMediaValid(false);

	};

	const handleCancel = () => {

		navigate(config.routes.listLessons);

	};

	const handleAddLessons = () => {

		setIsError(false);

		if (lessonId) {

			handleUpdateLessons();

			return;

		}

		if(lessonName === "") {

			setIsError(true);
			setErrorMessage("*Lesson name should not be empty");
			setToastMessage("Lesson name should not be empty");
			
			return;
		
		}

		if (!selectedCourse.value || !selectedModule.value || !selectedLevel.value || !uploadPostMediaName || !videoId) {

			setIsError(true);
			setErrorMessage("*Please Upload Video");
			setToastMessage("Please Upload Video");
			
			return;
		
		}
	
		if (!selectedCourse.value) {

			setIsError(true);
			setErrorMessage("*Course should be selected");
			setToastMessage("Course should be selected");
			
			return;
		
		}
	
		if (!selectedModule.value) {

			setIsError(true);
			setErrorMessage("*Module should be selected");
			setToastMessage("Module should be selected");
			
			return;
		
		}
	
		if (!selectedLevel.value) {

			setIsError(true);
			setErrorMessage("*Level should be selected");
			setToastMessage("Level should be selected");
			
			return;
		
		}
	
		if (!uploadPostMediaName) {

			setIsError(true);
			setErrorMessage("*Upload media name should not be empty");
			setToastMessage("Upload video file");
			
			return;
		
		}
	
		if (!videoId) {

			setIsError(true);
			setErrorMessage("*Video ID should not be empty");
			setToastMessage("Video ID should not be empty");
			
			return;
		
		}
	

		setIsError(false);
		setErrorMessage("");
		setToastMessage("");
		const payLoad = {
			"courseId"     : selectedCourse.value,
			"moduleId"     : selectedModule.value,
			"levelId"      : selectedLevel.value,
			"lessonName"   : lessonName,
			"lessonDesc"   : lessonDescription,
			"lessonContent": videoId,
			"videoDuration": videoDuration
		};


		ApiRequest("post", APIUrl.addLesson, { "lessonData": payLoad }).then(() => {

			navigate(config.routes.listLessons);

		}).catch((error: any) => {

			console.error("Error adding lesson", error);

		});


	};

	const handleUpdateLessons = () => {

		setIsError(false);

		if (lessonName === "" || !selectedCourse?.value || !selectedModule?.value || !selectedLevel?.value || !videoId) {

			setIsError(true);
			setErrorMessage("*Fill All Required Fields");

			return;

		}

		const payLoad = {
			_id          : lessonId,
			lessonName   : lessonName,
			lessonDesc   : lessonDescription,
			courseId     : selectedCourse?.value,
			moduleId     : selectedModule?.value,
			levelId      : selectedLevel?.value,
			lessonContent: videoId,
			videoDuration: videoDuration
		};

		ApiRequest("post", APIUrl.updateLesson, payLoad).then(() => {

			navigate(config.routes.listLessons);

		}).catch((error: any) => {

			console.error("Error updating lesson", error);

		});
		

	};

	const postMediaDrop = (acceptedFiles: any) => {

		setIsFileUploading(true);

		if (config?.acceptedVideoFormats.includes(acceptedFiles[0].type)) {

			setIsFileUploading(false);
			setUploadPostMediaName(acceptedFiles[0].name);
			setFileDetails(acceptedFiles[0]);
			setIsFileLoaded(true);
			setIsUploadedMediaValid(true);
			
			const videoElement = document.createElement("video");
			const url = URL.createObjectURL(acceptedFiles[0]);

			videoElement.src = url;

			videoElement.onloadedmetadata = () => {

				const duration = videoElement.duration;
				setVideoDuration(duration);
				URL.revokeObjectURL(url);
			
			};

			videoElement.load();
			

			const video = document.querySelector("video");

			if (video) {

				video.remove();

			}

		} else {

			setIsFileUploading(false);
			setIsFileLoaded(false);
			console.error("File type not supported");
			setIsUploadedMediaValid(false);
			setUploadPostMediaName("File type not supported");

		}

	};

	const handleFileUpload = () => {

		if(Object.keys(selectedCourse).length > 0 && Object.keys(selectedModule).length > 0 && Object.keys(selectedLevel).length > 0 && uploadPostMediaName != "" && fileDetails != null) {

			setIsFileUploading(true);
	
			const payload: any = {
				"lessonData": {
					"lessonName": lessonName,
					"courseId"  : selectedCourse.value,
					"moduleId"  : selectedModule.value,
					"levelId"   : selectedLevel.value,
					"videoType" : fileDetails.type,
					"fileName"  : fileDetails.name
				}
			};
	
			if (videoId) {
	
				payload.lessonData.videoId = videoId;
	
			}
					
			if (lessonId) {
						
				payload.lessonData._id = lessonId;
						
			}
	
			ApiRequest("post", APIUrl.getSignedUrlForUploadVideo, payload).then(async (response: any) => {
						
				const onUploadProgress = (progressEvent: any) => {
		
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setUploadProgress(percentCompleted);
	
				};
	
				try {
	
					const url = response?.url;
					setLessonId(response?.lessonId);
		
					await axios.put(url, fileDetails, { headers: { "Content-Type": fileDetails.type }, onUploadProgress: onUploadProgress });
		
					setIsFileUploading(false);
		
					const triggerPayload = {
						"lessonData": {
							"lessonId": response?.lessonId ? response?.lessonId : lessonId
						}
					};
		
					const res: any = await ApiRequest("post", APIUrl.uploadVideoToBunnyStream, triggerPayload);
	
					if(res.data) {
	
						setVideoId(res.data);
					
					}
	
				} catch (error) {
	
					console.error("Error uploading file", error);
	
				}
	
			}).catch((error: any) => {
	
				console.error("Error getting signed url", error);
	
			});

		}

	};
	
	return (
		<>
			<Container>
				<Row >
					<Col md={12} sm={12} xl={12} xs={12}>
						<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}>
							<Back
								props={{
									route  : config.routes.listLessons,
									state  : null,
									toolTip: "Back to list lessons",
									text   : "Back to List Lessons"
								}}
							/>
						</div>
					</Col>
					
					
				</Row>
				<Row>
						
					<Col md={12} sm={12} xl={12} xs={12} >
						<div className="admin-dash-board-back-title-con" style={{ marginBottom: theme.spacing(2) }}>
							<Typography variant="h2" >
								{isEditLesson ? "Edit Lesson" : "Add Lesson"}
							</Typography>
						</div>
					</Col>

				</Row>
				<Row>
					<Col className="sh-add-lesson-enter-lesson-col">
						<Row className="sh-addlesson-enter-lesson-name sh-addlesson-marginTop">
							<Col className="sh-add-lesson-typography-label">
								<div className="type-con-div">
									<Typography
										style={{ color: theme?.palette?.text.primary }}
										className="sh-enter-lesson-align-center">Enter Lesson Name <span style={{ color: theme?.palette?.error?.main }} className="sh-ashkey-margin-top">*</span></Typography>
								</div>
							</Col>
							<Col className="sh-add-lesson-input-label">
								<Form.Control
									id="outlined-basic"
									color="secondary"
									className="sh-add-module-name-text-field sh-user-basic-edit-label-div custom-placeholder"
									placeholder="Enter the lesson name"
									value={lessonName}
									onChange={e => setLessonName(e.target.value)}
									onBlur={(e: any) => { setLessonName(e.target.value); }}
									style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
								/>
							</Col>
							<style>
								{`
								.custom-placeholder::placeholder 
								{
									color:${theme.palette.text.primary} !important;
									opacity: 1;
									font-size: 14px;
								}
								`}
							</style>
							<Col className="sh-addlesson-addcircle" ></Col>
						</Row>
						<Row className="sh-addlesson-enter-lesson-name">
							<Col className="sh-add-lesson-typography-label" >
								<div className="type-con-div">
									<Typography style={{ color: theme?.palette?.text.primary }}>
										Enter the Lesson Description
									</Typography>
								</div>
							</Col>
							<Col className="sh-add-lesson-input-label">
								<Form.Control as="textarea" rows={5} placeholder="Enter Lesson Description"
									className="sh-user-basic-edit-label-div sh-add-module-font-size custom-placeholder"
									value={lessonDescription}
									onChange={e => setLessonDescription(e.target.value)}
									style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
									onFocus={(e: any) => {

										const focusColor: string = theme.palette.secondary.main;

										e.target.style.borderColor = focusColor;
										e.target.style.borderWidth = "1.5px";
										e.target.style.boxShadow = `rgba(0, 0, 0, 0.16) 0px 1px 0px, ${focusColor} 0px 0px 0px 0.3px`;

									}}
									onBlur={(e: any) => {

										e.target.style.borderColor = "";
										e.target.style.boxShadow = "";

									}}
								/>
							</Col>
							<Col className="sh-addlesson-addcircle" ></Col>
						</Row>
						<Row className="sh-addlesson-enter-lesson-name">
							<Col className="sh-add-lesson-typography-label">
								<div className="type-con-div">
									<Typography style={{ color: theme?.palette?.text.primary }}>
										Select Course <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span>
									</Typography>
								</div>
							</Col>
							<Col className="sh-add-lesson-input-label">
								<div className="type-con-div">
									<Whisper placement="top"
										speaker={!selectedCourse.value ? <Tooltip>Please Select Course</Tooltip> : <div></div>}>
										<div className="sh-add-lesson-select-course"
											// style={{ display: "flex", alignItems: "center", width: "100%" }}
										>
											<Select
												className="sh-add-lesson-select-input-div "
												style={{ color: theme.palette.text.primary }}
												options={courseOptions}
												value={selectedCourse || null}
												handleChange={(e: any) => handleCourseSelect(e)}
												isDisabled={false}
												placeholder="Select the Course"
												width="100%"
											/>
										</div>
									</Whisper>
								</div>
							</Col>
							<Col className="sh-addlesson-addcircle" ></Col>

						</Row>
						<Row className="sh-addlesson-enter-lesson-name">
							<Col className="sh-add-lesson-typography-label">
								<div className="type-con-div">
									<Typography style={{ color: theme?.palette?.text.primary }}>
										Select Module <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span>
									</Typography>
								</div>
							</Col>
							<Col className="sh-add-lesson-input-label">
								<div className="type-con-div">
									<Whisper placement="top" speaker={!selectedModule.value ? <Tooltip>Please Select Module</Tooltip> : <div></div>}>
										<div
										>
											<Select
												className="sh-add-lesson-select-input-div"
												options={moduleOptions}
												value={selectedModule}
												handleChange={handleModuleSelect}
												isDisabled={Object.keys(selectedCourse).length === 0}
												placeholder={"Select the Module"}
											/>
										</div>
									</Whisper>
								</div>
							</Col>
							<Col className="sh-addlesson-addcircle" >
								<AddCircleOutlinedIcon
									onClick={() => setIsAddModuleModelOpen(true)}
									className="sh-addcircle-outline-con sh-add-lesson-addcircle-pointer"
									style={{ color: theme.palette.text.primary }} />
							</Col>

						</Row>
						<Row className="sh-addlesson-enter-lesson-name">
							<Col className="sh-add-lesson-typography-label">
								<div className="type-con-div">
									<Typography style={{ color: theme?.palette?.text.primary }} >
										Select Level <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span>
									</Typography>
								</div>
							</Col>
							<Col className="sh-add-lesson-input-label">
								<div className="type-con-div">
									<Whisper placement="top" speaker={!selectedLevel.value ? <Tooltip>Please Select Level</Tooltip> : <div></div>}>
										<div
										>
											<Select
												className="sh-add-lesson-select-input-div"
												options={levelOptions}
												value={selectedLevel}
												handleChange={handleLevelSelect}
												maxMenuHeight="80px"
												isDisabled={Object.keys(selectedModule).length === 0}
												placeholder={"Select the Level"}
											/>
										</div>
									</Whisper>
								</div>
							</Col>
							<Col className="sh-addlesson-addcircle" >
								<AddCircleOutlinedIcon
									onClick={() => setIsAddLevelModelOpen(true)}
									className="sh-addcircle-outline-con "
									style={{ color: theme.palette.text.primary }} />
							</Col>
						</Row>
					</Col>
					<Col className="sh-add-lesson-video " >
						<Row className="sh-add-lesson-uploadvideo sh-add-lesson-pad">
							<Row style={{ marginBottom: "1em" }} > <Typography variant="h5">Upload Lesson Video <span style={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop">*</span></Typography> </Row>
							<Row className="box-shadow sh-add-lession-admin-dropzone-file-upload-title-con" style={{ background: theme.palette.background.default }}>
								<Row className="sh-add-lesson-dropzone">
									<DropZone
										dropMedia={postMediaDrop}
										isUploadedMediaValid={isUploadedMediaValid}
										uploadPostMediaName={uploadPostMediaName}
										acceptFileType={config?.acceptedVideoFormats}
										accept={config.acceptedFilesVideos}
										disabled={!(Object.keys(selectedCourse).length > 0) || !(Object.keys(selectedModule).length > 0 ) || !(Object.keys(selectedLevel).length > 0)}
										disabledMessage={"Please select the level"}
										message={isFileUploading ? "Uploading File" : isFileLoaded ? "Please Click Upload" : "Please Upload File"}
									/>
								</Row>
								{
									videoId && <Row>
										<div style={{
											color: theme?.palette?.secondary.main
											
										}} className="sh-add-lesson-video-id">
										Video Id - {videoId}
			
										</div>
									</Row>
								}
								<Row>
									{
										fileDetails && isFileUploading &&
										<Progress.Line percent={uploadProgress} status={
											uploadProgress === 100 ? "success" : "active"
										}
										className="sh-progress-bar-margin-top" />
									}
								</Row>
								
								<Row className="sh-add-lesson-upload-button">
									<Button
										style={{
											backgroundColor: !(Object.keys(selectedCourse).length > 0 || Object.keys(selectedModule).length > 0 || Object.keys(selectedLevel).length > 0 || uploadPostMediaName || !fileDetails) ?
												theme.palette.grey[400] : theme?.palette?.secondary.main,
											color : theme.palette.text.primary,
											cursor: Object.keys(selectedCourse).length > 0 && Object.keys(selectedModule).length > 0 && Object.keys(selectedLevel).length > 0 && uploadPostMediaName != "" && fileDetails != null ? "pointer" : "not-allowed"
										}}
										// className={!(Object.keys(selectedCourse).length > 0 || Object.keys(selectedModule).length > 0 || Object.keys(selectedLevel).length > 0 || uploadPostMediaName || !fileDetails) ? "" : "sh-upload-disabled"}
										onClick={() => handleFileUpload()}
										disabled={!(Object.keys(selectedCourse).length > 0 || Object.keys(selectedModule).length > 0 || Object.keys(selectedLevel).length > 0 || uploadPostMediaName || !fileDetails)}
									>
										Upload</Button>
								</Row>
							</Row>
						</Row>
					</Col>
				</Row>
				<Row className="sh-ad-lesson-Rbutton-cancel-add">
					<Row
						className="sh-add-lesson-cancel"
					>
						<Button
							style={{
								borderColor: theme.palette.secondary.main,
								color      : theme.palette.text.primary
							}}
							className="sh-module-intro-add-btn sh-add-lesson-cancel-button sh-add-lesson-cancel-border" onClick={handleCancel}>
							Cancel
						</Button>
						<Button
							style={isFileUploading || !isUploadedMediaValid || !videoId || !lessonName || Object.keys(selectedCourse).length === 0 || Object.keys(selectedModule).length === 0 || Object.keys(selectedLevel).length === 0 ? { backgroundColor: theme?.palette?.secondary?.main, color: theme.palette.text.primary } : { backgroundColor: theme?.palette?.secondary?.main, color: theme.palette.text.primary }}
							className="sh-module-intro-add-btn"
							onClick={() => isEditLesson ? handleUpdateLessons() : handleAddLessons()}>
							{ isEditLesson ? "Update" : "Add" }
						</Button>

					</Row>

				</Row>
				<Row
					className="sh-add-lesson-cancel"
				>
					{
						isError &&
							<div className="">
								<Typography variant="subtitle1" sx={{ color: theme?.palette?.error?.main }} className="sh-add-lesson-marginTop"> {errorMessage} </Typography>
							</div>
					}
				</Row>
				<AddModule
					isAddModuleModelOpen={isAddModuleModelOpen}
					setIsAddModuleModelOpen={setIsAddModuleModelOpen}
					selectedCourse={selectedCourse}
					courseOptionsList={courseOptions}
				/>
				<AddLevel
					isAddLevelModelOpen={isAddLevelModelOpen}
					setIsAddLevelModelOpen={setIsAddLevelModelOpen}
					selectedCourse={selectedCourse}
					selectedModule={selectedModule}
					courseOptionsList={courseOptions}
				/>

			</Container>
		</>
	);

};

export default AddLesson;
