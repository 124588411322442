import shaka from "shaka-player/dist/shaka-player.ui.js";
import React, { useState, useRef, useEffect, useImperativeHandle, ForwardedRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "shaka-player/dist/controls.css";

interface ShakaPlayerProps {
  src: string;
  config?: shaka.extern.PlayerConfiguration;
  chromeless?: boolean;
  className?: string;
  player?: shaka.Player;
  setPlayer?: (player: shaka.Player) => void;
	watchDuration?: number;
	videoRef: any;
	updateUserProgressAndNavigate?: (duration: number) => void;
	isVideoLoading?: boolean;
	setIsVideoLoading?: (loading: boolean) => void;
  [key: string]: any;
}

const ShakaPlayer = (
	{ src, config, chromeless, className, player, setPlayer, watchDuration, videoRef, updateUserProgressAndNavigate, isVideoLoading, setIsVideoLoading, ...rest }: ShakaPlayerProps,
	ref: ForwardedRef<any>
) => {

	const uiContainerRef: any = useRef<HTMLDivElement>(null);
	// const videoRef: any = useRef<HTMLVideoElement>(null);

	const [ ui, setUi ] = useState<shaka.ui.Overlay | null>(null);
	const [ currentTime, setCurrentTime ] = useState(0);
		
	useEffect(() => {

		if (videoRef?.current?.readyState > 2) {

			setIsVideoLoading?.(false);
		
		}

	}, [ currentTime ]);

	useEffect(() => {

		let playerInstance: shaka.Player; let uiInstance: shaka.ui.Overlay | null;

		try {
	
			const onErrorEvent = (event: Event) => {

				setIsVideoLoading?.(false);
	
				console.error("Error code", (event as any).detail.code, "object", (event as any).detail);
			
			};
	
			playerInstance = new shaka.Player(videoRef?.current);
			setPlayer?.(playerInstance);
	
			playerInstance.addEventListener("error", onErrorEvent);
	
			 // Buffering event listener to handle the loading spinner
			 const onBuffering = (event: any) => {
	
				const isBuffering = event.buffering;

				setIsVideoLoading?.(!isBuffering);
			  
			};
			  
			  playerInstance.addEventListener("buffering", onBuffering);

			if (!chromeless) {
	
				uiInstance = new shaka.ui.Overlay(playerInstance, uiContainerRef?.current, videoRef?.current);
				uiInstance.getControls();
	
				const uiConfig = {
					overflowMenuButtons: [
						"captions", "quality", "language", "playback_rate"
					],
					controlPanelElements: [
						"mute", "volume", "time_and_duration", "spacer", "fullscreen", "overflow_menu"
					],
					seekBarColors: {
						base    : "rgba(255, 255, 255, 0.3)",
						buffered: "rgba(255, 255, 255, 0.54)",
						played  : "rgb(255, 255, 255)"
					},
					addSeekBar      : true,
					addBigPlayButton: true
				};
	
				uiInstance.configure(uiConfig);
	
				setUi(uiInstance);
			
			}

		} catch (error) {

			console.error("Error mounting ShakaPlayer", error);

			setIsVideoLoading?.(false);

		}


		return () => {

			playerInstance.destroy();
			uiInstance?.destroy();
		
		};
	
	}, []);

	useEffect(() => {

		if (player && config) {

			player.configure(config);
		
		}
	
	}, [ player, config ]);

	useEffect(() => {

		if (player && src) {

			player?.load(src);
		
		}
	
	}, [ player, src ]);

	useEffect(() => {

		if (player && src) {

			player?.load(src).then(() => {

				// Set the start time if watchDuration is available
				if (watchDuration) {

					if (videoRef.current && videoRef.current.duration && videoRef.current.duration !== watchDuration) {

						if (Math.floor(videoRef.current.duration) !== Math.floor(watchDuration)) {
						
							videoRef.current.currentTime = watchDuration;

						}
					
					}

				
				}
			
			});
		
		}
	
	}, [
		player, src, watchDuration
	]);

	let videoUpdated = false;

	useEffect(() => {

		if (videoRef.current) {

			// Add event listener to update current time
			videoRef.current.addEventListener("timeupdate", () => {

				if (videoRef.current && !videoRef.current.paused && !videoRef.current.ended && videoRef.current.readyState > 2) {
					
					setCurrentTime(videoRef.current.currentTime);
					
				} else {
					
					if (videoRef.current && videoRef?.current?.ended) {
	
						if (videoRef.current.currentTime === videoRef.current.duration) {

							
							if (!videoUpdated) {
								
								videoUpdated = true;
								setIsVideoLoading?.(false);
								updateUserProgressAndNavigate?.(videoRef.current.duration);
							
							}

						}

					}

				}
			
			});
		
		}
	
	}, []);

	useImperativeHandle(ref, () => ({
		get player() {

			return player;
		
		},
		get ui() {

			return ui;
		
		},
		get videoElement() {

			return videoRef.current;
		
		},

		getCurrentTime() {
		
			// Added method to get current time

			return currentTime;
		
		}

	}), [
		player, ui, currentTime
	]);

	return <>
		{ isVideoLoading && !videoRef && !videoRef?.current?.src ? <>
			<div className="video-player-main-con">
				<CircularProgress />
			</div></> : <div ref={uiContainerRef} className={className}>
			<video
				ref={videoRef}
				style={{
					maxWidth: "100%",
					width   : "100%"
				}}
				{...rest}
			/>
		</div>
		}
	</>
		
	;

};

export default React.forwardRef(ShakaPlayer);
